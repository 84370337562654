import { useState } from 'react';
import { Menu, MenuItem, styled } from '@mui/material';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { createInvestorEditRoute } from 'adminConstants/routes';
import { useGetPermissions } from 'hooks/ui/useGetPermissions';
import { AccountType } from 'further-types/investor';
import { useInvestor } from 'hooks/data/investor/useInvestor';
import downloadFile from 'utils/downloadFile';
import { useGetRole } from 'hooks/ui/useGetRole';
import LaunchPortalButton from './LaunchPortalButton';
import { ArrowDropDown } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import IndividualDetails from './IndividualDetails';
import LegalEntityDetails from './LegalEntityDetails';
import Spacing from 'components/Spacing';
import PageHeader from 'components/PageHeader';
import Heading from 'components/Heading';
import ResponsiveActions from 'components/PageHeader/ResponsiveActions';
import Button from 'components/Button';

const HiddenDatePicker = styled(DatePicker)({
  visibility: 'hidden',
  width: 0,
});

type Props = any;

const InvestorDetails: React.FC<Props> = ({ investorDetail, id }) => {
  const [canEdit] = useGetPermissions(['edit:investor']);
  const { isFirmManager } = useGetRole();

  const onExportInvestorStatement = (data, valuationDate) => {
    const dateSuffix = valuationDate
      ? format(new Date(valuationDate), 'dd MM yyyy')
      : format(new Date(), 'dd MM yyyy');

    downloadFile(
      data,
      `${investorDetail?.fullName} - ${dateSuffix}.${
        isFirmManager ? 'xlsx' : 'zip'
      }`,
    );
  };

  const { exportInvestorStatement, isExportInvestorStatementLoading } =
    useInvestor(id, {
      onExportInvestorStatement,
    });

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  return (
    <Spacing>
      <PageHeader>
        <Heading variant="h2" noMargin>
          Investor details
        </Heading>
        <ResponsiveActions>
          {canEdit && investorDetail?.isEditAllow && (
            <Link to={createInvestorEditRoute(id)}>
              <Button size="small" variant="contained" color="primary">
                Edit investor
              </Button>
            </Link>
          )}
          {!!investorDetail?.investmentsCount && (
            <div>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                color="primary"
                variant="contained"
                size="small"
                endIcon={<ArrowDropDown />}
                //@ts-expect-error
                onClick={(event) => setMenuAnchorEl(event.currentTarget)}
                loading={isExportInvestorStatementLoading}
              >
                Download investor statement
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={menuAnchorEl}
                keepMounted
                open={Boolean(menuAnchorEl)}
                onClose={() => setMenuAnchorEl(null)}
              >
                <MenuItem
                  onClick={() => {
                    exportInvestorStatement(undefined);
                    setMenuAnchorEl(null);
                  }}
                  key="current-valuation"
                >
                  Download current valuation
                </MenuItem>
                <MenuItem
                  onClick={() => setIsDatePickerOpen(true)}
                  key="earlier-valuation"
                >
                  Download earlier valuation
                </MenuItem>
              </Menu>

              <HiddenDatePicker
                name="date"
                open={isDatePickerOpen}
                onChange={(date) => {
                  //@ts-expect-error
                  exportInvestorStatement(date);
                }}
                onClose={() => {
                  setIsDatePickerOpen(false);
                  setMenuAnchorEl(null);
                }}
                slotProps={{
                  actionBar: { actions: ['cancel'] },
                }}
                value={undefined}
                disableFuture
              />
            </div>
          )}
          <LaunchPortalButton investorId={id} />
        </ResponsiveActions>
      </PageHeader>
      {investorDetail.accountType === AccountType.LegalEntity ? (
        <LegalEntityDetails investorDetail={investorDetail} />
      ) : (
        <IndividualDetails investorDetail={investorDetail} />
      )}
    </Spacing>
  );
};

export default InvestorDetails;

import { useState } from 'react';

export const useLoadMoreAndHideContent = <T>(
  record: Array<T> = [],
  numberOfRecordDefault = 10,
) => {
  const [numberOfRecords, setNumberOfRecords] = useState(numberOfRecordDefault);
  const displayedContent = record.slice(0, numberOfRecords);

  const onToggle = () => {
    setNumberOfRecords(
      displayedContent.length === numberOfRecordDefault
        ? record.length
        : numberOfRecordDefault,
    );
  };

  return {
    onToggle,
    displayedContent,
  };
};

export type LoadMoreAndHideContent = ReturnType<
  typeof useLoadMoreAndHideContent
>;

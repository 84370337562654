export enum FileType {
  Excel = 'application/vnd.ms-excel',
  ExcelAlt = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  CSV = 'text/csv',
  PDF = 'application/pdf',
  JPGImage = 'image/jpeg',
  PNGImage = 'image/png',
  SVGImage = 'image/svg+xml',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
}

const fileTypeExtensions = {
  [FileType.Excel]: 'xls',
  [FileType.ExcelAlt]: 'xls',
  [FileType.CSV]: 'csv',
  [FileType.PDF]: 'pdf',
  [FileType.JPGImage]: 'jpg',
  [FileType.PNGImage]: 'png',
  [FileType.SVGImage]: 'svg',
  [FileType.DOCX]: 'docx',
};

export const getExtensionByType = (fileType: FileType) =>
  fileTypeExtensions[fileType];

export const fetchFile = async (fileUrl: string) => {
  try {
    return await (await fetch(new Request(fileUrl))).arrayBuffer();
  } catch {
    return null;
  }
};

export function convertFileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.onerror = () => {
      reject(new Error('Failed to convert file to base64'));
    };
  });
}

import React, { ComponentType, MouseEventHandler, ReactNode } from 'react';
import {
  Box,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  styled,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/ReportProblemOutlined';
import IconButton from '@mui/material/IconButton';
import Button from 'components/Button';
import Spacing from 'components/Spacing';
import Heading from 'components/Heading';
import { Row } from 'components/Layout';

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  color: theme.palette.grey[500],
  right: theme.spacing(1),
  top: theme.spacing(1),
}));

const ContentWrapper = styled(DialogContent, {
  shouldForwardProp: (prop) => prop !== 'noPaddingBottom',
})<{ noPaddingBottom?: boolean }>(({ noPaddingBottom }) => ({
  paddingBottom: noPaddingBottom ? 0 : undefined,
}));

const Content = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'centered',
})<{ centered?: boolean }>(({ theme, centered }) => ({
  fontSize: theme.fontSizes?.lg,
  color: theme.palette.text.secondary,
  letterSpacing: 0,
  textAlign: centered ? 'center' : 'left',
}));

const DialogIcon = styled(WarningIcon, {
  shouldForwardProp: (prop) => prop !== 'centered',
})<{ centered?: boolean }>(({ theme, centered }) => ({
  color: theme.palette.warning.main,
  fontSize: centered ? 65 : 36,
  margin: centered ? '0 auto' : 0,
  display: 'block',
}));

const Actions = styled(DialogActions, {
  shouldForwardProp: (prop) =>
    !['alignActionsVertically', 'centered'].includes(prop.toString()),
})<{ alignActionsVertically?: boolean; centered?: boolean }>(
  ({ theme, alignActionsVertically, centered }) => ({
    justifyContent: centered ? 'center' : 'flex-end',
    padding: theme.spacing(0, 6, 6),
    gap: theme.spacing(2),
    flexDirection: alignActionsVertically ? 'column' : 'row',
    marginTop: theme.spacing(2),
  }),
);

type Props = Pick<DialogProps, 'open'> & {
  size?: 'sm' | 'md';
  onConfirm?: MouseEventHandler;
  onAlternateConfirm?: MouseEventHandler;
  onClose?: MouseEventHandler;
  title?: string;
  content?: ReactNode;
  btnLabels?: {
    cancel: string | boolean;
    confirm: string | boolean;
    alternateConfirm?: string | boolean;
  };
  icon?: ComponentType;
  cancelBtnProps?: ButtonProps & { whiteSpace?: 'nowrap' | 'normal' };
  confirmBtnProps?: ButtonProps & {
    isFetching?: boolean;
    whiteSpace?: 'nowrap' | 'normal';
  };
  alternateConfirmBtnProps?: ButtonProps & {
    whiteSpace?: 'nowrap' | 'normal';
  };
  confirmBtnComponent?: ReactNode;
  alignActionsVertically?: boolean;
  centeredContent?: boolean;
};

const AlertDialog: React.FC<Props> = ({
  onClose,
  onConfirm,
  onAlternateConfirm,
  content,
  icon,
  cancelBtnProps,
  confirmBtnProps,
  alternateConfirmBtnProps,
  confirmBtnComponent,
  open = false,
  title = 'Confirm Delete',
  btnLabels = {
    cancel: 'Cancel',
    confirm: 'Confirm',
    alternateConfirm: false,
  },
  size = 'md',
  alignActionsVertically = false,
  centeredContent = true,
}) => {
  if (!open) return null;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      maxWidth={size}
      fullWidth
    >
      <CloseButton aria-label="close" onClick={onClose} size="large">
        <CloseIcon />
      </CloseButton>

      <ContentWrapper noPaddingBottom={!centeredContent}>
        <Spacing>
          {centeredContent && (
            <DialogIcon as={icon ? icon : WarningIcon} centered />
          )}
          <DialogContentText component="div">
            <Heading variant="h1" centered={centeredContent} noMargin>
              <Row spacing="xs" centered>
                {!centeredContent && (
                  <DialogIcon centered={false} as={icon ? icon : WarningIcon} />
                )}
                {title}
              </Row>
            </Heading>
          </DialogContentText>
          <Content centered={centeredContent}>{content}</Content>
        </Spacing>
      </ContentWrapper>
      <Actions
        alignActionsVertically={alignActionsVertically}
        centered={centeredContent}
      >
        <Button onClick={onClose} variant="outlined" {...cancelBtnProps}>
          {btnLabels.cancel}
        </Button>
        {btnLabels.alternateConfirm && (
          <Button
            onClick={onAlternateConfirm}
            variant="outlined"
            {...alternateConfirmBtnProps}
          >
            {btnLabels.alternateConfirm}
          </Button>
        )}
        {btnLabels.confirm && !confirmBtnComponent ? (
          <>
            <Button
              onClick={onConfirm}
              color="primary"
              variant="contained"
              loading={confirmBtnProps?.isFetching}
              {...confirmBtnProps}
            >
              {btnLabels.confirm}
            </Button>
          </>
        ) : null}
        {confirmBtnComponent ? confirmBtnComponent : null}
      </Actions>
    </Dialog>
  );
};

export default AlertDialog;

import { FC, ReactNode } from 'react';
import { FormControlLabel, Radio, styled } from '@mui/material';
import Grid from '@mui/material/Grid2';

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  '& .MuiFormControlLabel-label': {
    fontSize: 13,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.secondary,
  },
}));

interface RadioWithInputProps {
  value: string;
  label: string | ReactNode;
  disabled?: boolean;
  inputComponent: ReactNode;
  onRadioClick?: () => void;
}

const RadioWithInput: FC<RadioWithInputProps> = ({
  value,
  label,
  disabled,
  inputComponent,
  onRadioClick,
}) => {
  return (
    <Grid container size={{ xs: 12 }} spacing={{ xs: 6 }}>
      <Grid size={{ xs: 12, md: 4 }}>
        <StyledFormControlLabel
          value={value}
          control={<Radio />}
          label={label}
          disabled={disabled}
          onClick={onRadioClick}
        />
      </Grid>
      <Grid size={{ xs: 12, md: 1 }} />
      <Grid size={{ xs: 12, md: 7 }}>{inputComponent}</Grid>
    </Grid>
  );
};

export default RadioWithInput;

import { InputAdornment } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import FieldRow from 'components/FormElements/FieldRow';
import TextField from 'components/FormElements/AppTextInput';
import { baseDomain } from 'adminConstants/redirectConstant';
import ColourPicker from './ColourPicker';
import Spacing from 'components/Spacing';
import { InvestmentFirmFormData } from '../schema';
import CkEditor from 'components/CkEditor';

const WhitelabellingSettings = () => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext<InvestmentFirmFormData>();
  return (
    <Spacing>
      <FieldRow title="Background (primary) colour" centerTitle>
        <Controller
          name="whiteLabelConfig.bgColor"
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <ColourPicker {...rest} ref={ref} />
          )}
        />
      </FieldRow>

      <FieldRow title="Button and text colour" centerTitle>
        <Controller
          name="whiteLabelConfig.primaryColor"
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <ColourPicker {...rest} ref={ref} />
          )}
        />
      </FieldRow>
      <FieldRow title="Button and text hover colour" centerTitle>
        <Controller
          name="whiteLabelConfig.primaryHoverColor"
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <ColourPicker {...rest} ref={ref} />
          )}
        />
      </FieldRow>
      <FieldRow title="Menu icon colour" centerTitle>
        <Controller
          name="whiteLabelConfig.secondaryColor"
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <ColourPicker {...rest} ref={ref} />
          )}
        />
      </FieldRow>
      <FieldRow
        title="Onboarding side panel text"
        centerTitle
        tooltipText="All investors and advisers will see an informational side panel during your fund's subscription flow. This side panel contains your contact details as well as three optional bullets (with a tick icon next to each bullet). You may use these bullets to provide advice, fund benefits or other brief information if you wish."
      >
        <Controller
          name={`contactDetails.keyChecks.0`}
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <TextField
              {...rest}
              inputRef={ref}
              placeholder="First bullet point, e.g. Subscription specialists since 2002"
              fullWidth
            />
          )}
        />
      </FieldRow>
      <FieldRow title=" ">
        <Controller
          name={`contactDetails.keyChecks.1`}
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <TextField
              {...rest}
              inputRef={ref}
              placeholder="Second bullet point, e.g. Managing over £2bn of investors' capital"
              fullWidth
            />
          )}
        />
      </FieldRow>
      <FieldRow title=" ">
        <Controller
          name={`contactDetails.keyChecks.2`}
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <TextField
              {...rest}
              inputRef={ref}
              placeholder="Third bullet point, e.g. Supporting over 200 high growth companies"
              fullWidth
            />
          )}
        />
      </FieldRow>
      <FieldRow title="Contact days and times" centerTitle>
        <Controller
          name="contactDetails.phoneHours"
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <TextField
              {...rest}
              inputRef={ref}
              placeholder="When investors can contact you, e.g. Mon-Fri 9:00-17:00"
              fullWidth
              error={!!errors.contactDetails?.phoneHours}
              helperText={errors.contactDetails?.phoneHours?.message}
            />
          )}
        />
      </FieldRow>
      <FieldRow title="Investor service phone number" centerTitle>
        <Controller
          name="contactDetails.phoneNo"
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <TextField
              {...rest}
              inputRef={ref}
              placeholder="Enter investor service phone number"
              fullWidth
              error={!!errors.contactDetails?.phoneNo}
              helperText={errors.contactDetails?.phoneNo?.message}
            />
          )}
        />
      </FieldRow>
      <FieldRow
        title="Verified reply-to email address*"
        centerTitle
        tooltipText="Please ensure this email has been verified by Further’s team before adding it here."
      >
        <Controller
          name="whiteLabelConfig.clientServiceEmail"
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <TextField
              {...rest}
              inputRef={ref}
              placeholder="What should the reply-to address be on system emails?"
              fullWidth
              error={!!errors.whiteLabelConfig?.clientServiceEmail}
              helperText={errors.whiteLabelConfig?.clientServiceEmail?.message}
            />
          )}
        />
      </FieldRow>
      <FieldRow title="Investor service email address*" centerTitle>
        <Controller
          name="contactDetails.email"
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <TextField
              {...rest}
              inputRef={ref}
              placeholder="What email address should investors use to contact you?"
              fullWidth
              error={!!errors.contactDetails?.email}
              helperText={errors.contactDetails?.email?.message}
            />
          )}
        />
      </FieldRow>
      <FieldRow title="Subdomain*" centerTitle>
        <Controller
          name="domain"
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <TextField
              {...rest}
              inputRef={ref}
              placeholder="Enter text here"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">.{baseDomain}</InputAdornment>
                ),
              }}
              fullWidth
              error={!!errors.domain}
              helperText={errors.domain?.message}
            />
          )}
        />
      </FieldRow>
      <FieldRow title="Email footer text">
        <Controller
          name="whiteLabelConfig.emailFooterText"
          control={control}
          render={({ field: { value, name }, formState: { isSubmitting } }) => (
            <CkEditor
              disabled={isSubmitting}
              placeholder="Enter any footer text you need to include here, such as your limited company name, number, address or other legal information."
              onBlur={(value: string) => {
                setValue(name, value);
              }}
              value={value}
              onChange={undefined}
              extraLine
              showLists={false}
            />
          )}
        />
      </FieldRow>
      <FieldRow title="Portal footer text">
        <Controller
          name="whiteLabelConfig.portalFooterText"
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <TextField
              {...rest}
              inputRef={ref}
              placeholder="Enter any footer text you need to include here, such as your limited company name, number, address or other legal information."
              fullWidth
              multiline
              minRows={4}
            />
          )}
        />
      </FieldRow>
    </Spacing>
  );
};

export default WhitelabellingSettings;

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from 'lib/trpcClient';
import Grid from '@mui/material/Grid2';
import GridContainer from 'components/GridContainer';
import PageContainer from 'components/PageContainer';
import PageContent from 'components/PageContent';
import { Step, StepLabel, Stepper } from '@mui/material';
import Spacing from 'components/Spacing';
import { useLayoutContext } from 'contexts/LayoutContext';
import StepAddAndAllocate from './StepAddAndAllocate';
import StepReviewAndConfirm from './StepReviewAndConfirm';
import FullPageSuccess from 'components/FullPageSuccess';
import { INCOME_OR_EXPENSE_ADD } from 'adminConstants/routes';
import { Allocations } from 'components/AllocationsTable/types';
import { AllocationRule } from 'further-types/lp/allocations';
import FormWrapper from './FormWrapper';
import { ActionRef } from 'further-types/lp/action';

enum Steps {
  AddAndAllocate,
  ReviewAndConfirm,
  Success,
}

const stepLabels = {
  [Steps.AddAndAllocate]: 'Add and allocate',
  [Steps.ReviewAndConfirm]: 'Review and confirm',
};

const pageTitle = 'Income and expenses: Process income and expenses';

const AddEditIncomeOrExpense: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  const [activeStep, setActiveStep] = useState<Steps>(Steps.AddAndAllocate);
  const { selectedVehicleId } = useLayoutContext();
  const [allocationValues, setAllocationValues] = useState<{
    allocations: Array<Allocations>;
    allocationRule: AllocationRule;
  }>({
    allocations: [],
    allocationRule: AllocationRule.ByCommitmentAndClosingDate,
  });

  const contacts = api.contact.list.useQuery();

  const { data: actions, isLoading: actionsLoading } =
    api.action.listActions.useQuery({
      vehicleId: selectedVehicleId ?? '',
      unreconciledOnly: false,
      page: 1,
      rowsPerPage: Infinity,
      order: 'desc',
      orderBy: 'ledgerDate',
    });

  //TODO: This is a temporary filter to get the investment actions.
  const investmentActions = actions?.results.filter(
    (action) => action.actionRef === ActionRef.Investment,
  );

  const selectedVehicle = api.vehicle.getById.useQuery({
    vehicleId: selectedVehicleId ?? '',
  });
  const allVehicles = api.vehicle.listAllVehicles.useQuery({
    order: 'desc',
    orderBy: 'vehicleName',
  });
  const { data: incomeOrExpense, isLoading: incomeOrExpenseLoading } =
    api.incomeAndExpenses.get.useQuery(
      { vehicleId: selectedVehicleId ?? '', id: id ?? '' },
      { enabled: !!id },
    );

  useEffect(() => {
    if (incomeOrExpense) {
      setAllocationValues({
        allocations: incomeOrExpense.allocations,
        allocationRule:
          incomeOrExpense.allocationRule ??
          AllocationRule.ByCommitmentAndClosingDate,
      });
    }
  }, [incomeOrExpense]);

  const breadcrumbs = [
    { label: 'Dashboard' },
    { label: 'Firms', link: '/firm' },
    {
      label: id ? 'Update Income or Expense' : 'Add Income or Expense',
      link: '/',
      isActive: true,
    },
  ];

  if (activeStep === Steps.Success) {
    return (
      <PageContainer heading={pageTitle} breadcrumbs={breadcrumbs}>
        <FullPageSuccess
          title="Your transaction has been processed successfully"
          additionalText="The General Ledger has been updated and this transaction will be available to add into the next capital call/distribution (if applicable)."
          proceedText="Process capital call/distribution"
          //TODO: Add link to process capital call/distribution when it's implemented
          proceedLink="#"
          secondaryText="Add another income/expense"
          secondaryLink={INCOME_OR_EXPENSE_ADD}
        />
      </PageContainer>
    );
  }

  const loading =
    selectedVehicle.isLoading ||
    !selectedVehicle.data ||
    allVehicles.isLoading ||
    contacts.isLoading ||
    actionsLoading ||
    incomeOrExpenseLoading;

  return (
    <PageContainer
      heading={pageTitle}
      loading={loading}
      breadcrumbs={breadcrumbs}
    >
      <PageContent>
        <Spacing size="lg">
          <GridContainer>
            <Grid size={{ sm: 12, md: 12, lg: 8 }}>
              <Stepper activeStep={activeStep}>
                {Object.values(stepLabels).map((stepLabel) => {
                  return (
                    <Step key={stepLabel}>
                      <StepLabel>{stepLabel}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </Grid>
          </GridContainer>

          <FormWrapper
            values={
              incomeOrExpense
                ? {
                    ...incomeOrExpense,
                    ledgerDate: incomeOrExpense.ledgerDate.toUTCDate(),
                  }
                : undefined
            }
            vehicleId={selectedVehicleId ?? ''}
            baseCurrency={selectedVehicle.data?.baseCurrency}
          >
            {activeStep === Steps.AddAndAllocate && (
              <StepAddAndAllocate
                vehicles={allVehicles.data?.result ?? []}
                contacts={contacts.data ?? []}
                allocationValues={allocationValues}
                setAllocationValues={setAllocationValues}
                onCompletionStep1B={() => {
                  setActiveStep(Steps.ReviewAndConfirm);
                }}
                actions={investmentActions ?? []}
              />
            )}
            {activeStep === Steps.ReviewAndConfirm && selectedVehicle.data && (
              <StepReviewAndConfirm
                id={id}
                vehicle={selectedVehicle.data}
                completedAllocationValues={allocationValues}
                onPreviousStep={() => {
                  setActiveStep(Steps.AddAndAllocate);
                }}
                onSuccess={() => {
                  setActiveStep(Steps.Success);
                }}
              />
            )}
          </FormWrapper>
        </Spacing>
      </PageContent>
    </PageContainer>
  );
};

export default AddEditIncomeOrExpense;

import { FC } from 'react';
import { round } from 'lodash';
import { dateToLabel, numberToCurrencyString } from 'further-ui/utils';
import { styled, Checkbox } from '@mui/material';
import { AppTextInput } from 'components/FormElements';
import { Controller, Control, UseFormSetValue } from 'react-hook-form';
import { FormValues } from './types';
import { NumericFormat } from 'react-number-format';

const TableCell = styled('td', {
  shouldForwardProp: (prop) => prop !== 'newAllocationsPending',
})<{ newAllocationsPending?: boolean }>(({ theme, newAllocationsPending }) => ({
  paddingRight: theme.spacing(2),
  color: newAllocationsPending ? theme.palette.grey[500] : 'inherit',
}));

type Props = {
  fields: FormValues['allocations'];
  control: Control<FormValues>;
  setValue: UseFormSetValue<FormValues>;
  inputAmount: number;
  isNotEditable: boolean;
  setToManualMode: () => void;
  excludedCommitmentIds: Array<string>;
  onExcludedCommitmentIdsChange: (value: Array<string>, index?: number) => void;
  newAllocationsPending: boolean;
};

const NarrowAppTextInput = (props) => <AppTextInput {...props} narrow />;

const AllocationsFormBody: FC<Props> = ({
  fields,
  control,
  setValue,
  inputAmount,
  isNotEditable,
  setToManualMode,
  excludedCommitmentIds,
  onExcludedCommitmentIdsChange,
  newAllocationsPending,
}) => {
  return (
    <>
      {fields.map((field, index) => (
        <tr key={field.commitmentId}>
          <TableCell newAllocationsPending={newAllocationsPending}>
            {field.investorName}
          </TableCell>
          <TableCell newAllocationsPending={newAllocationsPending}>
            {numberToCurrencyString(field.totalCommitment)}
          </TableCell>
          <TableCell newAllocationsPending={newAllocationsPending}>
            {numberToCurrencyString(field.unallocatedCommitment)}
          </TableCell>
          <TableCell newAllocationsPending={newAllocationsPending}>
            {dateToLabel(field.closeDate)}
          </TableCell>
          <TableCell>
            <Controller
              control={control}
              name={`allocations.${index}.allocationAmount`}
              render={({ field: { value, onChange } }) => (
                <NumericFormat
                  value={value}
                  onChange={(e) => {
                    const newValue = e.target.value.replace(/,/g, '');
                    const newNumberValue = Number(newValue);

                    setToManualMode();

                    const isNaN = Number.isNaN(newNumberValue);
                    if (isNaN) {
                      return;
                    } else {
                      onChange(newNumberValue);
                    }
                    const allocationPercentage = round(
                      (newNumberValue / inputAmount) * 100,
                      5,
                    );
                    setValue(
                      `allocations.${index}.allocationPercentage`,
                      allocationPercentage,
                    );
                  }}
                  allowNegative={false}
                  decimalScale={5}
                  allowLeadingZeros={false}
                  thousandSeparator={true}
                  customInput={NarrowAppTextInput}
                  disabled={
                    isNotEditable ||
                    field.excludeFromAllocation ||
                    newAllocationsPending
                  }
                />
              )}
            />
          </TableCell>
          <TableCell>
            <Controller
              control={control}
              name={`allocations.${index}.allocationPercentage`}
              render={({ field: { value, onChange } }) => (
                <NumericFormat
                  value={value}
                  onChange={(e) => {
                    const newValue = e.target.value.replace(/,/g, '');
                    const newNumberValue = Number(newValue);

                    setToManualMode();

                    const isNaN = Number.isNaN(newNumberValue);
                    if (isNaN) {
                      return;
                    } else {
                      onChange(newNumberValue);
                    }
                    const allocationAmount = round(
                      (newNumberValue / 100) * inputAmount,
                      2,
                    );
                    setValue(
                      `allocations.${index}.allocationAmount`,
                      allocationAmount,
                    );
                  }}
                  allowNegative={false}
                  decimalScale={5}
                  allowLeadingZeros={false}
                  thousandSeparator={true}
                  customInput={NarrowAppTextInput}
                  disabled={
                    isNotEditable ||
                    field.excludeFromAllocation ||
                    newAllocationsPending
                  }
                />
              )}
            />
          </TableCell>

          <TableCell>
            <Controller
              control={control}
              name={`allocations.${index}.excludeFromAllocation`}
              render={({ field: { value, onChange } }) => (
                <Checkbox
                  disabled={isNotEditable}
                  checked={value}
                  onChange={(e) => {
                    onChange(e.target.checked);
                    let newExcludedCommitmentIds;

                    if (e.target.checked) {
                      newExcludedCommitmentIds = [
                        ...excludedCommitmentIds,
                        field.commitmentId,
                      ];
                    } else {
                      newExcludedCommitmentIds = excludedCommitmentIds.filter(
                        (id) => id !== field.commitmentId,
                      );
                    }

                    onExcludedCommitmentIdsChange(
                      newExcludedCommitmentIds,
                      index,
                    );
                  }}
                />
              )}
            />
          </TableCell>
        </tr>
      ))}
    </>
  );
};

export default AllocationsFormBody;

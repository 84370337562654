import { FC, useEffect, useState, useRef } from 'react';
import AppSelectBox from 'components/FormElements/AppSelectBox';
import { useAllocations } from 'hooks/data/allocation/useAllocations';
import { styled } from '@mui/material';
import getAllocationLabel from 'utils/allocationLabel';

const Placeholder = styled('span')(() => ({
  color: '#999999',
}));

interface Props {
  firmId?: string;
  companyId?: string;
  companyIds?: string[];
  onChange: (allocationIds: string[]) => void;
  value: string[];
  error?: boolean;
  helperText?: string;
  required?: boolean;
  disabled?: boolean;
  selectAllAllocationsByDefault?: boolean;
}

const AllocationSelector: FC<Props> = ({
  firmId,
  companyId,
  companyIds,
  onChange,
  value,
  selectAllAllocationsByDefault = false,
  ...rest
}) => {
  const { data, isLoading } = useAllocations({
    params: { firmId, companyId: companyId || companyIds },
  });

  const allocations = data?.uploadShareHoldings.map((allocation) => ({
    _id: allocation._id,
    allocationName: getAllocationLabel(allocation),
  }));

  const prevAllocationsLength = useRef<number | null>(null);

  useEffect(() => {
    if (
      allocations &&
      selectAllAllocationsByDefault &&
      allocations.length !== prevAllocationsLength.current
    ) {
      onChange(allocations.map((allocation) => allocation._id));
      prevAllocationsLength.current = allocations.length;
    }
  }, [allocations, selectAllAllocationsByDefault, onChange]);

  const [allAllocationsSelected, setAllAllocationsSelected] = useState(false);

  const handleAllocationsSelected = (allocationDropdownValue) => {
    if (allocationDropdownValue.includes('all')) {
      if (!allAllocationsSelected) {
        setAllAllocationsSelected(true);
        onChange(allocations.map((allocation) => allocation._id));
      } else {
        setAllAllocationsSelected(false);
        onChange([]);
      }
    } else {
      onChange(allocationDropdownValue);
      setAllAllocationsSelected(
        allocationDropdownValue.length === allocations.length,
      );
    }
  };

  return (
    <AppSelectBox
      required
      data={allocations || []}
      valueKey="_id"
      labelKey="allocationName"
      fullWidth
      value={value}
      variant="outlined"
      multiple
      disabled={isLoading || allocations?.length === 0}
      renderValue={() => {
        if (isLoading) {
          return <Placeholder>Loading...</Placeholder>;
        } else if (allocations?.length === 0) {
          return <Placeholder>No allocations found</Placeholder>;
        } else if (value.length === allocations.length) {
          return 'All allocations selected';
        } else if (value.length === 0) {
          return <Placeholder>Select allocations</Placeholder>;
        } else if (value.length > 1) {
          return `${value.length} allocations selected`;
        } else if (value.length === 1) {
          return allocations.find((allocation) => allocation._id === value[0])
            ?.allocationName;
        }
      }}
      onChange={(e) => {
        handleAllocationsSelected(e.target.value);
      }}
      {...rest}
    />
  );
};

export default AllocationSelector;

import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CircularProgress, Tooltip } from '@mui/material';
import { round } from 'lodash';
import PageContainer from 'components/PageContainer';
import InvestmentDetails, {
  EditableInvestmentDetails,
} from './InvestmentDetails';
import CurrentShareholdings from './CurrentShareholdings';
import ExitedShareholdings from './ExitedShareholdings';
import CoreFees from './CoreFees';
import FutureFees from './FutureFees';
import PaymentsReconciliationTable from './PaymentsReconciliationTable';
import Dividend from './Dividend';
import InterestPayments from './InterestPayments';
import InvestmentBalanceAndValue from './InvestmentBalanceAndValue';
import { useDisclosure } from 'further-ui/hooks';
import { useInvestment } from 'hooks/data/investment/useInvestments';
import FeesRecalculationWarningModal from './FeesRecalculationWarningModal';
import UpdatedCommitmentErrorModal from './UpdatedCommitmentErrorModal';
import NegativeBalanceErrorModal from './NegativeBalanceErrorModal';
import { usePayments } from 'hooks/data/investment/usePayments';
import { createAddInvestmentTransferRoute } from 'adminConstants/routes';
import useInvestmentDetails from 'hooks/data/investment/useInvestmentDetails';
import Notes from 'components/Notes';
import { NoteRelation } from 'further-types/notes';
import { Api } from 'further-types/investment';
import { OverallPerformanceTabs } from './OverallPerformanceTabs';
import PageContent from 'components/PageContent';
import Heading from 'components/Heading';
import Button from 'components/Button';
import ButtonGroup from 'components/ButtonGroup';
import PageSection from 'components/PageSection';
import PageHeader from 'components/PageHeader';
import ResponsiveActions from 'components/PageHeader/ResponsiveActions';
import { Row } from 'components/Layout';
import { useInvestor } from 'hooks/data/investor/useInvestor';
import UpdatedDefaultAdviserWarningModal from './UpdatedDefaultAdviserWarningModal';
import { useQueryClient } from '@tanstack/react-query';

const breadcrumbs = [
  { label: 'Dashboard' },
  { label: 'Subscriptions', link: '/investment' },
  { label: 'Update Subscription', link: '/', isActive: true },
];

export type Shareholding = Api.GetInvestmentShareholdingsResponse[number] & {
  isTransferred?: boolean;
};

export type Exits = Api.GetInvestmentExitsResponse['exits'];
export type InvestmentBalance =
  Api.GetInvestmentDetailsResponse['investmentBalance'];

const UpdateInvestment = ({ history }) => {
  const { id } = useParams<{ id: string }>();
  // disclosures
  const feesRecalculationWarningDisclosure = useDisclosure();
  const updatedCommitmentErrorDisclosure = useDisclosure();
  const defaultAdviserDisclosure = useDisclosure();
  const negativeBalanceErrorDisclosure = useDisclosure();
  // state hooks
  const [editableDetails, setEditableDetails] =
    useState<EditableInvestmentDetails>({});
  const [initialTotalCommitment, setInitialTotalCommitment] = useState(0);
  //data hooks
  const { fetch, update } = useInvestment(
    id,
    negativeBalanceErrorDisclosure.onOpen,
  );
  const fetchPayments = usePayments(id);
  const { getFees, getExits, getShareholdings } = useInvestmentDetails();
  const { data: fees } = getFees(id);
  const { data: exitResponse } = getExits(id);
  const { data: currentShareholdings } = getShareholdings(id);

  const exits: Exits = exitResponse?.exits ?? [];

  const combinedShareholdings = useMemo(() => {
    return (
      //@ts-expect-error
      currentShareholdings?.reduce((combined, shareholding) => {
        return [
          ...combined,
          ...(shareholding.sharesCurrentlyHeld ? [shareholding] : []),
          ...shareholding.transferredShareholdings
            .filter(({ sharesCurrentlyHeld }) => !!sharesCurrentlyHeld)
            .map((transferredShareholding) => ({
              ...transferredShareholding,
              isTransferred: true,
            })),
        ];
      }, []) ?? []
    );
  }, [currentShareholdings]);

  const allowLeaveFeesUnchangedOption =
    (editableDetails?.totalCommitment ?? 0) >=
    (fetch.data?.investmentBalance?.deploymentSummary
      .cashFeesOnInitialInvestment ?? 0);

  useEffect(
    function fetchInvestment() {
      if (fetch.data) {
        setEditableDetails({
          adviser: fetch.data.adviser,
          adviserFee: fetch.data.adviserFee ?? 0,
          totalCommitment: fetch.data.totalCommitment ?? 0,
          externalInvestmentId: fetch.data.externalInvestmentId ?? '',
        });

        setInitialTotalCommitment(fetch.data.totalCommitment);
      }
    },
    [id, fetch.data],
  );

  const investmentDetails = fetch.data;
  const { investmentBalance, interest, dividends } = investmentDetails || {};
  const { deploymentSummary } = investmentBalance || {};

  const handleInvestmentUpdate = async ({
    recalculateFees,
  }: {
    recalculateFees?: boolean;
  }) => {
    feesRecalculationWarningDisclosure.onClose();

    const values: Api.UpdateInvestmentRequest = {
      adviserId: editableDetails.adviser?._id || null,
      externalInvestmentId: editableDetails.externalInvestmentId ?? '',
      adviserFee: editableDetails.adviserFee ?? 0,
    };

    if (initialTotalCommitment !== editableDetails.totalCommitment) {
      values.totalCommitment = editableDetails.totalCommitment;
      values.recalculateFees = recalculateFees;
    }

    update.mutate(values, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['investor', investmentDetails?.investor?._id],
        });
        queryClient.invalidateQueries({ queryKey: ['investors'] });
      },
    });
  };

  const { fetch: investorFetch } = useInvestor(
    investmentDetails?.investor?._id,
  );
  const investorDetail = investorFetch.data;
  const defaultAdviserId = investorDetail?.firmSpecificSettings?.find(
    (setting) => setting.firmId === investmentDetails?.firm?._id,
  )?.defaultAdviserId;

  const queryClient = useQueryClient();

  const handleSave = async () => {
    const hasTotalCommitmentError =
      round(
        (editableDetails?.totalCommitment ?? 0) +
          (editableDetails?.adviserFee ?? 0),
        2,
      ) < (deploymentSummary?.fundsReceived ?? 0);

    if (hasTotalCommitmentError) {
      updatedCommitmentErrorDisclosure.onOpen();
      return;
    }

    if (defaultAdviserId && editableDetails.adviser?._id !== defaultAdviserId) {
      defaultAdviserDisclosure.onOpen();
      return;
    }

    if (editableDetails.totalCommitment !== initialTotalCommitment) {
      feesRecalculationWarningDisclosure.onOpen();
    } else {
      void handleInvestmentUpdate({});
    }
  };

  if (fetch.isLoading) return <CircularProgress />;
  if (!fetch.data || !fetch.isSuccess) return <div>Subscription not found</div>;

  const isTransferDisabled = !!deploymentSummary?.heldByFundAwaitingInvestment;
  const transferDisabledTooltipText =
    "To transfer any holdings from this subscription, please first freeze the subscription and withdraw all this subscription's uninvested capital.";

  const onTransfer = () =>
    isTransferDisabled
      ? null
      : history.push(
          createAddInvestmentTransferRoute(fetch.data?.investor._id ?? ''),
        );

  return (
    <PageContainer heading="Update Subscription" breadcrumbs={breadcrumbs}>
      <FeesRecalculationWarningModal
        disclosure={feesRecalculationWarningDisclosure}
        onConfirm={handleInvestmentUpdate}
        allowLeaveFeesUnchangedOption={allowLeaveFeesUnchangedOption}
      />
      <UpdatedCommitmentErrorModal
        disclosure={updatedCommitmentErrorDisclosure}
      />
      <NegativeBalanceErrorModal disclosure={negativeBalanceErrorDisclosure} />
      <UpdatedDefaultAdviserWarningModal
        disclosure={defaultAdviserDisclosure}
        onConfirm={async () => {
          await handleInvestmentUpdate({ recalculateFees: false });
        }}
      />
      <PageContent withSpacing>
        <PageSection>
          <InvestmentDetails
            shareholdings={currentShareholdings ?? []}
            //@ts-expect-error
            investmentDetails={investmentDetails}
            paymentExpectations={fetchPayments.data ?? []}
            editableDetails={editableDetails}
            setEditableDetails={setEditableDetails}
            fees={fees?.fees ?? []}
          />
          <ButtonGroup>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              loading={update.isPending}
            >
              Update
            </Button>
          </ButtonGroup>
        </PageSection>
        <PageSection showDivider>
          <OverallPerformanceTabs
            investmentBalance={investmentBalance ?? null}
            //@ts-expect-error
            combinedShareholdings={combinedShareholdings ?? null}
            exits={exits}
          />
        </PageSection>
        <PageSection showDivider withSpacing>
          <PageHeader>
            <Heading variant="h2" noMargin>
              Current shareholdings
            </Heading>
            <ResponsiveActions>
              <Tooltip
                title={isTransferDisabled ? transferDisabledTooltipText : ''}
              >
                <div>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={onTransfer}
                    disabled={isTransferDisabled}
                  >
                    Transfer holdings
                  </Button>
                </div>
              </Tooltip>
            </ResponsiveActions>
          </PageHeader>
          <CurrentShareholdings
            //@ts-expect-error
            combinedShareholdings={combinedShareholdings ?? []}
            transferDate={fetch.data?.transferDate}
          />
        </PageSection>
        <PageSection>
          <Heading variant="h2">Exited shareholdings</Heading>
          <ExitedShareholdings exitedShareholdings={exits} />
        </PageSection>
        <PageSection>
          <Heading variant="h2">Dividends</Heading>
          <Dividend
            dividends={dividends}
            firmName={investmentDetails?.firm.firmName}
            fundName={investmentDetails?.fund.fundName}
          />
        </PageSection>
        <PageSection>
          <Heading variant="h2">Interest received</Heading>
          <InterestPayments interestPayments={interest} />
        </PageSection>
        <PageSection>
          <Heading variant="h2">Initial and recurring fees</Heading>
          <CoreFees fees={fees?.fees ?? []} />
        </PageSection>
        <PageSection>
          <FutureFees fees={fees?.futureFees ?? []} />
        </PageSection>
        <PageSection>
          <Heading variant="h2">Subscription cash balance</Heading>
          <InvestmentBalanceAndValue
            investmentBalance={investmentBalance ?? undefined}
          />
        </PageSection>

        <PageSection showDivider>
          <Heading variant="h2">Funds received</Heading>
          {!fetchPayments.isLoading ? (
            <PaymentsReconciliationTable
              //@ts-expect-error
              investmentId={investmentDetails?._id}
              //@ts-expect-error
              firmId={investmentDetails?.firm._id}
              payments={fetchPayments.data ?? []}
            />
          ) : (
            <Row justify="center">
              <CircularProgress size={24} />
            </Row>
          )}
        </PageSection>
        <PageSection showDivider>
          <Notes relationId={id} noteRelation={NoteRelation.Investment} />
        </PageSection>
      </PageContent>
    </PageContainer>
  );
};

export default UpdateInvestment;

import api from 'lib/trpcClient';

export default function useVehicle(vehicleId?: string | null) {
  const { data: vehicle, isLoading } = api.vehicle.getById.useQuery(
    { vehicleId: vehicleId ?? '' },
    { enabled: !!vehicleId, staleTime: 1000 * 60 }, // 1 minute
  );

  return { vehicle, isLoading };
}

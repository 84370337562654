import { Box, Grid2, Pagination, styled, Typography } from '@mui/material';
import GridContainer from 'components/GridContainer';
import Spacing from 'components/Spacing';
import { BankAccountOperationStatus } from 'further-types/lp/bank-reconciliation';
import api from 'lib/trpcClient';
import { ceil } from 'lodash';
import React, { useState } from 'react';
import FindAndMatch from './FindAndMatch';
import ErrorBoundary from 'components/ErrorBoundary';
import Transaction from './Transaction';

const TransactionsHeading = styled(Grid2)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.borderColor?.dark}`,
}));

const PaginationRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  borderTop: `1px solid ${theme.palette.borderColor?.dark}`,
  paddingTop: theme.spacing(4),

  [theme.breakpoints.down('md')]: {
    borderTop: 'none',
  },
}));

type Props = {
  bankAccount: {
    id: string;
    vehicleId: string;
  };
};

const ReconcileTransactions: React.FC<Props> = ({ bankAccount }) => {
  const [page, setPage] = useState(1);
  const [selectedTransactionId, setSelectedTransactionId] = useState<string>();
  const [preselectedActionId, setPreselectedActionId] = useState<string>();

  const [data] =
    api.bankReconciliation.listBankAccountOperations.useSuspenseQuery({
      bankAccountId: bankAccount.id,
      vehicleId: bankAccount.vehicleId,
      status: BankAccountOperationStatus.Unreconciled,
      page,
      rowsPerPage: 10,
      order: 'desc',
      orderBy: 'date',
    });

  const [{ results: actions }] = api.action.listActions.useSuspenseQuery({
    vehicleId: bankAccount.vehicleId,
    unreconciledOnly: true,
    page: 1,
    rowsPerPage: 100,
    order: 'desc',
    orderBy: 'ledgerDate',
  });

  const handleShowFindAndMatch = (transactionId: string, actionId?: string) => {
    setSelectedTransactionId(transactionId);
    setPreselectedActionId(actionId);
  };

  return (
    <Spacing>
      <GridContainer>
        <TransactionsHeading size={{ xs: 12, md: 5 }}>
          <Typography variant="body1" color="textSecondary">
            Review bank statement lines
          </Typography>
        </TransactionsHeading>
        <Grid2 size={{ xs: 0, md: 2 }}></Grid2>
        <TransactionsHeading size={{ xs: 12, md: 5 }}>
          <Typography variant="body1" color="textSecondary">
            Match with transactions on Further
          </Typography>
        </TransactionsHeading>
      </GridContainer>
      {data.results.map((transaction) => (
        <React.Fragment key={transaction.id}>
          <Transaction
            bankAccount={bankAccount}
            {...transaction}
            actions={actions}
            onFindAndMatch={handleShowFindAndMatch}
          />
          {selectedTransactionId === transaction.id && (
            <ErrorBoundary>
              <FindAndMatch
                vehicleId={bankAccount.vehicleId}
                bankAccountId={bankAccount.id}
                preselectedActionId={preselectedActionId}
                transaction={transaction}
                defaultActions={actions}
                onClose={() => setSelectedTransactionId(undefined)}
              />
            </ErrorBoundary>
          )}
        </React.Fragment>
      ))}
      <PaginationRow>
        <Pagination
          color="primary"
          count={ceil(data.totalCount / 10)}
          showFirstButton
          showLastButton
          page={page}
          onChange={(_, page) => setPage(page)}
        />
      </PaginationRow>
    </Spacing>
  );
};

export default ReconcileTransactions;

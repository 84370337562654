import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Person, AttachMoney } from '@mui/icons-material';
import { styled, Tab, Tabs } from '@mui/material';
import Heading from 'components/Heading';
import PageContainer from 'components/PageContainer';
import PageContent from 'components/PageContent';
import PageSection from 'components/PageSection';
import Spacing from 'components/Spacing';
import { FundSetupTab } from 'adminConstants/routes';

import ChartOfAccounts from './ChartOfAccounts';
import UserManagement from './UserManagement';
import FundSetup from './FundSetup';
import AccountMapping from './AccountMapping';
import ErrorBoundary from 'components/ErrorBoundary';

const tabsData = {
  [FundSetupTab.FundSetup]: {
    icon: Person,
    title: 'Fund setup',
  },
  [FundSetupTab.UserManagement]: {
    icon: Person,
    title: 'User management',
  },
  [FundSetupTab.ApprovalFlows]: {
    icon: Person,
    title: 'Approval flows',
  },
  [FundSetupTab.ChartOfAccounts]: {
    icon: AttachMoney,
    title: 'Chart of accounts',
  },
  [FundSetupTab.AccountMapping]: {
    icon: AttachMoney,
    title: 'Account mapping',
  },
};

const PageContentStyled = styled(PageContent)({
  overflow: 'visible',
});

const FundAccounting: React.FC = () => {
  const { hash } = useLocation();
  const [activeTab, setActiveTab] = useState<FundSetupTab>(
    (hash as FundSetupTab) || FundSetupTab.FundSetup,
  );

  const handleTabChange = (_: unknown, selectedTab: FundSetupTab) => {
    setActiveTab(selectedTab);
  };

  return (
    <PageContainer heading="Fund setup">
      <PageContentStyled>
        <Spacing size="lg">
          <Tabs
            showDivider
            value={activeTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
          >
            {Object.keys(tabsData).map((tab) => (
              <Tab
                key={tab}
                value={tab}
                label={
                  <Heading variant="h5" noMargin icon={tabsData[tab].icon}>
                    {tabsData[tab].title}
                  </Heading>
                }
              />
            ))}
          </Tabs>
          <PageSection>
            {activeTab === FundSetupTab.FundSetup && (
              <ErrorBoundary loaderSize={24}>
                <FundSetup />
              </ErrorBoundary>
            )}
            {activeTab === FundSetupTab.UserManagement && <UserManagement />}
            {activeTab === FundSetupTab.ChartOfAccounts && <ChartOfAccounts />}
            {activeTab === FundSetupTab.AccountMapping && <AccountMapping />}
          </PageSection>
        </Spacing>
      </PageContentStyled>
    </PageContainer>
  );
};

export default FundAccounting;

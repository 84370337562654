import { Grid2 } from '@mui/material';
import GridContainer from 'components/GridContainer';
import DeleteTransaction from './DeleteTransaction';
import TransactionDetails from './TransactionDetails';
import TransactionActionSubmit from './TransactionActionSubmit';
import { CurrencyCode } from 'further-types/lp/vehicle';
import TransactionActions, {
  TransactionActionsMode,
} from './TransactionActions';
import { ActionRef } from 'further-types/lp/action';
import { CalendarDay } from 'further-ui/utils';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

const formSchema = z.discriminatedUnion('mode', [
  z.object({
    mode: z.literal(TransactionActionsMode.CreateTransfer),
    reference: z.string().optional(),
    targetBankAccountId: z.string(),
  }),
  z.object({
    mode: z.literal(TransactionActionsMode.QuickReconcile),
    actionId: z.string(),
  }),
]);

export type FormValues = z.infer<typeof formSchema>;

type Props = {
  bankAccount: {
    vehicleId: string;
    id: string;
  };
  id: string;
  description: string;
  date: CalendarDay;
  spent?: number;
  received?: number;
  accountCurrency: CurrencyCode;
  actions: Array<{
    id: string;
    amount: number;
    counterpartyLabel: string;
    actionRef: ActionRef;
    currencyCode: CurrencyCode;
    isFullyReconciled: boolean;
  }>;
  onFindAndMatch: (transactionId: string, actionId?: string) => void;
};

const Transaction: React.FC<Props> = ({
  id,
  bankAccount,
  spent,
  received,
  actions,
  accountCurrency,
  description,
  date,
  onFindAndMatch,
}) => {
  const amount = (spent ? spent : received) ?? 0;
  const matchingActions = actions.filter(
    (action) => !action.isFullyReconciled && action.amount === Math.abs(amount),
  );

  const formMethods = useForm<FormValues>({
    shouldFocusError: true,
    resolver: zodResolver(formSchema),
    defaultValues: {
      mode: TransactionActionsMode.QuickReconcile,
      actionId: matchingActions[0]?.id ?? '',
    },
    criteriaMode: 'all',
  });

  return (
    <FormProvider {...formMethods}>
      <GridContainer>
        <Grid2 size={{ xs: 12, md: 5 }}>
          <DeleteTransaction
            vehicleId={bankAccount.vehicleId}
            bankAccountId={bankAccount.id}
            transactionId={id}
          />
          <TransactionDetails
            description={description}
            date={date}
            spent={spent}
            received={received}
            accountCurrency={accountCurrency}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, md: 2 }} order={{ xs: 1, md: 0 }}>
          <TransactionActionSubmit
            vehicleId={bankAccount.vehicleId}
            bankAccountId={bankAccount.id}
            bankAccountOperationId={id}
            amount={amount}
            hasMatchingActions={!!matchingActions.length}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, md: 5 }}>
          <TransactionActions
            bankAccountOperationId={id}
            matchingActions={matchingActions}
            onFindAndMatch={onFindAndMatch}
          />
        </Grid2>
      </GridContainer>
    </FormProvider>
  );
};

export default Transaction;

import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Grid from '@mui/material/Grid2';
import PageSection from 'components/PageSection';
import { CurrencyCode } from 'further-types/lp/vehicle';
import CurrencySelector from 'components/CurrencySelector';
import AppSelectBox from 'components/FormElements/AppSelectBox';
import AppTextInput from 'components/FormElements/AppTextInput';
import { FieldRow } from 'components/FormElements';
import {
  antiDilutionRightsOptions,
  couponRightsOptions,
  liquidationPreferenceTypeOptions,
  monitoringRightsOptions,
} from './consts';
import { NumericFormat } from 'react-number-format';

interface HoldingClassRightsFormProps {
  index: number;
}

export const HoldingClassRightsForm: FC<HoldingClassRightsFormProps> = ({
  index,
}) => {
  const { control } = useFormContext();

  return (
    <PageSection title="Class rights">
      <Grid container spacing={6}>
        <Grid size={{ xs: 12 }}>
          <FieldRow title="Class name*" centerTitle>
            <Controller
              name={`holdingClasses.${index}.name`}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <AppTextInput
                  {...field}
                  error={!!error}
                  helperText={error?.message}
                  placeholder="Enter class name"
                />
              )}
            />
          </FieldRow>
        </Grid>

        <Grid size={{ xs: 12 }}>
          <FieldRow title="Currency" centerTitle>
            <Controller
              name={`holdingClasses.${index}.currency`}
              control={control}
              defaultValue={CurrencyCode.GBP}
              render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                <CurrencySelector
                  {...rest}
                  error={error ? { message: error.message } : undefined}
                  placeholder="Select currency"
                />
              )}
            />
          </FieldRow>
        </Grid>

        <Grid size={{ xs: 12 }}>
          <FieldRow title="Monitoring rights" centerTitle>
            <Controller
              name={`holdingClasses.${index}.monitoringRights`}
              control={control}
              render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                <AppSelectBox
                  {...rest}
                  fullWidth
                  data={[
                    { value: undefined, title: 'None' },
                    ...monitoringRightsOptions,
                  ]}
                  error={!!error}
                  helperText={error?.message}
                  placeholder="Select monitoring rights"
                />
              )}
            />
          </FieldRow>
        </Grid>

        <Grid size={{ xs: 12 }}>
          <FieldRow title="Liquidation preference type" centerTitle>
            <Controller
              name={`holdingClasses.${index}.liquidationPreferenceType`}
              control={control}
              render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                <AppSelectBox
                  {...rest}
                  fullWidth
                  data={[
                    { value: undefined, title: 'None' },
                    ...liquidationPreferenceTypeOptions,
                  ]}
                  error={!!error}
                  helperText={error?.message}
                  placeholder="Select liquidation preference type"
                />
              )}
            />
          </FieldRow>
        </Grid>

        <Grid size={{ xs: 12 }}>
          <FieldRow title="Liquidation preference multiple" centerTitle>
            <Controller
              name={`holdingClasses.${index}.liquidationPreferenceMultiple`}
              control={control}
              render={({
                field: { ref, onChange, value, ...rest },
                fieldState: { error },
              }) => (
                <NumericFormat
                  {...rest}
                  inputRef={ref}
                  customInput={AppTextInput}
                  decimalScale={2}
                  fixedDecimalScale
                  allowNegative={false}
                  allowLeadingZeros={false}
                  thousandSeparator={true}
                  suffix="x"
                  value={value || ''}
                  onValueChange={({ value }) => {
                    onChange(value ? Number(value) : 0);
                  }}
                  error={!!error}
                  helperText={error?.message}
                  placeholder="Enter liquidation preference multiple"
                />
              )}
            />
          </FieldRow>
        </Grid>

        <Grid size={{ xs: 12 }}>
          <FieldRow title="Anti-dilution rights" centerTitle>
            <Controller
              name={`holdingClasses.${index}.antiDilutionRights`}
              control={control}
              render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                <AppSelectBox
                  {...rest}
                  fullWidth
                  data={[
                    { id: undefined, title: 'None' },
                    ...antiDilutionRightsOptions,
                  ]}
                  error={!!error}
                  helperText={error?.message}
                  placeholder="Select anti-dilution rights"
                />
              )}
            />
          </FieldRow>
        </Grid>

        <Grid size={{ xs: 12 }}>
          <FieldRow title="Coupon rights" centerTitle>
            <Controller
              name={`holdingClasses.${index}.couponRights`}
              control={control}
              render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                <AppSelectBox
                  {...rest}
                  fullWidth
                  data={[
                    { id: undefined, title: 'None' },
                    ...couponRightsOptions,
                  ]}
                  error={!!error}
                  helperText={error?.message}
                  placeholder="Select coupon rights"
                />
              )}
            />
          </FieldRow>
        </Grid>

        <Grid size={{ xs: 12 }}>
          <FieldRow title="Coupon amount (per annum)" centerTitle>
            <Controller
              name={`holdingClasses.${index}.couponAmount`}
              control={control}
              render={({
                field: { ref, onChange, value, ...rest },
                fieldState: { error },
              }) => (
                <NumericFormat
                  {...rest}
                  inputRef={ref}
                  customInput={AppTextInput}
                  decimalScale={0}
                  fixedDecimalScale
                  allowNegative={false}
                  allowLeadingZeros={false}
                  thousandSeparator={true}
                  suffix="%"
                  value={value || ''}
                  onValueChange={({ value }) => {
                    onChange(value ? Number(value) : null);
                  }}
                  error={!!error}
                  helperText={error?.message}
                  placeholder="Enter coupon amount"
                />
              )}
            />
          </FieldRow>
        </Grid>
      </Grid>
    </PageSection>
  );
};

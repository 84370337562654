import {
  Button as MuiButton,
  ButtonProps,
  CircularProgress,
  Tooltip,
  styled,
} from '@mui/material';

const Spinner = styled(CircularProgress, {
  shouldForwardProp: (prop) => prop !== 'iconOnly',
})<{ iconOnly?: boolean }>(({ iconOnly }) => ({
  color: 'rgba(0, 0, 0, 0.26)',
  marginRight: iconOnly ? 0 : '10px',
}));

interface Props extends ButtonProps {
  loading?: boolean;
  iconOnly?: boolean;
  whiteSpace?: 'nowrap' | 'normal';
  tooltip?: string;
}

const Button: React.FC<Props> = ({
  loading,
  children,
  disabled,
  iconOnly,
  whiteSpace = 'normal',
  tooltip,
  ...props
}) => {
  const button = (
    <MuiButton {...props} disabled={disabled || loading} sx={{ whiteSpace }}>
      {loading ? <Spinner iconOnly={iconOnly} size={18} /> : null}
      {!loading || !iconOnly ? children : null}
    </MuiButton>
  );

  return tooltip ? (
    <Tooltip arrow title={tooltip}>
      <span>{button}</span>
    </Tooltip>
  ) : (
    button
  );
};

export default Button;

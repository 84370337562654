import PageFilters, { Filter } from 'components/PageFilters';
import AppSelectBox from 'components/FormElements/AppSelectBox';
import TextField from 'components/FormElements/AppTextInput';
import Autocomplete from 'components/FormElements/AppAutocomplete';
import { STATUS_OPTIONS, ARCHIVE_OPTIONS } from '../constants';
import {
  InvestmentArchiveStatus,
  InvestmentCompletionStatus,
} from 'further-types/investment';

interface InvestmentFilters {
  archiveStatus: InvestmentArchiveStatus;
  status: InvestmentCompletionStatus;
  investorName: string;
  fundId: string | null;
}

interface InvestmentFiltersProps {
  filters: InvestmentFilters;
  handleChangeFilters: (filters: Partial<InvestmentFilters>) => void;
  exportExcelSheet: () => void;
  tranchesList: Array<{
    _id: string;
    firmId: { firmName: string };
    fundName: string;
  }>;
  isExporting: boolean;
}

export const InvestmentFilters: React.FC<InvestmentFiltersProps> = ({
  filters,
  handleChangeFilters,
  exportExcelSheet,
  tranchesList,
  isExporting,
}) => {
  return (
    <PageFilters
      pageId="incomplete-investments-table"
      buttons={[
        {
          disabled: isExporting,
          label: 'Export',
          onClick: exportExcelSheet,
        },
      ]}
    >
      <Filter>
        <TextField
          type="search"
          name="searchInvestorsByName"
          value={filters.investorName}
          placeholder="Search by investor name"
          onChange={(event) => {
            handleChangeFilters({
              investorName: event.target.value,
            });
          }}
        />
      </Filter>
      <Filter>
        <Autocomplete
          id="fundId"
          value={
            filters.fundId && tranchesList
              ? tranchesList.find(({ _id }) => _id === filters.fundId) ?? null
              : null
          }
          options={tranchesList ?? []}
          getOptionLabel={(option) =>
            option ? `${option?.firmId?.firmName} ${option?.fundName}` : ''
          }
          onChange={(_, newValue) => {
            handleChangeFilters({
              ...filters,
              fundId: newValue?._id ?? null,
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Select tranche"
            />
          )}
        />
      </Filter>
      <Filter>
        <AppSelectBox
          data={ARCHIVE_OPTIONS}
          value={filters.archiveStatus}
          valueKey="value"
          labelKey="label"
          placeholder="Select archive status"
          onChange={(event) => {
            handleChangeFilters({
              ...filters,
              archiveStatus: event.target.value as InvestmentArchiveStatus,
            });
          }}
        />
      </Filter>
      <Filter>
        <AppSelectBox
          placeholder="Select status"
          data={STATUS_OPTIONS}
          value={filters.status}
          valueKey="value"
          labelKey="label"
          onChange={(event) => {
            handleChangeFilters({
              ...filters,
              status: event.target.value as InvestmentCompletionStatus,
            });
          }}
        />
      </Filter>
    </PageFilters>
  );
};

import { FC } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import Button from 'components/Button';
import ButtonGroup from 'components/ButtonGroup';
import { CurrencyCode } from 'further-types/lp/vehicle';
import { TransactionCurrencyRateDetails } from 'further-types/lp/transaction';
import { Disclosure } from 'further-ui/hooks';
import {
  AppNumberInput,
  AppTextInput,
  FieldRow,
} from 'components/FormElements';
import GridContainer from 'components/GridContainer';
import Spacing from 'components/Spacing';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import CloseIcon from '@mui/icons-material/Close';

type Props = {
  disclosure: Disclosure;
  baseCurrency: CurrencyCode;
  transactionCurrency: CurrencyCode;
  fxRate: TransactionCurrencyRateDetails | null;
  onRateChange: (rate: TransactionCurrencyRateDetails) => void;
};

const Title = styled(DialogTitle)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(4, 6, 0, 6),
  fontSize: '1.25rem',
}));

const CustomRateInputDialog: FC<Props> = ({
  disclosure,
  baseCurrency,
  transactionCurrency,
  fxRate,
  onRateChange,
}) => {
  const form = useForm<{ overrideRate: number; overrideSource: string }>({
    values: {
      overrideRate: fxRate?.overrideRate ?? fxRate?.fetchedRate ?? 1,
      overrideSource: fxRate?.overrideSource ?? '',
    },
    resolver: zodResolver(
      z.object({
        overrideRate: z.number().min(0),
        overrideSource: z.string().optional(),
      }),
    ),
  });

  const onSubmit = (data: { overrideRate: number; overrideSource: string }) => {
    onRateChange({
      ...fxRate,
      overrideRate: data.overrideRate,
      overrideSource: data.overrideSource,
    });
    disclosure.onClose();
  };

  return (
    <Dialog open={disclosure.isOpen} onClose={disclosure.onClose}>
      <Title>
        <span>Set transaction rate</span>
        <IconButton aria-label="close" onClick={disclosure.onClose}>
          <CloseIcon />
        </IconButton>
      </Title>

      <form onSubmit={form.handleSubmit(onSubmit)}>
        <DialogContent>
          <Spacing size="md">
            <GridContainer>
              <Grid size={{ sm: 12 }}>
                <Spacing size="sm">
                  <FieldRow title={baseCurrency} centerTitle>
                    <AppTextInput value={1} disabled />
                  </FieldRow>
                  <FieldRow title={transactionCurrency} centerTitle>
                    <Controller
                      control={form.control}
                      name="overrideRate"
                      render={({
                        fieldState: { error },
                        field: { ref, ...rest },
                      }) => (
                        <AppNumberInput
                          {...rest}
                          min={0}
                          decimals={15}
                          error={!!error}
                          helperText={error?.message?.toString()}
                        />
                      )}
                    />
                  </FieldRow>
                  <FieldRow title="Source" centerTitle>
                    <Controller
                      control={form.control}
                      name="overrideSource"
                      render={({ field }) => <AppTextInput {...field} />}
                    />
                  </FieldRow>
                </Spacing>
              </Grid>
            </GridContainer>
            <ButtonGroup>
              <Button variant="outlined" onClick={disclosure.onClose}>
                Go back
              </Button>
              <Button variant="contained" type="submit">
                Save and override rate
              </Button>
            </ButtonGroup>
          </Spacing>
        </DialogContent>
      </form>
    </Dialog>
  );
};

export default CustomRateInputDialog;

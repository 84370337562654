import {
  FormControl,
  ListSubheader,
  MenuItem,
  Select,
  styled,
} from '@mui/material';
import Table from 'components/Table';
import {
  TransactionCurrencyRateDetails,
  TransactionOperation,
} from 'further-types/lp/transaction';
import { CurrencyCode, FxDataFeed } from 'further-types/lp/vehicle';
import { CalendarDay, numberToCurrencyString } from 'further-ui/utils';
import { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import FxRateInformation from './FxRateInformation';
import Spacing from 'components/Spacing';

export type Transaction = {
  id?: string;
  actionId?: string;
  transactionTypeId: string;
  operationType: TransactionOperation;
  amountExVatLcy?: number;
  amountIncVatLcy?: number;
  amountExVatTcy: number;
  amountIncVatTcy: number;
  transactionCurrencyRate?: TransactionCurrencyRateDetails;
};

type Props = {
  defaultTransactions: Array<Transaction>;
  transactionTypeOptions: Array<{
    label: string;
    value: string | null;
  }>;
  onChange: (transactions: Array<Transaction>) => void;
  transactionCurrency: CurrencyCode;
  transactionDate: CalendarDay;
  vehicle: {
    _id: string;
    baseCurrency: CurrencyCode;
    fxDataFeed: FxDataFeed;
  };
};

const TableBookEnd = styled('div')(({ theme }) => ({
  height: '42px',
  backgroundColor: theme.palette.background.header,
}));

const TransactionAllocator: React.FC<Props> = ({
  defaultTransactions,
  transactionTypeOptions,
  onChange,
  transactionCurrency,
  transactionDate,
  vehicle,
}) => {
  const { control, setValue } = useForm<{ transactions: Array<Transaction> }>({
    values: {
      transactions: defaultTransactions,
    },
  });

  const { fields } = useFieldArray({
    control,
    name: 'transactions',
  });

  const { transactions } = useWatch({ control });

  const defaultFxRate = defaultTransactions[0]?.transactionCurrencyRate;
  const [fxRate, setFxRate] = useState<TransactionCurrencyRateDetails | null>(
    defaultFxRate ?? null,
  );
  const isDifferentCurrency = transactionCurrency !== vehicle.baseCurrency;

  const computeLcyAmount = (
    amountTcy?: number,
    rateDetails?: TransactionCurrencyRateDetails | null,
  ) => {
    if (!amountTcy) return 0;

    return isDifferentCurrency && rateDetails
      ? amountTcy / (rateDetails.overrideRate ?? rateDetails.fetchedRate ?? 1)
      : amountTcy;
  };

  const buildTransactions = (
    transactions: Array<{
      id?: string;
      actionId?: string;
      transactionTypeId?: string;
      operationType?: TransactionOperation;
      amountIncVatTcy?: number;
      amountExVatTcy?: number;
      amountExVatLcy?: number;
      amountIncVatLcy?: number;
    }>,
    rateDetails: TransactionCurrencyRateDetails | null,
    includeRateDetails: boolean,
  ) =>
    transactions.map((t) => ({
      id: t.id,
      actionId: t.actionId,
      transactionTypeId: t.transactionTypeId!,
      operationType: t.operationType!,
      amountIncVatTcy: t.amountIncVatTcy ?? 0,
      amountExVatTcy: t.amountExVatTcy ?? 0,
      amountIncVatLcy: computeLcyAmount(t.amountIncVatTcy, rateDetails),
      amountExVatLcy: computeLcyAmount(t.amountExVatTcy, rateDetails),
      ...(includeRateDetails && {
        transactionCurrencyRate: rateDetails ?? undefined,
      }),
    }));

  const handleRateChange = (rateDetails: TransactionCurrencyRateDetails) => {
    if (transactions && rateDetails) {
      setValue(
        'transactions',
        buildTransactions(transactions, rateDetails, false),
      );
      setFxRate(rateDetails);
    }
  };

  useEffect(() => {
    if (!transactions) return;
    onChange(buildTransactions(transactions, fxRate, true));
  }, [transactions]);

  const columns = [
    {
      key: 'transactionTypeId',
      label: 'General ledger account and transaction type',
      render: (_: unknown, index: number) => (
        <Controller
          name={`transactions.${index}.transactionTypeId`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth error={!!error}>
              <Select
                {...field}
                placeholder="Select transaction type"
                fullWidth
                renderValue={(value) => {
                  const option = transactionTypeOptions.find(
                    (option) => option.value === value,
                  );
                  return (
                    <>
                      <strong>{option?.label.split('-')[0]}</strong> -{' '}
                      {option?.label.split('-')[1]}
                    </>
                  );
                }}
              >
                {transactionTypeOptions.map((option) =>
                  !option.value ? (
                    <ListSubheader key={option.label}>
                      {option.label}
                    </ListSubheader>
                  ) : (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ),
                )}
              </Select>
            </FormControl>
          )}
        />
      ),
    },
    {
      key: 'debitAmount',
      label: isDifferentCurrency ? 'Debit (TCY)' : 'Debit amount',
      render: (row: Transaction) =>
        row.operationType === TransactionOperation.Debit
          ? numberToCurrencyString(row.amountIncVatTcy, {
              currency: transactionCurrency,
            })
          : '-',
    },
    {
      key: 'creditAmount',
      label: isDifferentCurrency ? 'Credit (TCY)' : 'Credit amount',
      render: (row: Transaction) =>
        row.operationType === TransactionOperation.Credit
          ? numberToCurrencyString(row.amountIncVatTcy, {
              currency: transactionCurrency,
            })
          : '-',
    },
    ...(isDifferentCurrency
      ? [
          {
            key: 'debitAmountLcy',
            label: 'Debit (LCY)',
            render: (row: Transaction) =>
              row.operationType === TransactionOperation.Debit
                ? numberToCurrencyString(row.amountIncVatLcy, {
                    currency: vehicle.baseCurrency,
                  })
                : '-',
          },
          {
            key: 'creditAmountLcy',
            label: 'Credit (LCY)',
            render: (row: Transaction) =>
              row.operationType === TransactionOperation.Credit
                ? numberToCurrencyString(row.amountIncVatLcy, {
                    currency: vehicle.baseCurrency,
                  })
                : '-',
          },
        ]
      : []),
  ];

  return (
    <Spacing size="md">
      {isDifferentCurrency && transactions && (
        <FxRateInformation
          transactionCurrency={transactionCurrency}
          transactionDate={transactionDate}
          vehicle={vehicle}
          fxRate={fxRate}
          onRateChange={handleRateChange}
        />
      )}
      <div>
        <Table
          title="Account transactions"
          columns={columns}
          tablebody={fields}
          fixedColumns={!isDifferentCurrency}
        />
        <TableBookEnd />
      </div>
    </Spacing>
  );
};

export default TransactionAllocator;

import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { dateToLabel } from 'further-ui/utils';
import { Api } from 'further-types/eis-wizard';

type TasklistItems = Api.GetEisWizardTaskListResponse['results'];

const getProcessCompletion = (tasklistItem: TasklistItems[number]) => {
  if (!tasklistItem.taxReliefElegible) return 'N/A';

  return tasklistItem.eisWizard?.id || tasklistItem.isKiFundCertificate
    ? 'Yes'
    : 'No';
};

const exportEisWizardTasklist = (exportData: TasklistItems) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('SEIS & EIS Certificates');

  worksheet.columns = [
    { header: 'Company Name', key: 'companyName' },
    { header: 'Share class', key: 'shareClass' },
    { header: 'Firm', key: 'firm' },
    { header: 'Allocation date', key: 'allocationDate' },
    { header: 'Funds allocated', key: 'fundsAllocated' },
    { header: 'Number of investments', key: 'numberOfInvestments' },
    { header: 'Number of tranches', key: 'numberOfTranches' },
    { header: 'Number of shares allocated', key: 'numberOfSharesAllocated' },
    { header: 'S/EIS eligible?', key: 'taxReliefElegible' },
    { header: 'S/EIS Process completed', key: 'processCompleted' },
    { header: 'Date of completion', key: 'dateOfCompletion' },
    { header: 'Investment type', key: 'investmentType' },
    { header: 'Description of share', key: 'descriptionOfShare' },
    { header: 'Nominal value of each share', key: 'nominalValueOfEachShare' },
    { header: 'Company address 1', key: 'companyAddress1' },
    { header: 'Company address 2', key: 'companyAddress2' },
    { header: 'Company city', key: 'companyCity' },
    { header: 'Company postcode', key: 'companyPostcode' },
    { header: 'Company country', key: 'companyCountry' },
    { header: 'Unique investment reference', key: 'uniqueInvestmentReference' },
    { header: 'Date of share issue', key: 'dateOfShareIssue' },
    { header: 'Termination date of shares', key: 'terminationDateOfShares' },
    { header: 'Name of company representative', key: 'companyRep' },
    { header: 'Capacity in which signed', key: 'capacityInWhichSigned' },
    { header: 'Signature date', key: 'signatureDate' },
    {
      header: 'Further internal allocation ID',
      key: 'shareAllocationUploadId',
    },
  ];

  exportData.forEach((item) => {
    worksheet.addRow({
      companyName: item.companyName,
      shareClass: item.shareClass,
      firm: item.firmName,
      allocationDate: dateToLabel(item.allocationDate),
      fundsAllocated: item.subscriptionAmount,
      numberOfInvestments: item.investmentsIds.length,
      numberOfTranches: item.fundNames.length,
      numberOfSharesAllocated: item.noOfShares,
      taxReliefElegible: item.taxReliefElegible ? 'Yes' : 'No',
      processCompleted: getProcessCompletion(item),
      dateOfCompletion: dateToLabel(item.completionDate),
      investmentType: item.isKiFundCertificate
        ? 'EIS'
        : item.eisWizard?.investmentType ?? '',
      descriptionOfShare: item.eisWizard?.descriptionShare ?? '',
      nominalValueOfEachShare: item.eisWizard?.nominalValueOfEachShare ?? '',
      companyAddress1: item.eisWizard?.address1 ?? '',
      companyAddress2: item.eisWizard?.address2 ?? '',
      companyCity: item.eisWizard?.city ?? '',
      companyPostcode: item.eisWizard?.postcode ?? '',
      companyCountry: item.eisWizard?.country ?? '',
      uniqueInvestmentReference: item.eisWizard?.uir ?? '',
      dateOfShareIssue: dateToLabel(item.eisWizard?.dateOfShareIssue),
      terminationDateOfShares: dateToLabel(
        item.eisWizard?.terminationDateOfShare,
      ),
      companyRep: item.eisWizard?.nameOfCompanyRepresentative ?? '',
      capacityInWhichSigned: item.eisWizard?.capicityInWhichSigned ?? '',
      signatureDate: dateToLabel(item.eisWizard?.signatureDate),
      shareAllocationUploadId: item.shareAllocationUploadId,
    });
  });

  workbook.xlsx.writeBuffer().then(function (buffer) {
    saveAs(
      new Blob([buffer], { type: 'application/octet-stream' }),
      'SEIS & EIS Certificates.xlsx',
    );
  });
};

export default exportEisWizardTasklist;

import {
  ExpenseReconciliationType,
  ExpenseType,
  IncomeReconciliationType,
  IncomeType,
} from 'further-types/lp/income-and-expenses';

export const IncomeAndExpensesLabels = {
  [IncomeType.DepositInterestIncome]: 'Deposit interest income',
  [IncomeType.DividendIncome]: 'Dividend income',
  [IncomeType.InterestIncome]: 'Interest income',
  [IncomeType.OtherIncome]: 'Other income',
  [IncomeReconciliationType.DepositInterestIncomePaid]:
    'Deposit interest income paid',
  [IncomeReconciliationType.DividendIncomePaid]: 'Dividend income paid',
  [IncomeReconciliationType.InterestIncomePaid]: 'Interest income paid',
  [IncomeReconciliationType.OtherIncomePaid]: 'Other income paid',
  [ExpenseType.AccountingFeeExpense]: 'Accounting fee expense',
  [ExpenseType.AdministrationFeeExpense]: 'Administration fee expense',
  [ExpenseType.AdvisoryFeeExpense]: 'Advisory fee expense',
  [ExpenseType.AifmFeeExpense]: 'AIFM fee expense',
  [ExpenseType.AuditFeeExpense]: 'Audit fee expense',
  [ExpenseType.BankCharges]: 'Bank charges',
  [ExpenseType.ConsultancyFeeExpense]: 'Consultancy fee expense',
  [ExpenseType.CustodianFeeExpense]: 'Custodian fee expense',
  [ExpenseType.DepositoryFeeExpense]: 'Depository fee expense',
  [ExpenseType.DirectorsFeeExpense]: 'Directors fee expense',
  [ExpenseType.IncentiveFeeExpense]: 'Incentive fee expense',
  [ExpenseType.InformationTechnologyExpense]: 'Information technology expense',
  [ExpenseType.InsuranceExpense]: 'Insurance expense',
  [ExpenseType.OtherExpense]: 'Other expense',
  [ExpenseType.LegalAndProfessionalFeesExpense]:
    'Legal and professional fees expense',
  [ExpenseType.ManagementFee]: 'Management fee',
  [ExpenseType.OperatingExpense]: 'Operating expense',
  [ExpenseType.OperationAndMaintenanceExpense]:
    'Operation and maintenance expense',
  [ExpenseType.OrganisationExpense]: 'Organisation expense',
  [ExpenseType.PlacementFeeExpense]: 'Placement fee expense',
  [ExpenseType.RegulatoryAndTaxFee]: 'Regulatory and tax fee',
  [ExpenseType.ReimbursementFeeExpense]: 'Reimbursement fee expense',
  [ExpenseType.SecretarialFeeExpense]: 'Secretarial fee expense',
  [ExpenseType.SundryExpense]: 'Sundry expense',
  [ExpenseType.TaxationExpense]: 'Taxation expense',
  [ExpenseType.TravelAndEntertainmentExpense]:
    'Travel and entertainment expense',
  [ExpenseType.InterestExpense]: 'Interest expense',

  [ExpenseReconciliationType.AccountingFeeExpensePaid]:
    'Accounting fee expense paid',
  [ExpenseReconciliationType.AdministrationFeeExpensePaid]:
    'Administration fee expense paid',
  [ExpenseReconciliationType.AdvisoryFeeExpensePaid]:
    'Advisory fee expense paid',
  [ExpenseReconciliationType.AifmFeeExpensePaid]: 'AIFM fee expense paid',
  [ExpenseReconciliationType.AuditFeeExpensePaid]: 'Audit fee expense paid',
  [ExpenseReconciliationType.BankChargesPaid]: 'Bank charges paid',
  [ExpenseReconciliationType.ConsultancyFeeExpensePaid]:
    'Consultancy fee expense paid',
  [ExpenseReconciliationType.CustodianFeeExpensePaid]:
    'Custodian fee expense paid',
  [ExpenseReconciliationType.DepositoryFeeExpensePaid]:
    'Depository fee expense paid',
  [ExpenseReconciliationType.DirectorsFeeExpensePaid]:
    'Directors fee expense paid',
  [ExpenseReconciliationType.IncentiveFeeExpensePaid]:
    'Incentive fee expense paid',
  [ExpenseReconciliationType.InformationTechnologyExpensePaid]:
    'Information technology expense paid',
  [ExpenseReconciliationType.InsuranceExpensePaid]: 'Insurance expense paid',
  [ExpenseReconciliationType.OtherExpensePaid]: 'Other expense paid',
  [ExpenseReconciliationType.LegalAndProfessionalFeesExpensePaid]:
    'Legal and professional fees expense paid',
  [ExpenseReconciliationType.ManagementFeePaid]: 'Management fee paid',
  [ExpenseReconciliationType.OperatingExpensePaid]: 'Operating expense paid',
  [ExpenseReconciliationType.OperationAndMaintenanceExpensePaid]:
    'Operation and maintenance expense paid',
  [ExpenseReconciliationType.OrganisationExpensePaid]:
    'Organisation expense paid',
  [ExpenseReconciliationType.PlacementFeeExpensePaid]:
    'Placement fee expense paid',
  [ExpenseReconciliationType.RegulatoryAndTaxFeePaid]:
    'Regulatory and tax fee paid',
  [ExpenseReconciliationType.ReimbursementFeeExpensePaid]:
    'Reimbursement fee expense paid',
  [ExpenseReconciliationType.SecretarialFeeExpensePaid]:
    'Secretarial fee expense paid',
  [ExpenseReconciliationType.SundryExpensePaid]: 'Sundry expense paid',
  [ExpenseReconciliationType.TaxationExpensePaid]: 'Taxation expense paid',
  [ExpenseReconciliationType.TravelAndEntertainmentExpensePaid]:
    'Travel and entertainment expense paid',
  [ExpenseReconciliationType.InterestExpensePaid]: 'Interest expense paid',
};

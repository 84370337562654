import { z } from 'zod';
import { ActionRef, ActionTypes } from 'further-types/lp/action';
import { withPaginationParams } from '../../helpers';
import { CalendarDay } from '../../../utils';

export const GetTransactionTypesForAction = z.object({
  actionRef: z.nativeEnum(ActionRef),
  actionType: z.nativeEnum(ActionTypes),
  amountIncVatTcy: z.number(),
  amountExVatTcy: z.number(),
});

export const ListActionsInput = withPaginationParams(
  z.object({
    unreconciledOnly: z.boolean().optional(),
    reconciledAndPartiallyReconciledOnly: z.boolean().optional(),
    searchQuery: z.string().optional(),
    dateRange: z
      .object({
        startDate: z.instanceof(CalendarDay),
        endDate: z.instanceof(CalendarDay),
      })
      .optional(),
  }),
  ['ledgerDate'],
);

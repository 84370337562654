export const renderMultiple = (value: number) => {
  const multiple = new Intl.NumberFormat('en-GB', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(value);

  return `${multiple}x`;
};

export const displayInvestmentMultiple = (
  startingValue?: number | null,
  currentValue?: number | null,
) => {
  if (!startingValue && startingValue !== 0) return '-';
  if (!currentValue && currentValue !== 0) return '-';
  if (startingValue === 0 && currentValue === 0) return '1.00x';
  return renderMultiple(currentValue / startingValue);
};

export default displayInvestmentMultiple;

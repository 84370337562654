import { Button, Avatar } from '@mui/material';
import React, { useMemo } from 'react';
import Table from 'components/Table';
import { useParams } from 'react-router-dom';
import { useDisclosure } from 'further-ui/hooks';

import Dialog from 'components/Dialog';
import AddAccountManager from './AddAccountManager';
import useCommonStyles from '../commonStyles';
import useFileUpload from 'hooks/ui/useFileUpload';
import { Delete, Edit } from '@mui/icons-material';
import { useFirm } from 'hooks/data/firm/useFirm';
import AlertDialog from 'components/AlertDialog';
import { useNotification } from 'hooks/ui/useNotification';
import { UploadType } from 'further-types/files';
import { TableActions } from 'components/Table';

function AccountManagersTable({ accountManagerData, setKeyManagersData }) {
  const { classes: commonClasses } = useCommonStyles();
  const { id } = useParams<{ id?: string }>();
  const { createAccountManager, updateAccountManager, deleteAccountManager } =
    useFirm({
      params: { firmId: id },
    });
  const { uploadFile } = useFileUpload();
  const { error, success } = useNotification();
  const [currentValue, setCurrentValue] = React.useState({
    name: '',
    description: '',
    order: '',
    profileImage: null,
    previewProfileImage: null,
  });
  const [errorCurrentValue, setErrorCurrentValue] = React.useState({
    name: '',
  });
  const deleteDisclosure = useDisclosure();
  const addDisclosure = useDisclosure();
  const managerId =
    addDisclosure?.actionProps?._id || deleteDisclosure.actionProps?._id;

  const handleAddMore = (rec) => {
    setCurrentValue({
      name: rec?.name || '',
      description: rec?.description || '',
      order: rec?.order || '',
      profileImage: rec?.profileImage || '',
      previewProfileImage: rec?.previewProfileImage || '',
    });
    addDisclosure.onToggle();
  };

  const onClickClose = () => {
    if (errorCurrentValue.name) {
      setErrorCurrentValue({ name: '' });
    }
    addDisclosure.onToggle();
    setCurrentValue({
      name: '',
      description: '',
      order: '',
      profileImage: null,
      previewProfileImage: null,
    });
  };

  const handleConfirmAddManager = async () => {
    if (currentValue.name) {
      if (errorCurrentValue.name) {
        setErrorCurrentValue({ name: '' });
      }
      if (id) {
        if (managerId) {
          //@ts-expect-error
          if (currentValue?.profileImage?.includes('.com/')) {
            currentValue.profileImage =
              //@ts-expect-error
              currentValue?.profileImage.split('.com/')[1];
          }
          //@ts-expect-error
          const response = await updateAccountManager.mutateAsync({
            ...currentValue,
            id: managerId,
            firmId: id,
          });
          if (response.data) {
            accountManagerData?.forEach((singleCurrent, index) => {
              if (singleCurrent?._id === response.data._id) {
                accountManagerData[index] = response.data;
              }
            });

            success(response.responseMsg);
          }
        } else {
          //@ts-expect-error
          const response = await createAccountManager.mutateAsync({
            accountManagerData: [currentValue],
            firmId: id,
          });
          accountManagerData.push(response.data);
          success(response.responseMsg);
        }
      } else {
        accountManagerData.push(currentValue);
      }
      setKeyManagersData(accountManagerData);
      addDisclosure.onToggle();
    } else {
      setErrorCurrentValue({ name: 'Manager name is required.' });
    }
  };

  const onDropFile = async (files) => {
    if (!files?.[0]?.path) return;

    try {
      const { url, filePath } = await uploadFile(
        files[0],
        UploadType.UserImages,
      );

      setCurrentValue({
        ...currentValue,
        //@ts-expect-error
        profileImage: filePath,
        profileImageurl: url,
        previewProfileImage: files[0].preview,
      });
    } catch (_) {
      error("Something went wrong - the file couldn't be uploaded.");
    }
  };

  const handleConfirmDeleteManager = async () => {
    if (managerId) {
      const response = await deleteAccountManager.mutateAsync(managerId);
      if (response.status === 200) {
        accountManagerData = accountManagerData?.filter(
          (value) => value?._id !== managerId,
        );

        success(response.data.responseMsg);
      }
    } else {
      accountManagerData = accountManagerData?.filter(
        (_, index) => index !== deleteDisclosure.actionProps.index,
      );
    }
    setKeyManagersData(accountManagerData);
    deleteDisclosure.onToggle();
  };

  const columns = useMemo(
    () => [
      {
        label: 'Profile Image',
        key: '',
        render: (elm) => <Avatar src={elm?.profileImage} />,
      },
      { label: 'Name', key: 'name', sort: false },
      { label: 'Description', key: 'description' },
      {
        label: 'Order',
        key: 'order',
      },
      {
        label: 'Actions',
        key: '',
        sort: false,
        render: (elm, index) => (
          <TableActions
            showAsDropdown
            actions={[
              {
                label: 'Edit',
                icon: Edit,
                color: 'primary',
                onClick: () => {
                  setCurrentValue({
                    ...elm,
                    previewProfileImage: elm?.profileImage,
                  });
                  addDisclosure.stageAction(elm);
                },
              },
              {
                label: 'Delete',
                icon: Delete,
                color: 'error',
                onClick: () => deleteDisclosure.stageAction({ ...elm, index }),
              },
            ]}
          />
        ),
      },
    ],
    [id],
  );

  return (
    <>
      <div className={commonClasses.container}>
        <Table
          pagination={false}
          tablebody={accountManagerData}
          columns={columns}
          order={'asc'}
          orderBy={'name'}
          page={1}
          rowsPerPage={1}
          variant="nohover"
        />
        <div className={commonClasses.buttonContainer}>
          <Button
            className={commonClasses.addMoreButton}
            onClick={handleAddMore}
            variant="text"
            color="primary"
            size="small"
          >
            Add more
          </Button>
        </div>
      </div>

      <Dialog
        open={addDisclosure.isOpen}
        title={managerId ? `Update manager` : `Add new manager`}
        onConfirm={handleConfirmAddManager}
        onClose={onClickClose}
        btnLabels={{ cancel: 'Cancel', confirm: 'Save' }}
      >
        <AddAccountManager
          setCurrentValue={setCurrentValue}
          currentValue={currentValue}
          onDropFile={onDropFile}
          errorCurrentValue={errorCurrentValue}
        />
      </Dialog>
      <AlertDialog
        open={deleteDisclosure.isOpen}
        onClose={deleteDisclosure.onClose}
        onConfirm={handleConfirmDeleteManager}
        title={`Delete individual manager`}
        content={
          <p>
            Are you sure you wish to delete this manager? This cannot be undone.
          </p>
        }
        btnLabels={{
          cancel: 'Go back',
          confirm: 'Delete manager',
        }}
      />
    </>
  );
}

export default AccountManagersTable;

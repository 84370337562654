import React from 'react';
import Table from 'components/Table';
import {
  numberToCurrencyString,
  dateToLabel,
  numberToDisplayString,
} from 'further-ui/utils';

import { Api } from 'further-types/investment';
import useStyles from './styles';
import { Box, Tooltip } from '@mui/material';
import { CompareArrows } from '@mui/icons-material';
import { createInvestmentEditRoute } from 'adminConstants/routes';

type Exit = Api.GetInvestmentExitsResponse['exits'][number];

type Props = {
  exitedShareholdings: Array<Exit>;
};

const ShareholdingCompanyName: React.FC<{
  exit: Exit;
}> = ({ exit }) => {
  const { classes } = useStyles();

  if (!exit.isTransferredOut && !exit.isTransferredIn)
    return <>{exit.company?.name}</>;

  const tooltipText = exit.isTransferredOut
    ? `${exit.noOfShares} exited shares were transferred to  <a class="${
        classes.tooltipLink
      }" href="${createInvestmentEditRoute(
        exit.investmentId,
      )}" target="_blank">${exit.investorName}</a> on ${dateToLabel(
        exit.transferDate,
      )}.`
    : `${exit.noOfShares} exited shares were transferred from <a class="${
        classes.tooltipLink
      }" href="${createInvestmentEditRoute(
        exit.sourceInvestmentId ?? '',
      )}" target="_blank">${exit.sourceInvestorName}</a> on ${dateToLabel(
        exit.transferDate,
      )}.`;

  return (
    <>
      <Tooltip
        title={<div dangerouslySetInnerHTML={{ __html: tooltipText }} />}
      >
        <Box alignItems="center" display="flex" gap={4}>
          {exit.company?.name} <CompareArrows className={classes.splitIcon} />
        </Box>
      </Tooltip>
    </>
  );
};

const ExitedShareholdings: React.FC<Props> = ({ exitedShareholdings }) => {
  const { classes } = useStyles();

  const columns = [
    {
      label: 'Company',
      key: 'companyName',
      render: (elm: Exit) => <ShareholdingCompanyName exit={elm} />,
    },
    {
      label: 'Exit date',
      key: 'exitDate',
      render: (elm: Exit) => dateToLabel(elm.date),
    },
    {
      label: 'Investment date',
      key: 'allocationDate',
      render: (elm: Exit) => {
        return elm.isAmendment ? '(Edit)' : dateToLabel(elm.allocationDate);
      },
    },
    {
      label: 'Capital allocated',
      key: 'capitalAllocated',
      render: (elm: Exit) =>
        elm.isAmendment
          ? ''
          : numberToCurrencyString(elm.capitalAllocated, { fallback: '-' }),
    },
    {
      label: 'Shares exited',
      key: 'noOfShares',
      render: (elm: Exit) =>
        elm.isAmendment ? '' : numberToDisplayString(elm?.noOfShares),
    },
    {
      label: 'Initial share price',
      key: 'intitalSharePrice',
      render: (elm: Exit) => {
        return elm.isAmendment
          ? ''
          : numberToCurrencyString(elm.initialSharePrice, {
              unlimitedDp: true,
            });
      },
    },
    {
      label: 'Exit share price',
      key: 'exitedSharePrice',
      render: (elm: Exit) => {
        const value = elm?.sharePrice;
        return numberToCurrencyString(value, { unlimitedDp: true });
      },
    },
    {
      label: 'Gross exit value',
      key: 'valueBeforeDeductions',
      render: (elm: Exit) => numberToCurrencyString(elm.valueBeforeDeductions),
    },
    {
      label: 'Performance fee',
      key: 'performanceFee',
      render: (elm: Exit) => numberToCurrencyString(elm.performanceFeesCharged),
    },
    {
      label: 'Accrued fees',
      key: 'accruedFeesCharged',
      render: (elm: Exit) => numberToCurrencyString(elm.accruedFeesCharged),
    },
    {
      label: 'Net exit value',
      key: 'netExitValue',
      render: (elm: Exit) => numberToCurrencyString(elm.netExitValue),
    },
    {
      label: 'Gain/Loss',
      key: 'gainOrLoss',
      render: (elm: Exit) => (
        <div className={classes.noWrap}>
          {numberToCurrencyString(elm.gainLossValue, {
            fallback: '-',
          })}
        </div>
      ),
    },
    {
      label: 'Income tax relief',
      key: 'incomeTaxRelief',
      render: (elm: Exit) => numberToCurrencyString(elm.incomeTaxRelief),
    },
  ];

  const getTotalCapitalAllocatedAndExitValue = (dataArray: Array<Exit>) => {
    let totalCapitalAllocated = 0;
    let totalExitValue = 0;
    let grossExitValue = 0;
    let performanceFee = 0;
    let gainLoss = 0;
    let accruedFees = 0;

    dataArray.forEach((item) => {
      totalCapitalAllocated += item.isAmendment ? 0 : item.capitalAllocated;
      totalExitValue +=
        item.valueAfterDeductions - (item.accruedFeesCharged ?? 0);
      performanceFee += item.performanceFeesCharged;
      grossExitValue += item.valueBeforeDeductions;
      gainLoss += item.gainLossValue;
      accruedFees += item.accruedFeesCharged;
    });

    return {
      totalCapitalAllocated: numberToCurrencyString(totalCapitalAllocated),
      totalExitValue: numberToCurrencyString(totalExitValue),
      grossExitValue: numberToCurrencyString(grossExitValue),
      performanceFee: numberToCurrencyString(performanceFee),
      accruedFees: numberToCurrencyString(accruedFees),
      gainLoss: numberToCurrencyString(gainLoss),
    };
  };

  const totals = getTotalCapitalAllocatedAndExitValue(exitedShareholdings);

  return (
    <Table
      //@ts-expect-error
      columns={columns.map((col) => ({
        ...col,
        sort: false,
        getCellClassName: (exit: Exit & { isTransferredOut: boolean }) =>
          `${exit.isTransferredOut ? classes.inactiveCell : ''} ${
            exit.isAmendment ? classes.amendmentCell : ''
          }`,
      }))}
      tablebody={exitedShareholdings}
      pagination={false}
      emptyMessage="No exited shareholdings found"
      variant="nohover"
      TableFooter={
        <tfoot>
          <tr className={classes.summaryRow}>
            <td className={classes.summaryValue} colSpan={3} />
            <td className={classes.summaryValue}>
              {totals.totalCapitalAllocated}
            </td>
            <td className={classes.summaryValue} colSpan={3} />
            <td className={classes.summaryValue}>{totals.grossExitValue}</td>
            <td className={classes.summaryValue}>{totals.performanceFee}</td>
            <td className={classes.summaryValue}>{totals.accruedFees}</td>
            <td className={classes.summaryValue}>{totals.totalExitValue}</td>
            <td className={classes.summaryValue} colSpan={3}>
              {totals.gainLoss}
            </td>
          </tr>
        </tfoot>
      }
    />
  );
};

export default ExitedShareholdings;

import { DeleteOutline, Edit } from '@mui/icons-material';
import { TableActions } from 'components/Table';
import { useDisclosure } from 'further-ui/hooks';
import { numberToCurrencyString } from 'further-ui/utils';

type Props = {
  handleDeleteAccount: (id: string) => void;
  accountFormDisclosure: ReturnType<
    typeof useDisclosure<{ accountId: string }>
  >;
};

export default function getColumns({
  handleDeleteAccount,
  accountFormDisclosure,
}: Props) {
  return [
    {
      field: 'accountName',
      headerName: 'Account',
      flex: 1,
    },
    {
      field: 'transactionTypes',
      headerName: 'Transaction types',
      renderCell: ({ row }) => {
        return `(${row.transactionTypes.length})`;
      },
      flex: 1,
    },
    {
      field: 'runningBalance',
      headerName: 'Running balance',
      renderCell: ({ row }) => {
        return numberToCurrencyString(row.runningBalance);
      },
      flex: 1,
    },
    {
      field: 'actions',
      headerName: '',
      width: 100,
      renderCell: ({ row }) => {
        return (
          <TableActions
            actions={[
              {
                label: 'Edit',
                icon: Edit,
                color: 'primary',
                onClick: () => {
                  accountFormDisclosure.stageAction({
                    accountId: row.id ?? '',
                  });
                  accountFormDisclosure.onOpen();
                },
              },
              {
                disabled: row.transactionTypes.length > 0,
                tooltip:
                  row.transactionTypes.length > 0
                    ? 'This account cannot be deleted while active transaction types exist within it.'
                    : undefined,
                label: 'Delete',
                icon: DeleteOutline,
                color: 'error',
                onClick: !row.transactionTypes.length
                  ? () => handleDeleteAccount(row.id ?? '')
                  : undefined,
              },
            ]}
          />
        );
      },
    },
  ];
}

import { z } from 'zod';
import { withSortableParams } from '../../helpers';
import { ActionRef } from 'further-types/lp/action';
import { CalendarDay } from '../../../utils';

export const ListTransactionsInput = withSortableParams(
  z.object({
    dateRange: z
      .object({
        startDate: z.instanceof(CalendarDay),
        endDate: z.instanceof(CalendarDay),
      })
      .optional(),
    searchQuery: z.string().optional(),
    actionRefs: z.array(z.nativeEnum(ActionRef)).optional(),
  }),
  [
    'accountLabel',
    'accountSection',
    'transactionType',
    'transactionDate',
    'vatRate',
    'amountIncVatLcy',
    'amountIncVatTcy',
    'transactionCurrency',
    'description',
    'ledgerComment',
    'counterpartyLabel',
    'counterpartyRef',
    'createdByLabel',
    'bankAccountLabel',
  ],
);

export const ExportTransactionListInput = z.object({
  dateRange: z
    .object({
      startDate: z.instanceof(CalendarDay),
      endDate: z.instanceof(CalendarDay),
    })
    .optional(),
  searchQuery: z.string().optional(),
  actionRefs: z.array(z.nativeEnum(ActionRef)).optional(),
});

import { useEffect, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Switch, Divider } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { makeStyles } from 'tss-react/mui';
import TextField from 'components/FormElements/AppTextInput';
import AppSelectBox from 'components/FormElements/AppSelectBox';
import PageContainer from 'components/PageContainer';
import {
  addCompany,
  getCompanyById,
  updateCompany,
} from '@redux/actions/Company';
import { runValidationRules } from 'helpers/company/formValidations';
import NonVctFields from './NonVctFields';
import DividendRows from './DividendRows';
import ShareSplitRows from './ShareSplitRows';
import RevaluationRows from './RevaluationRows';
import NewsRows from './NewsRows';
import FieldRow from 'components/FormElements/FieldRow';
import StageAndSector from './StageAndSector';
import { COMPANY, COMPANY_DUPLICATE } from 'adminConstants/routes';
import useFileUpload from 'hooks/ui/useFileUpload';
import AllocationRecords from './AllocationRecords';
import useApiRequestHandler from 'hooks/ui/useApiRequestHandler';
import { InitialSharePrice } from './InitialSharePrice';
import { UploadType } from 'further-types/files';
import { useGetRole } from 'hooks/ui/useGetRole';
import { useFunds } from 'hooks/data/fund/useFunds';
import { FirmSelector } from 'components/FirmSelector';
import { toRequestDate } from 'further-ui/utils';
import { useQueryClient } from '@tanstack/react-query';
import Shareholdings from './Shareholdings';
import PageContent from 'components/PageContent';
import RecordView from 'components/RecordView';
import ButtonGroup from 'components/ButtonGroup';
import Button from 'components/Button';
import PageSection from 'components/PageSection';
import PageHeader from 'components/PageHeader';
import Heading from 'components/Heading';
import ResponsiveActions from 'components/PageHeader/ResponsiveActions';
import { Row } from 'components/Layout';
import Spacing from 'components/Spacing';

const useStyles = makeStyles()(() => ({
  marginLeftBtn: {
    marginLeft: 10,
  },
  addRevalutionBtn: {
    textAlign: 'end',
    marginTop: '14px',
  },
  errorCk: {
    color: '#f44336',
    marginTop: '4px',
    fontSize: '0.75rem',
  },
  smallFlexContainer: {
    alignItems: 'center',
    display: 'flex',
  },
  spanLabel: {
    color: '#C4C4C4',
    fontSize: 12,
    fontWeight: 'bold',
    width: 115,
  },
  divider: {
    marginBottom: 32,
    marginTop: 16,
  },
  deleteButton: {
    color: '#E8453C',
  },
  wideColumn: {
    width: '250px',
  },
  mediumColumn: {
    width: '150px',
  },
  shareholdingsContainer: {
    marginTop: 42,
  },
}));

const SectionDivider = ({ classes }) => (
  <Grid size={{ md: 12, xs: 12 }}>
    <Divider className={classes.divider} />
  </Grid>
);

const AddUpdateCompany: React.FC = () => {
  const queryClient = useQueryClient();
  const { id: companyId } = useParams<{ id?: string }>();
  const isDuplicate = useRouteMatch(COMPANY_DUPLICATE)?.isExact ?? false;

  const breadcrumbs = [
    { label: 'Dashboard' },
    { label: 'Company', link: COMPANY },
    {
      label: companyId && !isDuplicate ? 'Update Company' : 'Add Company',
      link: '/',
      isActive: true,
    },
  ];
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const history = useHistory();
  const { isSuperAdmin, firmId: usersFirmId } = useGetRole();
  const handleApiRequest = useApiRequestHandler();
  const [value, setValue] = useState({
    tradingName: '',
    legalName: '',
    url: '',
    twitterUrl: '',
    linkedinUrl: '',
    description: '',
    initialPrice: '',
    date: undefined,
    firmId: usersFirmId || '',
    shareClass: '',
    nominalShareValue: '',
    stage: '',
    sector: '',
    companyLogo: null,
    previewCompanyLogo: null,
    companiesHouseNumber: null,
  });
  const [fundId, setFundId] = useState('');
  const [news, setNews] = useState([]);
  const [revaluation, setRevaluation] = useState([]);
  const [dividends, setDividends] = useState([]);
  const [errorText, setErrorText] = useState<Record<string, string>>({});
  const [errorTextRevalution, setErrorTextRevalution] = useState<
    Array<Record<string, string>>
  >([]);
  const [errorTextDividends, setErrorTextDividends] = useState<
    Array<Record<string, string>>
  >([]);
  const [errorTextNews, setErrorTextNews] = useState<
    Array<Record<string, string>>
  >([]);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const [isVct, setIsVct] = useState(false);
  const { uploadFile } = useFileUpload();

  const { tranches } = useFunds({
    firmId: value.firmId,
    isVCT: true,
    disabled: !isVct,
  });

  const validate = () => {
    const { dividendsError, errors, revalutionError, newsError, isError } =
      runValidationRules(isVct, value, revaluation, news, dividends, fundId);

    setErrorTextDividends(dividendsError);
    setErrorText(errors);
    setErrorTextRevalution(revalutionError);

    setErrorTextNews(newsError);

    return {
      errors,
      isError,
    };
  };

  const onSubmit = async () => {
    setSubmitDisabled(true);
    const validateData = validate();
    if (!validateData.isError) {
      let response;
      value.date = value.date !== 'Invalid date' ? value.date : undefined;
      let values = {
        id: undefined,
        fundId: undefined,
        revaluation: revaluation.map((rev) => ({
          //@ts-expect-error
          ...rev,
          //@ts-expect-error
          date: toRequestDate(new Date(rev.date)),
        })),
        news: news.map((news) => ({
          //@ts-expect-error
          ...news,
          //@ts-expect-error
          date: toRequestDate(new Date(news.date)),
        })),
        isVCT: isVct,
        firmId: value.firmId,
        initialPrice: value.initialPrice,
        //@ts-expect-error
        date: toRequestDate(new Date(value.date)),
        dividends: dividends.map((dividend) => ({
          //@ts-expect-error
          ...dividend,
          //@ts-expect-error
          date: toRequestDate(new Date(dividend.date)),
        })),
        shareClass: value.shareClass,
        companiesHouseNumber: value?.companiesHouseNumber,
      };
      //@ts-expect-error
      if (value?.companyLogo?.includes('.com/')) {
        //@ts-expect-error
        value.companyLogo = value?.companyLogo.split('.com/')[1];
      }

      if (isVct) {
        values = {
          ...values,
          //@ts-expect-error
          fundId,
        };
      } else {
        values = {
          ...value,
          ...values,
        };
      }

      await handleApiRequest(async (showSuccess, showError) => {
        if (companyId && !isDuplicate) {
          //@ts-expect-error
          values.id = companyId;
          // @ts-expect-error
          response = await dispatch(updateCompany({ ...values }));
        } else {
          response = await dispatch(addCompany({ ...values }));
        }

        if (response.status === 200) {
          showSuccess(response.data.responseMsg);
          queryClient.invalidateQueries({ queryKey: ['company'] });
          queryClient.invalidateQueries({ queryKey: ['groupedCompanies'] });
          history.push(COMPANY);
        } else {
          showError(response.data.responseMsg);
          setValue({
            ...value,
            url: value?.url,
            twitterUrl: value?.twitterUrl,
            linkedinUrl: value?.linkedinUrl,
          });
        }
      });
      setSubmitDisabled(false);
    }
    setSubmitDisabled(false);
  };

  const handleChangeLogo = async (file) => {
    if (file?.[0]?.path) {
      await handleApiRequest(async () => {
        const { filePath } = await uploadFile(file[0], UploadType.CompanyLogos);

        setValue({
          ...value,
          //@ts-expect-error
          companyLogo: filePath,
          previewCompanyLogo: file[0]?.preview,
        });
      }, "Something went wrong - the file couldn't be uploaded");
    }
  };

  useEffect(() => {
    const getCompanyDetail = async (id) => {
      await handleApiRequest(async () => {
        const response = await dispatch<any>(getCompanyById(id));
        if (response) {
          let data = {
            tradingName: response.tradingName,
            legalName: response.legalName,
            url: response?.url?.replace('https://', ''),
            twitterUrl: response?.twitterUrl?.replace('https://', ''),
            linkedinUrl: response?.linkedinUrl?.replace('https://', ''),
            description: response?.description,
            sector: response?.sector,
            stage: response?.stage,
            firmId: response?.firmId,
            date: undefined,
            shareClass: response?.shareClass,
            companyLogo: response?.companyLogo,
            previewCompanyLogo: response?.companyLogo,
            companiesHouseNumber: response?.companiesHouseNumber,
            nominalShareValue: response?.nominalShareValue,
            initialPrice: undefined,
          };
          if (!isDuplicate) {
            data = {
              ...data,
              initialPrice: response?.initialPrice,
              date: response?.date,
            };
            setDividends(response?.dividends);
            if (response?.revaluation) {
              setRevaluation(response?.revaluation);
            }
            if (response?.news) {
              response.news.forEach((newsItem) => {
                newsItem.url = newsItem?.url?.replace('https://www.', '');
              });
              setNews(response?.news);
            }
          }
          //@ts-expect-error
          setValue(data);
          setFundId(response?.fundId);
          setIsVct(response?.isVCT);
          setLoading(false);
        }
      });
    };

    if (companyId) {
      getCompanyDetail(companyId);
    } else {
      setLoading(false);
    }
  }, [dispatch]);

  return (
    <>
      <PageContainer
        heading={
          companyId && !isDuplicate
            ? 'Companies: Update Company'
            : 'Companies: Add Company'
        }
        breadcrumbs={breadcrumbs}
        loading={loading}
      >
        <PageContent>
          <Spacing>
            <PageHeader>
              <Heading variant="h2" noMargin>
                Company details
              </Heading>
              <ResponsiveActions>
                <Row centered spacing="xs">
                  <span>EIS/SEIS</span>
                  <Switch
                    checked={isVct}
                    onChange={(event) => {
                      if (event.target.checked) {
                        setNews([]);
                      }
                      setErrorText({});
                      setIsVct(!isVct);
                    }}
                  />
                  <span>VCT</span>
                </Row>
              </ResponsiveActions>
            </PageHeader>
            <RecordView>
              {isSuperAdmin && (
                <FieldRow title="Firm*" centerTitle>
                  <FirmSelector
                    firmId={value.firmId}
                    onChange={(firmId) => {
                      setValue({ ...value, firmId });
                      setFundId('');
                    }}
                    placeholder="Select a firm"
                    required
                    error={!!errorText.firmId}
                    helperText={errorText.firmId}
                  />
                </FieldRow>
              )}

              {!isVct && (
                <NonVctFields
                  values={value}
                  setValues={setValue}
                  errorText={errorText}
                  handleChangeLogo={handleChangeLogo}
                  classes={classes}
                />
              )}
              {isVct && (
                <FieldRow title="Fund*" centerTitle>
                  <AppSelectBox
                    disabled={!value.firmId || !tranches.data?.length}
                    required
                    data={tranches.data}
                    valueKey="_id"
                    label={
                      value.firmId && !tranches.data?.length
                        ? 'No VCT funds found'
                        : 'Fund'
                    }
                    name="fundId"
                    labelKey="fundName"
                    variant="outlined"
                    value={fundId}
                    error={!!errorText.fundId}
                    helperText={errorText.fundId}
                    onChange={(event) => {
                      //@ts-expect-error
                      setFundId(event.target.value);
                    }}
                  />
                </FieldRow>
              )}
              <SectionDivider classes={classes} />
              <FieldRow title="Share class*" centerTitle>
                <TextField
                  name="shareClass"
                  placeholder="Enter share class. e.g. Ordinary"
                  required
                  fullWidth
                  onChange={(event) =>
                    setValue({ ...value, shareClass: event.target.value })
                  }
                  value={value.shareClass}
                  error={!!errorText.shareClass}
                  helperText={errorText.shareClass}
                />
              </FieldRow>
              <FieldRow title="Nominal share value" centerTitle>
                <TextField
                  name="nominalShareValue"
                  placeholder="Enter nominal share value"
                  fullWidth
                  onChange={(event) =>
                    setValue({
                      ...value,
                      nominalShareValue: event.target.value,
                    })
                  }
                  value={value.nominalShareValue}
                />
              </FieldRow>
              <PageSection showDivider title="Share price record">
                <RecordView size="full">
                  <InitialSharePrice
                    classes={classes}
                    //@ts-expect-error
                    setValue={setValue}
                    value={value}
                    errorText={errorText}
                  />
                  <RevaluationRows
                    revaluations={revaluation}
                    //@ts-expect-error
                    setRevaluations={setRevaluation}
                    errorTextRevaluation={errorTextRevalution}
                    //@ts-expect-error
                    companyId={companyId}
                    isDuplicate={isDuplicate}
                  />
                </RecordView>
              </PageSection>

              <PageSection showDivider title="Stage and sector">
                <StageAndSector
                  values={value}
                  setValues={setValue}
                  errorText={errorText}
                />
              </PageSection>

              <ButtonGroup>
                <Button
                  variant="outlined"
                  onClick={() => history.push(COMPANY)}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onSubmit}
                  disabled={submitDisabled}
                  loading={submitDisabled}
                >
                  {companyId && !isDuplicate ? 'Update' : 'Save'}
                </Button>
              </ButtonGroup>
            </RecordView>
          </Spacing>
        </PageContent>
      </PageContainer>

      {companyId && !isDuplicate && (
        <PageContainer
          heading="Additional company data"
          className={classes.shareholdingsContainer}
          loading={loading}
        >
          <PageContent>
            <Spacing>
              <RecordView>
                <PageSection title="Dividend record">
                  <DividendRows
                    dividends={dividends}
                    setDividends={setDividends}
                    errorTextDividends={errorTextDividends}
                    classes={classes}
                  />
                </PageSection>
                {!isVct && (
                  <>
                    <PageSection showDivider title="News">
                      <NewsRows
                        news={news}
                        setNews={setNews}
                        errorTextNews={errorTextNews}
                        classes={classes}
                      />
                    </PageSection>
                  </>
                )}
                <PageSection showDivider title="Share split record">
                  <ShareSplitRows companyId={companyId} />
                </PageSection>

                <ButtonGroup>
                  <Button
                    variant="outlined"
                    onClick={() => history.push(COMPANY)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onSubmit}
                    disabled={submitDisabled}
                    loading={submitDisabled}
                  >
                    Update
                  </Button>
                </ButtonGroup>
              </RecordView>
            </Spacing>
          </PageContent>
        </PageContainer>
      )}

      {companyId && !isDuplicate && (
        <PageContainer
          heading="Shareholding & allocation history"
          className={classes.shareholdingsContainer}
          loading={loading}
        >
          <PageContent>
            <Spacing>
              <RecordView size="full">
                <PageSection title="Allocation record">
                  <AllocationRecords companyId={companyId} />
                </PageSection>

                <PageSection showDivider title="Shareholdings">
                  <Shareholdings
                    companyId={companyId}
                    classes={classes}
                    tradingName={value.tradingName}
                  />
                </PageSection>
              </RecordView>
            </Spacing>
          </PageContent>
        </PageContainer>
      )}
    </>
  );
};
export default AddUpdateCompany;

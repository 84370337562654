import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { dateToLabel, renderMultiple } from 'further-ui/utils';
import { format } from 'date-fns';
import { AccountType } from 'further-types/investor';

export const exportExcelSheet = (exportInvestment) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(`Subscriptions-${Date.now()}`);

  worksheet.columns = [
    { header: 'First name', key: 'firstName' },
    { header: 'Middle name', key: 'middleName' },
    { header: 'Last/corporate name', key: 'lastName' },
    { header: 'Email address', key: 'email' },
    { header: 'Date of birth', key: 'dob' },
    { header: 'System subscription ID', key: 'systemId' },
    { header: 'Phone number', key: 'phoneNumber' },
    {
      header: 'Firm',
      key: 'firm',
    },
    {
      header: 'Fund',
      key: 'fund',
    },
    {
      header: 'Investment date',
      key: 'investmentDate',
      style: { numFmt: 'dd/mm/yyyy' },
    },
    {
      header: 'Total commitment',
      key: 'investmentAmount',
      style: { numFmt: '£#,##0.00' },
    },
    {
      header: 'Advice fee',
      key: 'adviserFee',
      style: { numFmt: '£#,##0.00' },
    },
    {
      header: 'Funds received',
      key: 'fundsReceived',
      style: { numFmt: '£#,##0.00' },
    },
    {
      header: 'Commitment received',
      key: 'commitmentReceived',
      style: { numFmt: '£#,##0.00' },
    },
    {
      header: 'Adviser',
      key: 'adviser',
    },
    {
      header: 'Organisation',
      key: 'organisation',
    },
    {
      header: 'Adviser email',
      key: 'adviserEmail',
    },
    {
      header: 'Advised client suitability assessment',
      key: 'advisedClientSuitabilityAssessment',
    },

    {
      header: 'Commission',
      key: 'commission',
      style: { numFmt: '£#,##0.00' },
    },
    {
      header: 'External investment ID',
      key: 'externalInvestmentID',
    },

    {
      header: 'Amount invested',
      key: 'amountInvested',
      style: { numFmt: '£#,##0.00' },
    },
    {
      header: 'Cash fees',
      key: 'cashFeesOnInitialInvestment',
      style: { numFmt: '£#,##0.00' },
    },
    {
      header: 'Uninvested capital',
      key: 'uninvestedCapital',
      style: { numFmt: '£#,##0.00' },
    },
    {
      header: 'Reserved for future cash fees',
      key: 'reservedForFutureCashFees',
      style: { numFmt: '£#,##0.00' },
    },
    {
      header: 'Transferred to investor cash balance',
      key: 'transferredToInvestorCashBalance',
      style: { numFmt: '£#,##0.00' },
    },
    {
      header: 'Held awaiting investment',
      key: 'heldByFundAwaitingInvestment',
      style: { numFmt: '£#,##0.00' },
    },
    {
      header: 'Total exit value',
      key: 'totalExitValue',
      style: { numFmt: '£#,##0.00' },
    },
    {
      header: 'Dividends',
      key: 'dividends',
      style: { numFmt: '£#,##0.00' },
    },
    {
      header: 'Interest',
      key: 'interest',
      style: { numFmt: '£#,##0.00' },
    },
    {
      header: 'Undeployed capital transferred to investor cash balance',
      key: 'undeployedInitialInvestmentTransferredToInvestorCashBalance',
      style: { numFmt: '£#,##0.00' },
    },
    {
      header: 'Accrued fees charged to investor',
      key: 'lessAccruedFeesChargedToInvestor',
      style: { numFmt: '£#,##0.00' },
    },
    {
      header: 'Total realised return',
      key: 'totalRealisedReturn',
      style: { numFmt: '£#,##0.00' },
    },
    {
      header: 'Current value of unexited shareholdings',
      key: 'currentValueOfUnexitedShareholdings',
      style: { numFmt: '£#,##0.00' },
    },
    {
      header: 'Undeployed capital held by fund',
      key: 'undeployedInitialInvestmentHeldByFund',
      style: { numFmt: '£#,##0.00' },
    },
    {
      header: 'Accrued fees not yet charged',
      key: 'lessAccruedFeesNotYetCharged',
      style: { numFmt: '£#,##0.00' },
    },
    {
      header: 'Total unrealised return',
      key: 'totalUnrealisedReturn',
      style: { numFmt: '£#,##0.00' },
    },
    {
      header: 'Total investment value',
      key: 'totalInvestmentValue',
      style: { numFmt: '£#,##0.00' },
    },
    {
      header: 'Multiple on investment',
      key: 'multipleOnInvestment',
    },
    {
      header: 'Multiple on invested capital',
      key: 'multipleOnInvestedCapital',
    },
  ];
  exportInvestment?.forEach((item) => {
    const {
      _id,
      investorId,
      firmId,
      fundId,
      investmentAmount,
      investmentDate,
      investmentBalance = {},
      adviserFee,
      adviserId = {},
      organisationUserId = {},
      commissionFee,
      externalInvestmentId,
      advisedClientCertifiedAt = null,
    } = item;
    const {
      deploymentSummary,
      realisedValue,
      unrealisedValue,
      investmentValue,
      valueChangesOnInitialInvestment = {},
    } = investmentBalance;
    worksheet.addRows([
      {
        firstName: investorId?.firstName || '',
        middleName: investorId?.middleName || '',
        lastName:
          investorId?.accountType === AccountType.LegalEntity
            ? investorId?.registeredName || ''
            : investorId?.lastName || '',
        email: investorId?.email,
        dob: dateToLabel(investorId?.dob),
        systemId: _id,
        phoneNumber: investorId?.phoneNumber,
        firm: firmId?.firmName,
        fund: fundId?.fundName,
        investmentDate: new Date(investmentDate),
        investmentAmount,
        fundsReceived: deploymentSummary?.fundsReceived,
        adviser: adviserId?.fullName ? adviserId.fullName : 'N/A',
        organisation: adviserId?.organisation?.name
          ? `${adviserId?.organisation?.name}${
              adviserId?.organisation?.parent?.name
                ? ` (${adviserId?.organisation?.parent?.name})`
                : ''
            }`
          : 'N/A',
        adviserEmail: adviserId?.email,
        advisedClientSuitabilityAssessment: advisedClientCertifiedAt
          ? `Confirmed by ${
              organisationUserId?.fullName
                ? `${organisationUserId?.fullName} on behalf of ${adviserId?.fullName}`
                : adviserId?.fullName
            } - ${format(
              new Date(advisedClientCertifiedAt),
              'dd/MM/yyyy hh:mm a',
            )}`
          : 'N/A - Direct investment',
        adviserFee: adviserFee || 0,
        commission: commissionFee || 0,
        externalInvestmentID: externalInvestmentId,
        commitmentReceived: deploymentSummary?.commitmentReceived,
        amountInvested: deploymentSummary?.purchasePriceOfAllShareholdings,
        cashFeesOnInitialInvestment:
          deploymentSummary?.cashFeesOnInitialInvestment,
        uninvestedCapital: deploymentSummary?.undeployedCommitment,
        reservedForFutureCashFees: deploymentSummary?.reservedForFutureCashFee,
        transferredToInvestorCashBalance:
          deploymentSummary?.transferredToInvestorCashBalance,
        heldByFundAwaitingInvestment:
          deploymentSummary?.heldByFundAwaitingInvestment,
        totalExitValue: realisedValue?.exitProceeds,
        dividends: realisedValue?.dividends,
        interest: realisedValue?.interest,
        undeployedInitialInvestmentTransferredToInvestorCashBalance:
          realisedValue?.residualCash,
        lessAccruedFeesChargedToInvestor:
          realisedValue?.accruedFeesChargedToInvestor,
        totalRealisedReturn: realisedValue?.total,
        currentValueOfUnexitedShareholdings:
          unrealisedValue?.valueOfAllShareholdings,
        undeployedInitialInvestmentHeldByFund:
          deploymentSummary?.undeployedInitialInvestmentHeldByFund,
        lessAccruedFeesNotYetCharged: unrealisedValue?.accruedFeesNotYetCharged,
        totalUnrealisedReturn: unrealisedValue?.total,
        totalInvestmentValue: investmentValue,
        multipleOnInvestment: renderMultiple(
          valueChangesOnInitialInvestment.multipleOnInvestment,
        ),
        multipleOnInvestedCapital: renderMultiple(
          valueChangesOnInitialInvestment.multipleOnInvestedCapital,
        ),
      },
    ]);
  });
  workbook.xlsx.writeBuffer().then(function (buffer) {
    saveAs(
      new Blob([buffer], { type: 'application/octet-stream' }),
      `Subscriptions-${Date.now()}.xlsx`,
    );
  });
};

import isCountryCode from '../../helpers/is-country-code';
import { isMongoId, withPaginationParams } from '../../helpers';
import { z } from 'zod';
import { CurrencyCode } from 'further-types/lp/vehicle';
import { BankAccountOperationStatus } from 'further-types/lp/bank-reconciliation';
import { CreateTransactions } from '../transaction';

const BankAccountInput = z.object({
  accountName: z.string().min(1, { message: 'Account name is required' }),
  bankName: z.string().min(1, { message: 'Bank name is required' }),
  accountCurrency: z.nativeEnum(CurrencyCode),
  iban: z.string().optional(),
  accountAddress: z
    .object({
      address1: z.string().min(1, { message: 'Address line 1 is required' }),
      address2: z.string().optional(),
      city: z.string().min(1, { message: 'Town/City is required' }),
      postcode: z.string().min(1, { message: 'Postal code is required' }),
      country: z.string().min(1, { message: 'Country is required' }),
    })
    .optional(),
});

export const ListBanksInput = z.object({
  country: z
    .string()
    .min(1, { message: 'Please select a country' })
    .refine(isCountryCode, { message: 'Please provide a valid country code' }),
});

export const ListBankAccountOperationsInput = withPaginationParams(
  z.object({
    bankAccountId: z.string().refine(isMongoId(), {
      message: 'Please provide a valid bank account ID',
    }),
    status: z.nativeEnum(BankAccountOperationStatus).optional(),
  }),
  ['date'],
);

export const AuthoriseBankInput = z.object({
  institutionId: z.string().min(1, { message: 'Please select a bank' }),
  redirectUrl: z
    .string()
    .min(1, { message: 'Please enter a redirect URL' })
    .url({ message: 'Please enter a valid redirect URL' }),
});

export const GetAvaiableBankAccountsInput = z.object({
  requisitionId: z.string().min(1, { message: 'Missing requisition ID' }),
});

export const ImportOperationsInput = z.object({
  bankAccountId: z.string().refine(isMongoId()),
});

export const DeleteBankAccountOperationsInput = z.object({
  bankAccountId: z.string().refine(isMongoId()),
  operationIds: z.array(z.string().refine(isMongoId())),
});

export const AddBankAccountInput = z.object({
  bankAccount: BankAccountInput,
});

export const UpdateBankAccountInput = z.object({
  bankAccount: BankAccountInput.extend({
    id: z.string().refine(isMongoId()),
  }),
});

export const AddBankAccountsFromBankFeedInput = z.object({
  bankAccountId: z
    .string()
    .optional()
    .refine(isMongoId({ optional: true })),
  accounts: z.array(
    BankAccountInput.extend({
      bankFeedAccountId: z
        .string()
        .min(1, { message: 'Missing bank feed account ID' }),
      bankFeedInstitutionId: z
        .string()
        .min(1, { message: 'Missing bank feed institution ID' }),
      bankFeedAccessValidTo: z.date(),
    }),
  ),
});

export const GetBankStatementPreviewInput = withPaginationParams(
  z.object({
    bankAccountId: z.string().refine(isMongoId()),
    dateFormat: z.string().min(1, { message: 'Date format is required.' }),
    bankStatementFilePath: z
      .string()
      .min(1, { message: 'Bank statement file is required.' }),
  }),
  ['date'],
);

export const ImportBankStatementInput = z.object({
  bankAccountId: z.string().refine(isMongoId()),
  dateFormat: z.string().min(1, { message: 'Date format is required.' }),
  bankStatementFilePath: z
    .string()
    .min(1, { message: 'Bank statement file is required.' }),
});

export const ReconcileBankAccountOperationInput = z.object({
  bankAccountId: z.string().refine(isMongoId()),
  bankAccountOperationId: z.string().refine(isMongoId()),
  reconciledActions: z.array(
    z.object({
      actionId: z.string().refine(isMongoId()),
      amount: z.number(),
    }),
  ),
  transactions: CreateTransactions.optional(),
});

export const CreateBankAccountTransferInput = z.object({
  sourceAccountId: z.string().refine(isMongoId()),
  destinationAccountId: z.string().refine(isMongoId()),
  sourceBankOperationId: z.string().refine(isMongoId()),
  reference: z.string().optional(),
});

import { styled } from '@mui/material';
import React from 'react';
import api from 'lib/trpcClient';
import SectionContent from './SectionContent';
import { Schema as AccountSchema } from 'further-types/lp/account';
import Heading from 'components/Heading';
import {
  DateRange,
  FileType,
  getDateRange,
  numberToCurrencyString,
} from 'further-ui/utils';
import { Row } from 'components/Layout';
import PageLoader from 'components/PageLoader';
import Spacing from 'components/Spacing';
import PageHeader from 'components/PageHeader';
import ResponsiveActions from 'components/PageHeader/ResponsiveActions';
import Button from 'components/Button';
import { Link } from 'react-router-dom';
import { createFundSetupRoute, FundSetupTab } from 'adminConstants/routes';
import PageFilters, { Filter } from 'components/PageFilters';
import useFilters from 'hooks/ui/useFilters';
import SearchField from 'components/SearchField';
import { usePagination } from 'hooks/ui/usePagination';
import { useLayoutContext } from 'contexts/LayoutContext';
import { MuiDateRangePicker } from 'components/FormElements/DateRangePicker';
import { ActionRef } from 'further-types/lp/action';
import DropdownCheckboxList from 'components/DropdownCheckboxList';
import { ActionRefLabels } from 'further-ui/labels';
import { TableContextProvider } from 'components/InteractiveTable';
import useVehicle from 'hooks/lp/data/vehicle/useVehicle';

const { AccountSection } = AccountSchema;

const LedgerTotal = styled(Row)(({ theme }) => ({
  border: `1px solid ${theme.palette.borderColor?.dark}`,
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(5, 9, 5, 2),
  width: '100%',
}));

const pageId = 'general-ledger';

const defaultActionRefs = Object.values(ActionRef);

const GeneralLedger: React.FC = () => {
  const { selectedVehicleId } = useLayoutContext();
  const { filters, handleChangeFilters } = useFilters<{
    dateRange?: DateRange;
    searchQuery?: string;
    actionRefs?: Array<ActionRef>;
  }>(pageId, {
    defaultFilters: {
      dateRange: undefined,
      searchQuery: '',
      actionRefs: defaultActionRefs,
    },
  });
  const pagination = usePagination({
    id: pageId,
    order: 'desc',
    orderBy: 'transactionDate',
  });
  const { vehicle } = useVehicle(selectedVehicleId);

  const { data, isFetching, isLoading } =
    api.generalLedger.listTransactions.useQuery({
      ...filters,
      dateRange: getDateRange(filters.dateRange),
      orderBy: pagination.orderBy,
      order: pagination.order,
      vehicleId: selectedVehicleId ?? '',
    });

  const sections = data?.sections ?? [];

  const exportTransactions = api.generalLedger.exportTransactions.useMutation({
    onSuccess: (data) => {
      const mediaType = `data:${FileType.ExcelAlt};base64,`;

      const aElement = document.createElement('a');
      aElement.setAttribute('download', 'General ledger.xlsx');
      aElement.href = `${mediaType}${data.file}`;
      aElement.setAttribute('target', '_blank');
      aElement.click();
    },
  });

  if (isLoading || !vehicle) {
    return <PageLoader />;
  }

  const handleExport = () => {
    exportTransactions.mutate({
      ...filters,
      dateRange: getDateRange(filters.dateRange),
      vehicleId: selectedVehicleId ?? '',
    });
  };

  return (
    <Spacing>
      <PageHeader>
        <Heading variant="h3" noMargin>
          General ledger
        </Heading>
        <ResponsiveActions>
          <Link to={createFundSetupRoute(FundSetupTab.ChartOfAccounts)}>
            <Button variant="outlined">Manage Chart of Accounts</Button>
          </Link>
          <Button
            variant="contained"
            onClick={handleExport}
            loading={exportTransactions.isPending}
          >
            Export
          </Button>
        </ResponsiveActions>
      </PageHeader>
      <PageFilters pageId={pageId}>
        <Filter>
          <MuiDateRangePicker
            dateRange={filters.dateRange}
            onDateRangeChange={(dateRange) => {
              handleChangeFilters({ dateRange });
            }}
            placeholder="Select date range"
          />
        </Filter>
        <Filter>
          <SearchField
            autoSearchOnDebounce
            name="searchQuery"
            variant="standard"
            placeholder="Search by description, counterparty or author"
            fullWidth
            value={filters.searchQuery}
            onSearch={(searchQuery) => {
              handleChangeFilters({ searchQuery });
            }}
            allowEmptyQuery
            iconPlacement="left"
          />
        </Filter>
        <Filter disableGrow>
          <DropdownCheckboxList
            label="Actions"
            options={Object.values(ActionRef).map((actionRef) => ({
              value: actionRef,
              label: ActionRefLabels[actionRef],
            }))}
            value={filters.actionRefs ?? []}
            onChange={(actionRefs) => {
              handleChangeFilters({ actionRefs });
            }}
          />
        </Filter>
      </PageFilters>
      <div>
        <TableContextProvider tableId="general-ledger">
          <SectionContent
            sectionTitle="Assets"
            pagination={pagination}
            section={sections[AccountSection.Assets] ?? []}
            loading={isFetching}
            baseCurrency={vehicle.baseCurrency}
          />
          <SectionContent
            sectionTitle="Payables"
            pagination={pagination}
            section={sections[AccountSection.Payables] ?? []}
            loading={isFetching}
            baseCurrency={vehicle.baseCurrency}
          />
          <SectionContent
            sectionTitle="Partners' capital"
            pagination={pagination}
            section={sections[AccountSection.PartnersCapital] ?? []}
            loading={isFetching}
            baseCurrency={vehicle.baseCurrency}
          />
          <SectionContent
            sectionTitle="Income"
            pagination={pagination}
            section={sections[AccountSection.Income] ?? []}
            loading={isFetching}
            baseCurrency={vehicle.baseCurrency}
          />
          <SectionContent
            sectionTitle="Expenses"
            pagination={pagination}
            section={sections[AccountSection.Expenses] ?? []}
            loading={isFetching}
            baseCurrency={vehicle.baseCurrency}
          />
        </TableContextProvider>
        <LedgerTotal centered justify="space-between">
          <Heading variant="h3" noMargin>
            GL total
          </Heading>
          <Heading variant="h6" noMargin>
            GL total{' '}
            {numberToCurrencyString(data?.total ?? 0, {
              currency: vehicle.baseCurrency,
            })}
          </Heading>
        </LedgerTotal>
      </div>
    </Spacing>
  );
};

export default GeneralLedger;

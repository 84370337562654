import { CurrencyCode } from 'further-types/lp/vehicle';

export const currencyLabels = {
  // Major currencies (preserved at top)
  [CurrencyCode.AUD]: 'AUD - Australian dollar',
  [CurrencyCode.GBP]: 'GBP - British pound sterling',
  [CurrencyCode.CAD]: 'CAD - Canadian dollar',
  [CurrencyCode.EUR]: 'EUR - Euro',
  [CurrencyCode.HKD]: 'HKD - Hong Kong dollar',
  [CurrencyCode.JPY]: 'JPY - Japanese yen',
  [CurrencyCode.NZD]: 'NZD - New Zealand dollar',
  [CurrencyCode.CHF]: 'CHF - Swiss franc',
  [CurrencyCode.USD]: 'USD - United States dollar',
  [CurrencyCode.CNY]: 'CNY - Chinese renminbi',
  // Rest of currencies (alphabetically by label)
  [CurrencyCode.AFN]: 'AFN - Afghan afghani',
  [CurrencyCode.ALL]: 'ALL - Albanian lek',
  [CurrencyCode.DZD]: 'DZD - Algerian dinar',
  [CurrencyCode.AOA]: 'AOA - Angolan kwanza',
  [CurrencyCode.ARS]: 'ARS - Argentine peso',
  [CurrencyCode.AMD]: 'AMD - Armenian dram',
  [CurrencyCode.AWG]: 'AWG - Aruban florin',
  [CurrencyCode.AZN]: 'AZN - Azerbaijani manat',
  [CurrencyCode.BSD]: 'BSD - Bahamian dollar',
  [CurrencyCode.BHD]: 'BHD - Bahraini dinar',
  [CurrencyCode.BDT]: 'BDT - Bangladeshi taka',
  [CurrencyCode.BBD]: 'BBD - Barbadian dollar',
  [CurrencyCode.BYN]: 'BYN - Belarusian ruble',
  [CurrencyCode.BZD]: 'BZD - Belize dollar',
  [CurrencyCode.BMD]: 'BMD - Bermudian dollar',
  [CurrencyCode.BTN]: 'BTN - Bhutanese ngultrum',
  [CurrencyCode.BOB]: 'BOB - Bolivian boliviano',
  [CurrencyCode.BAM]: 'BAM - Bosnia and Herzegovina convertible mark',
  [CurrencyCode.BWP]: 'BWP - Botswana pula',
  [CurrencyCode.BRL]: 'BRL - Brazilian real',
  [CurrencyCode.BND]: 'BND - Brunei dollar',
  [CurrencyCode.BGN]: 'BGN - Bulgarian lev',
  [CurrencyCode.MMK]: 'MMK - Burmese kyat',
  [CurrencyCode.BIF]: 'BIF - Burundian franc',
  [CurrencyCode.KHR]: 'KHR - Cambodian riel',
  [CurrencyCode.CVE]: 'CVE - Cape Verdean escudo',
  [CurrencyCode.KYD]: 'KYD - Cayman Islands dollar',
  [CurrencyCode.XAF]: 'XAF - Central African CFA franc',
  [CurrencyCode.XPF]: 'XPF - CFP franc',
  [CurrencyCode.CLP]: 'CLP - Chilean peso',
  [CurrencyCode.COP]: 'COP - Colombian peso',
  [CurrencyCode.KMF]: 'KMF - Comorian franc',
  [CurrencyCode.CDF]: 'CDF - Congolese franc',
  [CurrencyCode.CRC]: 'CRC - Costa Rican colón',
  [CurrencyCode.CUP]: 'CUP - Cuban peso',
  [CurrencyCode.CZK]: 'CZK - Czech koruna',
  [CurrencyCode.DKK]: 'DKK - Danish krone',
  [CurrencyCode.DJF]: 'DJF - Djiboutian franc',
  [CurrencyCode.DOP]: 'DOP - Dominican peso',
  [CurrencyCode.XCD]: 'XCD - Eastern Caribbean dollar',
  [CurrencyCode.EGP]: 'EGP - Egyptian pound',
  [CurrencyCode.ERN]: 'ERN - Eritrean nakfa',
  [CurrencyCode.ETB]: 'ETB - Ethiopian birr',
  [CurrencyCode.FKP]: 'FKP - Falkland Islands pound',
  [CurrencyCode.FJD]: 'FJD - Fijian dollar',
  [CurrencyCode.GMD]: 'GMD - Gambian dalasi',
  [CurrencyCode.GEL]: 'GEL - Georgian lari',
  [CurrencyCode.GHS]: 'GHS - Ghanaian cedi',
  [CurrencyCode.GIP]: 'GIP - Gibraltar pound',
  [CurrencyCode.GTQ]: 'GTQ - Guatemalan quetzal',
  [CurrencyCode.GNF]: 'GNF - Guinean franc',
  [CurrencyCode.GYD]: 'GYD - Guyanese dollar',
  [CurrencyCode.HTG]: 'HTG - Haitian gourde',
  [CurrencyCode.HNL]: 'HNL - Honduran lempira',
  [CurrencyCode.HUF]: 'HUF - Hungarian forint',
  [CurrencyCode.ISK]: 'ISK - Icelandic króna',
  [CurrencyCode.INR]: 'INR - Indian rupee',
  [CurrencyCode.IDR]: 'IDR - Indonesian rupiah',
  [CurrencyCode.IRR]: 'IRR - Iranian rial',
  [CurrencyCode.IQD]: 'IQD - Iraqi dinar',
  [CurrencyCode.ILS]: 'ILS - Israeli new shekel',
  [CurrencyCode.JMD]: 'JMD - Jamaican dollar',
  [CurrencyCode.JOD]: 'JOD - Jordanian dinar',
  [CurrencyCode.KZT]: 'KZT - Kazakhstani tenge',
  [CurrencyCode.KES]: 'KES - Kenyan shilling',
  [CurrencyCode.KWD]: 'KWD - Kuwaiti dinar',
  [CurrencyCode.KGS]: 'KGS - Kyrgyz som',
  [CurrencyCode.LAK]: 'LAK - Lao kip',
  [CurrencyCode.LBP]: 'LBP - Lebanese pound',
  [CurrencyCode.LSL]: 'LSL - Lesotho loti',
  [CurrencyCode.LRD]: 'LRD - Liberian dollar',
  [CurrencyCode.LYD]: 'LYD - Libyan dinar',
  [CurrencyCode.MOP]: 'MOP - Macanese pataca',
  [CurrencyCode.MKD]: 'MKD - Macedonian denar',
  [CurrencyCode.MGA]: 'MGA - Malagasy ariary',
  [CurrencyCode.MWK]: 'MWK - Malawian kwacha',
  [CurrencyCode.MYR]: 'MYR - Malaysian ringgit',
  [CurrencyCode.MVR]: 'MVR - Maldivian rufiyaa',
  [CurrencyCode.MRU]: 'MRU - Mauritanian ouguiya',
  [CurrencyCode.MUR]: 'MUR - Mauritian rupee',
  [CurrencyCode.MXN]: 'MXN - Mexican peso',
  [CurrencyCode.MDL]: 'MDL - Moldovan leu',
  [CurrencyCode.MNT]: 'MNT - Mongolian tögrög',
  [CurrencyCode.MAD]: 'MAD - Moroccan dirham',
  [CurrencyCode.MZN]: 'MZN - Mozambican metical',
  [CurrencyCode.NAD]: 'NAD - Namibian dollar',
  [CurrencyCode.NPR]: 'NPR - Nepalese rupee',
  [CurrencyCode.ANG]: 'ANG - Netherlands Antillean guilder',
  [CurrencyCode.TWD]: 'TWD - New Taiwan dollar',
  [CurrencyCode.NIO]: 'NIO - Nicaraguan córdoba',
  [CurrencyCode.NGN]: 'NGN - Nigerian naira',
  [CurrencyCode.KPW]: 'KPW - North Korean won',
  [CurrencyCode.NOK]: 'NOK - Norwegian krone',
  [CurrencyCode.OMR]: 'OMR - Omani rial',
  [CurrencyCode.PKR]: 'PKR - Pakistani rupee',
  [CurrencyCode.PAB]: 'PAB - Panamanian balboa',
  [CurrencyCode.PGK]: 'PGK - Papua New Guinean kina',
  [CurrencyCode.PYG]: 'PYG - Paraguayan guaraní',
  [CurrencyCode.PEN]: 'PEN - Peruvian sol',
  [CurrencyCode.PHP]: 'PHP - Philippine peso',
  [CurrencyCode.PLN]: 'PLN - Polish złoty',
  [CurrencyCode.QAR]: 'QAR - Qatari riyal',
  [CurrencyCode.RON]: 'RON - Romanian leu',
  [CurrencyCode.RUB]: 'RUB - Russian ruble',
  [CurrencyCode.RWF]: 'RWF - Rwandan franc',
  [CurrencyCode.SHP]: 'SHP - Saint Helena pound',
  [CurrencyCode.WST]: 'WST - Samoan tālā',
  [CurrencyCode.STN]: 'STN - São Tomé and Príncipe dobra',
  [CurrencyCode.SAR]: 'SAR - Saudi riyal',
  [CurrencyCode.RSD]: 'RSD - Serbian dinar',
  [CurrencyCode.SCR]: 'SCR - Seychellois rupee',
  [CurrencyCode.SLE]: 'SLE - Sierra Leonean leone',
  [CurrencyCode.SGD]: 'SGD - Singapore dollar',
  [CurrencyCode.SBD]: 'SBD - Solomon Islands dollar',
  [CurrencyCode.SOS]: 'SOS - Somali shilling',
  [CurrencyCode.ZAR]: 'ZAR - South African rand',
  [CurrencyCode.KRW]: 'KRW - South Korean won',
  [CurrencyCode.SSP]: 'SSP - South Sudanese pound',
  [CurrencyCode.LKR]: 'LKR - Sri Lankan rupee',
  [CurrencyCode.SDG]: 'SDG - Sudanese pound',
  [CurrencyCode.SRD]: 'SRD - Surinamese dollar',
  [CurrencyCode.SZL]: 'SZL - Swazi lilangeni',
  [CurrencyCode.SEK]: 'SEK - Swedish krona',
  [CurrencyCode.SYP]: 'SYP - Syrian pound',
  [CurrencyCode.TJS]: 'TJS - Tajikistani somoni',
  [CurrencyCode.TZS]: 'TZS - Tanzanian shilling',
  [CurrencyCode.THB]: 'THB - Thai baht',
  [CurrencyCode.TOP]: 'TOP - Tongan paʻanga',
  [CurrencyCode.TTD]: 'TTD - Trinidad and Tobago dollar',
  [CurrencyCode.TND]: 'TND - Tunisian dinar',
  [CurrencyCode.TRY]: 'TRY - Turkish lira',
  [CurrencyCode.TMT]: 'TMT - Turkmenistani manat',
  [CurrencyCode.UGX]: 'UGX - Ugandan shilling',
  [CurrencyCode.UAH]: 'UAH - Ukrainian hryvnia',
  [CurrencyCode.AED]: 'AED - United Arab Emirates dirham',
  [CurrencyCode.UYU]: 'UYU - Uruguayan peso',
  [CurrencyCode.UZS]: 'UZS - Uzbekistani sum',
  [CurrencyCode.VUV]: 'VUV - Vanuatu vatu',
  [CurrencyCode.VES]: 'VES - Venezuelan sovereign bolívar',
  [CurrencyCode.VND]: 'VND - Vietnamese dong',
  [CurrencyCode.XOF]: 'XOF - West African CFA franc',
  [CurrencyCode.YER]: 'YER - Yemeni rial',
  [CurrencyCode.ZMW]: 'ZMW - Zambian kwacha',
  [CurrencyCode.ZWG]: 'ZWG - Zimbabwe gold',
  [CurrencyCode.ZWL]: 'ZWL - Zimbabwean dollar',
};

export const majorCurrencies = [
  CurrencyCode.AUD,
  CurrencyCode.GBP,
  CurrencyCode.CAD,
  CurrencyCode.EUR,
  CurrencyCode.HKD,
  CurrencyCode.JPY,
  CurrencyCode.NZD,
  CurrencyCode.CHF,
  CurrencyCode.USD,
  CurrencyCode.CNY,
];

import { PaymentEmailStatus } from 'further-types/investment';

export const paymentEmailOptions = [
  {
    value: PaymentEmailStatus.UnpaidEmail,
    label: 'Unpaid, send payment instruction email ',
  },
  { value: PaymentEmailStatus.UnpaidNoEmail, label: 'Unpaid, do not email' },
  {
    value: PaymentEmailStatus.UnpaidDisableEmail,
    label: 'Unpaid, disable emails ',
  },
  {
    value: PaymentEmailStatus.PaidEmail,
    label: 'Paid, send confirmation email',
  },
  { value: PaymentEmailStatus.PaidNoEmail, label: 'Paid, do not email' },
  { value: PaymentEmailStatus.PaidDisableEmail, label: 'Paid, disable emails' },
];

import { FC } from 'react';
import AlertDialog from 'components/AlertDialog';
import { Disclosure } from 'further-ui/hooks';

type DataLossWarningModalProps = {
  disclosure: Disclosure;
  onConfirm: () => void;
  onCancel: () => void;
  message: string;
};

const DataLossWarningModal: FC<DataLossWarningModalProps> = ({
  disclosure,
  onConfirm,
  onCancel,
  message,
}) => (
  <AlertDialog
    open={disclosure.isOpen}
    title="Warning"
    content={<p>{message}</p>}
    onConfirm={() => {
      onConfirm();
      disclosure.onClose();
    }}
    onClose={() => {
      onCancel();
      disclosure.onClose();
    }}
    btnLabels={{
      cancel: 'Go back',
      confirm: 'Remove',
    }}
    confirmBtnProps={{
      variant: 'contained',
      color: 'error',
    }}
    cancelBtnProps={{
      variant: 'outlined',
      color: 'primary',
    }}
  />
);

export default DataLossWarningModal;

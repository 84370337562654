import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiResponse } from 'further-types/api';
import { Api } from 'further-types/delegated-access';
import { api } from 'lib/httpClient';
import { useNotification } from 'hooks/ui/useNotification';
import useApiExceptionHandler from 'hooks/ui/useApiExceptionHandler';

type Options = { onCreate?: () => void };

export function useDelegatedAccess(options?: Options) {
  const queryClient = useQueryClient();
  const notification = useNotification();
  const handleApiException = useApiExceptionHandler();

  const create = useMutation({
    throwOnError: false,
    mutationFn: async (data: Api.CreateDelegatedAccessParams) => {
      const { data: result } = await api.post<
        ApiResponse<Api.MutateDelegatedAccessResponse>
      >(`delegated-access`, data);

      return result.data;
    },
    onSuccess: async () => {
      notification.success('Delegated access user added successfully.');
      options?.onCreate?.();
      await queryClient.invalidateQueries({
        queryKey: ['delegated-access-given'],
      });
    },
    onError: (error: Error) => {
      handleApiException(error);
    },
  });

  const edit = useMutation({
    throwOnError: false,
    mutationFn: async (data: Api.EditDelegatedAccessParams) => {
      const { data: result } = await api.put<
        ApiResponse<Api.MutateDelegatedAccessResponse>
      >(`delegated-access/${data.delegatedAccessId}`, {
        copyIntoSystemEmails: data.copyIntoSystemEmails,
      });

      return result.data;
    },
    onSuccess: async () => {
      notification.success('Delegated access user updated successfully.');

      await queryClient.invalidateQueries({
        queryKey: ['delegated-access-given'],
      });
    },
    onError: (error: Error) => {
      handleApiException(error);
    },
  });

  const remove = useMutation({
    throwOnError: false,
    mutationFn: async (delegatedAccessId: string) => {
      const { data: result } = await api.delete<
        ApiResponse<Api.MutateDelegatedAccessResponse>
      >(`delegated-access/${delegatedAccessId}`);

      return result.data;
    },
    onSuccess: async () => {
      notification.success('Delegated access user deleted successfully.');

      await queryClient.invalidateQueries({
        queryKey: ['delegated-access-given'],
      });
    },
    onError: (error: Error) => {
      handleApiException(error);
    },
  });

  return {
    create,
    edit,
    remove,
  };
}

import React from 'react';
import { CircularProgress, Button, styled } from '@mui/material';
import useKyc from 'hooks/data/kyc/useKyc';
import downloadKycPdf from 'helpers/investor/downloadKycPdf';
import { AccountType } from 'further-types/investor';
import KycResultsButton from './KycResultsButton';
import CertificateItem from './CertificateItem';
import { Api, KycProcessStatus } from 'further-types/kyc';
import { useGetRole } from 'hooks/ui/useGetRole';
import KycManualApprovalModal from './KycManualApprovalModal';
import { useDisclosure } from 'further-ui/hooks';
import AlertDialog from 'components/AlertDialog';
import { kycRiskRatingLabels } from 'further-ui/labels';
import KycResult from './KycResult';
import PreviousResults from './PreviousResults';
import { Attachment } from '@mui/icons-material';
import Spacing from 'components/Spacing';
import RecordRow from 'components/RecordRow';
import PageHeader from 'components/PageHeader';
import Heading from 'components/Heading';
import ResponsiveActions from 'components/PageHeader/ResponsiveActions';
import { Column, Row } from 'components/Layout';

const AttachmentIcon = styled(Attachment)({
  fontSize: '18px',
});

const AttachmentLink = styled('a')(({ theme }) => ({
  fontSize: '12px',
  color: theme.palette.text.primary,

  '&:hover': {
    color: theme.palette.text.rootColor,
  },
}));

type Props = {
  investorId: string;
  fullName?: string;
  accountType: AccountType;
  advisersList: Array<{ _id: string; fullName: string }>;
  identityVerificationCertificates?: Array<{
    adviserId: string;
    date: string;
    url: string;
  }>;
};

const KycResults: React.FC<Props> = ({
  investorId,
  fullName,
  identityVerificationCertificates,
  advisersList,
  accountType,
}) => {
  const { isSuperAdmin } = useGetRole();
  const manualApprovalModal = useDisclosure();
  const resetKycConfirmationModal = useDisclosure();

  const {
    kycDetails: { data: kycData, isFetching },
    expiredKyc: { data: expiredKycData, isFetching: isFetchingExpiredData },
    getKycImages,
    resetKyc,
    approveIdv,
    approveAml,
    manualKycProcess,
    saveAmlApprovalNotes,
    saveIdvApprovalNotes,
  } = useKyc(investorId);

  if (isFetching || isFetchingExpiredData) {
    return <CircularProgress size={24} />;
  }

  const isLegalEntity = accountType === AccountType.LegalEntity;
  const showResetKycButton =
    isSuperAdmin &&
    kycData?.idvProcessStatus === KycProcessStatus.Failed &&
    !isLegalEntity;
  const showApproveIdvButton =
    isSuperAdmin &&
    kycData?.idvProcessStatus === KycProcessStatus.Failed &&
    !isLegalEntity;
  const showApproveAmlButton =
    isSuperAdmin &&
    kycData?.amlProcessStatus === KycProcessStatus.Failed &&
    !isLegalEntity;

  const isPassedManually =
    kycData?.amlProcessStatus === KycProcessStatus.ManuallyApproved ||
    kycData?.idvProcessStatus === KycProcessStatus.ManuallyApproved;

  const showDownloadResultsButton = !!(
    kycData?.isKycStepCompleted && kycData?.hasYotiData
  );

  const handleResetKyc = () => {
    resetKyc();
    resetKycConfirmationModal.onClose();
  };

  const handleManualKycProcess = (data: Api.ManualKycProcessRequest) => {
    manualKycProcess(data);
    manualApprovalModal.onClose();
  };

  const handleDownloadNotes = () => {
    const blob = new Blob(
      [kycData?.manualApprovalNotes?.replace(/<[^>]*>/g, '') ?? ''],
      {
        type: 'text/plain',
      },
    );

    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'notes.txt';
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(url);
    a.remove();
  };

  const handleApproveIdv = (idvApprovalNotes: string) => {
    approveIdv({ idvApprovalNotes });
  };

  const handleApproveAml = (amlApprovalNotes: string) => {
    approveAml({ amlApprovalNotes });
  };

  const handleSaveIdvApprovalNotes = (idvApprovalNotes: string) => {
    saveIdvApprovalNotes({ idvApprovalNotes });
  };

  const handleSaveAmlApprovalNotes = (amlApprovalNotes: string) => {
    saveAmlApprovalNotes({ amlApprovalNotes });
  };

  const handleDownloadKycResults = async (kycDetails: typeof kycData) => {
    const images = await getKycImages();
    //@ts-expect-error
    downloadKycPdf(kycDetails, images.data ?? {}, { fullName });
  };

  const sectionTitle = isLegalEntity ? 'KYB information' : 'KYC results';
  const manualRecordTitle = isLegalEntity
    ? 'Manual KYB record'
    : 'Manual KYC record';
  const manualApprovalButtonTitle = isLegalEntity
    ? isPassedManually
      ? 'Edit manual KYB'
      : 'Complete manual KYB'
    : isPassedManually
    ? 'Edit manual KYC'
    : 'Upload manual KYC';

  return (
    <Spacing>
      <PageHeader>
        <Heading variant="h2" noMargin>
          {sectionTitle}
        </Heading>
        <ResponsiveActions>
          {showResetKycButton && (
            <Button
              onClick={resetKycConfirmationModal.onOpen}
              variant="contained"
              color="primary"
              size="small"
            >
              Reset eKYC
            </Button>
          )}
          {isSuperAdmin && (
            <Button
              onClick={manualApprovalModal.onOpen}
              variant="contained"
              color="primary"
              size="small"
            >
              {manualApprovalButtonTitle}
            </Button>
          )}
        </ResponsiveActions>
      </PageHeader>

      <Spacing size="sm">
        {isLegalEntity ? (
          <RecordRow label="KYB status">
            <Column spacing="xs" fullWidth>
              <KycResult
                approveButtonText="Admin approve IDV"
                showApproveButton={false}
                statusLabel={
                  kycData?.isKycProcessPassed ? 'Approved' : 'Not approved'
                }
                showDownloadResultsButton={false}
                showNotesTooltip={false}
              />
              <PreviousResults
                //@ts-expect-error
                expiredKycData={expiredKycData}
                type="idv"
                onDownloadResults={handleDownloadKycResults}
              />
            </Column>
          </RecordRow>
        ) : (
          <>
            <RecordRow label="IDV result">
              <Column spacing="xs" fullWidth>
                <KycResult
                  approveButtonText="Admin approve IDV"
                  showApproveButton={showApproveIdvButton}
                  //@ts-expect-error
                  handleApprove={handleApproveIdv}
                  statusLabel={kycData?.idvStatusLabel ?? 'Not attempted'}
                  showDownloadResultsButton={showDownloadResultsButton}
                  handleDowonloadResults={() =>
                    handleDownloadKycResults(kycData)
                  }
                  approvalNotes={kycData?.idvApprovalNotes ?? ''}
                  showNotesTooltip={
                    kycData?.idvProcessStatus === KycProcessStatus.AdminApproved
                  }
                  //@ts-expect-error
                  handleSaveNotes={handleSaveIdvApprovalNotes}
                />
                <PreviousResults
                  //@ts-expect-error
                  expiredKycData={expiredKycData}
                  type="idv"
                  onDownloadResults={handleDownloadKycResults}
                />
              </Column>
            </RecordRow>
            <RecordRow label="AML result">
              <Column spacing="xs" fullWidth>
                <KycResult
                  approveButtonText="Admin approve AML"
                  showApproveButton={showApproveAmlButton}
                  //@ts-expect-error
                  handleApprove={handleApproveAml}
                  statusLabel={kycData?.amlStatusLabel ?? 'Not attempted'}
                  showDownloadResultsButton={false}
                  approvalNotes={kycData?.amlApprovalNotes ?? ''}
                  showNotesTooltip={
                    kycData?.amlProcessStatus === KycProcessStatus.AdminApproved
                  }
                  //@ts-expect-error
                  handleSaveNotes={handleSaveAmlApprovalNotes}
                />
                {/* @ts-expect-error */}
                <PreviousResults expiredKycData={expiredKycData} type="aml" />
              </Column>
            </RecordRow>

            <RecordRow label="Adviser submitted documents">
              {identityVerificationCertificates?.length
                ? identityVerificationCertificates?.map((certificate, idx) => (
                    <CertificateItem
                      certificate={certificate}
                      advisersList={advisersList}
                      key={idx}
                    />
                  ))
                : '-'}
            </RecordRow>
          </>
        )}
        <RecordRow label="Risk rating">
          {kycRiskRatingLabels.find(
            (label) => label.value === kycData?.riskRating,
          )?.label ?? kycRiskRatingLabels[0].label}
        </RecordRow>
        {kycData?.manualApprovalFiles?.length ||
        kycData?.manualApprovalNotes?.length ? (
          <RecordRow label={manualRecordTitle}>
            <Column spacing="sm">
              <div>
                <KycResultsButton
                  visible={!!kycData?.manualApprovalNotes?.length}
                  onClick={handleDownloadNotes}
                  buttonText="Download notes"
                />
              </div>
              <Column spacing="xs">
                {kycData?.manualApprovalFiles?.map(({ name, url }) => (
                  <Row centered spacing="xs">
                    <AttachmentIcon />
                    <AttachmentLink target="_blank" rel="noreferrer" href={url}>
                      {name}
                    </AttachmentLink>
                  </Row>
                ))}
              </Column>
            </Column>
          </RecordRow>
        ) : null}
      </Spacing>

      {manualApprovalModal.isOpen && (
        <KycManualApprovalModal
          onClose={manualApprovalModal.onClose}
          processManualKyc={handleManualKycProcess}
          isPassedManually={isPassedManually}
          //@ts-expect-error
          kycData={kycData}
          isLegalEntity={isLegalEntity}
        />
      )}
      {resetKycConfirmationModal.isOpen && (
        <AlertDialog
          open
          title="Warning"
          content="Are you sure you wish to reset this investor's eKYC?"
          btnLabels={{
            cancel: 'Go back',
            confirm: 'Reset eKYC',
          }}
          onClose={resetKycConfirmationModal.onClose}
          onConfirm={handleResetKyc}
        />
      )}
    </Spacing>
  );
};

export default KycResults;

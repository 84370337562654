import { FormProvider, useForm } from 'react-hook-form';
import { incomeAndExpenses as rules } from 'further-ui/validations';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { PropsWithChildren } from 'react';
import { CurrencyCode } from 'further-types/lp/vehicle';

const formSchema = rules.CreateIncomeOrExpensePreAllocations.extend({
  ledgerDate: z.date(),
});

type FormValues = z.infer<typeof formSchema>;

type Props = {
  values?: FormValues;
  vehicleId: string;
  baseCurrency?: CurrencyCode;
};

const FormWrapper: React.FC<PropsWithChildren<Props>> = ({
  children,
  values,
  vehicleId,
  baseCurrency,
}) => {
  const formMethods = useForm<FormValues>({
    shouldFocusError: true,
    resolver: zodResolver(formSchema),
    defaultValues: values ?? {
      vehicleId,
      ledgerDate: new Date(),
      currencyCode: baseCurrency,
      transactions: [],
    },
    criteriaMode: 'all',
    resetOptions: {
      keepDefaultValues: false,
    },
  });

  return <FormProvider {...formMethods}>{children}</FormProvider>;
};

export default FormWrapper;

import RecordView from 'components/RecordView';
import PageSection from 'components/PageSection';
import Spacing from 'components/Spacing';
import ButtonGroup from 'components/ButtonGroup';
import Button from 'components/Button';
import Grid from '@mui/material/Grid2';
import { useFormContext } from 'react-hook-form';
import { useNotification } from 'hooks/ui/useNotification';
import useHoldingClasses from '../hooks/useHoldingClasses';
import { investment as rules } from 'further-ui/validations';
import HoldingClassInfoForm from './HoldingClassInfoForm';
import BaseInvestmentForm from './BaseInvestmentForm';
import AllocationsTable from 'components/AllocationsTable';
import api from 'lib/trpcClient';
import { useState } from 'react';
import { ActionStatus } from 'further-types/lp/action';

type CompleteAllocationProps = {
  onSuccess: () => void;
  onCancel: () => void;
};

const CompleteAllocation: React.FC<CompleteAllocationProps> = ({
  onSuccess,
  onCancel,
}) => {
  const notification = useNotification();
  const [isAllocationsValid, setIsAllocationsValid] = useState(false);
  const apiUtils = api.useUtils();
  const { control, watch, setValue, getValues, handleSubmit, setError } =
    useFormContext<rules.CreateInvestmentFormValues>();

  const {
    fields,
    calculateTotalInvestmentAmount,
    getCurrency,
    validateHoldingClassAmounts,
  } = useHoldingClasses({
    control,
    watch,
    setValue,
    getValues,
    setError,
  });

  const totalInvestmentAmount = calculateTotalInvestmentAmount();
  const currency = getCurrency();

  const upsertInvestmentMutation = api.lpInvestment.upsert.useMutation({
    onSuccess: () => {
      apiUtils.lpInvestment.invalidate();
      apiUtils.holdingClass.invalidate();
      apiUtils.asset.invalidate();
      apiUtils.generalLedger.invalidate();
      notification.success('Draft saved successfully');
    },
    onError: (error) => {
      notification.error(error.message || 'Failed to save draft.');
    },
  });

  const onSubmit = (data: any) => {
    if (!validateHoldingClassAmounts()) {
      return;
    }
    onSaveDraft(data);
    onSuccess();
  };

  const onSaveDraft = handleSubmit(async (data) => {
    if (!validateHoldingClassAmounts()) {
      return;
    }

    const response = await upsertInvestmentMutation.mutateAsync({
      ...data,
      status: ActionStatus.Draft,
    });

    const investmentId = response._id.toString();
    setValue('investmentId', investmentId);
    setValue('assetId', response.assetId.toString());
    setValue('isNewAsset', false);
    setValue(
      'holdingClasses',
      response.holdingClasses.map((holdingClass) => ({
        holdingClassId: holdingClass.holdingClassId.toString(),
        isNewHoldingClass: false as const,
        existingHoldingClassName: holdingClass.name,
        investmentAmount: holdingClass.investmentAmount,
        numberOfUnits: holdingClass.numberOfUnits,
        unitPrice: holdingClass.unitPrice,
        currency: holdingClass.currency,
      })),
    );
  });

  return (
    <RecordView>
      <Spacing size="lg">
        <form>
          <Spacing>
            <Grid container spacing={{ xs: 0, md: 6 }}>
              <Grid size={{ lg: 8, xs: 12 }}>
                <PageSection title="Input investment amounts and review allocation">
                  <Spacing>
                    <BaseInvestmentForm />
                    {fields.map((_, index) => (
                      <HoldingClassInfoForm key={index} index={index} />
                    ))}
                  </Spacing>
                </PageSection>
              </Grid>
            </Grid>
            <Grid container>
              <Grid size={{ xs: 12 }}>
                <PageSection title="Review allocations">
                  <AllocationsTable
                    inputAmount={totalInvestmentAmount}
                    inputLabel="investment"
                    currency={currency}
                    vehicleId={getValues('vehicleId')}
                    startingValues={getValues('allocations')}
                    startingAllocationRule={getValues('allocationRule')}
                    onAllocationsChange={(
                      isValid,
                      allocations,
                      allocationRule,
                      _,
                    ) => {
                      setIsAllocationsValid(isValid);
                      setValue('allocationRule', allocationRule);
                      setValue(
                        'allocations',
                        allocations.map((allocation) => ({
                          ...allocation,
                          investorLabel: allocation.investorName,
                        })),
                      );
                    }}
                  />
                </PageSection>
              </Grid>
            </Grid>
          </Spacing>
        </form>
        <ButtonGroup>
          <Button variant="outlined" onClick={onCancel}>
            Previous step
          </Button>
          <Button
            variant="outlined"
            loading={upsertInvestmentMutation.isPending}
            disabled={!isAllocationsValid}
            onClick={onSaveDraft}
            tooltip={
              !isAllocationsValid
                ? 'Please ensure that the total expense in the allocation equals the entered investment amount.'
                : undefined
            }
          >
            Save draft
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(onSubmit, () => {
              notification.error('Please fill in all required fields.');
            })}
            disabled={!isAllocationsValid}
            tooltip={
              !isAllocationsValid
                ? 'Please ensure that the total expense in the allocation equals the entered investment amount.'
                : undefined
            }
          >
            Save and next
          </Button>
        </ButtonGroup>
      </Spacing>
    </RecordView>
  );
};

export default CompleteAllocation;

import { alpha, Box, styled } from '@mui/material';
import AlertDialog from 'components/AlertDialog';
import Button from 'components/Button';
import ButtonGroup from 'components/ButtonGroup';
import InteractiveTable, { Column } from 'components/InteractiveTable';
import Spacing from 'components/Spacing';
import { BankAccountOperationStatus } from 'further-types/lp/bank-reconciliation';
import { useDisclosure } from 'further-ui/hooks';
import {
  BankAccountOperationSourceLabels,
  BankAccountOperationStatusLabels,
} from 'further-ui/labels';
import { dateToLabel, numberToCurrencyString } from 'further-ui/utils';
import useHandleTrpcMutation from 'hooks/ui/useHandleTrpcMutation';
import { usePagination } from 'hooks/ui/usePagination';
import api from 'lib/trpcClient';
import { useState } from 'react';

type Props = {
  bankAccount: {
    id: string;
    vehicleId: string;
  };
};

const Reconciled = styled(Box)(({ theme }) => ({
  color: theme.palette.text.rootColor,
}));
const Unreconciled = styled(Box)(({ theme }) => ({
  color: theme.palette.text.error,
}));

const DeleteButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.error,
  backgroundColor: alpha(theme.palette.background.danger ?? '', 0.1),
  fontWeight: theme.typography.fontWeightMedium,
}));

const BankStatement: React.FC<Props> = ({ bankAccount }) => {
  const pageId = `bank-statement-${bankAccount.id}`;
  const deleteDisclosure = useDisclosure();
  const [selectedOperations, setSelectedOperations] = useState<Array<string>>(
    [],
  );
  const pagination = usePagination({ id: pageId });

  const { data, isLoading } =
    api.bankReconciliation.listBankAccountOperations.useQuery({
      vehicleId: bankAccount.vehicleId,
      bankAccountId: bankAccount.id,
      page: pagination.page,
      rowsPerPage: pagination.rowsPerPage,
      orderBy: 'date',
      order: 'desc',
    });

  const deleteOperations =
    api.bankReconciliation.deleteBankAccountOperations.useMutation(
      useHandleTrpcMutation()({
        successMessage: 'Operations deleted successfully.',
        invalidationHandler: (apiUtils) => {
          apiUtils.bankReconciliation.invalidate();
        },
      }),
    );

  const handleDelete = () => {
    if (deleteOperations.isPending || !selectedOperations.length) return;

    deleteDisclosure.onClose();
    deleteOperations.mutate({
      bankAccountId: bankAccount.id,
      operationIds: selectedOperations,
      vehicleId: bankAccount.vehicleId,
    });
  };

  const columns: Array<Column> = [
    {
      field: 'date',
      headerName: 'Date',
      renderCell: ({ row }) => dateToLabel(row.date),
      flex: 1,
    },
    {
      field: 'counterparty',
      headerName: 'Counterparty',
      flex: 1,
    },
    {
      field: 'description',
      headerName: 'Details',
      flex: 1,
    },
    {
      field: 'spent',
      headerName: 'Spent',
      renderCell: ({ row }) =>
        numberToCurrencyString(row.spent, {
          fallback: '-',
          currency: row.accountCurrency,
        }),
      flex: 1,
    },
    {
      field: 'received',
      headerName: 'Received',
      renderCell: ({ row }) =>
        numberToCurrencyString(row.received, {
          fallback: '-',
          currency: row.accountCurrency,
        }),
      flex: 1,
    },
    {
      field: 'runningBalance',
      headerName: 'Running balance',
      renderCell: ({ row }) =>
        numberToCurrencyString(row.runningBalance, {
          currency: row.accountCurrency,
        }),
      flex: 1,
    },
    {
      field: 'source',
      headerName: 'Source',
      renderCell: ({ row }) => BankAccountOperationSourceLabels[row.source],
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      renderCell: ({ row }) =>
        row.status === BankAccountOperationStatus.Reconciled ? (
          <Reconciled>
            {BankAccountOperationStatusLabels[row.status]}
          </Reconciled>
        ) : (
          <Unreconciled>
            {BankAccountOperationStatusLabels[row.status]}
          </Unreconciled>
        ),
      flex: 1,
    },
  ];

  return (
    <>
      <Spacing size="lg">
        {!!selectedOperations.length && (
          <ButtonGroup>
            <DeleteButton
              variant="contained"
              color="error"
              onClick={deleteDisclosure.onOpen}
              loading={deleteOperations.isPending}
            >
              Delete selected statement lines
            </DeleteButton>
          </ButtonGroup>
        )}
        <InteractiveTable
          selectable
          disableTableConfiguration
          disableSorting
          id={pageId}
          columns={columns}
          rows={data?.results ?? []}
          loading={isLoading}
          pagination={pagination}
          rowCount={data?.totalCount ?? 0}
          onRowSelection={setSelectedOperations}
        />
      </Spacing>
      <AlertDialog
        open={deleteDisclosure.isOpen}
        onClose={deleteDisclosure.onClose}
        title="Are you sure?"
        content="This will change your overall statement balance. This is generally only required to remove duplicate statement lines."
        onConfirm={handleDelete}
        btnLabels={{ confirm: 'Delete', cancel: 'Go back' }}
        confirmBtnProps={{
          color: 'error',
          disabled: deleteOperations.isPending,
        }}
      />
    </>
  );
};

export default BankStatement;

import { Autocomplete, Divider } from '@mui/material';
import { sortBy } from 'lodash';
import { AppTextInput } from 'components/FormElements';
import { CurrencyCode } from 'further-types/lp/vehicle';
import { currencyLabels, majorCurrencies } from 'further-ui/labels';
import { forwardRef } from 'react';

type Props = {
  error?: {
    message?: string;
  };
  placeholder?: string;
  value: CurrencyCode;
  onChange: (value: CurrencyCode) => void;
  name?: string;
  disabled?: boolean;
};

const majorCurrencyOptions = majorCurrencies.map((currency) => ({
  label: currencyLabels[currency],
  value: currency,
}));

const nonMajorCurrencies = Object.values(CurrencyCode).filter(
  (currency) => !majorCurrencies.includes(currency),
);

const nonMajorCurrencyOptions = sortBy(
  nonMajorCurrencies.map((currency) => ({
    label: currencyLabels[currency],
    value: currency,
  })),
  'label',
);

const allOptions = [...majorCurrencyOptions, ...nonMajorCurrencyOptions];

const CurrencySelector = forwardRef<HTMLDivElement, Props>(
  ({ name, disabled, error, placeholder, value = '', onChange }, ref) => {
    return (
      <Autocomplete
        disableClearable
        disabled={disabled}
        ref={ref}
        options={allOptions}
        getOptionLabel={(option) => option.label}
        groupBy={(option) =>
          nonMajorCurrencies.includes(option.value as CurrencyCode)
            ? 'Other'
            : 'Major'
        }
        renderGroup={(params) => (
          <li key={params.key}>
            {params.group === 'Other' && Number(params.key) !== 0 && (
              <Divider sx={{ my: 2 }} />
            )}
            <ul>{params.children}</ul>
          </li>
        )}
        renderInput={(params) => (
          <AppTextInput
            {...params}
            name={name}
            error={!!error}
            helperText={error?.message?.toString()}
            type="text"
            placeholder={placeholder}
          />
        )}
        value={{ value, label: currencyLabels[value] }}
        onChange={(_, newValue) => {
          onChange(newValue.value as CurrencyCode);
        }}
      />
    );
  },
);

export default CurrencySelector;

import { useState } from 'react';
import { AllocationRule } from 'further-types/lp/allocations';
import { GenerateAllocationsParams } from 'hooks/lp/data/allocations/useAllocationsApi';

export interface UseAllocationRuleProps {
  startingAllocationRule?: AllocationRule;
  linkedActionId?: string | null;
  vehicleId: string;
  inputAmount: number;
  generateAllocations: (params: GenerateAllocationsParams) => void;
  excludedCommitmentIds: string[];
  setLinkedActionId?: (id?: string | null) => void;
  currentAction?: { incomeOrExpenseId?: string };
}

const useAllocationRule = ({
  startingAllocationRule,
  linkedActionId,
  vehicleId,
  inputAmount,
  generateAllocations,
  excludedCommitmentIds,
  setLinkedActionId,
  currentAction,
}: UseAllocationRuleProps) => {
  const [allocationRule, setAllocationRule] = useState(
    startingAllocationRule ?? AllocationRule.ByCommitmentAndClosingDate,
  );
  const [showLinkedActionWarning, setShowLinkedActionWarning] = useState(false);
  const [previousLinkedActionId, setPreviousLinkedActionId] = useState<
    string | null
  >(linkedActionId ?? null);

  const unlinkAction = (currentLinkedActionId: string) => {
    setShowLinkedActionWarning(true);
    setPreviousLinkedActionId(currentLinkedActionId);
    setLinkedActionId?.(null);
  };

  const onAllocationRuleChange = (newRule: AllocationRule) => {
    setAllocationRule(newRule);
    setShowLinkedActionWarning(false);

    if (newRule !== AllocationRule.LinkedAction) {
      setLinkedActionId?.(null);
    }

    if (newRule !== AllocationRule.Manual) {
      generateAllocations({
        allocationRule: newRule,
        vehicleId,
        amount: inputAmount,
        excludedCommitmentIds,
        currentAction,
        linkedActionId,
      });
    }

    return newRule;
  };

  return {
    allocationRule,
    setAllocationRule,
    showLinkedActionWarning,
    previousLinkedActionId,
    unlinkAction,
    onAllocationRuleChange,
    setShowLinkedActionWarning,
  };
};

export default useAllocationRule;

import React, { useState } from 'react';
import Grid from '@mui/material/Grid2';
import GridContainer from 'components/GridContainer';
import {
  Typography,
  Switch,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';
import TextField from 'components/FormElements/AppTextInput';
import AppSelectBox from 'components/FormElements/AppSelectBox';
import FieldRow from 'components/FormElements/FieldRow';
import CkEditor from 'components/CkEditor';
import { useInvestorReportingFormContext } from 'contexts/InvestorReportingFormContext';
import { Controller } from 'react-hook-form';
import { stripTags } from 'hooks/form/useInvestorReportingForm';
import Spacing from 'components/Spacing';

type Props = any;

const ManagerOverViewForm: React.FC<Props> = ({
  classes,
  manager,
  disabled,
  setValue,
  control,
  summaryClassName,
}) => {
  const { managerOverview } = useInvestorReportingFormContext();
  const [provideCommentary, setProvideCommentary] = useState(
    !!managerOverview.managerOverViewProvideCommentary,
  );
  const [shortDescriptionCharacterCount, setShortDescriptionCharacterCount] =
    useState(0);
  const shortDescriptionCharacterLimit = 220;
  const shortDescriptionCharacterError =
    shortDescriptionCharacterCount > shortDescriptionCharacterLimit;

  return (
    <>
      <Accordion className={classes.customAccordion} expanded={true}>
        <AccordionSummary
          className={summaryClassName}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.accordionHeader}>
            Manager’s overview (used for PDF updates, system emails, advisers
            and syndicates)
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.cmAccDetail}>
          <GridContainer>
            <Grid
              size={{
                md: 9,
                xs: 12,
              }}
            >
              <Spacing>
                <FieldRow title="Update title">
                  <Controller
                    name="managerOverview[managerOverViewTitle]"
                    defaultValue={managerOverview.managerOverViewTitle}
                    control={control}
                    render={({
                      field: { ref, ...rest },
                      formState: { errors },
                    }) => (
                      <TextField
                        {...rest}
                        inputRef={ref}
                        required
                        placeholder="e.g. Fund name - Q1 Quarterly Update"
                        fullWidth
                        //@ts-expect-error
                        error={!!errors?.managerOverview?.managerOverViewTitle}
                        helperText={
                          //@ts-expect-error
                          errors?.managerOverview?.managerOverViewTitle?.message
                        }
                        disabled={disabled}
                      />
                    )}
                  />
                </FieldRow>
                <FieldRow
                  title="Short description"
                  informationText="This is a two line preview of the update that will show in each investor’s portal before they open the update."
                >
                  <Controller
                    name="managerOverview[managerOverViewShortDescription]"
                    defaultValue={
                      managerOverview.managerOverViewShortDescription
                    }
                    control={control}
                    render={({
                      field: { name, value },
                      formState: { errors },
                    }) => (
                      <>
                        <CkEditor
                          placeholder="A brief 30-35 word (220 character) preview of fund performance and what the update contains."
                          //@ts-expect-error
                          name={name}
                          maxlength={220}
                          showLists={false}
                          onChange={(value: string) => {
                            setShortDescriptionCharacterCount(
                              stripTags(value ?? '').length,
                            );
                            setValue(
                              'managerOverview[managerOverViewShortDescription]',
                              value,
                            );
                          }}
                          onBlur={(value: string) => {
                            setValue(
                              'managerOverview[managerOverViewShortDescription]',
                              value,
                            );
                          }}
                          value={value}
                          disabled={disabled}
                        />
                        <p
                          className={classes.errorCk}
                          id="managerOverview[managerOverViewShortDescription]"
                        >
                          {
                            errors?.managerOverview /* @ts-expect-error */
                              ?.managerOverViewShortDescription?.message
                          }
                        </p>
                        {shortDescriptionCharacterCount > 0 && (
                          <p
                            className={`${classes.characterCount} ${
                              shortDescriptionCharacterError &&
                              classes.characterCountError
                            }`}
                          >
                            Character count: {shortDescriptionCharacterCount}{' '}
                            {shortDescriptionCharacterError &&
                              `(Character count must be ${shortDescriptionCharacterLimit} or less)`}
                          </p>
                        )}
                      </>
                    )}
                  />
                </FieldRow>
                <FieldRow title="Provide commentary">
                  <Controller
                    name="managerOverview[managerOverViewProvideCommentary]"
                    defaultValue={
                      !!managerOverview.managerOverViewProvideCommentary
                    }
                    control={control}
                    render={({ field: { name, value } }) => (
                      <Switch
                        name={name}
                        onChange={(event) => {
                          setValue(
                            'managerOverview[managerOverViewProvideCommentary]',
                            event.target.checked,
                          );
                          setProvideCommentary(event.target.checked);
                        }}
                        className={classes.switchCls}
                        checked={!!value}
                        disabled={disabled}
                      />
                    )}
                  />
                </FieldRow>
                {provideCommentary ? (
                  <>
                    <FieldRow
                      title="Fund manager commentary"
                      informationText="This is your firm’s introduction, where you can provide background context to the update and discuss any other topics you feel relevant. It is attributed to the manager selected below."
                    >
                      <Controller
                        name="managerOverview[managerOverViewCommentary]"
                        defaultValue={managerOverview.managerOverViewCommentary}
                        control={control}
                        render={({
                          field: { name, value },
                          formState: { errors },
                        }) => (
                          <>
                            <CkEditor
                              placeholder="A multi-paragraph overview of progress since the last update."
                              //@ts-expect-error
                              name={name}
                              onChange={(value: string) => {
                                setValue(
                                  'managerOverview[managerOverViewCommentary]',
                                  value,
                                );
                              }}
                              value={value}
                              disabled={disabled}
                              onBlur={(value: string) => {
                                setValue(
                                  'managerOverview[managerOverViewCommentary]',
                                  value,
                                );
                              }}
                            />
                            <p
                              className={classes.errorCk}
                              id="managerOverview[managerOverViewCommentary]"
                            >
                              {
                                errors?.managerOverview /* @ts-expect-error */
                                  ?.managerOverViewCommentary?.message
                              }
                            </p>
                          </>
                        )}
                      />
                    </FieldRow>
                    <FieldRow title="Select manager">
                      <Controller
                        name="managerOverview[managerOverViewAccountManagerId]"
                        defaultValue={
                          managerOverview.managerOverViewAccountManagerId
                        }
                        control={control}
                        render={({
                          field: { ref, ...rest },
                          formState: { errors },
                        }) => (
                          <AppSelectBox
                            {...rest}
                            inputRef={ref}
                            data={manager}
                            valueKey="_id"
                            labelKey="name"
                            variant="outlined"
                            error={
                              !!errors?.managerOverview /* @ts-expect-error */
                                ?.managerOverViewAccountManagerId
                            }
                            helperText={
                              errors?.managerOverview /* @ts-expect-error */
                                ?.managerOverViewAccountManagerId?.message
                            }
                            disabled={disabled}
                          />
                        )}
                      />
                    </FieldRow>
                    <FieldRow title="Manager's role">
                      <Controller
                        name="managerOverview[managerOverViewRole]"
                        defaultValue={managerOverview.managerOverViewRole}
                        control={control}
                        render={({
                          field: { ref, ...rest },
                          formState: { errors },
                        }) => (
                          <TextField
                            {...rest}
                            inputRef={ref}
                            placeholder="e.g. Partner, Fund name"
                            fullWidth
                            error={
                              //@ts-expect-error
                              !!errors?.managerOverview?.managerOverViewRole
                            }
                            helperText={
                              //@ts-expect-error
                              errors?.managerOverview?.managerOverViewRole
                                ?.message
                            }
                            disabled={disabled}
                          />
                        )}
                      />
                    </FieldRow>
                  </>
                ) : null}
              </Spacing>
            </Grid>
          </GridContainer>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default ManagerOverViewForm;

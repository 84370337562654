import React from 'react';
import { Box } from '@mui/material';
import Table from 'components/Table';
import { numberToCurrencyString, dateToLabel } from 'further-ui/utils';
import { useShareSplits } from 'hooks/data/company/useShareSplits';

const columns = [
  {
    label: 'Date',
    key: 'date',
    sort: false,
    render: (elm) => dateToLabel(elm.date),
  },
  {
    key: 'shareSplitFactor',
    label: 'Share split factor',
    sort: false,
  },
  {
    key: 'sharePricePreSplit',
    label: 'Original share price',
    sort: false,
    render: (elm) =>
      numberToCurrencyString(elm.sharePricePreSplit, { unlimitedDp: true }),
  },
  {
    key: 'sharePricePostSplit',
    label: 'New share price',
    sort: false,
    render: (elm) =>
      numberToCurrencyString(elm.sharePricePostSplit, { unlimitedDp: true }),
  },
];

type Props = {
  companyId: string;
};

const ShareSplitRows: React.FC<Props> = ({ companyId }) => {
  const { shareSplits } = useShareSplits({ params: { companyId } });

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Table columns={columns} tablebody={shareSplits?.data ?? []} />
    </Box>
  );
};

export default ShareSplitRows;

import { z } from 'zod';
import { isMongoId } from '../../helpers';
import { TransactionOperation } from 'further-types/lp/transaction';
import { FxDataFeed } from 'further-types/lp/vehicle';

export const IndividualTransaction = z.object({
  id: z
    .string()
    .optional()
    .refine(isMongoId({ optional: true })),
  actionId: z
    .string()
    .optional()
    .refine(isMongoId({ optional: true })),
  transactionTypeId: z.string().refine(isMongoId()),
  amountExVatTcy: z.number(),
  amountIncVatTcy: z.number(),
  amountExVatLcy: z.number(),
  amountIncVatLcy: z.number(),
  operationType: z.nativeEnum(TransactionOperation),
  transactionCurrencyRate: z
    .object({
      fetchedRate: z.number().optional(),
      fetchedSource: z.nativeEnum(FxDataFeed).optional(),
      overrideRate: z.number().optional(),
      overrideSource: z.string().optional(),
    })
    .optional(),
});

export const CreateTransactions = z.array(IndividualTransaction);

export const UpdateTransactions = z.array(
  IndividualTransaction.extend({
    id: z.string().refine(isMongoId()),
  }),
);

import { Schema } from 'further-types/lp/account';
import { isMongoId } from '../../helpers';
import { z } from 'zod';

export const ListAll = z.object({
  searchQuery: z.string().optional().nullable(),
});

export const CreateAccount = z.object({
  section: z.nativeEnum(Schema.AccountSection),
  accountName: z.string().min(1, 'Please enter account name.'),
  accountCode: z.string().min(1, 'Please enter account code.'),
});

export const UpdateAccount = CreateAccount.extend({
  id: z.string().refine(isMongoId()),
});

export const DeleteAccount = z.object({
  id: z.string().refine(isMongoId()),
});

import { z, ZodRawShape } from 'zod';

const withSortableParams = <T extends ZodRawShape, const U extends string>(
  schema: z.ZodObject<T>,
  sortableColumns: readonly [U, ...Array<U>],
) => {
  return schema.extend({
    order: z.enum(['asc', 'desc']),
    orderBy: z.enum(['createdAt', 'updatedAt', ...sortableColumns]),
  });
};
export default withSortableParams;

import { FC, useState } from 'react';
import { Button, styled, Typography } from '@mui/material';
import { AppSelectBox } from 'components/FormElements';
import FormLabel from 'components/FormElements/FormLabel';
import InvestorSearch from 'components/InvestorSearch';
import { FirmSelector } from 'components/FirmSelector';
import { useGetRole } from 'hooks/ui/useGetRole';
import { useDelegatedAccess } from 'hooks/data/delegatedAccess/useDelegatedAccess';
import { useNotification } from 'hooks/ui/useNotification';
import { NavLink } from 'react-router-dom';
import { INVESTOR_ADD } from 'adminConstants/routes';
import Heading from 'components/Heading';
import { Column, Row } from 'components/Layout';

const Container = styled(Column)({
  maxWidth: '60rem',
});

const FormRow = styled(Row)({
  alignItems: 'flex-end',
});

type Props = {
  investorId: string;
  closePanel: () => void;
};

const AddNewDelegatedAccessUser: FC<Props> = ({ investorId, closePanel }) => {
  const { isSuperAdmin, firmId: usersFirmId } = useGetRole();
  const [firmId, setFirmId] = useState<string | undefined>(usersFirmId);
  const [receivingAccessInvestorId, setReceivingAccessInvestorId] = useState<
    string | null
  >(null);
  const [copyIntoSystemEmails, setCopyIntoSystemEmails] = useState(false);
  const notification = useNotification();

  const clearForm = () => {
    setReceivingAccessInvestorId(null);
    setFirmId(usersFirmId);
    setCopyIntoSystemEmails(false);
  };

  const { create } = useDelegatedAccess({
    onCreate: () => {
      clearForm();
      closePanel();
    },
  });

  const submit = () => {
    if (!receivingAccessInvestorId) {
      if (isSuperAdmin && !firmId) {
        notification.error('Please select an investor and a firm.');
        return;
      } else {
        notification.error('Please select an investor.');
        return;
      }
    }

    create.mutate({
      receivingAccessInvestorId,
      givingAccessInvestorId: investorId,
      //@ts-expect-error
      firmId,
      copyIntoSystemEmails,
    });
  };

  return (
    <Container spacing="md">
      <Heading variant="h2" noMargin>
        Add a new delegated access user
      </Heading>
      <Typography color="text.secondary">
        Select an existing investor to provide delegated account access to.{' '}
        <NavLink target="_blank" to={INVESTOR_ADD}>
          <Typography color="primary" component="span" fontWeight="bold">
            Click here to create a new investor
          </Typography>
        </NavLink>
      </Typography>
      <FormRow spacing="md">
        <div style={{ flex: 2 }}>
          <FormLabel title="Select investor" />
          <InvestorSearch
            // @ts-expect-error
            onInvestorSelect={setReceivingAccessInvestorId}
            selectedInvestorId={receivingAccessInvestorId ?? undefined}
          />
        </div>
        {isSuperAdmin && (
          <div style={{ flex: 1 }}>
            <FormLabel title="Select firm" />
            <FirmSelector
              firmId={firmId}
              onChange={setFirmId}
              placeholder="Select firm"
              allowDeselection={false}
            />
          </div>
        )}
        <div style={{ flex: 1 }}>
          <FormLabel title="Copy user into emails?" />
          <AppSelectBox
            data={[
              { value: true, label: 'Yes' },
              { value: false, label: 'No' },
            ]}
            labelKey="label"
            valueKey="value"
            value={copyIntoSystemEmails}
            variant="outlined"
            onChange={(event: any) =>
              setCopyIntoSystemEmails(event.target.value)
            }
          />
        </div>
        <Button color="primary" variant="contained" onClick={submit}>
          Save
        </Button>
      </FormRow>
    </Container>
  );
};

export default AddNewDelegatedAccessUser;

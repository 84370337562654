import React, { useState } from 'react';
import { Typography, styled } from '@mui/material';
import { CheckCircleOutline, WarningOutlined } from '@mui/icons-material';
import Heading from 'components/Heading';
import { Row } from 'components/Layout';
import { CalendarDay, numberToCurrencyString } from 'further-ui/utils';
import useHandleTrpcMutation from 'hooks/ui/useHandleTrpcMutation';
import api from 'lib/trpcClient';
import Button from 'components/Button';
import ButtonGroup from 'components/ButtonGroup';
import { ActionOperationType } from 'further-types/lp/action';
import { round, sumBy } from 'lodash';
import { CurrencyCode } from 'further-types/lp/vehicle';
import TransactionAllocator, {
  Transaction,
} from 'components/TransactionAllocator';
import useTransactionTypeSelectorOptions from 'hooks/form/useTransactionTypeSelectorOptions';
import useVehicle from 'hooks/lp/data/vehicle/useVehicle';

const Totals = styled(Row)(({ theme }) => ({
  border: `1px solid ${theme.palette.borderColor?.dark}`,
  padding: theme.spacing(2, 5),
}));

type Props = {
  vehicleId: string;
  bankAccountId: string;
  transaction: {
    id: string;
    spent?: number;
    received?: number;
    accountCurrency: CurrencyCode;
    date: CalendarDay;
  };
  selectedActions: Array<{
    id: string;
    sourceActionId?: string;
    unreconciledAmount: number;
    operationType?: ActionOperationType;
  }>;
  onClose: () => void;
};

const Review: React.FC<Props> = ({
  vehicleId,
  bankAccountId,
  transaction,
  selectedActions,
  onClose,
}) => {
  const [transactions, setTransactions] = useState<Array<Transaction>>([]);
  const transactionAmount = transaction.spent ?? transaction.received ?? 0;

  const sumOfSelectedActions = round(
    sumBy(selectedActions, (action) =>
      action.operationType === ActionOperationType.Spent
        ? -action.unreconciledAmount
        : action.unreconciledAmount,
    ),
    3,
  );

  const amountMatches = sumOfSelectedActions === transactionAmount;
  const amountDiff = Math.abs(sumOfSelectedActions - transactionAmount);

  const Icon = amountMatches ? CheckCircleOutline : WarningOutlined;

  const [transactionTypes] =
    api.action.getDefaultReconciliationTransactions.useSuspenseQuery({
      vehicleId,
      actions: selectedActions.map((action) => ({
        amount: action.unreconciledAmount,
        amountLcy: 200,
        actionId: action.sourceActionId ?? action.id,
      })),
    });

  const reconcileOperation =
    api.bankReconciliation.reconcileBankAccountOperation.useMutation(
      useHandleTrpcMutation()({
        successMessage: 'Transaction reconciled.',
        invalidationHandler: (utils) => {
          utils.bankReconciliation.invalidate();
          utils.action.listActions.invalidate();
          utils.generalLedger.invalidate();
        },
      }),
    );

  const { vehicle } = useVehicle(vehicleId);
  const { transactionTypeOptions, isLoading: transactionTypesLoading } =
    useTransactionTypeSelectorOptions();

  const handleReconcile = () => {
    if (amountDiff) return;

    reconcileOperation.mutate({
      vehicleId,
      bankAccountId,
      bankAccountOperationId: transaction.id,
      reconciledActions: selectedActions.map((action) => ({
        amount:
          action.operationType === ActionOperationType.Spent
            ? -action.unreconciledAmount
            : action.unreconciledAmount,
        actionId: action.sourceActionId ?? action.id,
      })),
      transactions: transactions.map((transaction) => ({
        ...transaction,
        amountIncVatLcy: transaction.amountIncVatLcy ?? 0,
        amountExVatLcy: transaction.amountExVatLcy ?? 0,
      })),
    });
  };

  return (
    <>
      <Heading variant="h3" noMargin>
        1. Review selected transactions
      </Heading>
      <Row spacing="md" fullWidth centered>
        <Totals fullWidth centered justify="space-between">
          <Typography variant="body2" color="textSecondary">
            Bank statement line
          </Typography>
          <Typography variant="body2" color="textSecondary" fontWeight="bold">
            {numberToCurrencyString(transactionAmount, {
              fallback: '-',
              currency: transaction.accountCurrency,
            })}
          </Typography>
        </Totals>
        <Totals fullWidth centered justify="space-between">
          <Typography variant="body2" color="textSecondary">
            Selected transactions
          </Typography>
          <Typography variant="body2" color="textSecondary" fontWeight="bold">
            {numberToCurrencyString(sumOfSelectedActions, {
              currency: transaction.accountCurrency,
            })}
          </Typography>
        </Totals>
      </Row>
      <Row centered justify="flex-end" spacing="xs">
        <Icon color={amountMatches ? 'primary' : 'error'} />
        <Typography
          variant="body2"
          color={amountMatches ? 'textRootColor' : 'error'}
          fontWeight="bold"
        >
          {amountMatches
            ? 'Total matches'
            : `Total is out by ${numberToCurrencyString(amountDiff, {
                currency: transaction.accountCurrency,
              })}`}
        </Typography>
      </Row>

      {!!vehicle && !transactionTypesLoading && !amountDiff && (
        <>
          <Heading variant="h3" noMargin>
            2. Review account transactions
          </Heading>
          <TransactionAllocator
            transactionTypeOptions={transactionTypeOptions}
            defaultTransactions={transactionTypes ?? []}
            onChange={setTransactions}
            transactionCurrency={transaction.accountCurrency}
            transactionDate={transaction.date}
            vehicle={vehicle}
          />
        </>
      )}
      <ButtonGroup>
        <Button variant="outlined" onClick={onClose}>
          Cancel and close
        </Button>
        <Button
          variant="contained"
          disabled={!amountMatches}
          onClick={handleReconcile}
          loading={reconcileOperation.isPending}
        >
          Reconcile
        </Button>
      </ButtonGroup>
    </>
  );
};

export default Review;

import { api } from 'lib/httpClient';
import { addHttps } from 'utils/strings';
import { Api } from 'further-types/companies';
import { ApiResponse } from 'further-types/api';

export const getCompanyById = (id) => {
  return () => {
    return api
      .get(`investee/list/${id}`)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response.data.data);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
export const addCompany = (data) => {
  const companyData = data;

  companyData.url = addHttps(companyData.url);
  companyData.linkedinUrl = addHttps(companyData.linkedinUrl);
  companyData.twitterUrl = addHttps(companyData.twitterUrl);

  return () => {
    return api
      .post(`investee/create`, companyData)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.resolve(error);
      });
  };
};
export const updateCompany = (data: Api.UpdateInvesteeRequest) => {
  const companyData = data;

  companyData.url = addHttps(companyData.url);
  companyData.linkedinUrl = addHttps(companyData.linkedinUrl);
  companyData.twitterUrl = addHttps(companyData.twitterUrl);

  return () => {
    return api
      .put<ApiResponse<{ success: true }>>(
        `investee/update/${companyData.id}`,
        companyData,
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.resolve(error);
      });
  };
};

export const deleteCompany = (id) => {
  return () => {
    return api
      .delete(`investee/delete/${id}`)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const deleteDividend = (id) => {
  return () => {
    return api
      .delete(`investee/delete-dividend/${id}`)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const deleteNews = (id) => {
  return () => {
    return api
      .delete(`investee/delete-new/${id}`)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

import { RadioGroup, SelectChangeEvent, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import Grid from '@mui/material/Grid2';
import { investment as rules } from 'further-ui/validations';
import RadioWithInput from '../RadioWithInput';
import AppSelectBox from 'components/FormElements/AppSelectBox';
import { FC } from 'react';
import { styled } from '@mui/material/styles';
import { HoldingClassSelectionType } from 'further-types/lp/investment';
import { CurrencyCode } from 'further-types/lp/vehicle';

interface HoldingClassSelectionProps {
  index: number;
  holdingClassOptions: Array<{
    id: string;
    title: string;
    currency: CurrencyCode;
  }>;
  resetHoldingClassDropdown: (index: number) => void;
  isHoldingClassSelectDisabled: boolean;
}

const SelectionHelperText = styled(Typography)({
  fontSize: '14px',
  lineHeight: '22px',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  color: 'text.secondary',
});

const HoldingClassSelection: FC<HoldingClassSelectionProps> = ({
  index,
  holdingClassOptions,
  resetHoldingClassDropdown,
  isHoldingClassSelectDisabled,
}) => {
  const { control, setValue } =
    useFormContext<rules.CreateInvestmentFormValues>();

  const handleHoldingClassChange = (value: string) => {
    const selectedClass = holdingClassOptions.find(
      (option) => option.id === value,
    );
    if (selectedClass) {
      setValue(`holdingClasses.${index}.holdingClassId`, selectedClass.id);
      setValue(
        `holdingClasses.${index}.existingHoldingClassName`,
        selectedClass.title,
      );
      setValue(`holdingClasses.${index}.currency`, selectedClass.currency);
    }
  };

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isNew = e.target.value === HoldingClassSelectionType.NEW;
    setValue(`holdingClasses.${index}.isNewHoldingClass`, isNew);

    if (isNew) {
      setValue(`holdingClasses.${index}.holdingClassId`, '');
      setValue(`holdingClasses.${index}.existingHoldingClassName`, '');
    }
  };

  return (
    <Controller
      name={`holdingClasses.${index}.isNewHoldingClass`}
      control={control}
      render={({ field: { value, ...rest } }) => (
        <RadioGroup
          {...rest}
          value={
            value === null || value === undefined
              ? ''
              : value
              ? HoldingClassSelectionType.NEW
              : HoldingClassSelectionType.EXISTING
          }
          onChange={handleRadioChange}
        >
          <Grid container spacing={{ xs: 6 }}>
            <RadioWithInput
              value={HoldingClassSelectionType.EXISTING}
              label="Select existing holding class"
              disabled={!holdingClassOptions.length}
              inputComponent={
                <Controller
                  name={`holdingClasses.${index}.holdingClassId`}
                  control={control}
                  render={({
                    field: { ref, onChange: fieldOnChange, ...rest },
                    fieldState: { error },
                  }) => (
                    <AppSelectBox
                      {...rest}
                      fullWidth
                      data={holdingClassOptions}
                      placeholder="Select existing holding class"
                      error={!!error}
                      helperText={error?.message}
                      ref={ref}
                      disabled={isHoldingClassSelectDisabled}
                      key={`holding-class-select-${index}-${holdingClassOptions.length}`}
                      onChange={(event: SelectChangeEvent<unknown>) => {
                        fieldOnChange(event.target.value);
                        handleHoldingClassChange(event.target.value as string);
                      }}
                    />
                  )}
                />
              }
            />

            <RadioWithInput
              value={HoldingClassSelectionType.NEW}
              label="Create new holding class"
              onRadioClick={() => {
                resetHoldingClassDropdown(index);
              }}
              inputComponent={
                !value && (
                  <SelectionHelperText>
                    Select if the holding class is not yet in the system
                  </SelectionHelperText>
                )
              }
            />
          </Grid>
        </RadioGroup>
      )}
    />
  );
};

export default HoldingClassSelection;

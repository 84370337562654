import { Autocomplete, createFilterOptions } from '@mui/material';
import { AppTextInput } from 'components/FormElements';
import { forwardRef } from 'react';
import { CalendarDay } from 'further-ui/utils';

type Action = {
  id?: string;
  name: string;
  inputValue?: string;
  date: CalendarDay;
  assetName?: string;
};

type Props = {
  actions: Array<Action>;
  error?: {
    message?: string;
  };
  placeholder?: string;
  value?: Action | null;
  onChange: (value: Action | undefined) => void;
  name?: string;
  disabled?: boolean;
};

const ActionSelector = forwardRef<HTMLDivElement, Props>(
  (
    {
      name,
      disabled,
      actions,
      error,
      placeholder,
      value = undefined,
      onChange,
    },
    ref,
  ) => {
    const filter = createFilterOptions<Action>();

    return (
      <Autocomplete
        clearOnBlur
        handleHomeEndKeys
        selectOnFocus
        disabled={disabled}
        ref={ref}
        options={actions}
        value={value || null}
        onChange={(_, newValue) => {
          onChange(newValue ?? undefined);
        }}
        renderInput={(params) => (
          <AppTextInput
            {...params}
            name={name}
            error={!!error}
            helperText={error?.message?.toString()}
            placeholder={placeholder}
          />
        )}
        isOptionEqualToValue={(option, value) =>
          option.id === value.id && option.name === value.name
        }
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.name;
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, {
            ...params,
            inputValue: params.inputValue,
            getOptionLabel: (option) => option.name,
          });

          return filtered;
        }}
        renderOption={(props, option) => {
          const { key, ...optionProps } = props;
          return (
            <li key={key} {...optionProps}>
              {option.inputValue ? option.name : option.name}
            </li>
          );
        }}
      />
    );
  },
);

export default ActionSelector;

import { alpha, Box, styled, Typography } from '@mui/material';
import Button from 'components/Button';
import { AppAutocomplete } from 'components/FormElements';
import { Row } from 'components/Layout';
import Spacing from 'components/Spacing';
import { ActionRef } from 'further-types/lp/action';
import { CurrencyCode } from 'further-types/lp/vehicle';
import { numberToCurrencyString } from 'further-ui/utils';
import { Controller, useFormContext } from 'react-hook-form';
import { FormValues } from '..';

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.background.rootColor ?? '', 0.2),
  padding: theme.spacing(5),
  width: '100%',
  height: '146px',
}));

const FindMatchButton = styled(Button)({
  padding: 0,
  textDecoration: 'underline',
  lineHeight: 1.6,
});

const NoActions = styled(Box)(({ theme }) => ({
  height: '146px',
  width: '100%',
  border: `1px solid ${theme.palette.borderColor?.dark}`,
  display: 'flex',
  alignItems: 'stretch',
  justifyContent: 'stretch',
}));

type Props = {
  onFindAndMatch: (actionId?: string) => void;
  actions: Array<{
    id: string;
    amount: number;
    counterpartyLabel: string;
    actionRef: ActionRef;
    currencyCode: CurrencyCode;
  }>;
};

const QuickReconcile: React.FC<Props> = ({ actions, onFindAndMatch }) => {
  const { control } = useFormContext<FormValues>();

  if (!actions.length)
    return (
      <NoActions>
        <Row centered justify="center" fullWidth>
          <Button variant="outlined" onClick={() => onFindAndMatch()}>
            Find and match
          </Button>
        </Row>
      </NoActions>
    );

  const options = actions.map((action) => ({
    label: `${action.actionRef} - ${
      action.counterpartyLabel
    } - ${numberToCurrencyString(action.amount, {
      currency: action.currencyCode,
    })}`,
    value: action.id,
  }));

  return (
    <>
      <Container>
        <Spacing size="sm">
          <Typography variant="body1" fontWeight="bold" color="textSecondary">
            Quick reconcile
          </Typography>
          <Controller
            control={control}
            name="actionId"
            render={({ field: { onChange, value } }) => (
              <AppAutocomplete
                disableClearable
                options={options}
                onChange={(_, value) => {
                  if (!value) return;
                  onChange(value.value);
                }}
                value={options.find((option) => option.value === value)}
                getOptionLabel={(option) => option.label}
                placeholder="Select action"
              />
            )}
          />
          <Typography variant="body1" color="textSecondary">
            or{' '}
            <FindMatchButton onClick={() => onFindAndMatch()}>
              Find a match
            </FindMatchButton>
          </Typography>
        </Spacing>
      </Container>
    </>
  );
};

export default QuickReconcile;

import { KycRiskRating } from 'further-types/kyc';

export const kycRiskRatingLabels: Array<{
  value: KycRiskRating;
  label: string;
}> = [
  { value: KycRiskRating.Low, label: 'Low' },
  { value: KycRiskRating.Medium, label: 'Medium' },
  { value: KycRiskRating.MediumOrHigh, label: 'Medium/High' },
  { value: KycRiskRating.High, label: 'High' },
];

import { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { round } from 'lodash';
import Grid from '@mui/material/Grid2';

import RecordView from 'components/RecordView';
import GridContainer from 'components/GridContainer';
import PageSection from 'components/PageSection';
import Spacing from 'components/Spacing';
import ButtonGroup from 'components/ButtonGroup';
import Button from 'components/Button';
import InputDetailsForm from './InputDetailsForm';
import AllocationsTable from 'components/AllocationsTable';
import { Box, styled } from '@mui/material';
import { Allocations } from 'components/AllocationsTable/types';
import { AllocationRule } from 'further-types/lp/allocations';
import { CalendarDay } from 'further-ui/utils';

const AllocationSubStep = styled(Box)`
  margin-top: 2rem;
`;

type Props = {
  vehicles?: Array<{
    _id: string;
    vehicleName: string;
  }>;
  contacts: Array<{
    _id: string;
    name: string;
  }>;
  setAllocationValues: (allocationValues: {
    allocations: Array<Allocations>;
    allocationRule: AllocationRule;
  }) => void;
  allocationValues: {
    allocations: Array<Allocations>;
    allocationRule?: AllocationRule;
  };
  onCompletionStep1B: () => void;
  actions?: Array<{
    id: string;
    ledgerDate: CalendarDay;
    asset?: {
      name: string;
    };
  }>;
};

const StepAddAndAllocate: FC<Props> = ({
  vehicles,
  contacts,
  allocationValues,
  setAllocationValues,
  onCompletionStep1B,
  actions,
}) => {
  const [showAllocationSubStep, setShowAllocationSubStep] = useState(
    allocationValues.allocations.length > 0,
  );
  const { setValue, watch, handleSubmit } = useFormContext();
  const formValues = watch();

  const [inProgressAllocationsIsValid, setInProgressAllocationsIsValid] =
    useState(true);

  useEffect(() => {
    if (formValues.amountExVat !== null && formValues.vatRate !== null) {
      const amountIncVat = round(
        formValues.amountExVat * (1 + formValues.vatRate / 100),
        2,
      );
      if (formValues.amountIncVat !== amountIncVat) {
        setValue('amountIncVat', amountIncVat);
      }
    }
  }, [formValues.amountExVat, formValues.vatRate]);

  const onCompletionStep1A = () => {
    setShowAllocationSubStep(true);
  };

  useEffect(() => {
    if (allocationValues.allocations.length > 0) {
      setShowAllocationSubStep(true);
    }
  }, [allocationValues.allocations]);

  const onAllocationsChange = (
    isValid: boolean,
    allocations: Array<Allocations>,
    allocationRule: AllocationRule,
  ) => {
    setAllocationValues({ allocations, allocationRule });
    if (isValid) {
      setInProgressAllocationsIsValid(true);
    } else {
      setInProgressAllocationsIsValid(false);
    }
  };

  return (
    <RecordView size="full">
      <GridContainer>
        <Grid size={{ sm: 12, md: 12, lg: 8 }}>
          <PageSection title="A. Add income or expense">
            <Spacing size="lg">
              <p>
                Use this section to add a one-off (non-recurring) income,
                expense or refund.
              </p>
              <form>
                <Spacing>
                  <InputDetailsForm
                    vehicles={vehicles}
                    incomeOrExpense={formValues.category}
                    transactionCurrency={formValues.currencyCode}
                    contacts={contacts}
                    actions={actions}
                  />
                  {!showAllocationSubStep && (
                    <ButtonGroup>
                      <Button
                        variant="contained"
                        color="primary"
                        type="button"
                        onClick={handleSubmit(onCompletionStep1A)}
                      >
                        Next
                      </Button>
                    </ButtonGroup>
                  )}
                </Spacing>
              </form>
            </Spacing>
          </PageSection>
        </Grid>
      </GridContainer>

      {showAllocationSubStep && (
        <AllocationSubStep>
          <Spacing size="lg">
            <PageSection title="B. Create allocation">
              <AllocationsTable
                onAllocationsChange={onAllocationsChange}
                vehicleId={formValues.vehicleId}
                inputAmount={formValues.amountIncVat}
                inputLabel="income/expense"
                currency={formValues.currencyCode}
                startingValues={allocationValues.allocations}
                startingAllocationRule={allocationValues.allocationRule}
                currentAction={{ incomeOrExpenseId: formValues._id }}
                linkedActionId={formValues.linkedActionId}
                setLinkedActionId={(id) => setValue('linkedActionId', id)}
              />
            </PageSection>
            <ButtonGroup>
              <Button
                variant="contained"
                color="primary"
                type="button"
                onClick={() => onCompletionStep1B()}
                disabled={!inProgressAllocationsIsValid}
                tooltip={
                  !inProgressAllocationsIsValid
                    ? `Please ensure that the total ${formValues.category} in the allocation equals the entered ${formValues.category} amount`
                    : undefined
                }
              >
                Proceed to summary
              </Button>
            </ButtonGroup>
          </Spacing>
        </AllocationSubStep>
      )}
    </RecordView>
  );
};

export default StepAddAndAllocate;

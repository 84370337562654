import { FC, useEffect, useState } from 'react';
import { CircularProgress, styled } from '@mui/material';

interface Props {
  totalJobs?: number;
  onComplete: () => void;
}

const ProgressContainer = styled('div')({
  gap: '2rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

// fixed for EIS certificate generation
const minTime = 6000;
const timePerJob = 150;
const endTime = 6000;
const steps = 20;

const EnqueuedJobProgress: FC<Props> = ({ totalJobs, onComplete }) => {
  const totalJobsUnknown = !totalJobs && totalJobs !== 0;
  const [progress, setProgress] = useState(5);

  const totalTime = minTime + (totalJobs || 0) * timePerJob + endTime;
  const stepTime = totalTime / steps;

  useEffect(() => {
    const interval = setInterval(() => {
      if (totalJobsUnknown) {
        return;
      }
      setProgress((prevProgress) => {
        const newProgress = prevProgress + 100 / steps;
        if (newProgress >= 100 || totalJobs === 0) {
          clearInterval(interval);
          onComplete();
          return 100;
        }
        return newProgress;
      });
    }, stepTime);

    return () => clearInterval(interval);
  }, [onComplete, stepTime]);

  return (
    <ProgressContainer>
      {totalJobsUnknown ? (
        <CircularProgress />
      ) : (
        <CircularProgress variant="determinate" value={progress} />
      )}
      <p>This may take a few minutes. Please don't close the page.</p>
    </ProgressContainer>
  );
};

export default EnqueuedJobProgress;

import { ApiResponses } from 'lib/trpcClient';
import React, { useContext } from 'react';
import { useLocalStorage } from 'usehooks-ts';

type BankAccount =
  ApiResponses['bankReconciliation']['listBankAccounts'][number];

type BankReconciliationContextType = {
  selectedBankAccount: BankAccount | undefined;
  setSelectedBankAccountId: (bankAccountId: string) => void;
  bankAccounts: Array<BankAccount>;
};

const BankReconciliationContext =
  React.createContext<BankReconciliationContextType>({
    selectedBankAccount: undefined,
    setSelectedBankAccountId: () => {},
    bankAccounts: [],
  });

const BankReconciliationContextProvider: React.FC<{
  children: React.ReactNode;
  vehicleId: string;
  bankAccounts: Array<BankAccount>;
}> = ({ children, vehicleId, bankAccounts }) => {
  const [selectedBankAccountId, setSelectedBankAccountId] = useLocalStorage<
    string | null
  >(`current-bank-account-id-${vehicleId}`, null);

  const selectedBankAccount =
    bankAccounts?.find(
      (bankAccount) => bankAccount.id === selectedBankAccountId,
    ) ?? bankAccounts?.[0];

  const contextValue = {
    bankAccounts,
    selectedBankAccount,
    setSelectedBankAccountId,
  };

  return (
    <BankReconciliationContext.Provider value={contextValue}>
      {children}
    </BankReconciliationContext.Provider>
  );
};

export const useBankReconciliationContext = () =>
  useContext(BankReconciliationContext);

export default BankReconciliationContextProvider;

import { Step, StepLabel, Stepper } from '@mui/material';
import PageContainer from 'components/PageContainer';
import PageContent from 'components/PageContent';
import RecordView from 'components/RecordView';
import { useState } from 'react';
import UploadFile from './UploadFile';
import { UploadFileResult } from 'hooks/ui/useFileUpload';
import Settings from './Settings';
import Review from './Review';
import { useParams } from 'react-router-dom';
import FullPageSuccess from 'components/FullPageSuccess';
import {
  BANK_RECONCILIATION,
  createImportStatementFileRoute,
} from 'adminConstants/routes';

const pageTitle = 'Bank reconciliation';

enum Steps {
  Upload,
  Settings,
  Review,
  Success,
}

const stepLabels = {
  [Steps.Upload]: 'Upload',
  [Steps.Settings]: 'Import settings',
  [Steps.Review]: 'Review',
};

const ImportStatement: React.FC = () => {
  const [activeStep, setActiveStep] = useState(Steps.Upload);
  const [dateFormat, setDateFormat] = useState<string>();
  const [importedTransactions, setImportedTransactions] = useState<number>(0);
  const [uploadedFile, setUploadedFile] = useState<UploadFileResult | null>(
    null,
  );

  const { bankAccountId } = useParams<{ bankAccountId: string }>();

  const handleNextStep = (importedTransactions?: number) => {
    if (importedTransactions) {
      setImportedTransactions(importedTransactions);
    }
    setActiveStep(activeStep + 1);
  };

  const handlePreviousStep = () => {
    setActiveStep(activeStep - 1);
  };

  if (activeStep === Steps.Success) {
    return (
      <PageContainer heading={pageTitle}>
        <FullPageSuccess
          title="Bank statement imported successfully"
          additionalText={`Your bank statement with ${importedTransactions} transactions has been successfully imported.`}
          proceedText="Return to Bank Reconciliation page"
          proceedLink={BANK_RECONCILIATION}
          secondaryText="Import another bank statement"
          secondaryLink={createImportStatementFileRoute(bankAccountId)}
        />
      </PageContainer>
    );
  }

  return (
    <PageContainer heading={pageTitle}>
      <PageContent>
        <RecordView>
          <Stepper activeStep={activeStep}>
            {Object.values(stepLabels).map((stepLabel) => {
              return (
                <Step key={stepLabel}>
                  <StepLabel>{stepLabel}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === Steps.Upload && (
            <UploadFile
              onFileUpload={setUploadedFile}
              uploadedFile={uploadedFile ?? undefined}
              onNextStep={handleNextStep}
            />
          )}
          {activeStep === Steps.Settings && !!uploadedFile && (
            <Settings
              uploadedFile={uploadedFile}
              onNextStep={handleNextStep}
              onPreviousStep={handlePreviousStep}
              onDateFormatChange={setDateFormat}
              dateFormat={dateFormat}
              bankAccountId={bankAccountId}
            />
          )}
          {activeStep === Steps.Review && !!uploadedFile && !!dateFormat && (
            <Review
              uploadedFile={uploadedFile}
              dateFormat={dateFormat}
              bankAccountId={bankAccountId}
              onPreviousStep={handlePreviousStep}
              onNextStep={handleNextStep}
            />
          )}
        </RecordView>
      </PageContent>
    </PageContainer>
  );
};

export default ImportStatement;

import { forwardRef } from 'react';
import { MenuItem, Select, SelectProps, styled } from '@mui/material';

type Props = SelectProps & {
  options: Array<{ value: string; label: string; disabled?: boolean }>;
  placeholder?: string;
};

const Placeholder = styled('p')(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const AppSimpleSelect = forwardRef<HTMLDivElement, Props>(
  ({ options = [], placeholder = 'Select', value = '', ...rest }, ref) => {
    return (
      <Select
        displayEmpty
        native={false}
        renderValue={() =>
          value ? (
            options.find((option) => option.value === value)?.label
          ) : (
            <Placeholder>{placeholder}</Placeholder>
          )
        }
        ref={ref}
        value={value}
        {...rest}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            disabled={option.disabled}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    );
  },
);

export default AppSimpleSelect;

import { FC } from 'react';
import { Button, CircularProgress, IconButton, Tooltip } from '@mui/material';
import { InvestmentPaymentType } from 'further-types/payment';
import { Api } from 'further-types/investment';
import EmailIcon from '@mui/icons-material/Email';
import { styled } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { FormData } from '.';

type TableElement = Api.InvestmentPaymentTransaction & { isFormRow: boolean };

type Props = {
  elm: TableElement;
  control: Control<FormData>;
  onAddPayment: () => void;
  isAddingPayment: boolean;
  unreconcile: (id: string) => void;
  isUnreconciling: boolean;
};

const ActionContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  '& > button': {
    flex: 1,
  },
});

const EmailIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'isEnabled',
})<{ isEnabled: boolean }>(({ theme, isEnabled }) => ({
  color: isEnabled ? theme.palette.success.main : theme.palette.grey[300],
  flex: '0 !important',
  marginRight: '10px',
}));

const PaymentButton = styled(Button)({
  whiteSpace: 'nowrap',
  lineHeight: '19px !important',
});

const unreconcileLabel = (paymentType: InvestmentPaymentType) =>
  paymentType === InvestmentPaymentType.CashBalance ? 'Reverse' : 'Unreconcile';

const tooltipLabel = (
  <div>
    Removing this payment would result in <em>funds received</em> becoming lower
    than <em>allocated funds</em> plus{' '}
    <em>funds reserved for future cash fees</em>. Please remove an allocation or
    add a fee discount before unreconciling this payment.
  </div>
);

const FormActions: FC<Props> = ({
  elm,
  control,
  onAddPayment,
  isAddingPayment,
  unreconcile,
  isUnreconciling,
}) =>
  !elm.isFormRow ? (
    <ActionContainer>
      {elm.canBeUnreconciled ? (
        <Button
          variant="outlined"
          color="secondary"
          disabled={isUnreconciling || isAddingPayment}
          onClick={() => unreconcile(elm._id)}
          size="small"
        >
          {isUnreconciling ? (
            <CircularProgress size={20} style={{ margin: '0 1.5rem' }} />
          ) : (
            unreconcileLabel(elm.paymentType)
          )}
        </Button>
      ) : (
        <Tooltip title={tooltipLabel}>
          <Button
            variant="outlined"
            color="secondary"
            disabled={true}
            size="small"
          >
            {unreconcileLabel(elm.paymentType)}
          </Button>
        </Tooltip>
      )}
    </ActionContainer>
  ) : (
    <ActionContainer>
      <Controller
        control={control}
        name="sendEmailConfirmation"
        render={({ field: { onChange, value } }) => (
          <Tooltip
            placement="top"
            title={`Payment confirmation email is ${value ? 'ON' : 'OFF'}.`}
          >
            <EmailIconButton
              isEnabled={value}
              onClick={() => {
                onChange(!value);
              }}
            >
              <EmailIcon />
            </EmailIconButton>
          </Tooltip>
        )}
      />
      <PaymentButton
        variant="outlined"
        color="primary"
        disabled={isAddingPayment || isUnreconciling}
        onClick={onAddPayment}
        size="small"
      >
        {isAddingPayment ? (
          <CircularProgress size={19} style={{ margin: '0 1.5rem' }} />
        ) : (
          'Add payment'
        )}
      </PaymentButton>
    </ActionContainer>
  );
export default FormActions;

import { format } from 'date-fns';

export const getInvestorExitStatementPdfFilename = (
  exitStatement: {
    company: {
      tradingName: string;
      shareClass: string;
    };
    amendment?: {
      date?: string;
    };
    exit: {
      date: string;
    };
  },
  fullName?: string,
) => {
  const realisationDate = format(
    new Date(exitStatement.amendment?.date ?? exitStatement.exit.date),
    'dd-MM-yyyy',
  );

  return `${fullName ?? ''} - Exit Statement - ${
    exitStatement.company.tradingName
  } - ${exitStatement.company.shareClass} - ${realisationDate}.pdf`;
};

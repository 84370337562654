import { forwardRef } from 'react';
import { FileRejection, useDropzone, ErrorCode } from 'react-dropzone';
import { Delete, Attachment } from '@mui/icons-material';
import { Alert } from '@mui/material';
import { Uploads } from 'adminConstants/files';
import useStyles from './styles';
import { FileType } from 'further-ui/utils';
import { pluralize } from 'utils/pluralize';

const defaultAcceptedImages = {
  [FileType.JPGImage]: ['.jpg', '.jpeg'],
  [FileType.PNGImage]: ['.png'],
  [FileType.SVGImage]: ['.svg'],
};

const defaultAcceptedFiles = {
  [FileType.PDF]: ['.pdf'],
  [FileType.CSV]: ['.csv'],
  [FileType.Excel]: ['.xls'],
  [FileType.ExcelAlt]: ['.xlsx'],
  [FileType.DOCX]: ['.docx'],
};

const numbersToWordsMap = {
  1: 'one',
  10: 'ten',
  100: 'hundred',
};

export interface UploadedFile extends File {
  preview: string;
  path?: string;
}

type Props = (
  | {
      accept: Record<string, Array<string>>;
      defaultAcceptGroup?: never;
    }
  | {
      accept?: never;
      defaultAcceptGroup: 'files' | 'images' | 'filesAndImages';
    }
) & {
  name: string;
  files: Array<{
    fileName: string;
    preview: string;
  }>;
  onDropFile: (files: Array<UploadedFile>) => void;
  onRemove: (index: number) => void;
  maxFiles?: keyof typeof numbersToWordsMap;
  disabled?: boolean;
  maxFileSize?: number;
};

const Dropzone = forwardRef<HTMLDivElement, Props>(
  (
    {
      files,
      onDropFile,
      name,
      accept,
      defaultAcceptGroup,
      onRemove,
      maxFiles = 1,
      disabled,
      maxFileSize = Uploads.maxFileSize,
    },
    ref,
  ) => {
    const { classes, cx } = useStyles({});

    let acceptedFileTypes: Record<string, Array<string>> = {};

    if (accept && Object.keys(accept).length > 0) {
      acceptedFileTypes = accept;
    } else if (defaultAcceptGroup === 'files') {
      acceptedFileTypes = defaultAcceptedFiles;
    } else if (defaultAcceptGroup === 'images') {
      acceptedFileTypes = defaultAcceptedImages;
    } else if (defaultAcceptGroup === 'filesAndImages') {
      acceptedFileTypes = {
        ...defaultAcceptedFiles,
        ...defaultAcceptedImages,
      };
    }

    const onDrop = (acceptedFiles: Array<File>) => {
      onDropFile(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    };

    const formatErrorMessages = (fileRejections: Array<FileRejection>) => {
      const maxFilesError = fileRejections.find((rejectedFile) =>
        rejectedFile.errors.some(
          (error) => error.code === ErrorCode.TooManyFiles,
        ),
      );

      if (maxFilesError) {
        return `You can only upload ${numbersToWordsMap[maxFiles]} ${pluralize(
          'file',
          maxFiles,
        )}. Please check and try again.`;
      }

      const invalidTypeErrors = fileRejections.find((rejectedFile) =>
        rejectedFile.errors.some(
          (error) => error.code === ErrorCode.FileInvalidType,
        ),
      );

      if (invalidTypeErrors) {
        return `The following file types may be uploaded: ${Object.values(
          acceptedFileTypes,
        ).join(', ')}. Please check and try again.`;
      }

      const tooLargeErrors = fileRejections.find((rejectedFile) =>
        rejectedFile.errors.some(
          (error) => error.code === ErrorCode.FileTooLarge,
        ),
      );

      if (tooLargeErrors) {
        return `The file you are trying to upload is too large. The maximum file size is ${
          maxFileSize / 1024 / 1024
        }MB. Please check and try again.`;
      }

      const tooSmallErrors = fileRejections.find((rejectedFile) =>
        rejectedFile.errors.some(
          (error) => error.code === ErrorCode.FileTooSmall,
        ),
      );

      if (tooSmallErrors) {
        return `The file you are trying to upload is too small. Please check and try again.`;
      }

      return 'An error occurred while uploading the file. Please check and try again.';
    };

    const {
      getRootProps,
      getInputProps,
      isDragActive,
      isDragAccept,
      isDragReject,
      fileRejections,
    } = useDropzone({
      onDrop,
      accept: acceptedFileTypes,
      maxFiles,
      disabled,
      maxSize: maxFileSize,
    });

    const rootClasses = cx({
      [classes.root]: true,
      [classes.active]: isDragActive,
      [classes.accept]: isDragAccept,
      [classes.reject]: isDragReject,
    });

    const onlyImagesAccepted = Object.keys(acceptedFileTypes).every((a) =>
      a.startsWith('image/'),
    );

    const thumbs = files.map((file, index) => (
      <div key={index}>
        {acceptedFileTypes && !onlyImagesAccepted ? (
          <div className={classes.fileContent}>
            <div className={classes.fileName}>
              <Attachment className={classes.attachmentIcon} />{' '}
              <div
                className={classes.ellipsis}
                onClick={() => file.preview && window.open(file.preview)}
                role="button"
                tabIndex={0}
                onKeyUp={() => {}}
              >
                {
                  file?.fileName?.split('/')[
                    file?.fileName?.split('/')?.length - 1
                  ]
                }
              </div>
              <div>
                <Delete
                  onClick={() => onRemove(index)}
                  className={classes.removeIcon}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className={classes.thumbParent}>
            <div className={classes.thumb}>
              <div className={classes.thumbInner}>
                <img
                  src={file.preview}
                  className={classes.previewImage}
                  alt={'Preview of the file just uploaded'}
                />
              </div>
              <div className={classes.trashIcon}>
                <Delete onClick={() => onRemove(index)} />
              </div>
            </div>
          </div>
        )}
      </div>
    ));

    return (
      <section ref={ref}>
        <div {...getRootProps({})} className={rootClasses}>
          <input {...getInputProps()} name={name} />
          <span className={classes.dropzoneInstructions}>
            Drag and drop a file here or click to search
          </span>
        </div>
        {fileRejections?.length ? (
          <Alert severity="error" className={classes.fileErrors}>
            {formatErrorMessages(fileRejections)}
          </Alert>
        ) : null}
        {files?.length ? (
          <aside className={!onlyImagesAccepted ? '' : classes.thumbsContainer}>
            {thumbs}
          </aside>
        ) : (
          ''
        )}
      </section>
    );
  },
);

export default Dropzone;

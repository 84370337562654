import { ActionRef } from 'further-types/lp/action';
import { IncomeAndExpensesLabels } from './income-and-expenses';
import { InvestmentTypeLabels } from './investment';

export const ActionRefLabels = {
  [ActionRef.CapitalCall]: 'Capital Call',
  [ActionRef.IncomeOrExpense]: 'Income or Expense',
  [ActionRef.Investment]: 'Investment',
  [ActionRef.Realisation]: 'Realisation',
  [ActionRef.ShareRevaluation]: 'Share Revaluation',
  [ActionRef.Reconciliation]: 'Reconciliation',
};

export const ActionTypeLabels = {
  ...IncomeAndExpensesLabels,
  ...InvestmentTypeLabels,
};

import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  styled,
  Typography,
} from '@mui/material';
import Heading from 'components/Heading';
import Spacing from 'components/Spacing';
import { UploadFileResult } from 'hooks/ui/useFileUpload';
import TransactionPreview from './TransactionPreview';
import ButtonGroup from 'components/ButtonGroup';
import Button from 'components/Button';
import api from 'lib/trpcClient';
import { useLayoutContext } from 'contexts/LayoutContext';

const RadioLabel = styled(FormControlLabel)(({ theme }) => ({
  width: '400px',
  border: `1px solid ${theme.palette.borderColor?.dark}`,
  padding: theme.spacing(1, 3),
}));

const Label = styled(FormLabel)(({ theme }) => ({
  fontWeight: 700,
  fontSize: theme.fontSizes?.lg,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(2),
}));

const DateFormatContainer = styled(RadioGroup)(({ theme }) => ({
  paddingLeft: theme.spacing(3),
}));

type Props = {
  uploadedFile: UploadFileResult;
  onNextStep: () => void;
  onPreviousStep: () => void;
  onDateFormatChange: (dateFormat: string) => void;
  dateFormat?: string;
  bankAccountId: string;
};

const dateFormatOptions = [
  { label: 'dd/mm/yyyy', value: 'dd/MM/yyyy' },
  { label: 'mm/dd/yyyy', value: 'MM/dd/yyyy' },
  { label: 'dd/mm/yy', value: 'dd/MM/yy' },
  { label: 'mm/dd/yy', value: 'MM/dd/yy' },
];

const Settings: React.FC<Props> = ({
  uploadedFile,
  onNextStep,
  onPreviousStep,
  dateFormat,
  onDateFormatChange,
  bankAccountId,
}) => {
  const { selectedVehicleId } = useLayoutContext();

  const { data, isLoading } =
    api.bankReconciliation.bankStatement.getPreview.useQuery(
      {
        bankAccountId,
        vehicleId: selectedVehicleId ?? '',
        dateFormat: dateFormat ?? '',
        bankStatementFilePath: uploadedFile.filePath,
        page: 1,
        rowsPerPage: 1,
        order: 'asc',
        orderBy: 'date',
      },
      {
        enabled: !!dateFormat,
      },
    );

  const handleNextStep = () => {
    if (!data?.totalCount) {
      return;
    }

    onNextStep();
  };

  const operationPreview = data?.operations[0];

  return (
    <Spacing size="lg">
      <Spacing size="sm">
        <Heading variant="h3" noMargin>
          Set up transactions for import
        </Heading>
        <Typography variant="body1" color="textSecondary">
          Select the date format used in your bank statement. This will be
          applied to transactions you’re importing.
        </Typography>
      </Spacing>
      <FormControl>
        <Label id="date-format-label">Select date format</Label>
        <DateFormatContainer
          aria-labelledby="date-format-label"
          name="date-format"
          onChange={(e) => onDateFormatChange(e.target.value)}
          value={dateFormat}
        >
          {dateFormatOptions.map((dateFormat) => (
            <RadioLabel
              key={dateFormat.value}
              value={dateFormat.value}
              control={<Radio />}
              label={dateFormat.label}
            />
          ))}
        </DateFormatContainer>
      </FormControl>
      <Spacing size="sm">
        <Heading variant="h3" noMargin>
          Transaction preview
        </Heading>
        {isLoading ? (
          <CircularProgress />
        ) : (
          !!dateFormat && <TransactionPreview {...(operationPreview ?? {})} />
        )}
      </Spacing>
      <ButtonGroup>
        <Button variant="outlined" onClick={onPreviousStep}>
          Back
        </Button>
        <Button
          variant="contained"
          disabled={!dateFormat || !data?.totalCount}
          onClick={handleNextStep}
        >
          Next
        </Button>
      </ButtonGroup>
    </Spacing>
  );
};

export default Settings;

import {
  AntiDilutionRights,
  CouponType,
  LiquidationPreferenceType,
  MonitoringRights,
} from 'further-types/lp/holding-class';
import {
  AntiDilutionLabels,
  CouponTypeLabels,
  LiquidationPreferenceLabels,
  MonitoringRightsLabels,
} from 'further-ui/labels';

export const liquidationPreferenceTypeOptions = Object.values(
  LiquidationPreferenceType,
).map((value) => ({
  id: value,
  title: LiquidationPreferenceLabels[value] || value,
}));

export const monitoringRightsOptions = Object.values(MonitoringRights).map(
  (value) => ({
    id: value,
    title: MonitoringRightsLabels[value] || value,
  }),
);

export const antiDilutionRightsOptions = Object.values(AntiDilutionRights).map(
  (value) => ({
    id: value,
    title: AntiDilutionLabels[value] || value,
  }),
);

export const couponRightsOptions = Object.values(CouponType).map((value) => ({
  id: value,
  title: CouponTypeLabels[value] || value,
}));

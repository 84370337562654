import LogRocket from 'logrocket';
import * as Sentry from '@sentry/react';

const shouldRunTracking = () => process.env.REACT_APP_STAGE === 'production';

export const initLogrocket = (user: {
  _id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  roleSlug?: string;
  firm?: {
    _id?: string;
  };
}) => {
  if (user && shouldRunTracking()) {
    LogRocket.init('sq3uml/furthers-admin-panel');

    if (user._id) {
      LogRocket.identify(user._id, {
        name: `${user.firstName} ${user.lastName}`,
        email: user.email ?? '',
        firmId: user.firm?._id ?? '',
        roleSlug: user.roleSlug ?? '',
      });
    }
  }
};

export const initSentry = () => {
  if (shouldRunTracking()) {
    Sentry.init({
      dsn: 'https://800c52120f56cfab08746fcefeb732b3@o4504655840739328.ingest.us.sentry.io/4507021992984576',
      integrations: [Sentry.browserTracingIntegration()],
      tracesSampleRate: 0.1,
      environment: process.env.REACT_APP_STAGE,
      release: process.env.REACT_APP_API_VERSION_HASH,
    });
  }
};

export const identifySentry = (user: {
  firstName?: string;
  lastName?: string;
  email?: string;
  roleSlug?: string;
  firm?: {
    _id?: string;
  };
}) => {
  if (shouldRunTracking()) {
    Sentry.setUser({
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
      firmId: user.firm?._id,
      roleSlug: user.roleSlug,
    });
  }
};

import { PROCESS_BANK_ACCOUNT } from 'adminConstants/routes';
import useHandleTrpcMutation from 'hooks/ui/useHandleTrpcMutation';
import { useNotification } from 'hooks/ui/useNotification';
import api from 'lib/trpcClient';

type BankAccount = {
  id: string;
  vehicleId: string;
  bankFeedAccountId?: string;
  bankFeedInstitutionId?: string;
};

export default function useManageBankAccount(
  selectedBankAccount?: BankAccount,
) {
  const { error } = useNotification();

  const importOperations = api.bankReconciliation.importOperations.useMutation(
    useHandleTrpcMutation()({
      invalidationHandler: (apiUtils) =>
        apiUtils.bankReconciliation.invalidate(),
    }),
  );

  const deactivateBankFeed =
    api.bankReconciliation.deactivateBankFeed.useMutation(
      useHandleTrpcMutation()({
        successMessage: 'Bank feed deactivated successfully.',
        invalidationHandler: (apiUtils) =>
          apiUtils.bankReconciliation.invalidate(),
      }),
    );

  const authoriseBank = api.bankReconciliation.authoriseBank.useMutation({
    onSuccess: (data) => {
      if (!data) {
        error('Failed to authorise bank.');
        return;
      }

      window.location.href = data.redirectUrl;
    },
  });

  const handleRefreshBankFeed = () => {
    if (!selectedBankAccount || !selectedBankAccount.bankFeedAccountId) return;

    importOperations.mutate({
      bankAccountId: selectedBankAccount.id,
      vehicleId: selectedBankAccount.vehicleId,
    });
  };

  const handleDeactivateBankFeed = () => {
    if (!selectedBankAccount || !selectedBankAccount.bankFeedAccountId) return;

    deactivateBankFeed.mutate({
      bankAccountId: selectedBankAccount.id,
      vehicleId: selectedBankAccount.vehicleId,
    });
  };

  const handleRenewBankConnection = () => {
    if (
      !selectedBankAccount ||
      !selectedBankAccount.bankFeedAccountId ||
      !selectedBankAccount.bankFeedInstitutionId
    )
      return;

    authoriseBank.mutate({
      institutionId: selectedBankAccount.bankFeedInstitutionId,
      redirectUrl: `${window.location.protocol}//${window.location.host}${PROCESS_BANK_ACCOUNT}?bankAccountId=${selectedBankAccount.id}`,
    });
  };

  return {
    refreshBankFeed: handleRefreshBankFeed,
    deactivateBankFeed: handleDeactivateBankFeed,
    renewBankConnection: handleRenewBankConnection,
    isRefreshingBankFeed: importOperations.isPending,
    isPending: importOperations.isPending || deactivateBankFeed.isPending,
  };
}

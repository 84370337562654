import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from 'lib/httpClient';
import { ApiResponse } from 'further-types/api';
import { useNotification } from 'hooks/ui/useNotification';
import useApiExceptionHandler from 'hooks/ui/useApiExceptionHandler';
import { Api } from 'further-types/investment-cool-off';

function useSkipInvestmentCoolOff() {
  const queryClient = useQueryClient();
  const notification = useNotification();
  const handleApiException = useApiExceptionHandler();

  return useMutation({
    mutationFn: async ({ investorId, firmId }: Api.SkipCoolOffRequest) => {
      const response = await api.post<ApiResponse<Api.SkipCoolOffResponse>>(
        '/investment-cool-off/skip',
        { investorId, firmId },
      );
      return response.data.data;
    },
    onSuccess: async ({ investorId }) => {
      notification.success('Investment cool-off period skipped successfully.');

      await queryClient.invalidateQueries({
        queryKey: ['investment-cool-off'],
      });

      await queryClient.invalidateQueries({
        queryKey: ['investor', investorId],
      });

      await queryClient.invalidateQueries({
        queryKey: ['investors'],
      });
    },
    onError: (error: Error) => {
      handleApiException(error);
    },
  });
}

export default useSkipInvestmentCoolOff;

import { FC, useEffect } from 'react';
import { CalendarDay } from 'further-ui/utils';
import { styled } from '@mui/material';
import api from 'lib/trpcClient';
import { CurrencyCode, FxDataFeed } from 'further-types/lp/vehicle';
import { TransactionCurrencyRateDetails } from 'further-types/lp/transaction';
import { useDisclosure } from 'further-ui/hooks';
import CustomFxRateInputDialog from './CustomFxRateInputDialog';
import CancelCustomRateDialog from './CancelCustomRateDialog';
import { useNotification } from 'hooks/ui/useNotification';

const P = styled('p')(({ theme }) => ({
  color: theme.palette.text.darkBodyText,
}));

const InlineGreenTextButton = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
  cursor: 'pointer',
  textDecoration: 'underline',
}));

const InlineRedTextButton = styled('span')(({ theme }) => ({
  color: theme.palette.error.main,
  cursor: 'pointer',
  textDecoration: 'underline',
}));

type Props = {
  transactionCurrency: CurrencyCode;
  transactionDate: CalendarDay;
  vehicle: {
    _id: string;
    baseCurrency: CurrencyCode;
    fxDataFeed: FxDataFeed;
  };
  fxRate: TransactionCurrencyRateDetails | null;
  onRateChange: (rateDetails: TransactionCurrencyRateDetails) => void;
};

const FxRateInformation: FC<Props> = ({
  transactionCurrency,
  transactionDate,
  vehicle,
  fxRate,
  onRateChange,
}) => {
  const { _id, baseCurrency, fxDataFeed } = vehicle;
  const notification = useNotification();
  const customRateInputDisclosure = useDisclosure();
  const cancelCustomRateDisclosure = useDisclosure();

  const { mutate: getHistoricalRate, isPending } =
    api.transactionFx.historicalRate.useMutation({
      onSuccess: (data) => {
        if (!data) throw new Error('Failed to fetch historical rate');
        const key = `${baseCurrency}${transactionCurrency}`;
        const fetchedRate = data[key];
        onRateChange({ fetchedRate, fetchedSource: fxDataFeed });
      },
      onError: () => {
        notification.error(
          `Failed to fetch the foreign exhange rate from ${fxDataFeed}. The rate will be set to 1 but can be overridden.`,
        );
        onRateChange({ fetchedRate: 1 });
      },
    });

  useEffect(() => {
    if (!fxRate) {
      getHistoricalRate({
        transactionCurrency,
        vehicleId: _id,
        date: transactionDate.toUTCDate(),
      });
    }
  }, []);

  if (isPending) return <em>Loading exchange rate...</em>;

  return (
    <>
      <CustomFxRateInputDialog
        disclosure={customRateInputDisclosure}
        baseCurrency={baseCurrency}
        transactionCurrency={transactionCurrency}
        onRateChange={onRateChange}
        fxRate={fxRate}
      />
      <CancelCustomRateDialog
        disclosure={cancelCustomRateDisclosure}
        onRateChange={onRateChange}
        fxRate={fxRate}
      />
      {fxRate?.overrideRate ? (
        <P>
          <strong>
            {baseCurrency} 1.00 = {transactionCurrency} {fxRate?.overrideRate}
          </strong>{' '}
          at <strong>00:00</strong> on{' '}
          <strong>{transactionDate.toLabel()}</strong>{' '}
          <em>(Source: {fxRate.overrideSource})</em>{' '}
          <InlineGreenTextButton onClick={customRateInputDisclosure.onOpen}>
            Amend manual rate
          </InlineGreenTextButton>{' '}
          /{' '}
          <InlineRedTextButton onClick={cancelCustomRateDisclosure.onOpen}>
            Revert to live FX feed
          </InlineRedTextButton>
        </P>
      ) : (
        <P>
          <strong>
            {baseCurrency} 1.00 = {transactionCurrency} {fxRate?.fetchedRate}
          </strong>{' '}
          at <strong>00:00</strong> on{' '}
          <strong>{transactionDate.toLabel()}</strong>{' '}
          <em>(Feed: {fxDataFeed})</em>{' '}
          <InlineGreenTextButton onClick={customRateInputDisclosure.onOpen}>
            Override rate
          </InlineGreenTextButton>
        </P>
      )}
    </>
  );
};

export default FxRateInformation;

import {
  MonitoringRights,
  CouponType,
  AntiDilutionRights,
  LiquidationPreferenceType,
} from 'further-types/lp/holding-class';
import { CurrencyCode } from 'further-types/lp/vehicle';
import { isMongoId, withSortableParams } from '../../helpers';
import { z } from 'zod';
export const ListAll = withSortableParams(
  z.object({
    search: z.string().optional().nullable(),
    assetId: z.string().refine(isMongoId()),
  }),
  [''],
);

const BaseHoldingClassSchema = z.object({
  investmentAmount: z.number({
    required_error: 'Investment amount is required',
  }),
  numberOfUnits: z.number({
    required_error: 'Number of units is required',
  }),
  unitPrice: z.number({
    required_error: 'Unit price is required',
  }),
  currency: z.nativeEnum(CurrencyCode, {
    errorMap: () => ({ message: 'Currency is required' }),
  }),
});

const NewHoldingClassSchema = BaseHoldingClassSchema.extend({
  isNewHoldingClass: z.literal(true),
  holdingClassId: z.string().optional(),
  name: z
    .string({
      required_error: 'Class name is required',
    })
    .min(1, 'Class name is required'),
  monitoringRights: z
    .nativeEnum(MonitoringRights, {
      errorMap: () => ({
        message: 'Monitoring rights is required',
      }),
    })
    .optional(),
  liquidationPreferenceType: z
    .nativeEnum(LiquidationPreferenceType, {
      errorMap: () => ({
        message: 'Liquidation preference type is required',
      }),
    })
    .optional(),
  liquidationPreferenceMultiple: z.number().optional(),
  antiDilutionRights: z
    .nativeEnum(AntiDilutionRights, {
      errorMap: () => ({
        message: 'Anti-dilution rights is required',
      }),
    })
    .optional(),
  couponRights: z
    .nativeEnum(CouponType, {
      errorMap: () => ({ message: 'Coupon rights is required' }),
    })
    .optional(),
  couponAmount: z.number().optional(),
});

const ExistingHoldingClassSchema = BaseHoldingClassSchema.extend({
  isNewHoldingClass: z.literal(false),
  holdingClassId: z.string().refine(isMongoId(), {
    message: 'Please select an existing holding class or create a new one',
  }),
  existingHoldingClassName: z.string({
    required_error: 'Class name is required',
  }),
});

export const HoldingClassSchema = z.discriminatedUnion('isNewHoldingClass', [
  NewHoldingClassSchema,
  ExistingHoldingClassSchema,
]);

export type HoldingClassFormValues = z.infer<typeof HoldingClassSchema>;

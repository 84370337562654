import { isMongoId } from '../../helpers';
import { z } from 'zod';

export const CreateTransactionType = z.object({
  accountId: z.string().refine(isMongoId()),
  name: z.string().min(1, 'Please enter transaction type name.'),
});

export const UpdateTransactionType = z.object({
  transactionTypeId: z.string().refine(isMongoId()),
  name: z.string().min(1, 'Please enter transaction type name.'),
});

export const DeleteTransactionType = z.object({
  transactionTypeId: z.string().refine(isMongoId()),
});

export const ImportTransactionTypes = z.object({
  file: z.string().min(1, 'Please select a file.'),
});

export const ValidateUploadedTransactionType = z.object({
  id: z
    .string()
    .optional()
    .refine(isMongoId({ optional: true }), 'Invalid transaction id.'),
  accountCode: z.string().min(1, 'Account code is required.'),
  accountName: z.string().min(1, 'Account name is required.'),
  name: z.string().min(1, 'Transaction name is required.'),
});

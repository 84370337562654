import { Link } from 'react-router-dom';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import api from 'lib/trpcClient';
import { VEHICLES } from 'adminConstants/routes';
import PageContainer from 'components/PageContainer';
import PageContent from 'components/PageContent';
import PageSection from 'components/PageSection';
import ButtonGroup from 'components/ButtonGroup';
import Button from 'components/Button';
import RecordView from 'components/RecordView';
import { vehicle as rules } from 'further-ui/validations';
import useHandleTrpcMutation from 'hooks/ui/useHandleTrpcMutation';
import FieldRow from 'components/FormElements/FieldRow';
import TextField from 'components/FormElements/AppTextInput';

const AddVehicle: React.FC = () => {
  const mutationOpts = useHandleTrpcMutation();

  const addVehicle = api.vehicle.create.useMutation(
    mutationOpts({
      successMessage: 'Vehicle created successfully',
      successRedirect: VEHICLES,
      invalidationHandler: (utils) => utils.vehicle.invalidate(),
    }),
  );

  const breadcrumbs = [
    { label: 'Dashboard' },
    { label: 'Firms', link: '/firm' },
    { label: 'Add Vehicle', link: '/', isActive: true },
  ];

  const formMethods = useForm({
    shouldFocusError: true,
    resolver: zodResolver(rules.Create),
    criteriaMode: 'all',
  });

  const onSubmit = async (data) => {
    addVehicle.mutate({ ...data });
  };

  return (
    <PageContainer heading={'Add Vehicle'} breadcrumbs={breadcrumbs}>
      <PageContent>
        <FormProvider {...formMethods}>
          <form>
            <RecordView size="full">
              <PageSection title="General information">
                <FieldRow title="Vehicle name*" centerTitle>
                  <Controller
                    name="vehicleName"
                    control={formMethods.control}
                    render={({
                      fieldState: { error },
                      field: { ref, ...rest },
                    }) => (
                      <TextField
                        {...rest}
                        inputRef={ref}
                        placeholder="e.g. Venture Fund I"
                        helperText={error?.message?.toString()}
                        fullWidth
                      />
                    )}
                  />
                </FieldRow>
              </PageSection>

              <ButtonGroup>
                <Link to={VEHICLES}>
                  <Button variant="outlined">Cancel</Button>
                </Link>
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={formMethods.handleSubmit(onSubmit)}
                  loading={addVehicle.isPending}
                >
                  Save
                </Button>
              </ButtonGroup>
            </RecordView>
          </form>
        </FormProvider>
      </PageContent>
    </PageContainer>
  );
};

export default AddVehicle;

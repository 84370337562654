import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { api } from 'lib/httpClient';
import { useNotification } from 'hooks/ui/useNotification';
import downloadFile from 'utils/downloadFile';
import { ApiResponse } from 'further-types/api';
import { Api } from 'further-types/investmentTransfer';

export type ShareHolding = {
  id: string;
  noOfShare: number;
  subscriptionAmount: number;
  currentSharePrice: number;
  currentShareValue: number;
  date: string;
  sharePrice: number;
  investment: {
    id: string;
    investmentDate: string;
  };
  company: {
    initialPrice: number;
    tradingName: string;
    shareClass: string;
  };
  fund: {
    fundName: string;
  };
  investor: object;
};

export type InvestmentDetails = {
  investmentId: string;
  investmentDate: string;
  fundName: string;
  shareholdings: ShareHolding[];
  availableForTransfer: boolean;
};

export type CashBalance = {
  cashBalance: number;
  firm: {
    _id: string;
    firmName: string;
  };
};

export type InvestorDetailsResponse = {
  cashBalances: CashBalance[];
  investments: InvestmentDetails[];
};

type CreateInvestmentTransferResponse = {
  data: {
    _id: string;
  };
};

type Options = {
  onCreate?: (
    investmentTransfer: CreateInvestmentTransferResponse['data'],
  ) => void;
};

type CreateInvestmentTransferInput = {
  firmId: string;
  recipientId: string;
  sourceInvestorId: string;
  shareholdings: {
    shareholdingId: string;
    sharesToTransfer: number;
    investmentId: string;
  }[];
  cashBalances: {
    balanceToTransfer: number;
    firmId?: string;
  }[];
};

function useGetInvestorDetails(investorId?: string, firmId?: string) {
  return useQuery<InvestorDetailsResponse>({
    queryKey: ['transfers-investor-details', { investorId, firmId }],
    queryFn: async () => {
      const response = await api.get(
        `investment-transfer/investor-details/${investorId}`,
        {
          params: {
            firmId,
          },
        },
      );

      return response.data.data;
    },
    placeholderData: keepPreviousData,
    staleTime: 60 * 60 * 1000,
    enabled: !!investorId,
  });
}

function useCreateInvestmentTransfer(onCreate: Options['onCreate']) {
  const queryClient = useQueryClient();
  const notification = useNotification();

  return useMutation<
    CreateInvestmentTransferResponse,
    unknown,
    CreateInvestmentTransferInput
  >({
    mutationFn: async (data) => {
      const { data: result } = await api.post<CreateInvestmentTransferResponse>(
        'investment-transfer/create',
        data,
      );
      return result;
    },
    onSuccess: async (data) => {
      notification.success('Your transfer has been processed successfully.');
      await queryClient.invalidateQueries({ queryKey: ['investors'] });
      onCreate?.(data.data);
    },
    onError: (error) => {
      const errorMessage = (error as AxiosError<ApiResponse>).response?.data
        ?.responseMsg;
      notification.error(errorMessage ?? 'Something went wrong');
    },
  });
}

const exportInvestmentTransfer = async (investmentTransferId: string) => {
  const response = await api.get(
    `investment-transfer/${investmentTransferId}/export`,
    {
      responseType: 'blob',
    },
  );

  if (
    response.status !== 200 ||
    !response.headers['content-type']?.startsWith(
      'application/vnd.openxmlformats',
    )
  ) {
    throw Error('Unexpected export response.');
  }

  downloadFile(response.data, `Investment-Transfer-${Date.now()}.xlsx`);
};

const useDeleteInvestmentTransfer = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: { investmentTransferId: string }) => {
      const { data: response } = await api.delete<
        ApiResponse<Api.DeleteInvestmentTransfersResponse>
      >(`investment-transfer/${data.investmentTransferId}`);
      return response;
    },
    onSuccess: async (data) => {
      if (data.data.success) {
        await queryClient.invalidateQueries({
          queryKey: ['investment-transfers'],
        });
      }
    },
  });
};

const useInvestmentTransfer = ({ onCreate }: Options = {}) => {
  const {
    mutate: createInvestmentTransfer,
    isPending: isCreatingInvestmentTransfer,
  } = useCreateInvestmentTransfer(onCreate);

  const { mutateAsync: deleteInvestmentTransfer } =
    useDeleteInvestmentTransfer();

  return {
    getInvestorDetails: useGetInvestorDetails,
    createInvestmentTransfer,
    deleteInvestmentTransfer,
    exportInvestmentTransfer,
    isCreatingInvestmentTransfer,
  };
};

export default useInvestmentTransfer;

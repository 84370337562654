import { FC, useState, useEffect } from 'react';
import {
  useHistory,
  useRouteMatch,
  useParams,
  useLocation,
} from 'react-router-dom';
import { Stepper, Step, StepLabel } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import CompanyDetails from './CompanyDetails';
import EisFormData from './EisFormData';
import FormActionButtons from './FormActionButtons';
import Summary from './Summary';
import { Api } from 'further-types/eis-wizard';
import PageContainer from 'components/PageContainer';
import { EIS_EDIT, EIS } from 'adminConstants/routes';

import EnqueuedJobProgress from 'components/EnqueuedJobProgress';
import FullPageSuccess from 'components/FullPageSuccess';
import { useNotification } from 'hooks/ui/useNotification';
import { useEisWizard } from 'hooks/data/eisWizard/useEisWizard';
import { useDownloadByEisWizardId } from 'hooks/data/eisWizard/useDownloadEisCertificatePdfs';
import { format } from 'date-fns';
import PageContent from 'components/PageContent';
import RecordView from 'components/RecordView';

const steps = [
  'Select company and tranche(s)',
  'Input form data',
  'Summary',
  'Success',
];

const useStyles = makeStyles()(() => ({
  stepper: {
    background: 'transparent',
    marginBottom: '2rem',
    paddingLeft: 0,
  },
  cardContentPadding: {
    padding: '2rem',
  },
}));

type FormDataType = Omit<
  Api.MutateEisWizardRequest,
  | 'dateOfShareIssue'
  | 'terminationDateOfShare'
  | 'hmrcAuthorisationDate'
  | 'signatureDate'
> & {
  dateOfShareIssue: Date;
  terminationDateOfShare: Date;
  hmrcAuthorisationDate: Date;
  signatureDate: Date;
};

const AddUpdateEisWizard: FC = () => {
  const history = useHistory();

  const isEditPage = useRouteMatch(EIS_EDIT)?.isExact;
  const { id } = useParams<{ id: string }>();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const { error } = useNotification();

  const breadcrumbs = [
    { label: 'Dashboard', link: '/' },
    { label: 'S/EIS Tasklist', link: '/eiswizard' },
    {
      label: isEditPage
        ? 'Update S/EIS Certificates'
        : 'Create S/EIS Certificates',
      link: '/',
      isActive: true,
    },
  ];
  const [activeStep, setActiveStep] = useState(0);

  const [data, setData] = useState<FormDataType>({
    companyId: searchParams.get('presetCompanyId') || '',
    shareAllocationUploadId:
      searchParams.get('presetShareAllocationUploadId') || '',
    signatureType: '0',
    //@ts-expect-error
    signatureDate: null,
    //@ts-expect-error
    dateOfShareIssue: null,
    //@ts-expect-error
    terminationDateOfShare: null,
    //@ts-expect-error
    hmrcAuthorisationDate: null,
    notifyInvestors: false,
  });
  const [summaryData, setSummaryData] = useState<any>({});
  const [errorText, setErrorText] = useState({});
  const { classes } = useStyles();
  const downloadCertificates = useDownloadByEisWizardId();

  const onMutationSuccess = (data: Api.MutateEisWizardResponse) => {
    setSummaryData({
      ...summaryData,
      eiswizardId: data._id,
      jobsAddedToQueue: data.jobsAddedToQueue,
    });
  };

  const { eisWizard, createEisWizard, updateEisWizard } = useEisWizard(
    onMutationSuccess,
    id,
  );

  const showEnqueuedJobProgress =
    createEisWizard.isPending ||
    updateEisWizard.isPending ||
    createEisWizard.isSuccess ||
    updateEisWizard.isSuccess;

  const validate = () => {
    const errors: Record<string, string> = {};
    let isError = false;
    if (!data.investmentType) {
      errors.investmentType = 'Please select investment type.';
      isError = true;
    }
    if (!data.uir) {
      errors.uir = 'Please enter unique investment reference';
      isError = true;
    } else if (!/^[a-zA-Z0-9-~` ]+$/i.test(data.uir)) {
      errors.uir = 'Only alphanumeric are allowed.';
      isError = true;
    }
    if (!data.descriptionShare) {
      errors.descriptionShare = 'Please enter description of shares.';
      isError = true;
    } else if (data.descriptionShare.length >= 40) {
      errors.descriptionShare = 'Only 40 characters are allowed.';
      isError = true;
    }
    if (!data.nominalValueOfEachShare) {
      errors.nominalValueOfEachShare =
        'Please enter nominal value of each share.';
      isError = true;
    }
    if (!data.terminationDateOfShare) {
      errors.terminationDateOfShare =
        'Please select termination date of share.';
      isError = true;
    }
    if (!data.dateOfShareIssue) {
      errors.dateOfShareIssue = 'Please select date of share issue.';
      isError = true;
    }
    if (
      data.knowledgeIntensive === undefined ||
      data.knowledgeIntensive === null ||
      data.knowledgeIntensive === ''
    ) {
      errors.knowledgeIntensive = 'Please select an option';
      isError = true;
    }
    if (!data.address1) {
      errors.address1 = 'Please enter address 1.';
      isError = true;
    }
    if (!data.city) {
      errors.city = 'Please enter city.';
      isError = true;
    }
    if (!data.country) {
      errors.country = 'Please enter your country.';
      isError = true;
    }
    if (!data.postcode) {
      errors.postcode = 'Please enter your postcode.';
      isError = true;
    }
    if (!data.nameOfCompanyRepresentative) {
      errors.nameOfCompanyRepresentative =
        'Please enter name of company representative.';
      isError = true;
    }
    if (!data.capicityInWhichSigned) {
      errors.capicityInWhichSigned = 'Please enter capacity in which signed.';
      isError = true;
    }
    if (!data.signatureType && data.signatureType !== '0') {
      errors.signatureType = 'Please select an option.';
      isError = true;
    }
    if (!data.signatureDate) {
      errors.signatureDate = 'Please select signature date.';
      isError = true;
    }
    setErrorText(errors);

    return {
      errors,
      isError,
    };
  };

  const handleBack = () => {
    if (activeStep === 0) {
      history.push(EIS);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleNext = async () => {
    if (activeStep === 0) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else if (activeStep === 1) {
      const { isError } = validate();
      if (!isError) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else {
      const payload = {
        ...data,
        dateOfShareIssue: data.dateOfShareIssue
          ? format(data.dateOfShareIssue, 'dd/MM/yyyy')
          : null,
        terminationDateOfShare: data.terminationDateOfShare
          ? format(data.terminationDateOfShare, 'dd/MM/yyyy')
          : null,
        hmrcAuthorisationDate: data.hmrcAuthorisationDate
          ? format(data.hmrcAuthorisationDate, 'dd/MM/yyyy')
          : null,
        signatureDate: data.signatureDate
          ? format(data.signatureDate, 'dd/MM/yyyy')
          : null,
      };

      if (id) {
        //@ts-expect-error
        updateEisWizard.mutate(payload);
      } else {
        //@ts-expect-error
        createEisWizard.mutate(payload);
      }
    }
  };

  const handleCreateWithoutCertificates = async (investmentType: string) => {
    setData({ ...data, investmentType });
    setSummaryData({
      ...summaryData,
      investmentType,
      createdWithoutCertificates: true,
    });
    createEisWizard.mutate({
      shareAllocationUploadId: data.shareAllocationUploadId,
      companyId: data.companyId,
      investmentType,
      createdWithoutCertificates: true,
    });
  };

  useEffect(() => {
    if (isEditPage && id && eisWizard.data) {
      const item = eisWizard.data?.data;
      setData({
        //@ts-expect-error
        companyId: item.companyId,
        //@ts-expect-error
        shareAllocationUploadId: item.shareAllocationUploadId,
        descriptionShare: item.descriptionShare,
        uir: item.uir,
        knowledgeIntensive: item.knowledgeIntensive,
        signatureType: item.signatureType?.toString(),
        postcode: item.postcode,
        address1: item.address1,
        address2: item.address2,
        city: item.city,
        country: item.country,
        capicityInWhichSigned: item.capicityInWhichSigned,
        nominalValueOfEachShare: item.nominalValueOfEachShare,
        //@ts-expect-error
        dateOfShareIssue: new Date(item.dateOfShareIssue),
        //@ts-expect-error
        terminationDateOfShare: new Date(item.terminationDateOfShare),
        //@ts-expect-error
        hmrcAuthorisationDate: item.hmrcAuthorisationDate
          ? new Date(item.hmrcAuthorisationDate)
          : null,
        //@ts-expect-error
        signatureDate: new Date(item.signatureDate),
        nameOfCompanyRepresentative: item.nameOfCompanyRepresentative,
        investmentType: item.investmentType,
        createdWithoutCertificates: item.createdWithoutCertificates,
      });
    }
  }, [isEditPage, id, eisWizard.data]);

  const investmentTypeLabel = `${data.investmentType} certificates`;

  const handleDownload = async () => {
    if (!summaryData.eiswizardId) {
      return error('Cannot download - the certificates have not been made.');
    }
    downloadCertificates.download({
      eisWizardId: summaryData.eiswizardId,
      allocationName: summaryData.allocationName,
      companyName: summaryData.companyName,
      investmentTypeLabel,
      splitFolders: true,
    });
  };

  const heading = isEditPage
    ? 'S/EIS Certificates: Update certificates'
    : 'S/EIS Certificates: Create certificates';

  if (activeStep === steps.length - 1) {
    const createdWithoutCertificates = summaryData.createdWithoutCertificates;
    return (
      <FullPageSuccess
        title={
          createdWithoutCertificates
            ? 'Congratulations, your process completed successfully'
            : `Congratulations, you have created new ${investmentTypeLabel}`
        }
        additionalText={
          createdWithoutCertificates
            ? `This allocation has been successfully marked as an ${data.investmentType} deal.`
            : `Your investors' ${investmentTypeLabel} have been uploaded to their investor portals.`
        }
        proceedLink={EIS}
        proceedText="Back to S/EIS Tasklist"
        secondaryCallback={
          createdWithoutCertificates ? undefined : handleDownload
        }
        secondaryText={
          createdWithoutCertificates
            ? undefined
            : downloadCertificates.isLoading
            ? 'Downloading...'
            : 'Download these certificates'
        }
      />
    );
  }

  return (
    <PageContainer heading={heading} breadcrumbs={breadcrumbs}>
      <PageContent>
        <RecordView>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => {
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>

          {showEnqueuedJobProgress ? (
            <EnqueuedJobProgress
              totalJobs={summaryData.jobsAddedToQueue}
              onComplete={() => {
                setActiveStep(steps.length - 1);
              }}
            />
          ) : (
            <>
              {activeStep === 0 && (
                <CompanyDetails
                  errorText={errorText}
                  data={data}
                  setData={setData}
                  setSummaryData={setSummaryData}
                />
              )}

              {activeStep === 1 && (
                <EisFormData
                  errorText={errorText}
                  data={data}
                  setData={setData}
                />
              )}

              {activeStep === 2 && (
                <Summary data={data} summaryData={summaryData} />
              )}

              {activeStep !== 3 && (
                <FormActionButtons
                  activeStep={activeStep}
                  handleNext={handleNext}
                  handleBack={handleBack}
                  handleCreateWithoutCertificates={
                    handleCreateWithoutCertificates
                  }
                  data={data}
                  //@ts-expect-error
                  setData={setData}
                  isEditPage={!!isEditPage}
                  isFinalStep={activeStep === steps.length - 2}
                />
              )}
            </>
          )}
        </RecordView>
      </PageContent>
    </PageContainer>
  );
};

export default AddUpdateEisWizard;

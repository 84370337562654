import { Button } from '@mui/material';
import TextField from 'components/FormElements/AppTextInput';
import { makeStyles } from 'tss-react/mui';
import FieldRow from 'components/FormElements/FieldRow';
import { NumericFormat } from 'react-number-format';
import AppTextInput from 'components/FormElements/AppTextInput';
import RecordView from 'components/RecordView';
import ButtonGroup from 'components/ButtonGroup';
import { Switch } from '@mui/material';
import Spacing from 'components/Spacing';

const useStyles = makeStyles()(() => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  marginLeftBtn: {
    marginLeft: 10,
  },
}));

export default function TrancheDataSnapshot({
  errorTextKeyData,
  handleCancel,
  handleClickKeyData,
  id,
  keyData,
  setKeyData,
  disabled = false,
  tagName = '',
}) {
  const { classes } = useStyles();

  return (
    <RecordView>
      <FieldRow title="Minimum direct subscription*" centerTitle>
        <NumericFormat
          required
          prefix="£"
          decimalScale={0}
          customInput={AppTextInput}
          name="minimumDirectInvestment"
          placeholder="e.g. £5,000"
          allowNegative={false}
          allowLeadingZeros={false}
          thousandSeparator={true}
          onValueChange={({ value }) =>
            setKeyData({
              ...keyData,
              minimumDirectInvestment: value,
            })
          }
          value={keyData.minimumDirectInvestment}
          variant="outlined"
          error={errorTextKeyData.minimumDirectInvestment}
          helperText={errorTextKeyData.minimumDirectInvestment}
        />
      </FieldRow>
      <FieldRow title="Minimum advised subscription*" centerTitle>
        <NumericFormat
          required
          prefix="£"
          decimalScale={0}
          customInput={AppTextInput}
          name="minimumAdvisedInvestment"
          placeholder="e.g. £5,000"
          allowNegative={false}
          allowLeadingZeros={false}
          thousandSeparator={true}
          onValueChange={({ value }) =>
            setKeyData({
              ...keyData,
              minimumAdvisedInvestment: value,
            })
          }
          value={keyData.minimumAdvisedInvestment}
          variant="outlined"
          error={errorTextKeyData.minimumAdvisedInvestment}
          helperText={errorTextKeyData.minimumAdvisedInvestment}
        />
      </FieldRow>

      <FieldRow title="Minimum excludes or includes advice fee" centerTitle>
        <Spacing direction="row" size="sm">
          <span>Exclude</span>
          <Switch
            checked={keyData.minimumIncludesAdviceFee}
            onChange={(event) =>
              setKeyData({
                ...keyData,
                minimumIncludesAdviceFee: event.target.checked,
              })
            }
            color="primary"
          />
          <span>Include</span>
        </Spacing>
      </FieldRow>

      <FieldRow title={`Income tax relief* (EIS, SEIS, VCT only)`} centerTitle>
        <TextField
          required={tagName === 'LP' ? false : true}
          name="incomeTaxRebate"
          placeholder="Enter the maximum income tax relief available to investors. e.g. 30%"
          fullWidth
          onChange={(event) =>
            setKeyData({
              ...keyData,
              incomeTaxRebate: event.target.value,
            })
          }
          value={keyData.incomeTaxRebate}
          variant="outlined"
          error={errorTextKeyData.incomeTaxRebate}
          helperText={errorTextKeyData.incomeTaxRebate}
        />
      </FieldRow>
      <FieldRow title={`Target return* (EIS, SEIS, LP only)`} centerTitle>
        <TextField
          required={tagName !== 'VCT' ? true : false}
          name="targetReturn"
          placeholder="e.g. 5.5x"
          fullWidth
          onChange={(event) =>
            setKeyData({
              ...keyData,
              targetReturn: event.target.value,
            })
          }
          value={keyData.targetReturn}
          variant="outlined"
          error={errorTextKeyData.targetReturn}
          helperText={errorTextKeyData.targetReturn}
        />
      </FieldRow>

      <FieldRow
        title={`Target portfolio size* (EIS, SEIS, LP only)`}
        centerTitle
      >
        <TextField
          required={tagName !== 'VCT' ? true : false}
          name="targetPortfolioSize"
          placeholder="e.g. 8-10 companies"
          fullWidth
          onChange={(event) =>
            setKeyData({
              ...keyData,
              targetPortfolioSize: event.target.value,
            })
          }
          value={keyData.targetPortfolioSize}
          error={errorTextKeyData.targetPortfolioSize}
          helperText={errorTextKeyData.targetPortfolioSize}
          variant="outlined"
        />
      </FieldRow>

      <ButtonGroup>
        <Button variant="outlined" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          className={classes.marginLeftBtn}
          variant="contained"
          color="primary"
          disabled={disabled}
          onClick={handleClickKeyData}
        >
          {id ? 'Update' : 'Save'}
        </Button>
      </ButtonGroup>
    </RecordView>
  );
}

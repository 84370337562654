import { styled } from '@mui/material';
import Button from 'components/Button';

type Props = {
  handleOpenFund: () => void;
  hasPrerequisitesForPublishing: boolean;
};

const BannerContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'hasPrerequisitesForPublishing',
})<{
  hasPrerequisitesForPublishing: boolean;
}>(({ theme, hasPrerequisitesForPublishing }) => ({
  width: 'calc(100% + 48px)',
  height: '56px',
  top: 0,
  left: 0,
  color: theme.palette.text.white,
  backgroundColor: hasPrerequisitesForPublishing
    ? theme.palette.background.rootColor
    : theme.palette.background.info,
  padding: '6px',
  fontWeight: 700,
  textAlign: 'center',
  marginBottom: '25px',
  display: 'flex',
  gap: '15px',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: '-24px',
  marginRight: '-24px',
  marginTop: '-24px',
}));

const StyledButton = styled(Button)({
  fontSize: '14px',
  fontWeight: 700,
  padding: '6px 12px',
});

const StyledDiv = styled('div')({
  padding: '7px 0',
});

const Banner: React.FC<Props> = ({
  handleOpenFund,
  hasPrerequisitesForPublishing,
}) => {
  return (
    <BannerContainer
      hasPrerequisitesForPublishing={hasPrerequisitesForPublishing}
    >
      <StyledDiv>
        {hasPrerequisitesForPublishing
          ? 'This tranche is currently in draft'
          : 'To open this tranche, please first complete all required sections below.'}
      </StyledDiv>
      {hasPrerequisitesForPublishing && (
        <StyledButton
          variant="outlined"
          color="inherit"
          onClick={handleOpenFund}
        >
          Open tranche
        </StyledButton>
      )}
    </BannerContainer>
  );
};

export default Banner;

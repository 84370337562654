import React from 'react';
import InteractiveTable, { Column } from 'components/InteractiveTable';
import { dateToLabel, numberToCurrencyString } from 'further-ui/utils';
import { Transaction } from './types';
import { CurrencyCode } from 'further-types/lp/vehicle';

type Props = {
  parentTransactionId: string;
  subtransactions: Transaction['subtransactions'];
  baseCurrency: CurrencyCode;
};

const getColumns = (baseCurrency: CurrencyCode): Array<Column> => [
  {
    field: 'transactionDate',
    headerName: 'Date',
    renderCell: (cell) => {
      return dateToLabel(cell.value);
    },
    flex: 1,
  },
  {
    field: 'transactionType',
    headerName: 'Transaction type',
    flex: 1,
  },
  {
    field: 'description',
    headerName: 'Description',
    flex: 1,
  },
  {
    field: 'amountIncVatLcy',
    headerName: 'Amount (LCY)',
    flex: 1,
    renderCell: (cell) => {
      return numberToCurrencyString(cell.value, {
        currency: baseCurrency,
      });
    },
  },
  {
    field: 'amountIncVatTcy',
    headerName: 'Amount (TCY)',
    flex: 1,
    renderCell: (cell) => {
      return numberToCurrencyString(cell.value, {
        currency: cell.row.transactionCurrency,
      });
    },
  },
  {
    field: 'counterpartyLabel',
    headerName: 'Counterparty',
    flex: 1,
  },
  {
    field: 'bankAccountLabel',
    headerName: 'Bank account',
    flex: 1,
  },
];

const Subtransactions: React.FC<Props> = ({
  subtransactions,
  parentTransactionId,
  baseCurrency,
}) => {
  return (
    <InteractiveTable
      id={`general-ledger-subtransactions-${parentTransactionId}`}
      disableTableConfiguration
      disableSorting
      columns={getColumns(baseCurrency)}
      rows={subtransactions ?? []}
      disablePagination
      fullHeight
    />
  );
};

export default Subtransactions;

import { AllocationRule } from 'further-types/lp/allocations';
import { isMongoId } from '../../helpers';
import { z } from 'zod';

export const GenerateAllocations = z.object({
  vehicleId: z.string().refine(isMongoId()),
  allocationRule: z.nativeEnum(AllocationRule),
  amount: z.number(),
  excludedCommitmentIds: z.array(z.string()),
  currentAction: z
    .object({
      incomeOrExpenseId: z.string().optional(),
    })
    .optional(),
  linkedActionId: z
    .string()
    .optional()
    .nullable()
    .refine(isMongoId({ optional: true })),
});

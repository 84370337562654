import { Autocomplete, Box } from '@mui/material';
import { AppTextInput } from 'components/FormElements';
import { forwardRef } from 'react';
import { countries } from 'further-ui/labels';

type Props = {
  error?: {
    message?: string;
  };
  placeholder?: string;
  value: string;
  onChange: (
    value: string,
    option: { label: string; value: string; code: string } | null,
  ) => void;
  name?: string;
  disabled?: boolean;
  mode?: 'name' | 'code';
};

const CountrySelector = forwardRef<HTMLDivElement, Props>(
  (
    { name, disabled, error, placeholder, value, onChange, mode = 'name' },
    ref,
  ) => {
    const countryOptions = countries.map((country) => ({
      label: country.name,
      value: mode === 'name' ? country.name : country.code,
      code: country.code,
    }));

    return (
      <Autocomplete
        disabled={disabled}
        ref={ref}
        options={countryOptions}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <AppTextInput
            {...params}
            name={name}
            error={!!error}
            helperText={error?.message?.toString()}
            type="text"
            placeholder={placeholder}
          />
        )}
        value={countryOptions.find((country) => country.value === value)}
        onChange={(_, newValue) => {
          onChange(newValue?.value ?? '', newValue ?? null);
        }}
        renderOption={(props, option) => {
          const { key, ...optionProps } = props;
          return (
            <Box
              key={key}
              component="li"
              sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
              {...optionProps}
            >
              <img
                loading="lazy"
                width="20"
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                alt={option.label}
              />
              {option.label}
            </Box>
          );
        }}
      />
    );
  },
);

export default CountrySelector;

import { FC } from 'react';
import { Divider, styled } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React from 'react';
import { useDisclosure } from 'further-ui/hooks';
import DataLossWarningModal from '../DataLossWarningModal';
import { HoldingClassRightsForm } from './HoldingClassRightsForm';
import HoldingClassSelection from './HoldingClassSelection';
import { CurrencyCode } from 'further-types/lp/vehicle';

const Link = styled('a')<{ color?: 'primary' | 'error' }>(
  ({ theme, color = 'primary' }) => ({
    color:
      color === 'primary'
        ? theme.palette.primary.main
        : theme.palette.error.main,
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
    fontWeight: 700,
  }),
);

interface HoldingClassDetailsFormProps {
  index: number;
  field: Record<string, any>;
  holdingClassOptions: Array<{
    id: string;
    title: string;
    currency: CurrencyCode;
  }>;
  onAdd: () => void;
  onRemove: () => void;
  isLast: boolean;
  isFirst: boolean;
  resetHoldingClassDropdown: (index: number) => void;
  isNewHoldingClass: boolean;
  isHoldingClassSelectDisabled: boolean;
}

const RemoveHoldingClassLink: FC<{
  isFirst: boolean;
  isNewHoldingClass: boolean;
  onRemove: () => void;
  onOpenModal: () => void;
}> = ({ isFirst, isNewHoldingClass, onRemove, onOpenModal }) => {
  if (isFirst) return null;

  return (
    <Link
      target="_blank"
      onClick={() => {
        if (isNewHoldingClass) {
          onOpenModal();
        } else {
          onRemove();
        }
      }}
      color="error"
    >
      Remove additional holding class
    </Link>
  );
};

const HoldingClassDetailsForm: FC<HoldingClassDetailsFormProps> = ({
  index,
  field,
  holdingClassOptions,
  onAdd,
  onRemove,
  isLast,
  isFirst,
  resetHoldingClassDropdown,
  isNewHoldingClass,
  isHoldingClassSelectDisabled,
}) => {
  const removeDisclosure = useDisclosure();

  return (
    <React.Fragment key={field.id}>
      {index > 0 && <Divider />}
      <HoldingClassSelection
        index={index}
        holdingClassOptions={holdingClassOptions}
        resetHoldingClassDropdown={() => resetHoldingClassDropdown(index)}
        isHoldingClassSelectDisabled={isHoldingClassSelectDisabled}
      />

      {isNewHoldingClass && <HoldingClassRightsForm index={index} />}

      <Grid container>
        <Grid size={{ xs: 6 }} display="flex" justifyContent="flex-start">
          {isLast && (
            <Link target="_blank" onClick={onAdd} color="primary">
              Add additional holding classes to deal
            </Link>
          )}
        </Grid>
        <Grid size={{ xs: 6 }} display="flex" justifyContent="flex-end">
          <RemoveHoldingClassLink
            isFirst={isFirst}
            isNewHoldingClass={isNewHoldingClass}
            onRemove={onRemove}
            onOpenModal={removeDisclosure.onOpen}
          />
        </Grid>
      </Grid>

      <DataLossWarningModal
        message="If you remove this holding class, all class information you have input will be lost. Do you wish to continue?"
        disclosure={removeDisclosure}
        onConfirm={onRemove}
        onCancel={removeDisclosure.onClose}
      />
    </React.Fragment>
  );
};

export default HoldingClassDetailsForm;

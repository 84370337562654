import { AllocationRule } from 'further-types/lp/allocations';
import { FundingSource, InvestmentTypes } from 'further-types/lp/investment';

export const AllocationRuleLabels = {
  [AllocationRule.ByCommitmentAndClosingDate]: 'By commitment and closing date',
  [AllocationRule.Manual]: 'Manual',
  [AllocationRule.LinkedAction]: 'Linked action',
};

export const FundingSourceLabels = {
  [FundingSource.CAPITAL_CALL]: 'Capital call',
  [FundingSource.GENERAL_CAPITAL_ON_ACCOUNTS]: 'General capital on accounts',
};

export const InvestmentTypeLabels = {
  [InvestmentTypes.InvestmentCreated]: 'Investment (created)',
  [InvestmentTypes.InvestmentPortfolioCompany]:
    'Investment (portfolio company)',
  [InvestmentTypes.InvestmentRealisedCurrencyGain]:
    'Investment - Realised currency gain due to difference between rate at action and payment',
  [InvestmentTypes.InvestmentRealisedCurrencyLoss]:
    'Investment - Realised currency loss due to difference between rate at action and payment',
};

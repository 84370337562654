import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import useGetInvestorsLeanResponse from 'hooks/data/investor/useGetInvestorsLeanResponse';
import { useGetRole } from 'hooks/ui/useGetRole';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type MultiInvestorSelectorProps = {
  onChange: (value: string[]) => void;
  error?: boolean;
  helperText?: string;
  value: string[];
};

const MultiInvestorSelector = ({
  onChange,
  error,
  helperText,
  value,
}: MultiInvestorSelectorProps) => {
  const { isSuperAdmin, firmId } = useGetRole();
  const { data: investors, isFetching } = useGetInvestorsLeanResponse({
    ...(isSuperAdmin ? {} : { firmId }),
    excludeDisabledEmail: true,
  });

  const options = investors?.map((investor) => ({
    label: `${investor.fullName} (${investor.email})`,
    value: investor._id,
  }));

  return (
    <Autocomplete
      popupIcon={<ExpandMoreIcon color="primary" />}
      multiple
      disabled={isFetching}
      value={value.map((investorId) =>
        options?.find((investor) => investor.value === investorId),
      )}
      options={options ?? []}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={isFetching ? 'Loading...' : 'Select investors'}
          disabled={isFetching}
          error={error}
          helperText={helperText}
        />
      )}
      onChange={(_event, value) => {
        onChange(value.map((investor) => investor?.value ?? ''));
      }}
    />
  );
};

export default MultiInvestorSelector;

import { useState } from 'react';
import { IconButton, Button } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Delete } from '@mui/icons-material';
import GridContainer from 'components/GridContainer';
import { deleteDividend } from '@redux/actions/Company';
import AppDatePicker from 'components/FormElements/AppDatePicker';
import { useDispatch } from 'react-redux';
import FieldRow from 'components/FormElements/FieldRow';
import { NumericFormat } from 'react-number-format';
import AppTextInput from 'components/FormElements/AppTextInput';
import { useNotification } from 'hooks/ui/useNotification';
import RecordView from 'components/RecordView';
import AlertDialog from 'components/AlertDialog';

const DividendRows = ({
  dividends,
  setDividends,
  errorTextDividends,
  classes,
}) => {
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState(false);
  const [recordStagedForDeletion, setRecordStagedForDeletion] = useState();
  const dispatch = useDispatch();
  const { error, success } = useNotification();

  const handleInputDividendChange = (e, index, isDate) => {
    if (isDate) {
      const list = [...dividends];
      list[index]['date'] = e;
      setDividends(list);
    } else {
      const { name, value } = e;
      const list = [...dividends];
      list[index][name] = value;
      setDividends(list);
    }
  };

  const handleAddDividend = () => {
    setDividends([
      ...dividends,
      {
        amount: '',
        date: undefined,
      },
    ]);
  };

  const handleDelete = (rec, i) => {
    if (rec._id) {
      setRecordStagedForDeletion(rec._id);
      setIsConfirmDeleteDialogOpen(!isConfirmDeleteDialogOpen);
    } else {
      // support the ability to remove dividends from the list that haven't been saved yet
      dividends.splice(i, 1);
      setDividends([...dividends]);
    }
  };

  const handleConfirmDelete = () => {
    if (recordStagedForDeletion) {
      dispatch<any>(deleteDividend(recordStagedForDeletion))
        .then((response) => {
          if (response.status === 200) {
            success(response.data.responseMsg);
            const list = dividends.filter(
              (dividend) => dividend._id !== recordStagedForDeletion,
            );
            setDividends(list);
          }
        })
        .catch((err) => error(err.response.data.responseMsg));
    }
    setIsConfirmDeleteDialogOpen(false);
  };

  return (
    <RecordView size="full">
      {dividends &&
        dividends.map((dividend, i) => {
          return (
            <div key={i} className={classes.rowWrapper}>
              <FieldRow title={`Dividend per share ${i + 1}`} centerTitle>
                <GridContainer alignItems="center">
                  <Grid size={{ md: 5, xs: 12 }}>
                    <NumericFormat
                      decimalScale={2}
                      onValueChange={({ value }) => {
                        handleInputDividendChange(
                          {
                            name: 'amount',
                            value,
                          },
                          i,
                          false,
                        );
                      }}
                      required
                      name="amount"
                      placeholder="Enter amount"
                      disabled={dividend._id ? true : false}
                      value={dividend.amount}
                      allowNegative={false}
                      allowLeadingZeros={false}
                      thousandSeparator={true}
                      prefix="£"
                      customInput={AppTextInput}
                      error={errorTextDividends[i]?.amount}
                      helperText={errorTextDividends[i]?.amount}
                    />
                  </Grid>
                  <Grid size={{ md: 5, xs: 12 }}>
                    <AppDatePicker
                      required
                      name="date"
                      value={
                        dividend?.date ? new Date(dividend?.date) : undefined
                      }
                      onChange={(e) => handleInputDividendChange(e, i, true)}
                      error={errorTextDividends[i]?.date}
                      helperText={errorTextDividends[i]?.date}
                      maxDate={new Date()}
                      disabled={dividend._id ? true : false}
                    />
                  </Grid>
                  <Grid size={{ md: 2, xs: 12 }}>
                    {dividends.length && (
                      <IconButton
                        size="small"
                        className={classes.deleteButton}
                        onClick={() => handleDelete(dividend, i)}
                      >
                        <Delete />
                      </IconButton>
                    )}
                  </Grid>
                </GridContainer>
              </FieldRow>
            </div>
          );
        })}
      <Grid size={{ xs: 12, lg: 12 }} className={classes.addRevalutionBtn}>
        <Button
          className={classes.marginLeftBtn}
          onClick={handleAddDividend}
          variant="text"
          color="primary"
        >
          Add dividend
        </Button>
      </Grid>
      <AlertDialog
        open={isConfirmDeleteDialogOpen}
        title={`Confirm delete`}
        content={'Are you sure you wish to delete this dividend?'}
        onClose={() => setIsConfirmDeleteDialogOpen(false)}
        onConfirm={handleConfirmDelete}
      />
    </RecordView>
  );
};

export default DividendRows;

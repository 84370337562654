import React from 'react';
import PersonalDetails from './PersonalDetails';
import { AccountType } from 'further-types/investor';
import EntityDetails from './EntityDetails';

type Props = {
  id?: string;
  isSuperAdmin: boolean;
  accountType: AccountType;
};

const InvestorDetails: React.FC<Props> = ({
  id,
  isSuperAdmin,
  accountType,
}) => {
  return (
    <>
      {accountType === AccountType.LegalEntity ? (
        <EntityDetails isSuperAdmin={isSuperAdmin} id={id} />
      ) : (
        <PersonalDetails id={id} isSuperAdmin={isSuperAdmin} />
      )}
    </>
  );
};
export default InvestorDetails;

import { FC } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { useGetRole } from 'hooks/ui/useGetRole';
import RestrictedRoute from './RestrictedRoute';
import CommonRoutes from './CommonRoutes';

import OpenTrancheDashboard from '../Pages/OpenTrancheDashboard';
import FirmDashboard from '../Pages/FirmDashboard';
import ClosedTranchePage from '../Pages/AllTranches';
import ClosedTrancheDashboard from '../Pages/ClosedTrancheDashboard';
import ClosedTrancheAddUpdatePage from '../Pages/Tranche/TrancheForm';
import InvestorPage from '../Pages/Investor';
import InvestorDetailPage from '../Pages/Investor/ViewInvestor';
import InvestorForm from '../Pages/Investor/InvestorForm';
import InvestmentPage from '../Pages/Investment';
import UploadInvestorInvestment from '../Pages/UploadInvestorInvestment';
import PartiallyInvestmentPage from '../Pages/Investment/IncompleteInvestment';
import UpdateInvestmentPage from '../Pages/Investment/EditInvestment';
import UserPage from '../Pages/User';
import AddUserPage from '../Pages/User/UserForm';
import InvestmentFirmPage from '../Pages/InvestmentFirm';
import TagPage from '../Pages/Tag';
import AddTagPage from '../Pages/Tag/TagForm';
import TranchePage from '../Pages/Tranche';
import EisWizard from '../Pages/EisWizard/ListCertificates';
import CompanyPage from '../Pages/Company';
import AddUpdateCompanyPage from '../Pages/Company/CompanyForm';
import AddUpdateEisWizardPage from '../Pages/EisWizard/EisWizardForm';
import InterestPaymentListPage from '../Pages/InterestPayment';
import InterestPaymentCreatePage from '../Pages/InterestPayment/InterestPaymentCreate';
import UploadSheetInvestmentPage from '../Pages/UploadSheetInvestment';
import AddUploadSheet from '../Pages/UploadSheetInvestment/AddUpdateUploadSheet';
import ViewUploadSheet from '../Pages/UploadSheetInvestment/ViewUploadSheet';
import AdviserList from '../Pages/Adviser/AdviserList';
import AddUpdateAdviser from '../Pages/Adviser/AdviserForm';
import ViewAdviser from '../Pages/Adviser/ViewAdviser';
import Organisation from '../Pages/Organisation/ListOrganisations';
import AddUpdateOrganisation from '../Pages/Organisation/AddUpdateOrganisation';
import ViewOrganisation from '../Pages/Organisation/ViewOrganisation';
import AddOrganisationUser from '../Pages/Organisation/AddOrganisationUser';
import InvestorReporting from '../Pages/InvestorReporting/View';
import AddUpdateInvestorReporting from '../Pages/InvestorReporting/InvestorReportingWizard';
import InvestorReportingSuccess from '../Pages/InvestorReporting/InvestorReportingWizard/Success';
import InvestorReportingPdfDownload from '../Pages/InvestorReporting/InvestorReportingPdfDownload';
import FeeSummary from '../Pages/FeeSummary';
import AddFeeAndDiscount from '../Pages/FeeManagement/AddFeeAndDiscount';
import OneOffFeesAndDiscounts from '../Pages/FeeManagement/OneOffFeesAndDiscounts';
import FeeAndDiscountSuccess from '../Pages/FeeManagement/AddFeeAndDiscount/Success';
import ReviewWithdrawals from '../Pages/Withdrawals/ReviewWithdrawals';
import RequestWithdrawals from '../Pages/Withdrawals/RequestWithdrawals';
import CreateAndEditExit from '../Pages/Exits/ExitForm';
import ListExits from '../Pages/Exits/ListExits';
import InvestmentFirmAddEditScreen from '../Pages/InvestmentFirmAddEdit';
import AddShareSplit from '../Pages/ShareSplit/AddShareSplit';
import CustomUpdate from '../Pages/CustomUpdate/Add';
import ViewCustomUpdate from '../Pages/CustomUpdate/View';
import DeploymentUpdate from '../Pages/DeploymentUpdate/Add';
import ViewDeploymentUpdate from '../Pages/DeploymentUpdate/View';
import ViewExitStatement from '../Pages/Exits/ViewStatement';
import AddInvestmentTransfer from '../Pages/InvestmentTransfer/AddInvestmentTransfer';
import InvestmentTransferList from '../Pages/InvestmentTransfer/InvestmentTransferList';
import { TrancheLink } from '../Pages/Tranche/TrancheLink';
import InvestorBulletin from '../Pages/InvestorBulletin';
import AddInvestment from '../Pages/Investment/AddInvestment';
import KiFundCertificateBatchForm from '../Pages/KiFundCertificate/CertificateBatchForm';
import TaxReportAudit from '../Pages/TaxReportAudit';
import ChangeLog from '../Pages/ChangeLog';
import ExitStatementPdfDownload from '../Pages/InvestorReporting/ExitStatementPdfDownload';
import BulkPaymentAssignment from '../Pages/BulkPaymentAssignment';
import CreateExitStatement from '../Pages/Exits/CreateExitStatement';
import UpdateExitStatement from '../Pages/Exits/UpdateExitStatement';
import CashTransactionsReport from '../Pages/SystemExports/CashTransactions';
import InvestmentHoldingsReport from '../Pages/SystemExports/InvestmentHoldings';
import InvestmentTransactionsReport from '../Pages/SystemExports/InvestmentTransactions';
import LpFirmAddEditScreen from '../LpPages/LpFirm';

import {
  ADVISER,
  ADVISER_ADD,
  ADVISER_EDIT,
  ADVISER_VIEW,
  ALL_FUNDS,
  BULK_PAYMENT_ASSIGNMENT,
  COMPANY,
  COMPANY_ADD,
  COMPANY_EDIT,
  DASHBOARD,
  DASHBOARD_ALL_FIRMS,
  DASHBOARD_CLOSED_TRANCHE,
  DASHBOARD_MY_FIRM,
  DASHBOARD_OPEN_TRANCHE,
  DASHBOARD_SINGLE_FIRM,
  EIS,
  EIS_ADD,
  KI_EIS_ADD,
  EIS_EDIT,
  EXITS,
  EXITS_ADD,
  EXIT_STATEMENT_PDF_DOWNLOAD,
  FEE_DISCOUNT_ADD,
  FEE_DISCOUNT_SUCCESS,
  FEE_SUMMARY,
  FIRM,
  FIRM_ADD,
  FIRM_EDIT,
  INCOMPLETE_TRANSACTION,
  INTEREST_PAYMENTS,
  INTEREST_PAYMENTS_ADD,
  INVESTMENT,
  INVESTMENT_EDIT,
  INVESTOR,
  INVESTOR_VIEW,
  INVESTOR_EDIT,
  INVESTOR_REPORTING,
  INVESTOR_REPORTING_ADD,
  INVESTOR_REPORTING_EDIT,
  INVESTOR_REPORTING_SUCCESS,
  INVESTOR_REPORTING_VIEW,
  INVESTOR_REPORTING_PDF_DOWNLOAD,
  INVESTOR_UPLOAD,
  ORGANISATION,
  ORGANISATION_ADD,
  ORGANISATION_EDIT,
  ORGANISATION_VIEW,
  ORGANISATION_ADD_USER,
  TAG,
  TAG_ADD,
  TAG_EDIT,
  TRANCHE,
  TRANCHE_ADD,
  TRANCHE_EDIT,
  UPLOAD_SHEET,
  UPLOAD_SHEET_ADD,
  UPLOAD_SHEET_VIEW,
  USER,
  USER_ADD,
  USER_EDIT,
  WITHDRAWALS,
  WITHDRAWALS_ADD,
  SHARE_SPLIT_ADD,
  ADD_CUSTOM_UPDATE,
  VIEW_CUSTOM_UPDATE,
  ADD_DEPLOYMENT_UPDATE,
  ADD_EXIT_STATEMENT,
  UPDATE_EXIT_STATEMENT,
  VIEW_DEPLOYMENT_UPDATE,
  VIEW_EXIT_STATEMENT,
  EXITS_EDIT,
  INVESTOR_ADD,
  ADD_INVESTMENT_TRANSFER,
  INVESTMENT_TRANSFERS,
  TRANCHE_BESPOKE_LINK,
  INVESTOR_BULLETIN,
  INVESTMENT_ADD,
  KI_EIS_EDIT,
  TAX_REPORT_AUDIT,
  CHANGE_LOG,
  ONE_OFF_FEES_AND_DISCOUNTS,
  CASH_TRANSACTIONS_REPORT,
  INVESTMENT_TRANSACTIONS_REPORT,
  INVESTMENT_HOLDINGS_REPORT,
  LP_FIRM_ADD,
  LP_FIRM_EDIT,
  COMPANY_DUPLICATE,
} from 'adminConstants/routes';

const DashboardRedirectRoute = () => {
  const { isSuperAdmin } = useGetRole();

  if (isSuperAdmin) {
    return <Redirect to={DASHBOARD_ALL_FIRMS} />;
  }
  return <Redirect to={DASHBOARD_MY_FIRM} />;
};

const EisRoutes: FC = () => (
  <Switch>
    <RestrictedRoute path="/" exact component={DashboardRedirectRoute} />
    <RestrictedRoute
      component={DashboardRedirectRoute}
      exact
      path={DASHBOARD}
    />
    <RestrictedRoute
      component={OpenTrancheDashboard}
      path={DASHBOARD_OPEN_TRANCHE}
    />
    <RestrictedRoute
      component={ClosedTrancheDashboard}
      path={DASHBOARD_CLOSED_TRANCHE}
    />
    <RestrictedRoute component={FirmDashboard} path={DASHBOARD_SINGLE_FIRM} />
    <RestrictedRoute component={FirmDashboard} path={DASHBOARD_MY_FIRM} />
    <RestrictedRoute component={FirmDashboard} path={DASHBOARD_ALL_FIRMS} />

    <RestrictedRoute component={ClosedTranchePage} path={ALL_FUNDS} />
    <RestrictedRoute component={TranchePage} path={TRANCHE} />
    <RestrictedRoute
      component={ClosedTrancheAddUpdatePage}
      path={TRANCHE_ADD}
    />
    <RestrictedRoute component={TrancheLink} path={TRANCHE_BESPOKE_LINK} />
    <RestrictedRoute
      component={ClosedTrancheAddUpdatePage}
      path={TRANCHE_EDIT}
    />
    <RestrictedRoute component={InvestorPage} path={INVESTOR} />
    <RestrictedRoute component={InvestorDetailPage} path={INVESTOR_VIEW} />
    <RestrictedRoute component={InvestorForm} path={INVESTOR_EDIT} />
    <RestrictedRoute component={InvestorForm} path={INVESTOR_ADD} />
    <RestrictedRoute
      component={UploadInvestorInvestment}
      path={INVESTOR_UPLOAD}
    />
    <RestrictedRoute component={InvestmentPage} path={INVESTMENT} />
    <RestrictedRoute component={AddInvestment} path={INVESTMENT_ADD} />
    <RestrictedRoute
      component={PartiallyInvestmentPage}
      path={INCOMPLETE_TRANSACTION}
    />
    <RestrictedRoute component={UpdateInvestmentPage} path={INVESTMENT_EDIT} />
    <RestrictedRoute
      component={InterestPaymentListPage}
      exact
      path={INTEREST_PAYMENTS}
    />
    <RestrictedRoute
      component={InterestPaymentCreatePage}
      exact
      path={INTEREST_PAYMENTS_ADD}
    />
    <RestrictedRoute
      component={UploadSheetInvestmentPage}
      path={UPLOAD_SHEET}
    />
    <RestrictedRoute
      component={BulkPaymentAssignment}
      path={BULK_PAYMENT_ASSIGNMENT}
    />
    <RestrictedRoute component={AddUploadSheet} path={UPLOAD_SHEET_ADD} />
    <RestrictedRoute component={ViewUploadSheet} path={UPLOAD_SHEET_VIEW} />
    <RestrictedRoute component={UserPage} path={USER} />
    <RestrictedRoute component={AddUserPage} path={USER_ADD} />
    <RestrictedRoute component={AddUserPage} path={USER_EDIT} />
    <RestrictedRoute component={InvestmentFirmPage} path={FIRM} />
    <RestrictedRoute component={InvestmentFirmAddEditScreen} path={FIRM_ADD} />
    <RestrictedRoute component={LpFirmAddEditScreen} path={LP_FIRM_ADD} />
    <RestrictedRoute component={LpFirmAddEditScreen} path={LP_FIRM_EDIT} />
    <RestrictedRoute component={InvestmentFirmAddEditScreen} path={FIRM_EDIT} />
    <RestrictedRoute component={TagPage} path={TAG} />
    <RestrictedRoute component={AddTagPage} path={TAG_ADD} />
    <RestrictedRoute component={AddTagPage} path={TAG_EDIT} />
    <RestrictedRoute component={CompanyPage} path={COMPANY} />
    <RestrictedRoute component={AddUpdateCompanyPage} path={COMPANY_ADD} />
    <RestrictedRoute component={AddUpdateCompanyPage} path={COMPANY_EDIT} />
    <RestrictedRoute
      component={AddUpdateCompanyPage}
      path={COMPANY_DUPLICATE}
    />
    <RestrictedRoute component={EisWizard} path={EIS} />
    <RestrictedRoute component={AddUpdateEisWizardPage} path={EIS_ADD} />
    <RestrictedRoute component={AddUpdateEisWizardPage} path={EIS_EDIT} />
    <RestrictedRoute component={KiFundCertificateBatchForm} path={KI_EIS_ADD} />
    <RestrictedRoute
      component={KiFundCertificateBatchForm}
      path={KI_EIS_EDIT}
    />
    <RestrictedRoute component={AddFeeAndDiscount} path={FEE_DISCOUNT_ADD} />
    <RestrictedRoute
      component={OneOffFeesAndDiscounts}
      path={ONE_OFF_FEES_AND_DISCOUNTS}
    />
    <RestrictedRoute
      component={FeeAndDiscountSuccess}
      path={FEE_DISCOUNT_SUCCESS}
    />
    <RestrictedRoute component={AdviserList} path={ADVISER} />
    <RestrictedRoute component={AddUpdateAdviser} path={ADVISER_ADD} />
    <RestrictedRoute component={ViewAdviser} path={ADVISER_VIEW} />
    <RestrictedRoute component={AddUpdateAdviser} path={ADVISER_EDIT} />
    <RestrictedRoute component={Organisation} path={ORGANISATION} />
    <RestrictedRoute
      component={AddUpdateOrganisation}
      path={ORGANISATION_ADD}
    />
    <RestrictedRoute
      component={AddUpdateOrganisation}
      path={ORGANISATION_EDIT}
    />
    <RestrictedRoute component={ViewOrganisation} path={ORGANISATION_VIEW} />
    <RestrictedRoute
      component={AddOrganisationUser}
      path={ORGANISATION_ADD_USER}
    />
    <RestrictedRoute
      component={InvestorReporting}
      path={INVESTOR_REPORTING}
      exact
    />
    <RestrictedRoute
      component={AddUpdateInvestorReporting}
      path={INVESTOR_REPORTING_EDIT}
    />
    <RestrictedRoute
      component={AddUpdateInvestorReporting}
      path={INVESTOR_REPORTING_ADD}
    />
    <RestrictedRoute
      component={AddUpdateInvestorReporting}
      path={INVESTOR_REPORTING_VIEW}
    />
    <RestrictedRoute
      component={InvestorReportingSuccess}
      path={INVESTOR_REPORTING_SUCCESS}
    />
    <RestrictedRoute
      component={InvestorReportingPdfDownload}
      path={INVESTOR_REPORTING_PDF_DOWNLOAD}
    />
    <RestrictedRoute
      component={ExitStatementPdfDownload}
      path={EXIT_STATEMENT_PDF_DOWNLOAD}
    />
    <RestrictedRoute component={CustomUpdate} path={ADD_CUSTOM_UPDATE} />
    <RestrictedRoute component={ViewCustomUpdate} path={VIEW_CUSTOM_UPDATE} />
    <RestrictedRoute component={InvestorBulletin} path={INVESTOR_BULLETIN} />
    <RestrictedRoute component={TaxReportAudit} path={TAX_REPORT_AUDIT} />
    <RestrictedRoute component={FeeSummary} path={FEE_SUMMARY} />
    <RestrictedRoute component={RequestWithdrawals} path={WITHDRAWALS_ADD} />
    <RestrictedRoute component={ReviewWithdrawals} path={WITHDRAWALS} />
    <RestrictedRoute component={CreateAndEditExit} path={EXITS_ADD} />
    <RestrictedRoute component={CreateAndEditExit} path={EXITS_EDIT} />
    <RestrictedRoute component={ListExits} path={EXITS} />
    <RestrictedRoute component={AddShareSplit} path={SHARE_SPLIT_ADD} />
    <RestrictedRoute
      component={DeploymentUpdate}
      path={ADD_DEPLOYMENT_UPDATE}
    />
    <RestrictedRoute
      component={CreateExitStatement}
      path={ADD_EXIT_STATEMENT}
    />
    <RestrictedRoute
      component={UpdateExitStatement}
      path={UPDATE_EXIT_STATEMENT}
    />
    <RestrictedRoute
      component={ViewDeploymentUpdate}
      path={VIEW_DEPLOYMENT_UPDATE}
    />
    <RestrictedRoute component={ViewExitStatement} path={VIEW_EXIT_STATEMENT} />
    <RestrictedRoute
      component={InvestmentTransferList}
      path={INVESTMENT_TRANSFERS}
    />
    <RestrictedRoute
      component={AddInvestmentTransfer}
      path={ADD_INVESTMENT_TRANSFER}
    />
    <RestrictedRoute path={CHANGE_LOG} component={ChangeLog} />
    <RestrictedRoute
      path={CASH_TRANSACTIONS_REPORT}
      component={CashTransactionsReport}
    />
    <RestrictedRoute
      path={INVESTMENT_HOLDINGS_REPORT}
      component={InvestmentHoldingsReport}
    />
    <RestrictedRoute
      path={INVESTMENT_TRANSACTIONS_REPORT}
      component={InvestmentTransactionsReport}
    />
    {...CommonRoutes}
  </Switch>
);

export default EisRoutes;

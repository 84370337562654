import { format } from 'date-fns';
import { toDate } from 'date-fns-tz';
import { dateToLabel } from './dates';

class CalendarDay {
  public date: string;

  public constructor(calendarDay: string | Date) {
    if (calendarDay instanceof Date) {
      this.date = format(calendarDay, 'yyyy-MM-dd');
    } else {
      this.date = calendarDay;
    }
  }

  public toString() {
    return this.date;
  }

  public toLabel(fallback = 'N/A', format?: string) {
    return dateToLabel(this.date, fallback, false, false, format);
  }

  public endOfDay() {
    const date = toDate(this.date, {
      timeZone: 'UTC',
    });

    return new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        23,
        59,
        59,
        999,
      ),
    );
  }

  public startOfDay() {
    return this.toUTCDate();
  }

  public toUTCDate() {
    const date = toDate(this.date, {
      timeZone: 'UTC',
    });

    return new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0),
    );
  }
}

export default CalendarDay;

import { ChevronRight } from '@mui/icons-material';
import { alpha, Grid2, styled, Typography } from '@mui/material';
import { AppAutocomplete } from 'components/FormElements';
import GridContainer from 'components/GridContainer';
import { Row } from 'components/Layout';
import PageContainer from 'components/PageContainer';
import PageContent from 'components/PageContent';
import PageFilters, { Filter } from 'components/PageFilters';
import SearchField from 'components/SearchField';
import api from 'lib/trpcClient';
import { useState } from 'react';
import NoResults, { NoResultsMode } from './NoResults';
import PageLoader from 'components/PageLoader';
import Spacing from 'components/Spacing';
import { useNotification } from 'hooks/ui/useNotification';
import { PROCESS_BANK_ACCOUNT } from 'adminConstants/routes';
import { countries } from 'further-ui/labels';
import AccountSettings from '../AccountSettings';
import { useLocation } from 'react-router-dom';

const countryOptions = countries.map((country) => ({
  label: country.name,
  value: country.code,
}));

const Image = styled('img')(({ theme }) => ({
  maxWidth: '80px',
  maxHeight: '80px',
  borderRadius: theme.shape.borderRadius,
}));

const Filters = styled(PageFilters)(({ theme }) => ({
  padding: theme.spacing(4),
  border: `1px solid ${theme.palette.borderColor?.dark ?? ''}`,
  borderRadius: theme.shape.borderRadius,
}));

const BankItem = styled(Row)(({ theme }) => ({
  padding: theme.spacing(1),
  border: `1px solid ${theme.palette.borderColor?.dark ?? ''}`,
  borderRadius: theme.shape.borderRadius,
  height: '90px',
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: alpha(theme.palette.background.rootColor ?? '', 0.1),
  },
}));

const Icon = styled(ChevronRight)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const BankName = styled(Typography)(() => ({
  flexGrow: 1,
}));

const pageTitle = 'Bank Reconciliation';

const AddNewBankAccount: React.FC = () => {
  const { search } = useLocation();
  const { error } = useNotification();
  const [selectedCountry, setSelectedCountry] = useState<string>();
  const [searchQuery, setSearchQuery] = useState<string>();

  const urlSearchParams = new URLSearchParams(search);
  const addWithoutFeed = urlSearchParams.get('addWithoutFeed');

  const { data, isLoading } = api.bankReconciliation.listBanks.useQuery(
    {
      country: selectedCountry ?? '',
    },
    {
      enabled: !!selectedCountry,
    },
  );

  const authoriseBank = api.bankReconciliation.authoriseBank.useMutation({
    onSuccess: (data) => {
      if (!data) {
        error('Failed to authorise bank.');
        return;
      }

      window.location.href = data.redirectUrl;
    },
  });

  const handleSelectBank = (bankId: string) => {
    authoriseBank.mutate({
      institutionId: bankId,
      redirectUrl: `${window.location.protocol}//${
        window.location.host
      }${PROCESS_BANK_ACCOUNT}?${urlSearchParams.toString()}`,
    });
  };

  const availableBanks = (data ?? []).filter(
    (bank) =>
      !searchQuery ||
      bank.name.toLowerCase().includes(searchQuery?.toLowerCase() ?? ''),
  );

  if (addWithoutFeed) {
    return (
      <PageContainer heading={pageTitle}>
        <PageContent>
          <AccountSettings />
        </PageContent>
      </PageContainer>
    );
  }

  return (
    <PageContainer heading={pageTitle}>
      <PageContent header="Find account">
        <Spacing>
          <Filters pageId="add-new-bank-account">
            <Filter>
              <AppAutocomplete
                name="country"
                value={countryOptions.find(
                  (country) => country.value === selectedCountry,
                )}
                getOptionLabel={(option) => option.label}
                options={countryOptions}
                placeholder="Select country"
                onChange={(_, value) =>
                  setSelectedCountry(value?.value ?? undefined)
                }
              />
            </Filter>
            <Filter>
              <SearchField
                autoSearchOnDebounce
                name="searchQuery"
                variant="standard"
                placeholder="Search"
                fullWidth
                onSearch={setSearchQuery}
                allowEmptyQuery
                iconPlacement="left"
              />
            </Filter>
          </Filters>

          {isLoading || authoriseBank.isPending ? (
            <PageLoader />
          ) : !availableBanks.length ? (
            <NoResults
              mode={
                selectedCountry
                  ? NoResultsMode.NoResults
                  : NoResultsMode.NoCountrySelected
              }
            />
          ) : (
            <GridContainer>
              {availableBanks.map((bank) => (
                <Grid2
                  key={bank.id}
                  size={{ xs: 12, md: 6 }}
                  onClick={() => handleSelectBank(bank.id)}
                >
                  <BankItem centered spacing="sm">
                    <Image src={bank.logo} alt={bank.name} />
                    <BankName variant="h6">{bank.name}</BankName>
                    <Icon />
                  </BankItem>
                </Grid2>
              ))}
            </GridContainer>
          )}
        </Spacing>
      </PageContent>
    </PageContainer>
  );
};

export default AddNewBankAccount;

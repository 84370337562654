import React, { useState, useMemo, useEffect } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ReactGrid } from '@silevis/reactgrid';
import { uniq } from 'lodash';
import './style.css';
import { columns, groups } from 'helpers/uploadInvestorInvestment/columns';
import {
  applyChangesToPrevRows,
  buildRowsFromGridData,
  getCachedGridDataOrEmptyRow,
  getEmptyRow,
  saveGridDataToCache,
} from 'helpers/uploadInvestorInvestment/gridHelpers';
import CustomDropdownTemplate from './CustomDropdownTemplate';
import CustomSortcodeTemplate from './CustomSortcodeTemplate';
import CustomDateTemplate from './CustomDateTemplate';
import ActionButtons from './ActionButtons';
import ErrorsTable from './ErrorsTable';
import { useUploadedInvestorCashBalance } from 'hooks/data/investor/useUploadedInvestorCashBalance';
import { useUploadedInvestorValidation } from 'hooks/data/investor/useUploadedInvestorValidation';

const useStyles = makeStyles()(() => ({
  scrollableTable: {
    overflow: 'auto',
    padding: '10px 0',
    margin: '0 0 10px',
    clear: 'both',
  },
  tableHeaderGroup: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  loader: {
    width: '100%',
    height: '50vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

type Props = any;

const ReactGridForm: React.FC<Props> = ({
  gridDropdownsData,
  uploadPreviewSet,
}) => {
  const { classes } = useStyles();
  const [cols, setCols] = useState(columns);
  const [gridData, setGridData] = useState(() => getCachedGridDataOrEmptyRow());
  const [errors, setErrors] = useState([]);
  const [dropdownsData, setDropdownsData] = useState(gridDropdownsData);
  const [dobMismatchErrors, setDobMismatchErrors] = useState([]);
  const investorValidation = useUploadedInvestorValidation();
  const investorCashBalance = useUploadedInvestorCashBalance();

  useEffect(() => {
    setDropdownsData(gridDropdownsData);
  }, [gridDropdownsData]);

  useEffect(
    function cashBalanceRetrieved() {
      if (investorCashBalance.data) {
        setGridData((gridData) => {
          const { rowIdx, cashBalance } = investorCashBalance.data;
          gridData[rowIdx] = {
            ...gridData[rowIdx],
            cash_balance: cashBalance,
            use_cash_balance: cashBalance > 0 ? '1' : '0',
          };
          return [...gridData];
        });
      }
    },
    [investorCashBalance.data],
  );

  useEffect(
    function investorsValidated() {
      if (investorValidation.data) {
        const { overrideData, previewData, errors } = investorValidation.data;

        if (overrideData?.length) {
          setGridData((gridData) => {
            overrideData.forEach(
              ({ rowId, columnId, value }) =>
                (gridData[rowId][columnId] = value),
            );
            return [...gridData];
          });
        }

        const { fieldErrors, emailDobMismatchErrors } = errors;

        if (fieldErrors.length || emailDobMismatchErrors.length) {
          setErrors(
            fieldErrors
              ? fieldErrors.map((error) => ({
                  ...error,
                  borderColor: '#ff8888',
                }))
              : [],
          );
          setDobMismatchErrors(emailDobMismatchErrors || []);
          return;
        }

        const { newInvestors, existingInvestors, existingInvestorsNames } =
          previewData;

        const uploadPreview = {
          totalInvestors: uniq(newInvestors).length,
          existingInvestorAccounts: [],
        };

        if (existingInvestors.length) {
          //@ts-expect-error
          uploadPreview.existingInvestorAccounts = uniq(existingInvestors).map(
            (email) => {
              const investorData = gridData.find((row) => row.email === email);
              // @ts-expect-error
              investorData.fullName = existingInvestorsNames[email] ?? '';
              return investorData;
            },
          );
          uploadPreview.totalInvestors +=
            uploadPreview.existingInvestorAccounts.length;
        }
        uploadPreviewSet(uploadPreview);
      }
    },
    [investorValidation.data],
  );

  useEffect(
    function gridDataUpdated() {
      saveGridDataToCache(gridData);
    },
    [gridData],
  );

  /**
   * Cell change event handler
   * @see https://reactgrid.com/docs/4.0/7-api/1-types/2-cell-change/
   * @param {Array} changes
   */
  const handleChanges = (changes) => {
    const newGridData = applyChangesToPrevRows(changes, gridData);
    setGridData(newGridData);

    if (!investorCashBalance.isPending) {
      for (const { rowId } of changes) {
        const { use_cash_balance, cash_balance } = newGridData[rowId];

        if (!!+use_cash_balance && !cash_balance) {
          const { email, investment_amount, advice_fee, tranche } =
            newGridData[rowId];

          if (email?.length && investment_amount && tranche.length) {
            investorCashBalance.mutate({
              rowIdx: rowId,
              email,
              fundId: tranche,
              investmentAmount: investment_amount,
              adviserFee: advice_fee,
            });

            break;
          }
        }

        if (!+use_cash_balance && cash_balance !== 0) {
          newGridData[rowId].cash_balance = '';
        }
      }
    }
  };

  const handleValidateAndPreview = () =>
    investorValidation.mutate({ gridData, gridDropdownsData: dropdownsData });

  /**
   * Append rows to the bottom of the table
   * @param {number} count
   */
  const handleAddRow = (count = 1) => {
    // add rows as many as count
    if (gridData.length + count > 350) {
      return;
    }
    const newRows = Array.from({ length: count }, (_, k) =>
      getEmptyRow(gridData.length + k),
    );
    setGridData((prevGridData) => [...prevGridData, ...newRows]);
  };

  /**
   * Remove rows from the bottom of the table
   * @param {number} count
   */
  const handleRemoveRow = (count = 1) => {
    // if there are no rows, don't do anything
    // if there are fewer rows than count, remove all the rows except the first one
    if (gridData.length <= 1) {
      return;
    }
    if (gridData.length < count) {
      count = gridData.length - 1;
    }
    // remove errors from the removed rows
    setErrors((prevErrors) => [
      //@ts-expect-error
      ...prevErrors.filter((error) => error.rowId < gridData.length - count),
    ]);
    // add rows as many as count
    const newGridData = [...gridData.slice(0, -1 * count)];
    setGridData(newGridData);
  };

  const handleColumnResize = (ci, width) =>
    setCols((prevColumns) => {
      const columnIndex = prevColumns.findIndex((el) => el.columnId === ci);
      const resizedColumn = prevColumns[columnIndex];
      prevColumns[columnIndex] = { ...resizedColumn, width };
      return [...prevColumns];
    });

  const columnGroups = useMemo(
    () =>
      groups.map((group) => ({
        groupId: group.groupId,
        title: group.title,
        totalSize: group.columns.reduce((acc, col) => {
          // find the new column width
          const newCol = cols.find((c) => c.columnId === col.columnId);
          return acc + newCol.width;
        }, 0),
      })),
    [cols],
  );

  const { columnGroup, columnGroupWidth } = useMemo(() => {
    let columnGroupWidth = 0;
    const colGroups = columnGroups.map((group, index) => {
      columnGroupWidth += group.totalSize;
      if (index === 0) {
        return null;
      }

      return (
        <div
          key={group.title}
          className="group"
          style={{
            marginLeft: index === 1 ? 50 : undefined,
            width: group.totalSize,
            backgroundColor: index % 2 === 0 ? '#cccccc' : '#dddddd',
            padding: '2px 5px',
          }}
        >
          {group.title}
        </div>
      );
    });

    return { columnGroup: colGroups, columnGroupWidth };
  }, [columnGroups]);

  if (!dropdownsData)
    return <Box className={classes.loader}>Preparing an upload table...</Box>;

  return (
    <>
      <div className="d-flex justify-content-between">
        <div className={classes.scrollableTable}>
          <div
            className={classes.tableHeaderGroup}
            style={{ width: columnGroupWidth }}
          >
            {columnGroup}
          </div>
          <ReactGrid
            rows={buildRowsFromGridData(gridData, dropdownsData)}
            columns={cols}
            onCellsChanged={handleChanges}
            onColumnResized={handleColumnResize}
            enableRangeSelection
            enableRowSelection
            enableColumnSelection
            highlights={errors}
            stickyLeftColumns={1}
            stickyTopRows={1}
            customCellTemplates={{
              // @ts-expect-error
              custom_dropdown: new CustomDropdownTemplate(),
              // @ts-expect-error
              custom_sortcode: new CustomSortcodeTemplate(),
              // @ts-expect-error
              custom_date: new CustomDateTemplate(),
            }}
          />
        </div>
        <ActionButtons
          gridDataLength={gridData.length || 0}
          handleAddRow={handleAddRow}
          handleRemoveRow={handleRemoveRow}
          handleValidateAndPreview={handleValidateAndPreview}
          loadingUploadPreview={investorValidation.isPending}
        />
      </div>
      <div className="scrollable-table errors-table">
        {errors.length > 0 && <ErrorsTable errors={errors} />}
      </div>
      <div className="scrollable-table errors-table">
        {dobMismatchErrors.length > 0 && (
          <ErrorsTable
            errors={dobMismatchErrors}
            title="Investor details error"
            description="The following investor accounts are unable to be created as the email address matches an investor with an existing Further account, but the date of birth/registration date is different."
          />
        )}
      </div>
    </>
  );
};

export default ReactGridForm;

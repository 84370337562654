import { validationRegexes } from '../../utils';
import { z } from 'zod';

export const updateProfileInput = z.object({
  firstName: z
    .string()
    .min(1, 'Please enter your first name.')
    .regex(validationRegexes.userName),
  lastName: z
    .string()
    .min(1, 'Please enter your surname.')
    .regex(validationRegexes.userName),
  email: z
    .string()
    .min(1, 'Please enter email address.')
    .email({ message: 'Please enter a valid email address.' }),
  profilePic: z.string().optional(),
  password: z.string().regex(validationRegexes.password).optional(),
  updates: z.array(z.string()).optional(),
});

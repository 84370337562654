import { isEqual, sumBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDebounce } from 'usehooks-ts';
import {
  Delete,
  Edit,
  Email,
  FilterList,
  InfoOutlined,
} from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { deleteInvestment } from '@redux/actions/Investment';
import { createInvestmentEditRoute } from 'adminConstants/routes';
import AlertDialog from 'components/AlertDialog';
import Chip from 'components/Chip';
import Dialog from 'components/Dialog';
import { FirmSelector } from 'components/FirmSelector';
import Autocomplete from 'components/FormElements/AppAutocomplete';
import TextField from 'components/FormElements/AppTextInput';
import { Row } from 'components/Layout';
import PageContainer from 'components/PageContainer';
import PageContent from 'components/PageContent';
import PageFilters, { Filter } from 'components/PageFilters';
import Spacing from 'components/Spacing';
import Table, { FooterCell, FooterRow, TableActions } from 'components/Table';
import { startOfDay } from 'date-fns';
import { InvestmentSource } from 'further-types/investment';
import { dateToLabel, numberToCurrencyString } from 'further-ui/utils';
import { exportExcelSheet } from 'helpers/investment/exportExcelSheet';
import { useAdvisersLeanResponse } from 'hooks/data/adviser/useAdvisers';
import { useFunds } from 'hooks/data/fund/useFunds';
import {
  useInvestments,
  useInvestmentsExport,
  useSendPaymentReminder,
} from 'hooks/data/investment/useInvestments';
import useApiRequestHandler from 'hooks/ui/useApiRequestHandler';
import useFilters from 'hooks/ui/useFilters';
import { useGetPermissions } from 'hooks/ui/useGetPermissions';
import { useGetRole } from 'hooks/ui/useGetRole';
import { usePagination } from 'hooks/ui/usePagination';
import { useSortTranches } from 'hooks/ui/useSortTranches';
import FilterPanel from './FilterPanel';

const totalCurrentValueTooltipText = (
  <>
    This is the combined value of all current holdings, exits and uninvested
    paid in capital. Where there are unpaid commitments, this figure may be
    materially lower than subscribed investment capital.
  </>
);

const breadcrumbs = [
  { label: 'Dashboard', link: '/' },
  { label: 'Subscriptions', isActive: true },
];

const isFilterPanelApplied = (filters) => {
  const defaultFilters = {
    amount: ['0', '0'],
    startDate: undefined,
    endDate: undefined,
    paymentStatus: undefined,
  };
  return !isEqual(
    {
      paymentStatus: filters.paymentStatus,
      amount: filters.amount,
      startDate: filters.startDate,
      endDate: filters.endDate,
    },
    defaultFilters,
  );
};

const Investment: React.FC = () => {
  const dispatch = useDispatch();
  const [record, setRecord] = useState();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openApplyFilter, setOpenApplyFilter] = useState(false);
  const [errorText, setErrorText] = useState<{
    startDate?: string;
    endDate?: string;
  }>({});
  const [includeExportData, setIncludeExportData] = useState(false);
  const { firmId } = useGetRole();

  const { tranches } = useFunds({ leanResponse: true });
  const sortedTranches = useSortTranches(tranches?.data);
  const handleApiRequest = useApiRequestHandler();
  const { sendPaymentReminder } = useSendPaymentReminder();
  const pagination = usePagination({
    id: 'investments-table',
    orderBy: 'investmentDate',
    order: 'desc',
  });

  const { filters, handleChangeFilters } = useFilters('investments-table', {
    defaultFilters: {
      firmId,
      fundId: '',
      fundType: undefined,
      adviserId: '',
      searchByInvestorAndInvestment: undefined,
      amount: ['0', '0'],
      startDate: undefined,
      endDate: undefined,
      paymentStatus: undefined,
    },
    onFiltersChange: (newFilters) => {
      setFiltersApplied(isFilterPanelApplied(newFilters));
      pagination.toFirstPage();
    },
  });

  useEffect(() => {
    const qs = new URLSearchParams(window.location.search);
    const fundIdParam = qs.get('fundId');
    if (fundIdParam) {
      handleChangeFilters({ fundId: fundIdParam });
    }
  }, []);

  const [filtersApplied, setFiltersApplied] = useState(
    isFilterPanelApplied(filters),
  );

  const advisers = useAdvisersLeanResponse({
    firmId,
    isEmailVerify: 'true',
    order: 'asc',
    orderBy: 'fullName',
  });

  const debouncedInvestorAndInvestmentSearch = useDebounce(
    filters.searchByInvestorAndInvestment,
    400,
  );

  const [deletePermission, updatePermission] = useGetPermissions([
    'delete:investment',
    'edit:investment',
  ]);
  const { isSuperAdmin } = useGetRole();

  const params = {
    page: pagination.page,
    firmId: filters.firmId,
    fundId: filters.fundId,
    //@ts-expect-error
    fundType: filters.fundType?._id,
    perPage: pagination.rowsPerPage,
    type: pagination.order,
    keyName: pagination.orderBy,
    paymentStatus: filters.paymentStatus,
    minInvestment: filters.amount[0],
    maxInvestment: filters.amount[1],
    searchByInvestorAndInvestment: debouncedInvestorAndInvestmentSearch,
    adviserId: filters.adviserId,
    startDate: undefined,
    endDate: undefined,
  };
  if (filters.startDate && filters.endDate) {
    //@ts-expect-error
    params.startDate = startOfDay(new Date(filters.startDate));
    //@ts-expect-error
    params.endDate = startOfDay(new Date(filters.endDate));
  }
  const investmentsQuery = useInvestments({
    params,
  });
  const investmentsExportQuery = useInvestmentsExport({
    params: { ...params, includeExportData },
  });

  const handleSendPaymentReminder = (elm) => {
    sendPaymentReminder.mutate(elm._id);
  };

  const validate = () => {
    const errors: {
      startDate?: string;
      endDate?: string;
    } = {};
    let isError = false;
    if (!filters.startDate) {
      errors.startDate = 'Please select start date.';
      isError = true;
    }
    if (!filters.endDate) {
      errors.endDate = 'Please select end date.';
      isError = true;
    }
    setErrorText(errors);
    return {
      errors,
      isError,
    };
  };

  const columns = [
    {
      label: 'Investor name',
      key: 'fullName',
    },
    {
      label: 'Email',
      key: 'email',
      render: (elm) => elm?.investorId?.email,
    },
    {
      label: 'Tranche',
      key: 'trancheName',
    },
    {
      label: 'Subscription date',
      key: 'investmentDate',
      render: (elm) => dateToLabel(elm?.investmentDate),
    },
    {
      label: 'Subscription',
      key: 'investmentAmount',
      render: (elm) => numberToCurrencyString(elm?.investmentAmount),
    },
    {
      label: (
        <Row centered spacing="xs">
          <span>Total value</span>
          <Tooltip title={totalCurrentValueTooltipText}>
            <IconButton size="small">
              <InfoOutlined fontSize="small" />
            </IconButton>
          </Tooltip>
        </Row>
      ),
      key: 'investmentValue',
      render: (elm) =>
        numberToCurrencyString(elm?.investmentBalance?.investmentValue),
    },
    {
      label: 'Payment status',
      key: 'isPaymentReceived',
      render: (elm) => (
        <>
          {elm?.source === InvestmentSource.Transfer ? (
            <Chip small variant="success">
              Transfer
            </Chip>
          ) : elm?.isFullyFunded ? (
            <Chip small variant="success">
              Fully funded
            </Chip>
          ) : elm?.isPartiallyFunded ? (
            <Chip small variant="disabled">
              Partially funded
            </Chip>
          ) : (
            <Chip small variant="disabled">
              No payment
            </Chip>
          )}
        </>
      ),
    },
    {
      label: 'Adviser',
      key: 'adviser',
      render: (elm) => elm.adviserId?.fullName || '-',
    },
    {
      label: 'Subscription ID',
      key: 'externalInvestmentId',
      render: (elm) => elm?.externalInvestmentId || '-',
    },

    {
      label: 'Actions',
      key: '',
      sort: false,
      render: (elm) => (
        <TableActions
          showAsDropdown
          actions={[
            {
              visible: updatePermission,
              label: 'Edit',
              icon: Edit,
              link: createInvestmentEditRoute(elm?._id),
              color: 'primary',
            },
            {
              visible: deletePermission,
              label: 'Delete',
              icon: Delete,
              onClick: () => handleDelete(elm),
              color: 'error',
            },
            {
              visible:
                !elm?.isFullyFunded &&
                !elm?.isPartiallyFunded &&
                (!elm.investorId.disableAllEmailsForFirm || elm.adviserId),
              label: 'Send payment reminder',
              icon: Email,
              onClick: () => handleSendPaymentReminder(elm),
              color: 'primary',
            },
          ]}
        />
      ),
    },
  ];

  const handleDelete = (rec) => {
    setRecord(rec);
    setOpenConfirmDialog(!openConfirmDialog);
  };

  const handleConfirmDelete = async () => {
    await handleApiRequest(async (showSuccess) => {
      const response = await dispatch<{
        status: number;
        data?: { responseMsg: string };
      }>(
        //@ts-expect-error
        deleteInvestment(record._id),
      );
      if (response.status === 200) {
        showSuccess(response.data?.responseMsg ?? '');
        investmentsQuery.refetch();
      }
    });
    setOpenConfirmDialog(false);
  };

  const handleFinish = () => {
    pagination.handleChangePage(null, 0);
    if (filters.startDate || filters.endDate) {
      const { isError } = validate();
      if (!isError) {
        setOpenApplyFilter(!openApplyFilter);
      }
    } else {
      setOpenApplyFilter(!openApplyFilter);
    }
  };

  const filterClose = () => {
    handleChangeFilters({
      paymentStatus: undefined,
      amount: ['0', '0'],
      startDate: undefined,
      endDate: undefined,
    });
    setOpenApplyFilter(!openApplyFilter);
    setErrorText({});
    setFiltersApplied(false);
  };

  const exportExcelData = () => {
    setIncludeExportData(true);
  };

  useEffect(() => {
    if (
      includeExportData &&
      investmentsExportQuery.data?.result &&
      !investmentsExportQuery.isFetching
    ) {
      exportExcelSheet(investmentsExportQuery?.data?.result);
      setIncludeExportData(false);
    }
  }, [
    includeExportData,
    investmentsExportQuery.data,
    investmentsExportQuery.isFetching,
  ]);

  const filterButtonLabel = filtersApplied ? (
    <img
      src="images/customIcons/filter-applied.png"
      alt="filters applied"
      width="24px"
      height="24px"
    />
  ) : (
    <FilterList />
  );

  return (
    <PageContainer heading="Subscriptions" breadcrumbs={breadcrumbs}>
      <PageContent>
        <PageFilters
          pageId="investments-table"
          buttons={[
            {
              label: filterButtonLabel,
              onClick: () => setOpenApplyFilter(!openApplyFilter),
              isIcon: true,
            },
            {
              label: 'Export',
              onClick: exportExcelData,
              disabled: !investmentsQuery?.data?.result?.length,
              loading: investmentsExportQuery?.isFetching,
            },
          ]}
        >
          <Filter>
            <TextField
              type="search"
              name="searchByInvestorAndInvestment"
              placeholder="Search by investor name, email or subscription ID"
              fullWidth
              value={filters.searchByInvestorAndInvestment}
              onChange={(event) => {
                handleChangeFilters({
                  //@ts-expect-error
                  searchByInvestorAndInvestment: event.target.value,
                });
              }}
            />
          </Filter>
          {isSuperAdmin && (
            <Filter>
              <FirmSelector
                firmId={filters.firmId}
                placeholder="Search by firm"
                onChange={(firmId) => {
                  handleChangeFilters({ firmId });
                }}
              />
            </Filter>
          )}
          <Filter>
            {!tranches.isFetching ? (
              <Autocomplete
                id="fundId"
                options={sortedTranches}
                getOptionLabel={(option) => option.label}
                getOptionKey={(option) => option._id}
                value={(sortedTranches ?? []).find(
                  (tranche) => tranche._id === filters.fundId,
                )}
                onChange={(_, newValue) => {
                  if (!newValue) {
                    const newUrl = new URL(window.location.href);
                    newUrl.searchParams.delete('fundId');
                    window.history.pushState({}, '', newUrl.href);
                  }

                  handleChangeFilters({ fundId: newValue?._id });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Tranche"
                  />
                )}
              />
            ) : (
              <TextField
                variant="outlined"
                placeholder="Tranche"
                disabled
                value="Loading..."
              />
            )}
          </Filter>
          <Filter>
            <Autocomplete
              id="adviserId"
              options={
                advisers.data?.advisers.filter(
                  (adviser) => !!adviser.fullName,
                ) || []
              }
              getOptionLabel={(option) =>
                `${option?.fullName} (${option?.email})`
              }
              filterSelectedOptions
              onChange={(_, newValue) => {
                handleChangeFilters({ adviserId: newValue?._id });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Adviser"
                />
              )}
            />
          </Filter>
        </PageFilters>

        <Table
          columns={columns}
          onRequestSort={pagination.handleRequestSort}
          order={pagination.order}
          orderBy={pagination.orderBy}
          tablebody={investmentsQuery?.data?.result ?? []}
          onPageChange={pagination.handleChangePage}
          onRowsPerPageChange={pagination.handleChangeRowsPerPage}
          page={pagination.page}
          rowsPerPage={pagination.rowsPerPage}
          count={investmentsQuery?.data?.total ?? 0}
          pagination={true}
          loading={investmentsQuery.isFetching}
          TableFooter={
            <tfoot>
              <FooterRow>
                <FooterCell colSpan={4} />
                <FooterCell>
                  {numberToCurrencyString(
                    sumBy(investmentsQuery.data?.result, 'investmentAmount'),
                  )}
                </FooterCell>
                <FooterCell>
                  {numberToCurrencyString(
                    sumBy(
                      investmentsQuery.data?.result,
                      'investmentBalance.investmentValue',
                    ),
                  )}
                </FooterCell>
                <FooterCell />
                <FooterCell />
                <FooterCell />
                <FooterCell />
              </FooterRow>
            </tfoot>
          }
          variant="nohover"
        />
      </PageContent>

      <AlertDialog
        open={openConfirmDialog}
        title={`This cannot be undone and all information relating to the subscription will be lost.`}
        content={
          <Spacing>
            <p>
              Any money transferred to you must be refunded to the investor
              before the subscription is deleted.
            </p>
            <p>
              Any money that was transferred from the investor's cash balance to
              pay for this subscription will be returned to their cash balance.
            </p>
          </Spacing>
        }
        size="md"
        onClose={handleDelete}
        onConfirm={handleConfirmDelete}
        btnLabels={{ cancel: 'Go back', confirm: 'Delete subscription' }}
        cancelBtnProps={{ variant: 'outlined' }}
        confirmBtnProps={{ color: 'secondary' }}
      />
      <Dialog
        open={openApplyFilter}
        title="Advanced Filters"
        onClose={filterClose}
        onConfirm={handleFinish}
        btnLabels={{
          cancel: 'Reset',
          confirm: 'Apply',
        }}
      >
        <FilterPanel
          filters={filters}
          handleChangeFilters={handleChangeFilters}
          errorText={errorText}
        />
      </Dialog>
    </PageContainer>
  );
};

export default Investment;

import { Box, styled, Tab, Tabs, Typography } from '@mui/material';
import QuickReconcile from './QuickReconcile';
import { ActionRef } from 'further-types/lp/action';
import { CurrencyCode } from 'further-types/lp/vehicle';
import CreateTransfer from './CreateTransfer';
import { useFormContext } from 'react-hook-form';
import { FormValues } from '..';

export enum TransactionActionsMode {
  QuickReconcile,
  CreateTransfer,
}

const ActionsContainer = styled(Box)({
  alignSelf: 'flex-end',
});

const StyledTab = styled(Tab)({
  '& .MuiTypography-root': {
    fontSize: '11px',
    fontWeight: 500,
    letterSpacing: 0.4,
  },
});

type Props = {
  bankAccountOperationId: string;
  onFindAndMatch: (transactionId: string, actionId?: string) => void;
  matchingActions: Array<{
    id: string;
    amount: number;
    counterpartyLabel: string;
    actionRef: ActionRef;
    currencyCode: CurrencyCode;
  }>;
};

const TransactionActions: React.FC<Props> = ({
  bankAccountOperationId,
  onFindAndMatch,
  matchingActions,
}) => {
  const { watch, setValue } = useFormContext<FormValues>();

  const handleShowFindAndMatch = (actionId?: string) => {
    onFindAndMatch(bankAccountOperationId, actionId);
  };

  const handleTabChange = (_: unknown, selectedTab: TransactionActionsMode) => {
    setValue('mode', selectedTab);
  };

  const actionsMode = watch('mode');

  return (
    <ActionsContainer>
      <Tabs
        value={actionsMode}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
      >
        <StyledTab
          value={TransactionActionsMode.QuickReconcile}
          label={<Typography variant="body2">MATCH</Typography>}
        />
        <StyledTab
          value={TransactionActionsMode.CreateTransfer}
          label={<Typography variant="caption">CREATE TRANSFER</Typography>}
        />
      </Tabs>
      {actionsMode === TransactionActionsMode.QuickReconcile && (
        <QuickReconcile
          actions={matchingActions}
          onFindAndMatch={handleShowFindAndMatch}
        />
      )}
      {actionsMode === TransactionActionsMode.CreateTransfer && (
        <CreateTransfer />
      )}
    </ActionsContainer>
  );
};

export default TransactionActions;

import { useAdvisersLeanResponse } from 'hooks/data/adviser/useAdvisers';
import Button from 'components/Button';
import { useState } from 'react';
import { Grid2, Typography } from '@mui/material';
import { useInvestor } from 'hooks/data/investor/useInvestor';
import { FirmSelector } from 'components/FirmSelector';
import AppAutocomplete from 'components/FormElements/AppAutocomplete';
import Heading from 'components/Heading';
import { ArrowDropDown } from '@mui/icons-material';
import TextField from 'components/FormElements/AppTextInput';
import Row from 'components/Layout/Row';
import Spacing from 'components/Spacing';
import SearchableMenu from 'components/SearchableMenu';
import { useDisclosure } from 'further-ui/hooks';
import AlertDialog from 'components/AlertDialog';
import { NavLink } from 'react-router-dom';
import { ADVISER_ADD } from 'adminConstants/routes';

type Props = {
  investorDetail: {
    _id: string;
    dob: string;
    firmSpecificSettings: Array<{
      firmId: string;
      firmName: string;
      defaultAdviserId?: string;
      disableAllEmails: boolean;
    }>;
  };
};

const SuperAdminDefaultAdviserRow = ({ investorDetail }: Props) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [editingFirmId, setEditingFirmId] = useState<string | undefined>(
    undefined,
  );
  const [selectedAdviserId, setSelectedAdviserId] = useState<
    string | undefined
  >(undefined);
  const [showAddForm, setShowAddForm] = useState(false);

  const editDisclosure = useDisclosure<{
    adviserId: string;
    editingFirmId?: string;
  }>();
  const deleteDisclosure = useDisclosure<{ deletingFirmId: string }>();
  const addDisclosure = useDisclosure<{
    adviserId: string;
    firmId?: string;
  }>();

  const { setDefaultAdviser, isSettingDefaultAdviser } = useInvestor(
    investorDetail._id,
  );

  const { data, isLoading } = useAdvisersLeanResponse({
    order: 'asc',
    orderBy: 'fullName',
  });
  const advisers = data?.advisers ?? [];

  const handleUpdateInvestor = (
    adviserId: string | null,
    firmId?: string,
    removeAllConnections?: boolean,
  ) => {
    if (!firmId) return;

    setDefaultAdviser({
      firmId,
      defaultAdviserId: adviserId ?? null,
      removeAllConnections,
    });

    setMenuAnchorEl(null);
    setShowAddForm(false);
  };

  const defaultAdviserSettings = investorDetail.firmSpecificSettings.filter(
    (setting) => !!setting.defaultAdviserId,
  );

  const firmSpecificSettings = defaultAdviserSettings?.map((setting) => ({
    firmId: setting.firmId,
    firmName: setting.firmName,
    adviser: advisers.find(
      (adviser) => adviser._id === setting.defaultAdviserId,
    ),
  }));
  const getAdviserName = (adviser) =>
    `${adviser.fullName ?? ''} (${adviser.email})`;

  if (isSettingDefaultAdviser || isLoading) return <>Loading...</>;

  const hasDefaultAdviser = firmSpecificSettings?.length > 0;

  return (
    <>
      {!isSettingDefaultAdviser && !isLoading && (
        <Spacing size="md">
          {firmSpecificSettings?.map((setting) => (
            <Row spacing="xs" key={setting.adviser?._id} centered>
              <div>
                {setting.firmName} - {getAdviserName(setting.adviser)}
              </div>
              <Button
                aria-controls="select-adviser-menu"
                aria-haspopup="true"
                color="primary"
                variant="contained"
                size="extra-small"
                endIcon={<ArrowDropDown />}
                onClick={(el) => {
                  setMenuAnchorEl(el.currentTarget);
                  setEditingFirmId(setting.firmId);
                  setShowAddForm(false);
                }}
              >
                Edit
              </Button>
              <Button
                color="error"
                variant="outlined"
                size="extra-small"
                sx={{
                  height: '24px',
                }}
                onClick={() => {
                  deleteDisclosure.stageAction({
                    deletingFirmId: setting.firmId,
                  });
                  setShowAddForm(false);
                }}
              >
                Delete
              </Button>
            </Row>
          ))}
          {menuAnchorEl && (
            <SearchableMenu
              id="select-adviser-menu"
              anchorEl={menuAnchorEl}
              onClose={() => setMenuAnchorEl(null)}
              options={advisers}
              getOptionLabel={getAdviserName}
              onSelect={(adviser) => {
                editDisclosure.stageAction({
                  adviserId: adviser._id,
                  editingFirmId,
                });
                setMenuAnchorEl(null);
              }}
              searchPlaceholder="Search by adviser name or email"
            />
          )}
          <Row>
            <Button
              aria-controls="select-firm-menu"
              aria-haspopup="true"
              color="primary"
              variant="contained"
              size="extra-small"
              endIcon={<ArrowDropDown />}
              onClick={() => {
                setEditingFirmId(undefined);
                setSelectedAdviserId(undefined);
                setShowAddForm(!showAddForm);
              }}
            >
              {hasDefaultAdviser
                ? 'Add new default adviser'
                : 'Add default adviser'}
            </Button>
          </Row>
          {showAddForm && (
            <>
              <Spacing size="sm">
                <Heading variant="h3" noMargin>
                  Add a new default adviser
                </Heading>
                <Typography color="text.secondary" sx={{ fontSize: '14px' }}>
                  Select an existing adviser to be connected to this investor.
                  This adviser will be connected to all this investor's
                  subscriptions and will be able to see more investor data than
                  a partially connected adviser.{' '}
                  <NavLink target="_blank" to={ADVISER_ADD}>
                    <Typography
                      color="primary"
                      component="span"
                      fontWeight="bold"
                      fontSize="14px"
                    >
                      Click here to create a new adviser.
                    </Typography>
                  </NavLink>
                </Typography>
              </Spacing>
              <Grid2 container spacing={2} size={12}>
                <Grid2 size={{ sm: 12, md: 5 }}>
                  <FirmSelector
                    firmId={editingFirmId}
                    onChange={setEditingFirmId}
                    placeholder="Select firm"
                    allowDeselection={false}
                    filterFirm={(firm) =>
                      !firmSpecificSettings?.some(
                        (setting) => setting.firmId === firm._id,
                      )
                    }
                  />
                </Grid2>
                <Grid2 size={{ sm: 12, md: 5 }}>
                  <AppAutocomplete
                    renderInput={(params) => (
                      <TextField {...params} label="Select adviser" />
                    )}
                    options={advisers}
                    getOptionLabel={(adviser) => getAdviserName(adviser)}
                    value={
                      advisers.find(
                        (adviser) =>
                          adviser._id.toString() === selectedAdviserId,
                      ) ?? null
                    }
                    onChange={(_, adviser) =>
                      setSelectedAdviserId(adviser?._id.toString())
                    }
                    placeholder="Select adviser"
                  />
                </Grid2>
                <Grid2 size={{ sm: 12, md: 2 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    onClick={() =>
                      addDisclosure.stageAction({
                        adviserId: selectedAdviserId ?? '',
                        firmId: editingFirmId,
                      })
                    }
                  >
                    Save
                  </Button>
                </Grid2>
              </Grid2>
            </>
          )}
        </Spacing>
      )}
      <AlertDialog
        onClose={addDisclosure.onClose}
        onConfirm={() => {
          handleUpdateInvestor(
            addDisclosure.actionProps.adviserId,
            addDisclosure.actionProps.firmId,
          );
          addDisclosure.onClose();
          setShowAddForm(false);
          setEditingFirmId(undefined);
          setSelectedAdviserId(undefined);
        }}
        open={addDisclosure.isOpen}
        title="Add default adviser"
        content={
          <p>
            You are adding a new default adviser for this investor. This will
            add the default adviser to all existing subscriptions made by the
            investor, and will override any adviser(s) currently selected on the
            investor's existing subscriptions.
          </p>
        }
        confirmBtnProps={{ variant: 'contained', color: 'primary' }}
        cancelBtnProps={{ variant: 'outlined', color: 'primary' }}
        btnLabels={{
          cancel: 'Go back',
          confirm: 'Add default adviser',
        }}
      />
      <AlertDialog
        onClose={editDisclosure.onClose}
        onConfirm={() => {
          handleUpdateInvestor(
            editDisclosure.actionProps.adviserId,
            editDisclosure.actionProps.editingFirmId,
          );
          editDisclosure.onClose();
        }}
        open={editDisclosure.isOpen}
        title="Edit default adviser"
        content={
          <p>
            This will change the adviser on all existing subscriptions made by
            this investor. The previous default adviser will not be able to see
            any subscription details once this change has taken place.
          </p>
        }
        confirmBtnProps={{ variant: 'contained', color: 'primary' }}
        cancelBtnProps={{ variant: 'outlined', color: 'primary' }}
        btnLabels={{
          cancel: 'Go back',
          confirm: 'Proceed',
        }}
      />
      <AlertDialog
        onClose={deleteDisclosure.onClose}
        onConfirm={() => {
          handleUpdateInvestor(
            null,
            deleteDisclosure.actionProps.deletingFirmId,
            false,
          );
          deleteDisclosure.onClose();
        }}
        onAlternateConfirm={() => {
          handleUpdateInvestor(
            null,
            deleteDisclosure.actionProps.deletingFirmId,
            true,
          );
          deleteDisclosure.onClose();
        }}
        open={deleteDisclosure.isOpen}
        title="Removing default adviser"
        content={
          <p>
            Do you wish to remove this adviser from all subscriptions connected
            to this investor? The adviser will not be able to see any
            subscription details if they are removed.
          </p>
        }
        confirmBtnProps={{
          variant: 'contained',
          color: 'primary',
          whiteSpace: 'nowrap',
        }}
        alternateConfirmBtnProps={{
          variant: 'contained',
          color: 'error',
          whiteSpace: 'nowrap',
        }}
        cancelBtnProps={{
          variant: 'outlined',
          color: 'primary',
          whiteSpace: 'nowrap',
        }}
        btnLabels={{
          cancel: 'Go back',
          confirm: 'Keep connections',
          alternateConfirm: 'Remove connections',
        }}
      />
    </>
  );
};

export default SuperAdminDefaultAdviserRow;

import { api } from 'lib/httpClient';
import downloadFile from 'utils/downloadFile';

export const getSumOfSharesHeldOnDate = (body, notification) => {
  return async () => {
    try {
      const response = await api.post(`exit/sum-shares-held-on-date`, body);
      if (response.status === 200) {
        return response.data.data;
      }
    } catch (error: any) {
      notification.error(error.response.data.responseMsg);
    }
  };
};

export const calculatePerformanceFee = (body, notification) => {
  return async () => {
    try {
      const response = await api.post(`exit/calculate-performance-fee`, body);
      if (response.status === 200) {
        return response.data.data;
      }
    } catch (error: any) {
      notification.error(error.response.data.responseMsg);
    }
  };
};

export const downloadExits = (exitId, notification) => {
  return async () => {
    try {
      const response = await api.get(`exit/download/${exitId}`, {
        responseType: 'blob',
      });
      if (response.status === 200) {
        downloadFile(response.data, `Exit-${Date.now()}.xlsx`);
        return Promise.resolve(response);
      }
    } catch (error: any) {
      notification.error(
        error?.response?.data?.responseMsg ??
          'Error downloading exit transfer statement.',
      );
      return false;
    }
  };
};

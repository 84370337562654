import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useGetRole } from 'hooks/ui/useGetRole';
import Grid from '@mui/material/Grid2';
import { IconButton, styled, Tooltip, Switch } from '@mui/material';
import {
  Delete,
  Edit,
  Visibility,
  Email,
  InfoOutlined,
  ContactMail,
} from '@mui/icons-material';
import Autocomplete from 'components/FormElements/AppAutocomplete';
import TextField from 'components/FormElements/AppTextInput';
import GridContainer from 'components/GridContainer';
import Table, { TableActions } from 'components/Table';
import PageContainer from 'components/PageContainer';
import { deleteInvestor } from '@redux/actions/Investor';
import {
  INVESTOR_ADD,
  createInvestorEditRoute,
  createInvestorViewRoute,
} from 'adminConstants/routes';
import {
  useInvestorsExport,
  useInvestors,
} from 'hooks/data/investor/useInvestors';
import AlertDialog from 'components/AlertDialog';
import { dateToLabel, dateofBirthToLabel } from 'further-ui/utils';
import { useDisclosure } from 'further-ui/hooks';
import { useGetPermissions } from 'hooks/ui/useGetPermissions';
import { useSortTranches } from 'hooks/ui/useSortTranches';
import { useFunds } from 'hooks/data/fund/useFunds';
import { usePagination } from 'hooks/ui/usePagination';
import { useDebounce } from 'usehooks-ts';
import usePlatformInvites from 'hooks/data/investor/useInvestorPlatformInvites';
import { useInvestorDisability } from 'hooks/data/investor/useInvestorDisability';
import { useNotification } from 'hooks/ui/useNotification';
import downloadFile from 'utils/downloadFile';
import { useFirm } from 'hooks/data/firm/useFirm';
import useFilters from 'hooks/ui/useFilters';
import useApiRequestHandler from 'hooks/ui/useApiRequestHandler';
import PageContent from 'components/PageContent';
import PageFilters, { Filter } from 'components/PageFilters';
import { FirmSelector } from 'components/FirmSelector';

const ResendInvites = styled('div')({
  fontSize: '2rem',
});

const Investor: React.FC = () => {
  const dispatch = useDispatch();
  const deleteDisclosure = useDisclosure();
  const disabledDisclosure = useDisclosure();
  const resendInvitesDisclosure = useDisclosure();
  const { isSuperAdmin, firmId } = useGetRole();
  const [selectedInvestors, setSelectedInvestors] = useState([]);
  const { success, error } = useNotification();
  const handleApiRequest = useApiRequestHandler();

  const { firms } = useFirm({ params: { firmId } });
  const pagination = usePagination({
    pageSize: 10,
    orderBy: 'createdAt',
    order: 'desc',
    id: 'investor-list',
  });
  const { filters, handleChangeFilters } = useFilters('investor-filter', {
    defaultFilters: {
      firmId,
      fundId: '',
      searchTerm: '',
    },
    onFiltersChange: pagination.toFirstPage,
  });

  const {
    resendInvite: { mutate: resendInvite },
    resendInvites,
    resendInvitesToInvestorsWithoutVisit,
  } = usePlatformInvites({
    onResendInviteSuccess: () => {
      refetch();
      success('Investor invite sent successfully.');
    },
    onResendInvitesSuccess: () => {
      success('Investor invites sent successfully.');
      refetch();
    },
  });

  const [updatePermission, deletePermission] = useGetPermissions([
    'edit:investor',
    'delete:investor',
  ]);

  const { tranches } = useFunds({
    leanResponse: true,
  });
  const sortedTranches = useSortTranches(tranches?.data);

  const urlParams = new URLSearchParams(window.location.search);
  const fundIdParam = urlParams.get('fundId');

  const [filterSetFromSearchParams, setFilterSetFromSearchParams] =
    useState(false);

  useEffect(() => {
    if (!tranches?.isFetching && tranches?.data && !filterSetFromSearchParams) {
      const foundFund = tranches.data.find(
        (tranche) => tranche._id === fundIdParam,
      );
      if (foundFund) {
        handleChangeFilters({ fundId: foundFund?._id });
        setFilterSetFromSearchParams(true);
      }
    }
  }, [
    tranches?.data,
    filters.fundId,
    tranches?.isFetching,
    filterSetFromSearchParams,
  ]);

  const debouncedSearchTerm = useDebounce(filters.searchTerm, 400);
  const params = {
    page: pagination.page === 0 ? 1 : pagination.page,
    perPage: pagination.rowsPerPage,
    type: pagination.order,
    keyName: pagination.orderBy,
    fundId: filters.fundId,
    search: debouncedSearchTerm,
    firmId: filters.firmId,
  };

  const { data, refetch, isFetching } = useInvestors({ params });
  const { exportInvestors, isExportLoading } = useInvestorsExport({
    params: { ...params, includeExportData: true },
  });
  const { mutate: updateInvestorDisability } = useInvestorDisability();

  const emailsDisabledLabel = (elm) => {
    if (isSuperAdmin) {
      const anyFirmIsDisabled = elm?.firmSpecificSettings?.some(
        (item) => item?.disableAllEmails,
      );
      return anyFirmIsDisabled ? 'Mixed' : 'Enabled';
    }
    if (elm?.firmSpecificSettings?.every((item) => item?.disableAllEmails)) {
      return 'Disabled';
    }
    return 'Enabled';
  };
  const columns = useMemo(
    () => [
      {
        label: 'Investor name',
        key: 'fullName',
      },
      { label: 'Email', key: 'email' },
      {
        label: 'Birth/incorporation',
        key: 'birthOrIncorporation',
        render: (elm) => dateofBirthToLabel(elm.birthOrIncorporation),
      },
      {
        label: 'KYC',
        key: 'kycStatusLabel',
      },
      {
        label: 'Created date',
        key: 'createdAt',
        render: (elm) => dateToLabel(elm?.createdAt),
      },

      {
        label: 'System emails',
        key: 'disableAllEmails',
        sort: false,
        render: emailsDisabledLabel,
      },
      {
        label: 'Last login date',
        key: 'lastLoginDate',
        render: (elm) => dateToLabel(elm?.lastLoginDate),
      },
      {
        label: 'Actions',
        key: 'actions',
        sort: false,
        render: (elm) => (
          <TableActions
            showAsDropdown
            actions={[
              {
                link: createInvestorViewRoute(elm._id),
                label: 'View',
                color: 'primary',
                icon: Visibility,
              },
              {
                visible: updatePermission,
                label: 'Edit',
                icon: Edit,
                color: 'primary',
                ...(elm?.isEditAllow || isSuperAdmin
                  ? {
                      disabled: false,
                      link: createInvestorEditRoute(elm._id),
                    }
                  : {
                      disabled: true,
                      tooltip:
                        'As this investor is connected with multiple funds, they cannot be edited manually. Please send edit requests to support@joinfurther.com.',
                    }),
              },
              {
                label: 'Delete',
                icon: Delete,
                color: 'error',
                onClick: () => deleteDisclosure.stageAction({ id: elm._id }),
                disabled:
                  !deletePermission ||
                  elm?.investmentData?.length ||
                  !elm?.isDeleteAllowed,
                tooltip: !elm?.isDeleteAllowed
                  ? 'When an investor has made a subscription, financial regulation prohibits account deletion.'
                  : undefined,
              },
              {
                icon: Email,
                label: 'Resend invite',
                onClick: () => handleResendPlatformInvite(elm._id),
                color: 'primary',
                tooltip: isSuperAdmin
                  ? 'Resending investor invites is only possible for firm admin users.'
                  : 'This resends the investor invite and enables future emails to this investor.',
                disabled: isSuperAdmin,
              },
            ]}
          />
        ),
      },
      isSuperAdmin && {
        label: (
          <span>
            Active?
            <Tooltip title="When an investor has made a subscription, financial regulation prohibits account deletion. If an active user no longer wishes to use the Further platform, switch the green toggle off to deactivate the user.">
              <IconButton size="small">
                <InfoOutlined fontSize="small" />
              </IconButton>
            </Tooltip>
          </span>
        ),
        sort: false,
        key: 'active',
        render: (elm) =>
          elm?.investmentData?.length ? (
            <Tooltip
              title={
                elm?.isDisabled
                  ? 'Click to activate the user. Activated users are able to log in and view their portal.'
                  : 'Click to deactivate the user. Deactivated users are unable to log in and cannot view their portal.'
              }
            >
              <Switch
                inputProps={{ 'aria-label': 'Switch' }}
                checked={!elm?.isDisabled}
                onChange={(event) => handleSwitchInvestorDisability(event, elm)}
              />
            </Tooltip>
          ) : (
            ''
          ),
      },
    ],
    [updatePermission, deletePermission, deleteDisclosure, isSuperAdmin],
  );

  const breadcrumbs = [
    { label: 'Dashboard', link: '/' },
    { label: 'Investors', isActive: true },
  ];

  const handleResendPlatformInvite = (id) => {
    resendInvite(id);
  };

  const handleSwitchInvestorDisability = (event, rec) => {
    if (!event.target.checked) {
      disabledDisclosure.stageAction({ id: rec._id });
    } else {
      updateInvestorDisability({
        investorId: rec._id,
        isDisabled: false,
      });
    }
  };

  const confirmInvestorDisability = () => {
    updateInvestorDisability({
      investorId: disabledDisclosure.actionProps.id,
      isDisabled: true,
    });
    disabledDisclosure.onClose();
  };

  const handleConfirmDelete = async () => {
    const investorId = deleteDisclosure.actionProps.id;
    await handleApiRequest(async (showSuccess) => {
      const response = await dispatch<any>(deleteInvestor(investorId));
      if (response) {
        showSuccess(response.data.responseMsg);
        deleteDisclosure.onClose();
        await refetch();
      }
    });
  };

  const exportExcelData = async () => {
    const { data, error: exportError } = await exportInvestors();
    if (exportError) {
      error((exportError as Error).message);
      return;
    }
    if (data) {
      downloadFile(data, `Investors-${Date.now()}.xlsx`);
    }
  };

  const fundOptions = [
    { label: 'No subscriptions', _id: 'no-investment' },
  ].concat(sortedTranches);

  const currentFirm = firms.data?.result?.find((firm) => firm._id === firmId);

  return (
    <PageContainer heading="Investors" breadcrumbs={breadcrumbs}>
      <PageContent>
        <PageFilters
          pageId="investor-list"
          buttons={[
            {
              visible: !isSuperAdmin,
              onClick: resendInvitesDisclosure.onOpen,
              loading: resendInvitesToInvestorsWithoutVisit.isPending,
              label: (
                <Tooltip title="Click to re-invite all active investors who have not previously logged in (excludes disabled accounts and investors without subscriptions).">
                  <ResendInvites>
                    <ContactMail />
                  </ResendInvites>
                </Tooltip>
              ),
            },
            {
              disabled: isFetching,
              loading: isExportLoading,
              onClick: exportExcelData,
              label: 'Export',
            },
            {
              link: INVESTOR_ADD,
              label: 'Add investor',
            },
            {
              label: 'Resend invites for selected investors',
              visible: !!selectedInvestors.length,
              loading: resendInvites.isPending,
              disabled: isSuperAdmin,
              tooltip: isSuperAdmin
                ? 'Resending investor invites is only possible for firm admin users.'
                : undefined,
              onClick: () =>
                resendInvites.mutate(
                  //@ts-expect-error
                  selectedInvestors.map((investor) => investor._id),
                ),
            },
          ]}
        >
          {isSuperAdmin && (
            <Filter>
              <FirmSelector
                firmId={filters.firmId}
                placeholder="Search by firm"
                onChange={(firmId) => {
                  handleChangeFilters({ firmId });
                }}
              />
            </Filter>
          )}
          <Filter>
            <Autocomplete
              key={sortedTranches?.length}
              id="fundId"
              options={fundOptions}
              getOptionLabel={(option) => option.label}
              value={
                fundOptions?.find((fund) => fund._id === filters.fundId) ?? null
              }
              filterSelectedOptions
              onChange={(_, newValue) => {
                handleChangeFilters({ fundId: newValue?._id });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Tranche"
                />
              )}
            />
          </Filter>
          <Filter>
            <TextField
              type="search"
              name="investorName"
              placeholder="Search by name or email"
              fullWidth
              value={filters.searchTerm}
              onChange={(event) => {
                handleChangeFilters({ searchTerm: event.target.value });
              }}
            />
          </Filter>
        </PageFilters>
        <GridContainer>
          <Grid size={12}>
            <Table
              //@ts-expect-error
              columns={columns}
              onRequestSort={pagination.handleRequestSort}
              order={pagination.order}
              orderBy={pagination.orderBy}
              tablebody={data?.result ?? []}
              onPageChange={pagination.handleChangePage}
              onRowsPerPageChange={pagination.handleChangeRowsPerPage}
              page={pagination.page}
              rowsPerPage={pagination.rowsPerPage}
              count={data?.total || 0}
              pagination={true}
              loading={isFetching}
              variant="nohover"
              selectable
              onSelectionChange={setSelectedInvestors}
            />
          </Grid>
        </GridContainer>
      </PageContent>
      <AlertDialog
        open={deleteDisclosure.isOpen}
        onClose={deleteDisclosure.onClose}
        onConfirm={handleConfirmDelete}
        title={'Are you sure you wish to delete this investor?'}
        content={
          <p>
            This cannot be undone and all information relating to the investor
            will be lost.
          </p>
        }
        btnLabels={{
          cancel: 'Go back',
          confirm: 'Delete investor',
        }}
      />
      <AlertDialog
        open={disabledDisclosure.isOpen}
        onClose={disabledDisclosure.onClose}
        onConfirm={confirmInvestorDisability}
        title={'Are you sure you wish to deactivate this investor?'}
        content={
          <p>
            Deactivating this user will prevent them from being able to log in
            to the platform, make subscriptions or access their investor portal.
          </p>
        }
        btnLabels={{
          cancel: 'Go back',
          confirm: 'Deactivate investor',
        }}
      />
      <AlertDialog
        open={resendInvitesDisclosure.isOpen}
        onClose={resendInvitesDisclosure.onClose}
        onConfirm={() => {
          resendInvitesToInvestorsWithoutVisit.mutate();
          resendInvitesDisclosure.onClose();
        }}
        title="Are you sure?"
        content={
          <p>
            This will resend email invites to all investors that have not yet
            logged in to the {currentFirm?.firmName} portal and will activate
            system emails for these users. Re-sending invites cannot be undone.
          </p>
        }
        btnLabels={{
          cancel: 'Go back',
          confirm: 'Proceed',
        }}
      />
    </PageContainer>
  );
};
export default Investor;

import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Api } from 'further-types/upload-shareholding';
import { api } from 'lib/httpClient';

async function fetchAllocations({ queryKey }) {
  const [, params] = queryKey;
  const { data } = await api.get(`upload-shareholding`, {
    params,
  });

  return data.data;
}

export function useAllocations({
  params,
}: {
  params: Api.GetUploadShareholdingQueryParams;
}) {
  return useQuery({
    queryKey: ['allocations', { ...params }],
    queryFn: fetchAllocations,
    placeholderData: keepPreviousData,
    staleTime: 10 * 60 * 1000,
  });
}

import CalendarDay from './calendar-day';

export interface DateRange {
  startDate?: Date;
  endDate?: Date;
}

export const getDateRange = (dateRange?: DateRange) => {
  if (!dateRange?.startDate || !dateRange.endDate) {
    return undefined;
  }
  return {
    startDate: new CalendarDay(dateRange.startDate),
    endDate: new CalendarDay(dateRange.endDate),
  };
};

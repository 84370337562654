import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Divider } from '@mui/material';
import { pluralize } from 'utils/pluralize';
import PageContainer from 'components/PageContainer';
import InvestorSearch from './InvestorSearch';
import InvestorWithdrawalForm from './InvestorWithdrawalForm';
import QueuedWithdrawalsTable from './QueuedWithdrawalsTable';
import { useLocation } from 'react-router-dom';
import AlertDialog from 'components/AlertDialog';
import { useGetRole } from 'hooks/ui/useGetRole';
import { createWithdrawalRequests } from '@redux/actions/Withdrawals';
import { FirmSelector } from 'components/FirmSelector';
import FullPageSuccess from 'components/FullPageSuccess';
import { WITHDRAWALS } from 'adminConstants/routes';
import FieldRow from 'components/FormElements/FieldRow';
import { useNotification } from 'hooks/ui/useNotification';
import { useDisclosure } from 'further-ui/hooks';
import NoBankAccountWithdrawalModal from './NoBankAccountWithdrawalModal';
import PageContent from 'components/PageContent';
import RecordView from 'components/RecordView';
import PageSection from 'components/PageSection';
import ButtonGroup from 'components/ButtonGroup';
import Button from 'components/Button';

const RequestWithdrawals: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { error } = useNotification();
  const query = new URLSearchParams(location.search);
  const [investorIdInWithdrawalForm, setInvestorIdInWithdrawalForm] = useState(
    query.get('investorId') || null,
  );
  const [queuedWithdrawals, setQueuedWithdrawals] = useState<Array<any>>([]);
  const [isCancelConfirmOpen, setIsCancelConfirmOpen] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  // firm ID is required so that we retrieve the selected investors correct cash balance.
  // If user is a super admin they'll need to first select a firm from the dropdown
  const { isSuperAdmin, firmId: usersFirmId } = useGetRole();
  const [firmId, setFirmId] = useState(usersFirmId);
  const [isSubmittingWithdrawals, setIsSubmittingWithdrawals] = useState(false);
  const {
    isOpen: shouldDisplayPrompt,
    onOpen: displayPrompt,
    onClose: hidePrompt,
  } = useDisclosure();

  const addInvestorToWithdrawalQueue = (investor, withdrawalDetails) => {
    setQueuedWithdrawals([
      ...queuedWithdrawals,
      { ...investor, withdrawalDetails },
    ]);
  };

  const removeWithdrawalFromQueue = (investorId) => {
    setQueuedWithdrawals(
      queuedWithdrawals.filter((qw) => qw._id !== investorId),
    );
  };

  const checkAndSubmitWithdrawalRequests = async () => {
    if (
      queuedWithdrawals.some(
        (queuedWithdrawal) =>
          !queuedWithdrawal.bankDetails.length ||
          !queuedWithdrawal.bankDetails[0].bankAccountNumber,
      )
    ) {
      displayPrompt();
    } else {
      await submitWithdrawalRequests();
    }
  };

  const submitWithdrawalRequests = async () => {
    hidePrompt();
    setIsSubmittingWithdrawals(true);
    const requestBody = queuedWithdrawals.map((qw) => ({
      investorId: qw._id,
      firmId,
      amount: qw.withdrawalDetails.amountToWithdraw,
      withdrawalFee: qw.withdrawalDetails.withdrawalFee,
      notes: qw.withdrawalDetails.note,
      attachment: qw.withdrawalDetails.attachment?.key,
    }));
    try {
      await dispatch(createWithdrawalRequests(requestBody));

      setShowSuccess(true);
      setIsSubmittingWithdrawals(false);
    } catch (e: any) {
      error(e.response.data.responseMsg);
      setIsSubmittingWithdrawals(false);
    }
  };

  if (showSuccess) {
    return (
      <FullPageSuccess
        title="Your withdrawal requests have been successfully processed"
        additionalText="To export these to your custodian, please go to the Withdrawal Record by clicking on the button below."
        proceedText="Review Withdrawals"
        proceedLink={WITHDRAWALS}
      />
    );
  }

  return (
    <PageContainer heading="Withdrawals: Bulk withdrawal request">
      <PageContent>
        <PageSection title="Add a new withdrawal request" withSpacing>
          <RecordView size="small">
            {isSuperAdmin && (
              <FieldRow>
                <FirmSelector
                  placeholder="Select a firm"
                  firmId={firmId}
                  onChange={(selectedFirmId) => {
                    setFirmId(selectedFirmId);
                    setQueuedWithdrawals([]);
                  }}
                />
              </FieldRow>
            )}
            {firmId && (
              <InvestorSearch
                onInvestorSelect={(id) => setInvestorIdInWithdrawalForm(id)}
                selectedInvestorId={investorIdInWithdrawalForm}
                queuedWithdrawals={queuedWithdrawals}
                firmId={firmId}
              />
            )}
          </RecordView>
          <RecordView>
            {firmId && (
              <>
                <Divider />
                <InvestorWithdrawalForm
                  investorId={investorIdInWithdrawalForm}
                  addInvestorToWithdrawalQueue={(
                    investor,
                    withdrawalDetails,
                  ) => {
                    addInvestorToWithdrawalQueue(investor, withdrawalDetails);
                    setInvestorIdInWithdrawalForm(null);
                  }}
                  queuedWithdrawals={queuedWithdrawals}
                  firmId={firmId}
                />
              </>
            )}
            {queuedWithdrawals.length > 0 && (
              <PageSection title="Pending withdrawal requests" withSpacing>
                <QueuedWithdrawalsTable
                  queuedWithdrawals={queuedWithdrawals}
                  removeWithdrawalFromQueue={removeWithdrawalFromQueue}
                  firmId={firmId}
                />
                <ButtonGroup>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={checkAndSubmitWithdrawalRequests}
                    loading={isSubmittingWithdrawals}
                  >
                    Submit {pluralize('request', queuedWithdrawals.length)}
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setIsCancelConfirmOpen(true)}
                    loading={isSubmittingWithdrawals}
                  >
                    Cancel
                  </Button>
                </ButtonGroup>
              </PageSection>
            )}
          </RecordView>
        </PageSection>
      </PageContent>
      <NoBankAccountWithdrawalModal
        isOpen={shouldDisplayPrompt}
        onConfirm={submitWithdrawalRequests}
        onCancel={hidePrompt}
      />
      <AlertDialog
        open={isCancelConfirmOpen}
        onClose={() => setIsCancelConfirmOpen(false)}
        onConfirm={() => {
          setQueuedWithdrawals([]);
          setIsCancelConfirmOpen(false);
        }}
        title="Cancel all pending withdrawal requests?"
        content="This will remove all pending withdrawal requests from the table. Are you sure you want to continue?"
        cancelBtnProps={{ variant: 'outlined', color: 'primary' }}
        btnLabels={{
          cancel: 'Go back',
          confirm: 'OK',
        }}
      />
    </PageContainer>
  );
};

export default RequestWithdrawals;

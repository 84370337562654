import PageContainer from 'components/PageContainer';
import { CircularProgress, Theme, useTheme } from '@mui/material';
import { useGetRole } from 'hooks/ui/useGetRole';
import { useFunds } from 'hooks/data/fund/useFunds';
import PageContent from 'components/PageContent';
import { Link } from 'react-router-dom';
import { CheckCircle, Cancel, Archive, Unarchive } from '@mui/icons-material';
import { numberToCurrencyString, dateToLabel } from 'further-ui/utils';
import InvestmentTable from './components/InvestmentTable';
import { TableActions } from 'components/Table';
import { InvestmentFilters } from './components/InvestmentFilters';
import { InvestmentArchiveStatus } from 'further-types/investment';
import useFilters from 'hooks/ui/useFilters';
import { InvestmentCompletionStatus } from 'further-types/investment';
import { usePagination } from 'hooks/ui/usePagination';
import formatExitedAtToLabel from './utils/format-exited-at-to-label';
import useUnArchiveIncompleteInvestment from './hooks/useUnArchiveIncompleteInvestment';
import useExcelExport from './hooks/useExcelExport';
import Row from 'components/Layout/Row';
import useIncompleteInvestments from 'hooks/data/investment/useIncompleteInvestments';
import useArchiveIncompleteInvestment from 'hooks/data/investment/useArchiveIncompleteInvestment';
import { useEffect, useState } from 'react';
import { useDebounce } from 'usehooks-ts';

const breadcrumbs = [
  { label: 'Dashboard', link: '/' },
  { label: 'Incomplete Subscriptions', isActive: true },
];

const getColumns = (
  theme: Theme,
  archiveInvestment: (id: string) => void,
  unarchiveInvestment: (id: string) => void,
  setProcessingId: (id: string) => void,
  processingId: string | null,
) => [
  {
    label: 'Investor name',
    key: 'fullName',
    sort: true,
    render: (elm) => (
      <Link
        to={`/view-investor/${elm?.investor._id}`}
        style={{ fontWeight: 'bold', color: theme.palette.text.green }}
      >
        {elm?.fullName}
      </Link>
    ),
  },
  {
    label: 'Email',
    key: 'email',
    sort: true,
    render: (elm) => elm?.investor?.email,
  },
  {
    label: 'Firm',
    key: 'firmName',
    sort: true,
    render: (elm) => elm?.firmName,
  },
  {
    label: 'Tranche',
    sort: true,
    key: 'fundName',
    render: (elm) => elm?.fundName,
  },
  {
    label: 'Date',
    key: 'createdAt',
    sort: true,
    render: (elm) => dateToLabel(elm?.createdAt),
  },
  {
    label: 'Exited',
    key: 'step',
    sort: false,
    render: (elm) => formatExitedAtToLabel(elm),
  },
  {
    label: 'Advised',
    key: 'adviserId',
    sort: false,
    render: (elm) => (elm?.adviserId ? 'Yes' : 'No'),
  },
  {
    label: 'Subscription',
    sort: true,
    key: 'investmentAmount',
    render: (elm) => numberToCurrencyString(elm?.investmentAmount),
  },
  {
    label: 'Completed',
    key: 'completed',
    centered: true,
    sort: false,
    render: (elm) => (
      <Row centered justify="center">
        {elm.subsequentInvestment && elm.subsequentInvestment._id ? (
          <Link
            to={`/edit-investment/${elm.subsequentInvestment._id}`}
            style={{
              color: theme.palette.text.green,
            }}
          >
            <CheckCircle fontSize="small" />
          </Link>
        ) : (
          <Cancel color="error" fontSize="small" />
        )}
      </Row>
    ),
  },
  {
    label: 'Actions',
    key: 'actions',
    sort: false,
    centered: true,
    render: (row) => (
      <Row centered justify="center">
        <TableActions
          actions={[
            processingId === row._id
              ? {
                  icon: () => <CircularProgress size={16} />,
                }
              : row.deletedAt
              ? {
                  label: 'Unarchive',
                  tooltip: 'Unarchive',
                  onClick: (event) => {
                    event.stopPropagation();
                    unarchiveInvestment(row._id);
                    setProcessingId(row._id);
                  },
                  icon: Unarchive,
                }
              : {
                  label: 'Archive',
                  tooltip: 'Archive',
                  color: 'error',
                  onClick: (event) => {
                    event.stopPropagation();
                    archiveInvestment(row._id);
                    setProcessingId(row._id);
                  },
                  icon: Archive,
                },
          ]}
        />
      </Row>
    ),
  },
];

const IncompleteInvestments: React.FC = () => {
  const { firmId } = useGetRole();
  const theme = useTheme();

  const {
    orderBy,
    order,
    page,
    rowsPerPage,
    toFirstPage,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
  } = usePagination({
    id: 'incomplete-investments-table',
    orderBy: 'createdAt',
    order: 'desc',
  });

  const { filters, handleChangeFilters } = useFilters<{
    fundId: string | null;
    investorName: string;
    status: InvestmentCompletionStatus;
    archiveStatus: InvestmentArchiveStatus;
  }>('incomplete-investments-table', {
    defaultFilters: {
      fundId: null,
      investorName: '',
      status: InvestmentCompletionStatus.All,
      archiveStatus: InvestmentArchiveStatus.NotArchived,
    },
    onFiltersChange: () => toFirstPage(),
  });

  const { mutate: archiveInvestment, isError: archiveError } =
    useArchiveIncompleteInvestment();
  const { mutate: unarchiveInvestment, isError: unarchiveError } =
    useUnArchiveIncompleteInvestment();

  const [processingId, setProcessingId] = useState<string | null>(null);

  const { tranches } = useFunds({ firmId });

  const [searchTerm, setSearchTerm] = useState(filters.investorName);
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  const {
    data: incompleteInvestments,
    isLoading,
    isFetching,
  } = useIncompleteInvestments({
    page,
    firmId: firmId || '',
    fundId: filters.fundId || '',
    perPage: rowsPerPage,
    order,
    orderBy,
    investorName: debouncedSearchTerm,
    status: filters.status,
    archiveStatus: filters.archiveStatus,
  });

  useEffect(() => {
    if ((!isFetching && processingId) || archiveError || unarchiveError) {
      setProcessingId(null);
    }
  }, [isFetching, archiveError, unarchiveError]);

  const { exportExcelSheet, isExporting } = useExcelExport({
    firmId: firmId || '',
    fundId: filters.fundId || '',
    order,
    orderBy,
    investorName: filters.investorName,
    status: filters.status,
    archiveStatus: filters.archiveStatus,
    isExport: true,
  });

  const columns = getColumns(
    theme,
    archiveInvestment,
    unarchiveInvestment,
    setProcessingId,
    processingId,
  );

  const shouldReload = isFetching && !processingId;

  const handleFiltersChange = (newFilters: Partial<typeof filters>) => {
    setSearchTerm(newFilters.investorName ?? '');
    handleChangeFilters(newFilters);
  };

  return (
    <PageContainer heading="Incomplete Subscriptions" breadcrumbs={breadcrumbs}>
      <PageContent>
        <InvestmentFilters
          exportExcelSheet={exportExcelSheet}
          isExporting={isExporting}
          filters={{
            ...filters,
            investorName: searchTerm,
          }}
          handleChangeFilters={handleFiltersChange}
          tranchesList={tranches.data}
        />
        <InvestmentTable
          columns={columns}
          data={
            incompleteInvestments || {
              result: [],
              total: 0,
              perPage: rowsPerPage,
              totalInvestment: 0,
            }
          }
          paginationProps={{
            order,
            orderBy,
            page,
            rowsPerPage,
            handleChangePage,
            handleChangeRowsPerPage,
            handleRequestSort,
          }}
          isLoading={isLoading || shouldReload}
        />
      </PageContent>
    </PageContainer>
  );
};

export default IncompleteInvestments;

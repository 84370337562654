import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Grid from '@mui/material/Grid2';
import { CurrencyCode } from 'further-types/lp/vehicle';
import { AppSelectBox, FieldRow } from 'components/FormElements';
import AppTextInput from 'components/FormElements/AppTextInput';
import CurrencySelector from 'components/CurrencySelector';
import { Typography, styled } from '@mui/material';
import { AssetType } from 'further-types/lp/asset';
import CountrySelector from 'components/CountrySelector';

const InfoText = styled(Typography)({
  fontSize: '14px',
  lineHeight: '22px',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  color: 'text.secondary',
});

const AssetDetailsForm: FC = () => {
  const { control, setValue } = useFormContext();

  return (
    <Grid container spacing={6}>
      <Grid size={{ xs: 12 }}>
        <FieldRow title="Asset type" centerTitle>
          <Controller
            name={`asset.assetType`}
            control={control}
            defaultValue={AssetType.Company}
            render={({ field: { ref, ...rest }, fieldState: { error } }) => (
              <AppSelectBox
                {...rest}
                fullWidth
                disabled
                data={[{ id: AssetType.Company, title: 'Company' }]}
                error={!!error}
                helperText={error?.message}
                placeholder="Select asset type"
              />
            )}
          />
        </FieldRow>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <FieldRow title="Trading name*" centerTitle>
          <Controller
            name="asset.name"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <AppTextInput
                {...field}
                error={!!error}
                helperText={error?.message}
                placeholder="Enter trading name, e.g. Acme"
              />
            )}
          />
        </FieldRow>
      </Grid>

      <Grid size={{ xs: 12 }}>
        <FieldRow title="Full legal name*" centerTitle>
          <Controller
            name="asset.fullLegalName"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <AppTextInput
                {...field}
                error={!!error}
                helperText={error?.message}
                placeholder="Enter legal name, e.g. Acme Software Limited"
              />
            )}
          />
        </FieldRow>
      </Grid>

      <Grid size={{ xs: 12 }}>
        <FieldRow title="Base currency*" centerTitle>
          <Controller
            name="asset.baseCurrency"
            control={control}
            defaultValue={CurrencyCode.GBP}
            render={({ field: { ref, ...rest }, fieldState: { error } }) => (
              <CurrencySelector
                {...rest}
                error={error ? { message: error.message } : undefined}
                placeholder="Select base currency"
              />
            )}
          />
        </FieldRow>
      </Grid>

      <Grid size={{ xs: 12 }}>
        <FieldRow title="Registered number" centerTitle>
          <Controller
            name="asset.registeredNumber"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <AppTextInput
                {...field}
                error={!!error}
                helperText={error?.message}
                placeholder="Registered number"
              />
            )}
          />
        </FieldRow>
      </Grid>

      <Grid size={{ xs: 12 }}>
        <FieldRow title="Registration country" centerTitle>
          <Controller
            name="asset.registrationCountry"
            control={control}
            defaultValue=""
            render={({ field: { ref, value }, fieldState: { error } }) => (
              <CountrySelector
                ref={ref}
                value={value}
                name="asset.registrationCountry"
                placeholder="Select registration country"
                error={error}
                onChange={(_, option) => {
                  setValue('asset.registrationCountry', option?.code || '');
                }}
              />
            )}
          />
        </FieldRow>
      </Grid>

      <Grid size={{ xs: 12 }}>
        <FieldRow title="Registered address line 1" centerTitle>
          <Controller
            name="asset.registeredAddressLine1"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <AppTextInput
                {...field}
                error={!!error}
                helperText={error?.message}
                placeholder="Enter registered address 1"
              />
            )}
          />
        </FieldRow>
      </Grid>

      <Grid size={{ xs: 12 }}>
        <FieldRow title="Registered address line 2" centerTitle>
          <Controller
            name="asset.registeredAddressLine2"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <AppTextInput
                {...field}
                error={!!error}
                helperText={error?.message}
                placeholder="Enter registered address 2"
              />
            )}
          />
        </FieldRow>
      </Grid>

      <Grid size={{ xs: 12 }}>
        <FieldRow title="Registered address town/city" centerTitle>
          <Controller
            name="asset.registeredAddressTownCity"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <AppTextInput
                {...field}
                error={!!error}
                helperText={error?.message}
                placeholder="Enter registered address town/city"
              />
            )}
          />
        </FieldRow>
      </Grid>

      <Grid size={{ xs: 12 }}>
        <FieldRow title="Registered address postal code" centerTitle>
          <Controller
            name="asset.registeredAddressPostalCode"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <AppTextInput
                {...field}
                error={!!error}
                helperText={error?.message}
                placeholder="Enter registered address postal code"
              />
            )}
          />
        </FieldRow>
      </Grid>

      <Grid size={{ xs: 12 }}>
        <FieldRow title="Registered address country" centerTitle>
          <Controller
            name="asset.registeredAddressCountry"
            control={control}
            defaultValue=""
            render={({
              field: { ref, value, ...rest },
              fieldState: { error },
            }) => (
              <CountrySelector
                {...rest}
                ref={ref}
                value={value}
                name="asset.registeredAddressCountry"
                placeholder="Select registered address country"
                error={error}
                onChange={(_, option) => {
                  setValue(
                    'asset.registeredAddressCountry',
                    option?.code || '',
                  );
                }}
              />
            )}
          />
        </FieldRow>
      </Grid>
      <InfoText>
        To add other asset information, including portal data such as a logo
        image or website / social media URL, navigate to the Assets section once
        this investment is processed.
      </InfoText>
    </Grid>
  );
};

export default AssetDetailsForm;

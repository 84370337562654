import RecordView from 'components/RecordView';
import PageSection from 'components/PageSection';
import Spacing from 'components/Spacing';
import ButtonGroup from 'components/ButtonGroup';
import Button from 'components/Button';
import Grid from '@mui/material/Grid2';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { useNotification } from 'hooks/ui/useNotification';
import useHoldingClasses from '../hooks/useHoldingClasses';
import { investment as rules } from 'further-ui/validations';
import AllocationsTable from 'components/AllocationsTable';
import api from 'lib/trpcClient';
import { ActionRef, ActionStatus } from 'further-types/lp/action';
import { CurrencyCode, FxDataFeed } from 'further-types/lp/vehicle';
import { FieldRow } from 'components/FormElements';
import Typography from '@mui/material/Typography';
import {
  CalendarDay,
  numberToCurrencyString,
  numberToDisplayString,
} from 'further-ui/utils';
import useTransactionTypeSelectorOptions from 'hooks/form/useTransactionTypeSelectorOptions';
import PageLoader from 'components/PageLoader';
import TransactionAllocator, {
  Transaction,
} from 'components/TransactionAllocator';
import { FundingSource, InvestmentTypes } from 'further-types/lp/investment';
import { AllocationRuleLabels, FundingSourceLabels } from 'further-ui/labels';

type ApproveTransactionProps = {
  onSuccess: (fundingSource: FundingSource) => void;
  onCancel: () => void;
  selectedVehicle: {
    _id: string;
    vehicleName: string;
    baseCurrency: CurrencyCode;
    fxDataFeed: FxDataFeed;
  };
};

const ApproveTransaction: React.FC<ApproveTransactionProps> = ({
  onSuccess,
  onCancel,
  selectedVehicle,
}) => {
  const notification = useNotification();
  const apiUtils = api.useUtils();

  const { control, watch, setValue, getValues, handleSubmit, setError } =
    useFormContext<rules.CreateInvestmentFormValues>();

  const {
    calculateTotalInvestmentAmount,
    calculateAverageUnitPrice,
    calculateTotalUnits,
    getCurrency,
  } = useHoldingClasses({
    control,
    watch,
    setValue,
    getValues,
    setError,
  });
  const totalInvestmentAmount = calculateTotalInvestmentAmount();
  const currency = getCurrency();

  const { transactionTypeOptions, isLoading: transactionTypesLoading } =
    useTransactionTypeSelectorOptions();

  const defaultTransactionTypes =
    api.action.getTransactionTypesForAction.useQuery({
      vehicleId: selectedVehicle._id,
      actionRef: ActionRef.Investment,
      actionType: InvestmentTypes.InvestmentCreated,
      amountIncVatTcy: totalInvestmentAmount,
      amountExVatTcy: totalInvestmentAmount,
    });

  const preservedTransactions = watch('transactions')?.map((transaction) => {
    const defaultType = defaultTransactionTypes.data?.find(
      (t) => t.transactionTypeId === transaction.transactionTypeId,
    );

    return {
      ...transaction,
      ...(defaultType || {}),
      id: transaction.id,
      actionId: transaction.actionId,
      transactionTypeId: transaction.transactionTypeId,
      amountExVatLcy: transaction.amountExVatLcy,
      amountExVatTcy: totalInvestmentAmount,
      amountIncVatLcy: transaction.amountIncVatLcy,
      amountIncVatTcy: totalInvestmentAmount,
      transactionCurrencyRate: transaction.transactionCurrencyRate,
    };
  });

  const defaultTransactions = preservedTransactions?.length
    ? preservedTransactions
    : defaultTransactionTypes.data || [];

  const upsertInvestmentMutation = api.lpInvestment.upsert.useMutation({
    onSuccess: () => {
      notification.success('Investment saved successfully.');
      apiUtils.lpInvestment.invalidate();
    },
    onError: (error) => {
      notification.error(error.message || 'Failed to approve investment.');
    },
  });

  const onTransactionChange = (transactions: Array<Transaction>) => {
    setValue(
      'transactions',
      transactions.map((t) => ({
        ...t,
        amountExVatLcy: t.amountExVatLcy ?? 0,
        amountIncVatLcy: t.amountIncVatLcy ?? 0,
        vatRate: 0,
      })),
    );
  };

  const onSaveDraft = handleSubmit(async (data) => {
    const response = await upsertInvestmentMutation.mutateAsync({
      ...data,
      status: ActionStatus.Draft,
    });

    const investmentId = response._id.toString();
    setValue('investmentId', investmentId);

    setValue(
      'transactions',
      response.transactions?.map((t) => ({
        ...t,
        id: t._id?.toString(),
        transactionTypeId: t.transactionTypeId.toString(),
        actionId: t.actionId?.toString(),
        operationType: t.operationType,
      })),
    );
  });

  const onSubmit: SubmitHandler<rules.CreateInvestmentFormValues> = async (
    data,
  ) => {
    const fundingSource = getValues('fundingSource');
    if (!fundingSource) {
      notification.error('Funding source is required.');
      return;
    }

    await upsertInvestmentMutation.mutateAsync({
      ...data,
      status: ActionStatus.Approved,
    });

    onSuccess(fundingSource);
  };

  if (transactionTypesLoading) {
    return <PageLoader />;
  }

  if (!transactionTypeOptions.length) {
    return (
      <Typography>
        To set up account mappings, please first create some transaction types.
      </Typography>
    );
  }

  return (
    <RecordView>
      <Spacing size="lg">
        <form>
          <Spacing>
            <Grid container spacing={{ xs: 0, md: 6 }}>
              <Grid size={{ lg: 8, xs: 12 }}>
                <PageSection title="Transaction summary">
                  <Spacing>
                    <FieldRow title="Vehicle">
                      <Typography variant="body2">
                        {selectedVehicle.vehicleName}
                      </Typography>
                    </FieldRow>
                    <FieldRow title="Asset">
                      <Typography variant="body2">
                        {getValues('asset.name')}
                      </Typography>
                    </FieldRow>
                    <FieldRow title="Deal name">
                      <Typography variant="body2">
                        {getValues('dealName')}
                      </Typography>
                    </FieldRow>
                    <FieldRow title="Deal completion date">
                      <Typography variant="body2">
                        {getValues('dealCompletionDate')?.toLabel()}
                      </Typography>
                    </FieldRow>
                    <FieldRow title="Holding classe(s)">
                      <Typography variant="body2">
                        {getValues('holdingClasses')
                          .map((holdingClass) =>
                            holdingClass.isNewHoldingClass
                              ? holdingClass.name
                              : holdingClass.existingHoldingClassName,
                          )
                          .join(', ')}
                      </Typography>
                    </FieldRow>
                    <FieldRow title="Total investment amount">
                      <Typography variant="body2">
                        {numberToCurrencyString(totalInvestmentAmount, {
                          currency,
                        })}
                      </Typography>
                    </FieldRow>
                    <FieldRow title="Units">
                      <Typography variant="body2">
                        {numberToDisplayString(calculateTotalUnits())}
                      </Typography>
                    </FieldRow>
                    <FieldRow title="Average unit price">
                      <Typography variant="body2">
                        {numberToCurrencyString(calculateAverageUnitPrice(), {
                          currency,
                          unlimitedDp: true,
                        })}
                      </Typography>
                    </FieldRow>
                    <FieldRow title="Allocation rule">
                      <Typography variant="body2">
                        {
                          AllocationRuleLabels[
                            getValues('allocationRule') ?? ''
                          ]
                        }
                      </Typography>
                    </FieldRow>
                    <FieldRow title="Funding source">
                      <Typography variant="body2">
                        {FundingSourceLabels[getValues('fundingSource') ?? '']}
                      </Typography>
                    </FieldRow>
                  </Spacing>
                </PageSection>
              </Grid>
            </Grid>
            <Grid container>
              <Grid size={{ xs: 12 }}>
                <Spacing>
                  <PageSection title="A: Review allocations">
                    <AllocationsTable
                      inputAmount={totalInvestmentAmount}
                      inputLabel="investment"
                      isNotEditable={true}
                      currency={currency}
                      vehicleId={getValues('vehicleId')}
                      startingValues={getValues('allocations')}
                      startingAllocationRule={getValues('allocationRule')}
                      onAllocationsChange={(_, allocations, allocationRule) => {
                        setValue('allocationRule', allocationRule);
                        setValue(
                          'allocations',
                          allocations.map((allocation) => ({
                            ...allocation,
                            investorLabel: allocation.investorName,
                          })),
                        );
                      }}
                    />
                  </PageSection>
                  <PageSection title="B: Review account transactions">
                    <TransactionAllocator
                      transactionTypeOptions={transactionTypeOptions}
                      defaultTransactions={defaultTransactions}
                      onChange={onTransactionChange}
                      transactionCurrency={currency}
                      transactionDate={
                        getValues('dealCompletionDate') ??
                        new CalendarDay(new Date())
                      }
                      vehicle={selectedVehicle}
                    />
                  </PageSection>
                </Spacing>
              </Grid>
            </Grid>
          </Spacing>
        </form>
        <ButtonGroup>
          <Button variant="outlined" onClick={onCancel}>
            Previous step
          </Button>
          <Button
            variant="outlined"
            loading={upsertInvestmentMutation.isPending}
            onClick={onSaveDraft}
          >
            Save draft
          </Button>
          <Button
            variant="contained"
            color="primary"
            loading={upsertInvestmentMutation.isPending}
            onClick={handleSubmit(onSubmit, () => {
              notification.error('Please fill in all required fields.');
            })}
          >
            Save and approve
          </Button>
        </ButtonGroup>
      </Spacing>
    </RecordView>
  );
};

export default ApproveTransaction;

import Spacing from 'components/Spacing';
import PageSection from 'components/PageSection';
import { Controller } from 'react-hook-form';
import { AppTextInput, FieldRow } from 'components/FormElements';
import { investment as rules } from 'further-ui/validations';
import { useFormContext } from 'react-hook-form';
import { Typography } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import useHoldingClasses from '../hooks/useHoldingClasses';
type HoldingClassInfoFormProps = {
  index: number;
};

const HoldingClassInfoForm: React.FC<HoldingClassInfoFormProps> = ({
  index,
}) => {
  const { getValues, control, setValue, watch, setError } =
    useFormContext<rules.CreateInvestmentFormValues>();
  const { calculateUnitPrice } = useHoldingClasses({
    control,
    getValues,
    setValue,
    watch,
    setError,
  });
  const title = `Holding class (${index + 1})`;
  const className =
    getValues(`holdingClasses.${index}.name`) ||
    getValues(`holdingClasses.${index}.existingHoldingClassName`);

  return (
    <PageSection title={title}>
      <Spacing>
        <FieldRow title="Class name" centerTitle>
          <Typography variant="body2">{className}</Typography>
        </FieldRow>
        <FieldRow title="Investment amount" centerTitle>
          <Controller
            name={`holdingClasses.${index}.investmentAmount`}
            control={control}
            render={({
              fieldState: { error },
              field: { ref, onChange, ...rest },
            }) => (
              <NumericFormat
                {...rest}
                inputRef={ref}
                onValueChange={({ value }) => {
                  onChange(+value);
                  calculateUnitPrice(index);
                }}
                allowNegative={false}
                decimalScale={5}
                allowLeadingZeros={false}
                thousandSeparator={true}
                customInput={AppTextInput}
                error={!!error}
                helperText={error?.message?.toString()}
                placeholder="Enter investment amount"
              />
            )}
          />
        </FieldRow>
        <FieldRow
          title="Number of units"
          tooltipText="Add an estimated number of units if the actual number of units is unknown at the point of processing. This transaction can be edited after submission to include the actual number of units."
          centerTitle
        >
          <Controller
            name={`holdingClasses.${index}.numberOfUnits`}
            control={control}
            render={({
              fieldState: { error },
              field: { ref, onChange, ...rest },
            }) => (
              <NumericFormat
                {...rest}
                inputRef={ref}
                onValueChange={({ value }) => {
                  onChange(+value);
                  calculateUnitPrice(index);
                }}
                allowNegative={false}
                decimalScale={5}
                allowLeadingZeros={false}
                thousandSeparator={true}
                customInput={AppTextInput}
                error={!!error}
                helperText={error?.message?.toString()}
                placeholder="Enter number of units"
              />
            )}
          />
        </FieldRow>
        <FieldRow title="Unit price" centerTitle>
          <Controller
            name={`holdingClasses.${index}.unitPrice`}
            control={control}
            render={({
              fieldState: { error },
              field: { ref, onChange, ...rest },
            }) => (
              <NumericFormat
                {...rest}
                inputRef={ref}
                onValueChange={({ value }) => {
                  onChange(+value);
                }}
                allowNegative={false}
                decimalScale={10}
                allowLeadingZeros={false}
                thousandSeparator={true}
                customInput={AppTextInput}
                error={!!error}
                helperText={error?.message?.toString()}
                placeholder="Enter unit price"
              />
            )}
          />
        </FieldRow>
      </Spacing>
    </PageSection>
  );
};

export default HoldingClassInfoForm;

import React, { PropsWithChildren } from 'react';
import Box from '@mui/material/Box';
import PageBreadcrumbs, { BreadcrumbItem } from './PageBreadcrumbs';
import PageHeader from './PageHeader';
import Slide from '@mui/material/Slide';
import PageLoader from 'components/PageLoader';

interface PageContainerInterface {
  loading?: boolean;
  heading?: string;
  subheaderComponent?: React.ReactNode;
  breadcrumbs?: Array<BreadcrumbItem>;
  className?: string;
}

const PageContainer: React.FC<PropsWithChildren<PageContainerInterface>> = ({
  loading,
  heading,
  breadcrumbs,
  children,
  subheaderComponent,
  className,
}) => {
  return loading ? (
    <PageLoader />
  ) : (
    <Slide in={true} direction="up" mountOnEnter unmountOnExit>
      <Box width="100%" height="100%" className={className}>
        {(heading || breadcrumbs) && (
          <PageHeader
            heading={heading}
            breadcrumbComponent={
              breadcrumbs && <PageBreadcrumbs items={breadcrumbs} />
            }
            subheaderComponent={subheaderComponent}
          />
        )}
        {children}
      </Box>
    </Slide>
  );
};

export default PageContainer;

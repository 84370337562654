import { useCallback, useMemo } from 'react';
import { Control, UseFormWatch, UseFormSetValue } from 'react-hook-form';
import api from 'lib/trpcClient';
import { AssetType } from 'further-types/lp/asset';
import { investment as rules } from 'further-ui/validations';

export interface AssetOption {
  id: string;
  title: string;
}

export const useAsset = ({
  control,
  watch,
  setValue,
  vehicleId,
}: {
  control: Control<rules.CreateInvestmentFormValues>;
  watch: UseFormWatch<rules.CreateInvestmentFormValues>;
  setValue: UseFormSetValue<rules.CreateInvestmentFormValues>;
  vehicleId: string;
}) => {
  const selectedAssetId = watch('assetId');
  const isNewAsset = watch('isNewAsset') && !selectedAssetId;

  const { data: assets, isLoading } = api.asset.listAllAssets.useQuery({
    assetType: AssetType.Company,
    order: 'desc',
    orderBy: 'createdAt',
    vehicleId,
  });

  const assetOptions = useMemo<AssetOption[]>(
    () =>
      assets?.result.map((asset) => ({
        id: asset._id,
        title: asset.assetName,
      })) ?? [],
    [assets],
  );

  const resetAssetDropdown = useCallback(() => {
    setValue('assetId', '');
    setValue('holdingClasses', []);
  }, [setValue]);

  return {
    control,
    selectedAssetId,
    isNewAsset,
    assetOptions,
    resetAssetDropdown,
    isLoading,
  };
};

import React, { useEffect } from 'react';
import FieldRow from 'components/FormElements/FieldRow';
import TextField from 'components/FormElements/AppTextInput';
import CkEditor from 'components/CkEditor';
import MultiFirmTrancheSelector from 'components/MultiFirmTrancheSelector';
import MultiFirmCompanySelector from 'components/MultiFirmCompanySelector';
import AppCheckbox from 'components/FormElements/AppCheckBox';
import { AppSelectBox } from 'components/FormElements';
import { DeliveryType } from 'further-types/custom-updates';
import useFileUpload from 'hooks/ui/useFileUpload';
import { UploadType } from 'further-types/files';
import { useNotification } from 'hooks/ui/useNotification';
import Dropzone from 'components/Dropzone';
import { useGetRole } from 'hooks/ui/useGetRole';
import getFirmsInTranches from 'helpers/firm/get-firms-in-tranches';
import { useReportingAccountManagers } from 'hooks/data/investorReporting/useReportingAccountManagers';
import RecordView from 'components/RecordView';
import { Divider } from '@mui/material';
import MultiInvestorSelector from 'components/MultiInvestorSelector';
import AllocationSelector from 'components/AllocationSelector';
import { Api } from 'further-types/custom-updates';

type Props = any; //TODO: define props

const AddCustomUpdateDetails: React.FC<Props> = ({
  classes,
  data,
  errorText,
  setData,
  mappedTranche,
  firmId,
}) => {
  const onChange = (event) => {
    const { value, name } = event.target;
    data[name] = value;
    setData({ ...data });
  };
  const { uploadFile } = useFileUpload();
  const { error } = useNotification();
  const { isSuperAdmin } = useGetRole();
  const { data: managers } = useReportingAccountManagers(
    firmId || data?.selectedFirmIds?.[0],
  );

  async function uploadMedia(file) {
    try {
      const { filePath } = await uploadFile(
        file,
        UploadType.CustomUpdateAttachments,
      );
      return filePath;
    } catch (_) {
      error(`Something went wrong - the file couldn't be uploaded.`);
    }
  }

  const handleChangeAttachment = async (files) => {
    await Promise.all(
      files.map(async (file) => {
        if (file.path) {
          const filePath = await uploadMedia(file);
          if (!filePath) return;

          setData({
            ...data,
            attachments: [
              ...data.attachments,
              {
                title: file?.name,
                url: filePath,
                preview: file?.preview,
              },
            ],
          });
        }
      }),
    );
  };

  const handleRemoveAttachment = (fileIdx) => {
    setData({
      ...data,
      attachments: data.attachments.filter(
        (_attachment, idx) => idx !== fileIdx,
      ),
    });
  };

  const handleUpdateSendToAllUsers = (
    sendToAllUsers: boolean,
    sendToAllUsersWithNoSubscriptions: boolean,
    recipientFilter: Api.RecipientFilter,
  ) => {
    const { selectedFirmIds, selectedFirmNames } = getFirmsInTranches(
      mappedTranche.map((tranche) => tranche._id),
      mappedTranche,
    );

    setData({
      ...data,
      sendToAllUsers,
      sendToAllUsersWithNoSubscriptions,
      trancheId:
        sendToAllUsers || sendToAllUsersWithNoSubscriptions
          ? mappedTranche.map((tranche) => tranche._id)
          : [],
      selectedTrancheIds:
        sendToAllUsers || sendToAllUsersWithNoSubscriptions
          ? mappedTranche.map((tranche) => tranche._id)
          : [],
      companyId: [],
      allocationIds: [],
      investorIds: [],
      selectedFirmIds:
        sendToAllUsers ||
        sendToAllUsersWithNoSubscriptions ||
        recipientFilter === Api.RecipientFilter.Specific
          ? selectedFirmIds
          : [],
      selectedFirmNames:
        sendToAllUsers ||
        sendToAllUsersWithNoSubscriptions ||
        recipientFilter === Api.RecipientFilter.Specific
          ? selectedFirmNames
          : [],
      recipientFilter,
    });
  };

  useEffect(() => {
    if (!firmId && data.selectedFirmIds.length !== 1) {
      setData({ ...data, accountManager: [] });
    }
  }, [firmId, data.selectedFirmIds]);

  return (
    <RecordView>
      <FieldRow title="Select recipient filter">
        <AppSelectBox
          labelKey="label"
          valueKey="value"
          placeholder="Select a filter"
          displayEmpty
          data={[
            {
              label: 'By company and allocation',
              value: Api.RecipientFilter.Company,
            },
            { label: 'By tranche', value: Api.RecipientFilter.Tranche },
            ...(isSuperAdmin
              ? []
              : [
                  {
                    label: 'By specific investors',
                    value: Api.RecipientFilter.Specific,
                  },
                  { label: 'To all investors', value: Api.RecipientFilter.All },
                  {
                    label: 'To all investors without subscriptions',
                    value: Api.RecipientFilter.NoSubscriptions,
                  },
                ]),
          ]}
          error={errorText?.recipientFilter}
          helperText={errorText?.recipientFilter}
          value={data.recipientFilter}
          onChange={(event) => {
            handleUpdateSendToAllUsers(
              event.target.value === Api.RecipientFilter.All,
              event.target.value === Api.RecipientFilter.NoSubscriptions,
              event.target.value as Api.RecipientFilter,
            );
          }}
        />
      </FieldRow>

      {data.recipientFilter === Api.RecipientFilter.Company && (
        <>
          <FieldRow title="Select company">
            <MultiFirmCompanySelector
              handleCompanyMultiSelectChanges={(value) => {
                setData({
                  ...data,
                  ...value,
                  companyId: value.selectedCompanyIds,
                  allocationIds: [],
                });
              }}
              error={errorText?.companyId}
              disabled={data?.trancheId?.length || data?.sendToAllUsers}
              value={data?.companyId}
              key={`company-selector-${!!data?.sendToAllUsers}`}
            />
          </FieldRow>

          <FieldRow title="Select allocation">
            <AllocationSelector
              firmId={firmId}
              companyIds={data?.companyId}
              value={data?.allocationIds}
              onChange={(value) => {
                setData({ ...data, allocationIds: value });
              }}
              disabled={!data?.companyId?.length}
              selectAllAllocationsByDefault
            />
          </FieldRow>
        </>
      )}

      {data.recipientFilter === Api.RecipientFilter.Tranche && (
        <FieldRow title="Select tranche(s)">
          <MultiFirmTrancheSelector
            handleChangeSelectedTranches={(value) => {
              setData({
                ...data,
                ...value,
                trancheId: value.selectedTrancheIds,
              });
            }}
            trancheFilters={{ fundStatus: [0, 1] }}
            disabled={data?.companyId?.length || data?.sendToAllUsers}
            value={data?.trancheId}
            key={`tranche-selector-${!!data?.sendToAllUsers}`}
          />
        </FieldRow>
      )}

      {data.recipientFilter === Api.RecipientFilter.Specific && (
        <FieldRow title="Select specific investors">
          <MultiInvestorSelector
            onChange={(value) => {
              setData({ ...data, investorIds: value });
            }}
            error={errorText?.investorIds}
            value={data.investorIds}
            helperText={errorText?.investorIds}
          />
        </FieldRow>
      )}

      <Divider />

      {data.recipientFilter !== Api.RecipientFilter.Specific &&
        data.recipientFilter !== Api.RecipientFilter.NoSubscriptions && (
          <FieldRow title="Send to" centerTitle>
            <AppCheckbox
              label="Direct investors"
              key="includeDirectInvestors"
              checked={!!data.sendToDirectInvestors}
              onChange={(event) => {
                setData({
                  ...data,
                  sendToDirectInvestors: event.target.checked,
                });
              }}
              error={errorText?.sendTo}
              helperText={errorText?.sendTo}
            />
            <AppCheckbox
              label="Advised investors"
              key="includeAdvisedInvestors"
              checked={!!data.sendToAdvisedInvestors}
              onChange={(event) => {
                setData({
                  ...data,
                  sendToAdvisedInvestors: event.target.checked,
                });
              }}
            />
            <AppCheckbox
              label="Advisers"
              key="includeAdvisers"
              checked={!!data.sendToAdvisers}
              onChange={(event) => {
                setData({
                  ...data,
                  sendToAdvisers: event.target.checked,
                });
              }}
            />
            <AppCheckbox
              label="Organisations"
              key="includeOrganisations"
              checked={!!data.sendToOrganisations}
              onChange={(event) => {
                setData({
                  ...data,
                  sendToOrganisations: event.target.checked,
                });
              }}
            />
          </FieldRow>
        )}

      <FieldRow
        title="Delivery method"
        centerTitle
        tooltipText={
          data.recipientFilter === Api.RecipientFilter.Specific
            ? 'Custom reports to specific investors are currently only possible as emails.'
            : ''
        }
      >
        {data.recipientFilter !== Api.RecipientFilter.Specific ? (
          <AppSelectBox
            data={[
              { label: 'Email only', value: DeliveryType.Email },
              { label: 'Display in portal only', value: DeliveryType.Portal },
              {
                label: 'Email and display in portal',
                value: DeliveryType.EmailAndPortal,
              },
            ]}
            valueKey="value"
            labelKey="label"
            disabled={
              !(
                data.sendToDirectInvestors ||
                data.sendToAdvisedInvestors ||
                data.sendToAdvisers ||
                data.sendToOrganisations
              ) &&
              data.recipientFilter !== Api.RecipientFilter.All &&
              data.recipientFilter !== Api.RecipientFilter.NoSubscriptions &&
              data.recipientFilter !== Api.RecipientFilter.Specific
            }
            value={data.deliveryToInvestors}
            onChange={(event) => {
              setData({
                ...data,
                deliveryToInvestors: event.target.value,
              });
            }}
          />
        ) : (
          <span>Email only</span>
        )}
      </FieldRow>

      <FieldRow title="Subject line*" centerTitle>
        <TextField
          name="subject"
          placeholder="e.g. An update on Company X's performance"
          required
          fullWidth
          error={errorText?.subject}
          helperText={errorText?.subject}
          value={data.subject}
          onChange={onChange}
        />
      </FieldRow>
      <FieldRow title="Body title*" centerTitle>
        <TextField
          name="title"
          placeholder="e.g. Company X - an update"
          required
          fullWidth
          value={data.title}
          error={errorText?.title}
          helperText={errorText?.title}
          onChange={onChange}
        />
      </FieldRow>
      <FieldRow title="Body text*">
        Dear $investorfirstname,
        <CkEditor
          //@ts-expect-error
          name="emailBody"
          placeholder="e.g. An update on Company X’s performance"
          onChange={(value) => {
            setData({
              ...data,
              emailBody: value,
            });
          }}
          onBlur={(value) => {
            setData({
              ...data,
              emailBody: value,
            });
          }}
          value={data.emailBody}
        />
        <p className={classes.errorCk}>{errorText?.emailBody}</p>
      </FieldRow>

      <FieldRow title="Select sign-off manager*" topTitle>
        <AppSelectBox
          required
          data={managers?.accountManagers}
          valueKey="_id"
          labelKey="name"
          //@ts-expect-error
          renderValue={(value: { name: string }) => (
            <>{value.name || 'Select sign-off manager'}</>
          )}
          variant="outlined"
          disabled={
            !isSuperAdmin && !firmId && data.selectedFirmIds?.length !== 1
          }
          value={data.accountManager}
          error={!!errorText?.accountManager}
          helperText={errorText?.accountManager}
          onChange={(event) => {
            const value = event.target.value;
            setData({
              ...data,
              accountManager: managers?.accountManagers.find(
                (manager) => manager._id === value,
              ),
            });
          }}
        />
      </FieldRow>

      <FieldRow title="Manager's role*" topTitle>
        <TextField
          name="role"
          placeholder="e.g. Partner, Fund name"
          required
          disabled={
            !isSuperAdmin && !firmId && data.selectedFirmIds?.length !== 1
          }
          value={data.role}
          error={errorText?.role}
          helperText={errorText?.role}
          onChange={onChange}
        />
      </FieldRow>

      <FieldRow title="Upload attachment" topTitle>
        <Dropzone
          name="attachments"
          files={
            data.attachments.map((file) => ({
              fileName: file.title,
              preview: file.preview || file.url,
            })) ?? []
          }
          onDropFile={handleChangeAttachment}
          onRemove={handleRemoveAttachment}
          maxFiles={10}
          defaultAcceptGroup="filesAndImages"
        />
      </FieldRow>
    </RecordView>
  );
};

export default AddCustomUpdateDetails;

import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid2';
import GridContainer from 'components/GridContainer';
import PageContainer from 'components/PageContainer';
import PageContent from 'components/PageContent';
import { Step, StepLabel, Stepper } from '@mui/material';
import Spacing from 'components/Spacing';
import { useLayoutContext } from 'contexts/LayoutContext';
import FullPageSuccess from 'components/FullPageSuccess';
import StepCreateInvestment from './StepCreateInvestment';
import FormWrapper from './FormWrapper';
import CompleteAllocation from './CompleteAllocation';
import ApproveTransaction from './ApproveTransaction';
import { FundingSource } from 'further-types/lp/investment';
import PageLoader from 'components/PageLoader';

export enum Steps {
  CreateInvestment,
  CompleteAllocation,
  ApproveTransaction,
  SuccessCapitalCall,
  SuccessWorkingCapital,
}

const stepLabels = {
  [Steps.CreateInvestment]: 'Create investment',
  [Steps.CompleteAllocation]: 'Complete allocation',
  [Steps.ApproveTransaction]: 'Approve transaction',
};

const pageTitle = 'Investment: Process investment';

const CreateInvestment: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  const [activeStep, setActiveStep] = useState<Steps>(Steps.CreateInvestment);
  const {
    selectedVehicleId,
    vehicle: selectedVehicle,
    isVehicleLoading,
  } = useLayoutContext();

  const breadcrumbs = [
    { label: 'Dashboard' },
    { label: 'Investments', link: '/investments' },
    {
      label: 'Create investment',
      link: '/',
      isActive: true,
    },
  ];

  if (
    activeStep === Steps.SuccessCapitalCall ||
    activeStep === Steps.SuccessWorkingCapital
  ) {
    return (
      <PageContainer heading={pageTitle} breadcrumbs={breadcrumbs}>
        <FullPageSuccess
          title="Investment has been created successfully"
          additionalText={
            activeStep === Steps.SuccessWorkingCapital
              ? 'The General Ledger has been updated and this investment has been allocated from available working capital.'
              : 'The General Ledger has been updated and this investment will be available to add into the next capital call.'
          }
          proceedText="Process capital call"
          //TODO: change this to const
          proceedLink={`/investments/${id}`}
          secondaryText="Add another investment"
          secondaryLink="/investments/create"
        />
      </PageContainer>
    );
  }

  const loading = isVehicleLoading || !selectedVehicle;

  if (isVehicleLoading) {
    return <PageLoader />;
  }

  if (!selectedVehicle) {
    throw new Error('Vehicle not found.');
  }

  return (
    <PageContainer
      heading={pageTitle}
      loading={loading}
      breadcrumbs={breadcrumbs}
    >
      <PageContent>
        <Spacing size="lg">
          <GridContainer>
            <Grid size={{ sm: 12, md: 12, lg: 8 }}>
              <Stepper activeStep={activeStep}>
                {Object.values(stepLabels).map((stepLabel) => (
                  <Step key={stepLabel}>
                    <StepLabel>{stepLabel}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>
          </GridContainer>

          <FormWrapper
            vehicleId={selectedVehicleId ?? ''}
            baseCurrency={selectedVehicle?.baseCurrency}
          >
            {activeStep === Steps.CreateInvestment && (
              <StepCreateInvestment
                onSuccess={() => setActiveStep(Steps.CompleteAllocation)}
                selectedVehicle={selectedVehicle}
              />
            )}
            {activeStep === Steps.CompleteAllocation && (
              <CompleteAllocation
                onSuccess={() => setActiveStep(Steps.ApproveTransaction)}
                onCancel={() => setActiveStep(Steps.CreateInvestment)}
              />
            )}
            {activeStep === Steps.ApproveTransaction && (
              <ApproveTransaction
                selectedVehicle={selectedVehicle}
                onSuccess={(fundingSource: FundingSource) =>
                  setActiveStep(
                    fundingSource === FundingSource.CAPITAL_CALL
                      ? Steps.SuccessCapitalCall
                      : Steps.SuccessWorkingCapital,
                  )
                }
                onCancel={() => setActiveStep(Steps.CompleteAllocation)}
              />
            )}
          </FormWrapper>
        </Spacing>
      </PageContent>
    </PageContainer>
  );
};

export default CreateInvestment;

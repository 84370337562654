import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { round, sumBy } from 'lodash';
import { FormValues } from '../types';

const createFormSchema = (inputAmount: number) =>
  z.object({
    allocations: z
      .array(
        z.object({
          investorName: z.string(),
          totalCommitment: z.number(),
          closeDate: z.date(),
          allocationAmount: z.number(),
          allocationPercentage: z.number(),
          excludeFromAllocation: z.boolean(),
        }),
      )
      .refine((data) => {
        const totalAllocationAmount = sumBy(data, (item) =>
          Number(item.allocationAmount),
        );
        return round(totalAllocationAmount, 2) === inputAmount;
      }, 'Total allocation amount must match input amount'),
  });

const useAllocationsForm = (inputAmount: number) => {
  const { control, watch, trigger, setValue } = useForm<FormValues>({
    defaultValues: {
      allocations: [],
    },
    resolver: zodResolver(createFormSchema(inputAmount)),
    mode: 'onChange',
  });

  const values = watch('allocations');

  return {
    control,
    trigger,
    setValue,
    values,
  };
};

export default useAllocationsForm;

import { useRef } from 'react';
import { Box, Chip, styled, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { NumericFormat } from 'react-number-format';
import { sortBy } from 'lodash';
import TextField from 'components/FormElements/AppTextInput';
import AppSelectBox from 'components/FormElements/AppSelectBox';
import { type } from 'adminConstants/typeConstant';
import FieldRow from 'components/FormElements/FieldRow';
import { getFeeAccountingOptions } from 'utils/getFeeAccountingOptions';
import AppRadioButton from 'components/FormElements/AppRadioButton';
import useAddFeesAndDiscounts from 'hooks/data/investment/useAddFeesAndDiscounts';
import { useNotification } from 'hooks/ui/useNotification';
import AppDatePicker from 'components/FormElements/AppDatePicker';
import { FirmSelector } from 'components/FirmSelector';
import { useGetRole } from 'hooks/ui/useGetRole';
import ButtonGroup from 'components/ButtonGroup';
import RecordView from 'components/RecordView';
import { Row } from 'components/Layout';
import Button from 'components/Button';

const TryAgainButton = styled('button')(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '22px',
  color: theme.palette.primary.main,
  background: 'transparent',
  border: 'none',
  cursor: 'pointer',
  textDecoration: 'underline',
  fontFamily: theme.typography.fontFamily,
}));

const AddFeeAndDiscountForm = ({
  value,
  onChange,
  tranches,
  investments,
  allInvestmentsSelected,
  allTranchesSelected,
  errors,
  onUploadProcessed,
  onSubmit,
  isCalculatingFees,
  onChargeToInvestor,
  onDateChange,
  firmId,
  setFirmId,
  isFetchingTranches,
}) => {
  const { isSuperAdmin } = useGetRole();
  const {
    createFeesUploadFile,
    isCreatingFeesUploadFile,
    uploadFeesFile,
    isUploadingFeesFile,
  } = useAddFeesAndDiscounts();
  const { error } = useNotification();

  const inputFile = useRef(null);

  const sortedTranches = sortBy(
    (tranches ?? []).map((tranche) => ({
      _id: tranche._id,
      label: `${tranche.firmId.firmName} ${tranche.fundName}`,
    })) ?? [],
    (tranche) => tranche.label.toLowerCase(),
  );

  const handleFileDownload = async () => {
    if (!canGenerateUploadFile) {
      error(
        'You have to provide fee/discount name, date and select at least one tranche.',
      );
      return;
    }

    if (
      !(await createFeesUploadFile(
        value.fundIds,
        value.feeName,
        value.chargeTo,
        firmId,
      ))
    ) {
      error('Error when downloading file.');
    }
  };

  const handleInputMethodChange = (e) => {
    if (e.target.value === 'bulk') {
      handleFileDownload();
    }
    onChange(e);
  };

  const handleChargeToChange = (e) => {
    if (e.target.value === 'investor') {
      onChargeToInvestor();
    } else {
      onChange(e);
    }
  };

  const handleSubmitUpload = async (event) => {
    if (!value.date || isNaN(value.date.getTime())) {
      error('Invalid date provided.');
      return;
    }

    if (event.target.files && event.target.files.length) {
      try {
        const formData = new FormData();
        formData.append('createFeesFile', event.target.files[0]);
        formData.append('chargeTo', value.chargeTo);
        formData.append('date', value.date.toISOString());
        formData.append('firmId', firmId);

        const response = await uploadFeesFile(formData);
        if (response.status === 200) {
          onUploadProcessed(response.data.data);
        }
      } catch (err: any) {
        error(err.response?.data?.responseMsg ?? 'Error when uploading fees.');
      }
    }
  };

  const isInInvestorChargeMode = value.chargeTo === 'investor';

  const canGenerateUploadFile =
    value.feeName?.length && value.fundIds?.length && value.date;

  return (
    <RecordView>
      {isSuperAdmin && (
        <FieldRow title="Select firm" centerTitle>
          <FirmSelector firmId={firmId} onChange={setFirmId} />
        </FieldRow>
      )}
      <FieldRow title="Fee/discount name" centerTitle>
        <TextField
          required
          name="feeName"
          placeholder="e.g. Deal completion fees"
          fullWidth
          onChange={onChange}
          value={value?.feeName}
          error={!!errors.feeName}
          helperText={errors.feeName}
        />
      </FieldRow>
      <FieldRow title="Date" centerTitle>
        <AppDatePicker
          required
          name="date"
          fullWidth
          disableFuture
          onChange={(date) => {
            onDateChange(date ?? null);
          }}
          value={value?.date}
          error={!!errors.date}
          helperText={errors.date}
        />
      </FieldRow>
      <FieldRow title="Charge to" centerTitle>
        <AppRadioButton
          required
          key="investment"
          name="chargeTo"
          label="Subscription balance"
          value="investment"
          checked={value.chargeTo === 'investment'}
          onChange={handleChargeToChange}
          error={!!errors.chargeTo}
          helperText={errors.chargeTo}
        />
        <AppRadioButton
          key="investor"
          name="chargeTo"
          label="Investor balance"
          value="investor"
          checked={value.chargeTo === 'investor'}
          onChange={handleChargeToChange}
        />
      </FieldRow>
      <FieldRow title="Select tranches" centerTitle>
        <AppSelectBox
          required
          data={firmId ? sortedTranches : []}
          valueKey="_id"
          name="fundIds"
          multiple
          labelKey="label"
          variant="outlined"
          isCheckedAll={allTranchesSelected}
          onChange={onChange}
          value={value?.fundIds}
          error={!!errors.fundIds}
          helperText={errors.fundIds}
          disabled={isFetchingTranches || !firmId}
          //@ts-expect-error
          renderValue={(selected: Array<string>) => (
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                padding: '8px 0',
                gap: 4,
              }}
            >
              {allTranchesSelected ? (
                <Chip key={'all tranches'} label="All tranches" />
              ) : (
                selected.map((value) => (
                  <Chip
                    key={value}
                    label={
                      sortedTranches.find((tranche) => tranche._id === value)
                        .label
                    }
                  />
                ))
              )}
            </Box>
          )}
        />
      </FieldRow>
      <FieldRow title="Fee/discount input method" centerTitle>
        <AppRadioButton
          required
          key="individual"
          name="inputMethod"
          label="Select individual investors"
          value="individual"
          checked={value.inputMethod === 'individual'}
          onChange={handleInputMethodChange}
          error={!!errors.inputMethod}
          helperText={errors.inputMethod}
          disabled={!canGenerateUploadFile}
        />
        <AppRadioButton
          key="bulk"
          name="inputMethod"
          label="Bulk fee/discount import"
          value="bulk"
          checked={value.inputMethod === 'bulk'}
          onChange={handleInputMethodChange}
          disabled={!canGenerateUploadFile}
        />
      </FieldRow>
      {value.inputMethod === 'individual' ? (
        <>
          <FieldRow title="Select investors" centerTitle>
            <AppSelectBox
              required
              data={investments}
              valueKey="id"
              name="investmentIds"
              labelKey="label"
              variant="outlined"
              multiple
              error={!!errors.investmentIds}
              helperText={errors.investmentIds}
              value={value.investmentIds}
              isCheckedAll={allInvestmentsSelected}
              onChange={onChange}
              //@ts-expect-error
              renderValue={(selected: Array<string>) => (
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    padding: '8px 0',
                    gap: 4,
                  }}
                >
                  {allInvestmentsSelected ? (
                    <Chip key={'all investors'} label="All investors" />
                  ) : (
                    selected.map((value) => (
                      <Chip
                        key={value}
                        label={
                          investments.find((val) => val.id === value).label
                        }
                      />
                    ))
                  )}
                </Box>
              )}
            />
          </FieldRow>
          <FieldRow title="Charged or accrued" centerTitle>
            <Grid size={{ md: 6 }}>
              <AppSelectBox
                required
                data={getFeeAccountingOptions()}
                valueKey="value"
                name="feeAccounting"
                labelKey="label"
                variant="outlined"
                onChange={onChange}
                value={value?.feeAccounting}
                error={!!errors.feeAccounting}
                helperText={errors.feeAccounting}
                disabled={isInInvestorChargeMode}
              />
            </Grid>
          </FieldRow>
          <FieldRow title="Fee or discount?" centerTitle>
            <Grid size={{ md: 6 }}>
              <AppSelectBox
                required
                data={[
                  { label: 'Fee', value: type.feeTypes.fee },
                  { label: 'Discount', value: type.feeTypes.discount },
                ]}
                valueKey="value"
                name="feeType"
                labelKey="label"
                variant="outlined"
                onChange={onChange}
                value={value?.feeType}
                error={!!errors.feeType}
                helperText={errors.feeType}
              />
            </Grid>
          </FieldRow>
          <FieldRow
            title="Enter amount"
            centerTitle
            tooltipText="Where a percentage-based fee or discount is selected, this is calculated based on the relevant investor's initial subscription amount (not current subscription value)."
          >
            <Row centered spacing="sm">
              <NumericFormat
                required
                name="amount"
                placeholder="Enter figure"
                value={value?.amount}
                thousandSeparator={!value?.isPercentage}
                customInput={TextField}
                type="text"
                allowNegative={false}
                onChange={onChange}
                error={!!errors.amount}
                helperText={errors.amount}
              />
              <AppSelectBox
                required
                data={[
                  { label: '%', value: true },
                  { label: '£', value: false },
                ]}
                name="isPercentage"
                valueKey="value"
                labelKey="label"
                variant="outlined"
                onChange={onChange}
                value={value?.isPercentage}
                error={!!errors.isPercentage}
                helperText={errors.isPercentage}
                disabled={isInInvestorChargeMode}
              />
            </Row>
          </FieldRow>
          <FieldRow title="VAT" centerTitle>
            <Grid size={{ md: 6 }}>
              <AppSelectBox
                required
                data={[
                  { label: '20% VAT', value: 20 },
                  { label: 'No VAT', value: 0 },
                ]}
                name="vat"
                valueKey="value"
                labelKey="label"
                variant="outlined"
                onChange={onChange}
                value={value?.vat}
                error={!!errors.vat}
                helperText={errors.vat}
              />
            </Grid>
          </FieldRow>
        </>
      ) : (
        <>
          <Grid size={{ md: 12 }}>
            <Typography variant="body2">
              Please check your download folder for your fee import sheet, which
              enables you to add fees for investors within your chosen
              tranche(s). Please fill in the fee amounts and upload the sheet
              here. Form didn’t download?{' '}
              <TryAgainButton
                disabled={isCreatingFeesUploadFile}
                onClick={handleFileDownload}
              >
                Click here to try again.
              </TryAgainButton>
            </Typography>
          </Grid>
          <Grid size={{ md: 12 }}>
            <Button
              color="primary"
              variant="outlined"
              //@ts-expect-error
              onClick={() => inputFile.current?.click()}
              loading={isCreatingFeesUploadFile || isUploadingFeesFile}
            >
              Upload
              <input
                name="createFeesFile"
                type="file"
                ref={inputFile}
                hidden
                onChange={handleSubmitUpload}
                onClick={(e) => {
                  const { target = {} } = e || {};
                  // @ts-expect-error
                  target.value = '';
                }}
              />
            </Button>
          </Grid>
        </>
      )}
      <ButtonGroup>
        {value.inputMethod === 'individual' ? (
          <Button
            color="primary"
            variant="contained"
            onClick={onSubmit}
            disabled={isCalculatingFees || isFetchingTranches}
          >
            Proceed to Summary
          </Button>
        ) : null}
      </ButtonGroup>
    </RecordView>
  );
};

export default AddFeeAndDiscountForm;

import { Delete, Edit } from '@mui/icons-material';
import { Button } from '@mui/material';
import { deleteInvestment } from '@redux/actions/Investment';
import {
  createAddInvestmentTransferRoute,
  createInvestmentEditRoute,
} from 'adminConstants/routes';
import AlertDialog from 'components/AlertDialog';
import Heading from 'components/Heading';
import { Column } from 'components/Layout';
import PageHeader from 'components/PageHeader';
import ResponsiveActions from 'components/PageHeader/ResponsiveActions';
import Spacing from 'components/Spacing';
import { EIS3Download } from 'components/SVGIcons';
import Table, { FooterCell, FooterRow, TableActions } from 'components/Table';
import TooltipTotalValue from 'components/TooltipTotalValue';
import { useDisclosure } from 'further-ui/hooks';
import {
  dateToLabel,
  numberToCurrencyString,
  renderMultiple,
} from 'further-ui/utils';
import { useDownloadByInvestmentId } from 'hooks/data/eisWizard/useDownloadEisCertificatePdfs';
import { useInvestments } from 'hooks/data/investment/useInvestments';
import useApiRequestHandler from 'hooks/ui/useApiRequestHandler';
import { useGetRole } from 'hooks/ui/useGetRole';
import { usePagination } from 'hooks/ui/usePagination';
import { sumBy } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

type Props = {
  investorId: string;
};

const InvestmentsTable: React.FC<Props> = ({ investorId }) => {
  const dispatch = useDispatch();
  const pagination = usePagination();
  const deleteDisclosure = useDisclosure();
  const handleApiRequest = useApiRequestHandler();
  const { firmId, isSuperAdmin } = useGetRole();

  const downloadEisCerts = useDownloadByInvestmentId();
  const investments = useInvestments({
    params: {
      investorId,
      firmId,
      page: pagination.page,
      perPage: pagination.rowsPerPage,
      type: 'desc',
      keyName: 'investmentDate',
    },
  });

  const downloadAllEISCertificates = (investment) => {
    downloadEisCerts.download({
      investmentId: investment._id,
      fullName: investment.fullName,
      trancheName: `${investment.firmId.firmName}-${investment.fundId.fundName}`,
    });
  };

  const columns = [
    ...(isSuperAdmin
      ? [
          {
            label: 'Firm',
            key: 'firmName',
            sort: false,
            render: (elm) => elm?.firmId?.firmName,
          },
        ]
      : []),
    {
      label: 'Tranche',
      key: 'fundName',
      sort: false,
      render: (elm) => elm?.fundId?.fundName,
    },
    {
      label: 'External subscription ID',
      key: 'externalInvestmentId',
      sort: false,
      render: (elm) => elm.externalInvestmentId,
    },
    {
      label: 'Subscription date',
      key: 'investmentDate',
      sort: false,
      render: (elm) => dateToLabel(elm.investmentDate),
    },
    {
      label: 'Subscription amount',
      key: 'investmentAmount',
      sort: false,
      render: (elm) => numberToCurrencyString(elm?.investmentAmount),
    },
    {
      label: 'Uninvested capital',
      key: 'uninvestedCapital',
      sort: false,
      render: (elm) =>
        numberToCurrencyString(
          elm?.investmentBalance?.deploymentSummary?.undeployedCommitment,
        ),
    },
    {
      label: <TooltipTotalValue />,
      key: 'totalCurrentValue',
      sort: false,
      render: (elm) =>
        numberToCurrencyString(elm?.investmentBalance?.investmentValue),
    },
    {
      label: 'Net realised value',
      key: 'netRealisedValue',
      sort: false,
      render: (elm) =>
        numberToCurrencyString(elm?.investmentBalance?.realisedValue?.total),
    },
    {
      label: 'Multiple on investment',
      key: 'multipleOnInvestment',
      sort: false,
      render: (elm) =>
        renderMultiple(
          elm?.investmentBalance?.valueChangesOnInitialInvestment
            ?.multipleOnInvestment,
        ),
    },
    {
      label: 'Actions',
      key: 'actions',
      sort: false,
      render: (elm) => (
        <TableActions
          showAsDropdown
          actions={[
            {
              link: createInvestmentEditRoute(elm._id),
              color: 'primary',
              disabled: downloadEisCerts.isLoading,
              icon: Edit,
              label: 'Edit',
            },
            {
              visible: elm?.isAllocationExists,
              label: 'Download all EIS certificates',
              onClick: () => downloadAllEISCertificates(elm),
              icon: EIS3Download,
            },
            {
              onClick: () => deleteDisclosure.stageAction(elm),
              label: 'Delete',
              icon: Delete,
              color: 'error',
            },
          ]}
        />
      ),
    },
  ];

  const handleConfirmDelete = async () => {
    const record = deleteDisclosure.actionProps;

    await handleApiRequest(async (showSuccess) => {
      const response = await dispatch<any>(deleteInvestment(record._id));
      if (response.status === 200) {
        showSuccess(response.data.responseMsg);
        investments.refetch();
      }
    });

    deleteDisclosure.onClose();
  };

  return (
    <Spacing>
      <PageHeader>
        <Heading variant="h2" noMargin>
          Subscriptions
        </Heading>
        <ResponsiveActions>
          <Link to={createAddInvestmentTransferRoute(investorId)}>
            <Button color="primary" variant="contained" size="small">
              Transfer subscriptions
            </Button>
          </Link>
        </ResponsiveActions>
      </PageHeader>
      <Table
        pagination={true}
        columns={columns}
        tablebody={investments.data?.result || []}
        variant="nohover"
        emptyMessage={'No subscriptions created for this investor yet.'}
        onPageChange={pagination.handleChangePage}
        onRowsPerPageChange={pagination.handleChangeRowsPerPage}
        page={pagination.page}
        rowsPerPage={pagination.rowsPerPage}
        count={investments.data?.total ?? 0}
        TableFooter={
          <tfoot>
            <FooterRow>
              <FooterCell colSpan={isSuperAdmin ? 4 : 3} />
              <FooterCell>
                {numberToCurrencyString(
                  sumBy(investments.data?.result, 'investmentAmount'),
                )}
              </FooterCell>
              <FooterCell>
                {numberToCurrencyString(
                  sumBy(
                    investments.data?.result,
                    'investmentBalance.deploymentSummary.undeployedCommitment',
                  ),
                )}
              </FooterCell>
              <FooterCell>
                {numberToCurrencyString(
                  sumBy(
                    investments.data?.result,
                    'investmentBalance.investmentValue',
                  ),
                )}
              </FooterCell>
              <FooterCell>
                {numberToCurrencyString(
                  sumBy(
                    investments.data?.result,
                    'investmentBalance.realisedValue.total',
                  ),
                )}
              </FooterCell>
            </FooterRow>
          </tfoot>
        }
      />

      <AlertDialog
        open={deleteDisclosure.isOpen}
        onClose={deleteDisclosure.onClose}
        onConfirm={handleConfirmDelete}
        title={
          'This cannot be undone and all information relating to the subscription will be lost.'
        }
        content={
          <Column spacing="xs">
            <p>
              Any money transferred to you must be refunded to the investor
              before the subscription is deleted.
            </p>
            <p>
              Any money that was transferred from the investor's cash balance to
              pay for this subscription will be returned to their cash balance.
            </p>
          </Column>
        }
        confirmBtnProps={{ color: 'secondary' }}
        btnLabels={{
          cancel: 'Go back',
          confirm: 'Delete subscription',
        }}
      />
    </Spacing>
  );
};

export default InvestmentsTable;

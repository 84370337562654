import {
  Control,
  useFieldArray,
  UseFormGetValues,
  UseFormSetValue,
  UseFormWatch,
  UseFormSetError,
} from 'react-hook-form';
import api from 'lib/trpcClient';
import { investment as rules } from 'further-ui/validations';
import { sumBy, round } from 'lodash';
import { useLayoutContext } from 'contexts/LayoutContext';

export interface HoldingClassOption {
  id: string;
  title: string;
}
const useHoldingClasses = (
  {
    control,
    watch,
    setValue,
    getValues,
    setError,
  }: {
    control: Control<rules.CreateInvestmentFormValues>;
    watch: UseFormWatch<rules.CreateInvestmentFormValues>;
    setValue: UseFormSetValue<rules.CreateInvestmentFormValues>;
    getValues: UseFormGetValues<rules.CreateInvestmentFormValues>;
    setError: UseFormSetError<rules.CreateInvestmentFormValues>;
  },
  selectedAssetId?: string,
  isNewAsset?: boolean,
) => {
  const { selectedVehicleId } = useLayoutContext();
  const holdingClassesWatch = watch('holdingClasses');

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: 'holdingClasses',
  });

  const { data: holdingClasses } =
    api.holdingClass.listAllHoldingClasses.useQuery(
      {
        assetId: selectedAssetId ?? '',
        order: 'desc',
        orderBy: 'createdAt',
        vehicleId: selectedVehicleId ?? '',
      },
      {
        enabled: !!selectedAssetId && !isNewAsset,
      },
    );

  const holdingClassOptions =
    holdingClasses?.result.map((holdingClass) => ({
      id: holdingClass._id,
      title: holdingClass.name,
      currency: holdingClass.currency,
    })) ?? [];

  const selectedHoldingClassIds = holdingClassesWatch
    .map((hc) => hc.holdingClassId)
    .filter(Boolean);

  const getAvailableOptions = (currentIndex: number) => {
    return holdingClassOptions.filter(
      (option) =>
        !selectedHoldingClassIds.includes(option.id) ||
        option.id === holdingClassesWatch[currentIndex]?.holdingClassId,
    );
  };

  const addHoldingClass = () => {
    append({
      isNewHoldingClass: false,
      existingHoldingClassName: '',
      holdingClassId: '',
      investmentAmount: 0,
      numberOfUnits: 0,
      unitPrice: 0,
      currency: getCurrency(),
    });
  };

  const isNewHoldingClass = (index: number) => {
    return watch(`holdingClasses.${index}.isNewHoldingClass`);
  };

  const resetHoldingClassDropdown = (index: number) => {
    setValue(`holdingClasses.${index}.holdingClassId`, '');
  };

  const resetHoldingClassList = () => {
    replace([
      {
        isNewHoldingClass: false,
        existingHoldingClassName: '',
        holdingClassId: '',
        investmentAmount: 0,
        numberOfUnits: 0,
        unitPrice: 0,
        currency: getCurrency(),
      },
    ]);
    setValue('allocations', []);
  };

  const isHoldingClassSelectDisabled = (index: number) => {
    return (
      !!watch(`holdingClasses.${index}.isNewHoldingClass`) ||
      getAvailableOptions(index).length === 0
    );
  };

  const removeHoldingClass = (index: number) => {
    remove(index);
    setValue('allocations', []);
  };

  const calculateUnitPrice = (index: number) => {
    const investmentAmount = getValues(
      `holdingClasses.${index}.investmentAmount`,
    );
    const numberOfUnits = getValues(`holdingClasses.${index}.numberOfUnits`);
    if (investmentAmount && numberOfUnits) {
      const result = Number((investmentAmount / numberOfUnits).toFixed(10));
      setValue(`holdingClasses.${index}.unitPrice`, result);
    } else {
      setValue(`holdingClasses.${index}.unitPrice`, 0);
    }
  };

  const hasNewHoldingClass = () => {
    return holdingClassesWatch.some(
      (holdingClass) => holdingClass.isNewHoldingClass,
    );
  };

  const calculateTotalInvestmentAmount = () =>
    sumBy(holdingClassesWatch, 'investmentAmount') || 0;

  const calculateTotalUnits = () =>
    sumBy(holdingClassesWatch, 'numberOfUnits') || 0;

  const calculateAverageUnitPrice = () => {
    const totalInvestment = calculateTotalInvestmentAmount();
    const totalUnits = calculateTotalUnits();
    return totalUnits ? round(totalInvestment / totalUnits, 10) : 0;
  };

  const getCurrency = () => {
    const holdingClasses = getValues('holdingClasses');
    return holdingClasses[0].currency;
  };

  const validateHoldingClassAmounts = () => {
    const values = getValues();
    let hasErrors = false;

    values.holdingClasses.forEach((holdingClass, index) => {
      if (holdingClass.investmentAmount <= 0) {
        setError(`holdingClasses.${index}.investmentAmount`, {
          type: 'custom',
          message: 'Investment amount must be greater than 0',
        });
        hasErrors = true;
      }

      if (holdingClass.numberOfUnits <= 0) {
        setError(`holdingClasses.${index}.numberOfUnits`, {
          type: 'custom',
          message: 'Number of units must be greater than 0',
        });
      }

      if (holdingClass.unitPrice <= 0) {
        setError(`holdingClasses.${index}.unitPrice`, {
          type: 'custom',
          message: 'Unit price must be greater than 0',
        });
        hasErrors = true;
      }
    });

    return !hasErrors;
  };

  return {
    fields,
    addHoldingClass,
    removeHoldingClass,
    getAvailableOptions,
    isNewHoldingClass,
    resetHoldingClassDropdown,
    resetHoldingClassList,
    isHoldingClassSelectDisabled,
    hasNewHoldingClass,
    calculateUnitPrice,
    calculateTotalInvestmentAmount,
    calculateTotalUnits,
    calculateAverageUnitPrice,
    getCurrency,
    validateHoldingClassAmounts,
  };
};

export default useHoldingClasses;

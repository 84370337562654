import { Alert, AlertTitle, CircularProgress, styled } from '@mui/material';
import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import Button from 'components/Button';
import { PropsWithChildren, Suspense } from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

const TryAgainButton = styled(Button)({
  padding: 0,
});

type Props = {
  loaderSize?: number;
  loader?: React.ReactNode;
};

const ErrorBoundary: React.FC<PropsWithChildren<Props>> = ({
  children,
  loaderSize = 24,
  loader,
}) => {
  const { reset } = useQueryErrorResetBoundary();

  return (
    <ReactErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {error.message}{' '}
          <TryAgainButton onClick={resetErrorBoundary} size="extra-small">
            Click here to try again.
          </TryAgainButton>
        </Alert>
      )}
      onReset={reset}
    >
      <Suspense fallback={loader ?? <CircularProgress size={loaderSize} />}>
        {children}
      </Suspense>
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;

import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  Switch,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CkEditor from 'components/CkEditor';
import Dropzone, { UploadedFile } from 'components/Dropzone';
import { AppSelectBox } from 'components/FormElements';
import { Api, KycRiskRating } from 'further-types/kyc';
import useFileUpload, { UploadFileResult } from 'hooks/ui/useFileUpload';
import { useNotification } from 'hooks/ui/useNotification';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { UploadType } from 'further-types/files';
import Heading from 'components/Heading';
import { Column, Row } from 'components/Layout';
import { kycRiskRatingLabels } from 'further-ui/labels';
import { FileType } from 'further-ui/utils';

const Close = styled(IconButton)({
  position: 'absolute',
  right: 0,
  top: 0,
});

const FullWidth = styled('div')({
  width: '100%',
});

const FlexGrow = styled('div')({
  flexGrow: 1,
});

const KycModal = styled(Dialog)({
  '& .MuiDialog-paper': {
    width: '100%',

    '& .MuiDialogContent-root': {
      paddingTop: 0,
    },
  },
});

const Title = styled(DialogTitle)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(4, 6),
}));

const Actions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(4, 6),
}));

type FormValues = {
  manualApprovalNotes: string;
  riskRating: KycRiskRating;
  approve: boolean;
  manualApprovalFiles: Array<{
    name: string;
    url: string;
    preview: string;
    path: string;
  }>;
};

const formSchema = z.object({
  manualApprovalNotes: z.string().optional(),
  riskRating: z.nativeEnum(KycRiskRating),
  approve: z.boolean(),
  manualApprovalFiles: z.array(
    z.object({
      name: z.string(),
      path: z.string(),
    }),
  ),
});

const getRiskRatingOptions = () => {
  return kycRiskRatingLabels;
};

type Props = {
  onClose: () => void;
  processManualKyc: (data: Api.ManualKycProcessRequest) => void;
  kycData?: Api.KycDetails;
  isPassedManually: boolean;
  isLegalEntity: boolean;
};

const KycManualApprovalModal: React.FC<Props> = ({
  onClose,
  processManualKyc,
  kycData,
  isPassedManually,
  isLegalEntity,
}) => {
  const { uploadFile } = useFileUpload();
  const { error } = useNotification();
  const { control, setValue, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      manualApprovalNotes: kycData?.manualApprovalNotes,
      riskRating: kycData?.riskRating ?? KycRiskRating.Low,
      approve: isPassedManually,
      manualApprovalFiles: kycData?.manualApprovalFiles ?? [],
    },
    resolver: zodResolver(formSchema),
  });

  const onSubmit = (values: FormValues) => {
    processManualKyc({
      ...values,
    });
  };

  const title = isLegalEntity ? 'Manual KYB approval' : 'Manual KYC approval';
  const buttonText = isPassedManually
    ? 'Save changes'
    : isLegalEntity
    ? 'Save KYB'
    : 'Save KYC';

  const handleFileUpload = async (
    files: Array<UploadedFile>,
    onSuccess: (uploadFileResult: UploadFileResult) => void,
  ) => {
    if (!files?.[0]?.path) return;
    try {
      const uploadFileResult = await uploadFile(
        files[0],
        UploadType.KycDocuments,
      );
      onSuccess(uploadFileResult);
    } catch (_) {
      error("Something went wrong - the file couldn't be uploaded.");
    }
  };

  return (
    <KycModal
      open
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      maxWidth="sm"
    >
      <Close aria-label="close" onClick={onClose} size="large">
        <CloseIcon />
      </Close>
      <Title>
        <Heading variant="h2" noMargin>
          {title}
        </Heading>
      </Title>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Column spacing="md" centered>
            <Controller
              name="manualApprovalNotes"
              control={control}
              render={({
                field: { value, name },
                fieldState: { error },
                formState: { isSubmitting },
              }) => (
                <FullWidth>
                  <CkEditor
                    disabled={isSubmitting}
                    placeholder="Enter manual approval notes"
                    showLists={false}
                    onBlur={(value: string) => {
                      setValue(name, value);
                    }}
                    value={value}
                    onChange={undefined}
                  />
                  {error && <p>{error.message}</p>}
                </FullWidth>
              )}
            />
            <Controller
              name="riskRating"
              control={control}
              render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                <AppSelectBox
                  inputRef={ref}
                  {...rest}
                  label="Risk rating"
                  data={getRiskRatingOptions()}
                  valueKey="value"
                  labelKey="label"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
            <Controller
              name="manualApprovalFiles"
              control={control}
              render={({
                field: { value, name, onChange },
                fieldState: { error },
              }) => (
                <FullWidth>
                  <Dropzone
                    name={name}
                    accept={{
                      [FileType.JPGImage]: ['.jpg', '.jpeg'],
                      [FileType.PNGImage]: ['.png'],
                      [FileType.PDF]: ['.pdf'],
                      [FileType.DOCX]: ['.docx'],
                    }}
                    maxFiles={100}
                    onDropFile={async (files) => {
                      await handleFileUpload(files, (uploadFileResult) => {
                        return onChange([
                          ...value,
                          {
                            path: uploadFileResult.filePath,
                            name: uploadFileResult.name,
                            preview: uploadFileResult.url,
                          },
                        ]);
                      });
                    }}
                    files={value.map((file) => ({
                      preview: file?.preview ?? '',
                      fileName: file?.name ?? '',
                      path: file?.path ?? '',
                    }))}
                    onRemove={(deleteIndex) => {
                      setValue(
                        name,
                        value.filter((_, index) => index !== deleteIndex),
                      );
                    }}
                  />
                  {error && <p>{error.message}</p>}
                </FullWidth>
              )}
            />
          </Column>
        </DialogContent>
        <Actions>
          <FlexGrow>
            <Controller
              name="approve"
              control={control}
              render={({ field: { name, value } }) => (
                <Row centered spacing="xs">
                  <span>Save only</span>
                  <Switch
                    name={name}
                    checked={value}
                    inputProps={{ 'aria-label': 'Switch' }}
                    onChange={(_, checked) => {
                      setValue(name, checked);
                    }}
                  />

                  <span>Save and approve</span>
                </Row>
              )}
            />
          </FlexGrow>
          <Button onClick={onClose} variant="outlined">
            Go back
          </Button>
          <Button type="submit" color="primary" variant="contained">
            {buttonText}
          </Button>
        </Actions>
      </form>
    </KycModal>
  );
};

export default KycManualApprovalModal;

import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Disclosure } from 'further-ui/hooks';
import Dialog from 'components/Dialog';
import BankAccountForm from './BankAccountForm';
import { useEditFirmBankDetails } from 'hooks/data/firm/useFirmBankDetails';
import { useNotification } from 'hooks/ui/useNotification';
import { Api } from 'further-types/firm';
import schema from './schema';

type Props = {
  firmId: string;
  disclosure: Disclosure;
};

const EditBankAccountDialog = ({ firmId, disclosure }: Props) => {
  const notification = useNotification();
  const initialValues = disclosure.actionProps as Api.FirmBankDetail;
  const form = useForm({
    shouldFocusError: true,
    resolver: zodResolver(schema),
    criteriaMode: 'all',
    values: initialValues,
  });
  const { mutateAsync } = useEditFirmBankDetails(firmId, initialValues._id);

  const onEditBankAccount = async (data: Api.CreateFirmBankDetailBody) => {
    try {
      await mutateAsync(data);
      notification.success('Bank account updated.');
      form.reset();
    } catch (_) {
      notification.error('Bank account could not be updated.');
    } finally {
      disclosure.onClose();
    }
  };

  return (
    <Dialog
      open={disclosure.isOpen}
      title="Edit bank account"
      onConfirm={form.handleSubmit(onEditBankAccount)}
      onClose={disclosure.onClose}
      btnLabels={{ cancel: 'Cancel', confirm: 'Save' }}
    >
      <BankAccountForm errors={form.formState.errors} control={form.control} />
    </Dialog>
  );
};

export default EditBankAccountDialog;

interface IsMongoIdOptions {
  optional?: boolean;
}

export default function isMongoId(options?: IsMongoIdOptions) {
  const optional = options?.optional ?? false;

  return (value?: string | null): boolean => {
    if (optional && !value) {
      return true;
    }

    if (!value) {
      return false;
    }

    // Check if string matches MongoDB ObjectId format (24 hex characters)
    return /^[0-9a-fA-F]{24}$/.test(value);
  };
}

import { Autocomplete, createFilterOptions } from '@mui/material';
import { AppTextInput } from 'components/FormElements';
import { forwardRef } from 'react';

type Counterparty = {
  _id?: string;
  inputValue?: string;
  name: string;
};

type Props = {
  contacts: Array<{
    _id?: string;
    inputValue?: string;
    name: string;
  }>;
  error?: {
    message?: string;
  };
  placeholder?: string;
  value?: Counterparty;
  onChange: (value: Counterparty) => void;
  name?: string;
  disabled?: boolean;
};

const CounterpartySelector = forwardRef<HTMLDivElement, Props>(
  (
    { name, disabled, contacts, error, placeholder, value = '', onChange },
    ref,
  ) => {
    const filter = createFilterOptions<Counterparty>();

    return (
      <Autocomplete
        freeSolo
        disableClearable
        clearOnBlur
        handleHomeEndKeys
        selectOnFocus
        disabled={disabled}
        ref={ref}
        options={contacts}
        value={value}
        renderInput={(params) => (
          <AppTextInput
            {...params}
            name={name}
            error={!!error}
            helperText={error?.message?.toString()}
            placeholder={placeholder}
          />
        )}
        getOptionKey={(option) =>
          typeof option === 'string' ? option : option._id ?? ''
        }
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.name;
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;
          const trimmedInputValue = inputValue.trim();

          // Suggest the creation of a new value
          const isExisting = options.some(
            (option) => trimmedInputValue === option.name,
          );
          if (trimmedInputValue !== '' && !isExisting) {
            filtered.push({
              _id: undefined,
              inputValue: trimmedInputValue,
              name: `Add "${trimmedInputValue}" as a new counterparty`,
            });
          }

          return filtered;
        }}
        renderOption={(props, option) => {
          const { key, ...optionProps } = props;
          return (
            <li key={key} {...optionProps}>
              {option.name}
            </li>
          );
        }}
        onChange={(_, newValue) => {
          if (typeof newValue === 'string') {
            onChange({
              _id: undefined,
              name: newValue,
            });
          } else if (newValue && newValue.inputValue) {
            onChange({
              _id: undefined,
              name: newValue.inputValue,
            });
          } else {
            onChange(newValue);
          }
        }}
      />
    );
  },
);

export default CounterpartySelector;

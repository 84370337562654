import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid2';
import { Link } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useForm, Controller } from 'react-hook-form';
import TextField from 'components/FormElements/AppTextInput';
import AppPasswordInput from 'components/FormElements/AppPasswordTextField';
import GridContainer from 'components/GridContainer';
import PageContainer from 'components/PageContainer';
import DropzoneComponent from 'components/Dropzone';
import PageContent from 'components/PageContent';
import { DASHBOARD } from 'adminConstants/routes';
import useCurrentUser from 'hooks/data/currentUser/useCurrentUser';
import { useNotification } from 'hooks/ui/useNotification';
import useFileUpload from 'hooks/ui/useFileUpload';
import { UploadType } from 'further-types/files';
import { adminUser as rules } from 'further-ui/validations';

const useStyles = makeStyles()(() => ({
  marginLeftBtn: {
    marginLeft: 10,
  },
  dropZone: {
    minHeight: 0,
  },
}));

const Profile: React.FC = () => {
  const { success, error } = useNotification();
  const { uploadFile } = useFileUpload();
  const { classes } = useStyles();
  const [profilePicPreview, setProfilePicPreview] = useState<string>();
  const [profilePic, setProfilePic] = useState<string>();

  const {
    profile: { data, isFetching },
    updateProfile: { mutate, isPending },
  } = useCurrentUser();

  useEffect(() => {
    if (!data) return;

    if (!data.profilePic.endsWith('default.jpg')) {
      setProfilePicPreview(data.profilePic);
      setProfilePic(data.profilePic);
    }
  }, [data]);

  const {
    handleSubmit,
    control,
    resetField,
    formState: { errors },
  } = useForm({
    shouldFocusError: true,
    resolver: zodResolver(rules.updateProfileInput),
    criteriaMode: 'all',
  });

  const onSubmit = async (formData) => {
    const diff = Object.keys(formData).reduce((acc, key) => {
      if (formData[key] !== data?.[key]) {
        //@ts-expect-error
        acc.push(key);
      }
      return acc;
    }, []);

    mutate(
      {
        ...formData,
        profilePic: profilePic?.includes('.com/')
          ? profilePic.split('.com/')[1]
          : profilePic,
        updates: diff,
      },
      {
        onError: () => {
          error('Error when trying to update the profile.');
        },
        onSuccess: () => {
          success('Profile updated successfully.');
          resetField('password');
        },
      },
    );
  };

  const handleProfileImage = async (file) => {
    if (!file?.[0]?.path) return;

    try {
      const { filePath } = await uploadFile(file[0], UploadType.UserImages);

      setProfilePicPreview(file?.[0]?.preview);
      setProfilePic(filePath);
    } catch (_) {
      error(`Something went wrong - the file couldn't be uploaded.`);
    }
  };

  return (
    <PageContainer
      heading="Profile"
      breadcrumbs={[
        { label: 'Dashboard', link: '/dashboard' },
        { label: 'Profile', link: '/', isActive: true },
      ]}
      loading={isFetching}
    >
      <PageContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <GridContainer>
            <Grid
              size={{
                xs: 12,
                sm: 6,
              }}
            >
              <Controller
                name="firstName"
                defaultValue={data?.firstName}
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    {...rest}
                    inputRef={ref}
                    label="First name"
                    variant="outlined"
                    required
                    error={!!errors.firstName}
                    helperText={errors.firstName?.message?.toString()}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid
              size={{
                xs: 12,
                sm: 6,
              }}
            >
              <Controller
                name="lastName"
                defaultValue={data?.lastName}
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    {...rest}
                    inputRef={ref}
                    label="Last name"
                    variant="outlined"
                    required
                    error={!!errors.lastName}
                    helperText={errors.lastName?.message?.toString()}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid
              size={{
                xs: 12,
                sm: 6,
              }}
            >
              <Controller
                name="email"
                defaultValue={data?.email}
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    {...rest}
                    inputRef={ref}
                    label="Email Address"
                    variant="outlined"
                    required
                    error={!!errors.email}
                    helperText={errors.email?.message?.toString()}
                    fullWidth
                    disabled
                  />
                )}
              />
            </Grid>
            <Grid
              size={{
                xs: 12,
                sm: 6,
              }}
            >
              <Controller
                name="password"
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <AppPasswordInput
                    {...rest}
                    inputRef={ref}
                    label="Password"
                    error={!!errors.password?.message}
                    helperText={errors.password?.message?.toString()}
                  />
                )}
              />
            </Grid>

            <Grid
              size={{
                xs: 12,
                sm: 6,
              }}
            >
              <Typography component="h4" variant="inherit">
                Profile Image
              </Typography>
              <DropzoneComponent
                name="profilePic"
                defaultAcceptGroup="images"
                onDropFile={(files) => handleProfileImage(files)}
                onRemove={() => {
                  setProfilePicPreview(undefined);
                  setProfilePic(undefined);
                }}
                files={
                  profilePicPreview
                    ? [{ preview: profilePicPreview, fileName: '' }]
                    : []
                }
              />
            </Grid>
            <Grid
              size={{
                xs: 12,
                sm: 12,
              }}
            >
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={isPending}
              >
                Update
              </Button>
              <Link to={DASHBOARD}>
                <Button className={classes.marginLeftBtn} variant="contained">
                  Cancel
                </Button>
              </Link>
            </Grid>
          </GridContainer>
        </form>
      </PageContent>
    </PageContainer>
  );
};

export default Profile;

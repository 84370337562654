import { Box, styled } from '@mui/material';
import Button from 'components/Button';
import useHandleTrpcMutation from 'hooks/ui/useHandleTrpcMutation';
import api from 'lib/trpcClient';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormValues } from '.';
import { TransactionActionsMode } from './TransactionActions';

const SubmitContainer = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  alignContent: 'center',
  height: '100%',

  [theme.breakpoints.down('md')]: {
    borderBottom: `1px solid ${theme.palette.borderColor?.dark}`,
    paddingBottom: theme.spacing(6),
  },
}));

type Props = {
  vehicleId: string;
  bankAccountId: string;
  bankAccountOperationId: string;
  amount: number;
  hasMatchingActions?: boolean;
};

const TransactionActionSubmit: React.FC<Props> = ({
  vehicleId,
  bankAccountId,
  bankAccountOperationId,
  amount,
  hasMatchingActions,
}) => {
  const { handleSubmit: onSubmit, watch } = useFormContext<FormValues>();
  const mode = watch('mode');

  const reconcileOperation =
    api.bankReconciliation.reconcileBankAccountOperation.useMutation(
      useHandleTrpcMutation()({
        successMessage: 'Transaction reconciled.',
        invalidationHandler: (utils) => {
          utils.bankReconciliation.invalidate();
          utils.action.listActions.invalidate();
          utils.generalLedger.invalidate();
        },
      }),
    );

  const createTransfer =
    api.bankReconciliation.createBankAccountTransfer.useMutation(
      useHandleTrpcMutation()({
        successMessage: 'Transfer created.',
        invalidationHandler: (utils) => {
          utils.bankReconciliation.invalidate();
          utils.action.listActions.invalidate();
        },
      }),
    );

  const handleSubmit = (values: FormValues) => {
    if (!amount) return;

    if (values.mode === TransactionActionsMode.QuickReconcile) {
      reconcileOperation.mutate({
        vehicleId,
        bankAccountId,
        bankAccountOperationId,
        reconciledActions: [
          {
            amount,
            actionId: values.actionId,
          },
        ],
      });
    } else if (values.mode === TransactionActionsMode.CreateTransfer) {
      createTransfer.mutate({
        vehicleId,
        sourceAccountId: bankAccountId,
        destinationAccountId: values.targetBankAccountId,
        sourceBankOperationId: bankAccountOperationId,
        reference: values.reference,
      });
    }
  };

  const visible =
    hasMatchingActions || mode === TransactionActionsMode.CreateTransfer;

  return (
    <SubmitContainer>
      {visible && (
        <Button
          onClick={onSubmit(handleSubmit)}
          variant="outlined"
          loading={reconcileOperation.isPending || createTransfer.isPending}
        >
          OK
        </Button>
      )}
    </SubmitContainer>
  );
};

export default TransactionActionSubmit;

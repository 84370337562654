import { FC, useState } from 'react';
import { FieldRow } from 'components/FormElements';
import { Control, Controller, useFormContext } from 'react-hook-form';
import { AppTextInput, AppSimpleSelect } from 'components/FormElements';
import Spacing from 'components/Spacing';
import { Divider } from '@mui/material';
import HoldingClassDetailsForm from './HoldingClassForm';
import AssetDetailsForm from './AssetForm/AssetDetailsForm';
import { useAsset } from '../hooks/useAsset';
import useHoldingClasses from '../hooks/useHoldingClasses';
import { investment as rules } from 'further-ui/validations';
import { SelectedVehicle } from '.';
import DataLossWarningModal from './DataLossWarningModal';
import { useDisclosure } from 'further-ui/hooks';
import AssetSelection from './AssetForm/AssetSelection';

type InputDetailsFormProps = {
  selectedVehicle: SelectedVehicle;
};

const InputDetailsForm: FC<InputDetailsFormProps> = ({ selectedVehicle }) => {
  const { control, watch, setValue, getValues, setError } =
    useFormContext<rules.CreateInvestmentFormValues>();

  const removeModalDisclosure = useDisclosure();
  const [previousAssetId, setPreviousAssetId] = useState<string>('');

  const { selectedAssetId, isNewAsset, assetOptions, resetAssetDropdown } =
    useAsset({ control, watch, setValue, vehicleId: selectedVehicle._id });

  const {
    fields,
    addHoldingClass,
    removeHoldingClass,
    getAvailableOptions,
    isNewHoldingClass,
    resetHoldingClassDropdown,
    resetHoldingClassList,
    isHoldingClassSelectDisabled,
    hasNewHoldingClass,
  } = useHoldingClasses(
    {
      control,
      watch,
      setValue,
      getValues,
      setError,
    },
    selectedAssetId,
    isNewAsset,
  );

  const handleAssetChange = (name: string) => {
    setValue('asset.name', name);
    if (hasNewHoldingClass()) {
      setPreviousAssetId(selectedAssetId);
      removeModalDisclosure.onOpen();
    } else {
      resetHoldingClassList();
    }
  };

  const handleConfirmAssetChange = () => {
    resetHoldingClassList();
    removeModalDisclosure.onClose();
  };

  const handleCancelAssetChange = () => {
    setValue('assetId', previousAssetId);
    removeModalDisclosure.onClose();
  };

  return (
    <Spacing>
      <VehicleSelection selectedVehicle={selectedVehicle} control={control} />

      <FieldRow title="Deal name*" centerTitle>
        <Controller
          name="dealName"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <AppTextInput
              {...field}
              error={!!error}
              helperText={error?.message}
              placeholder="Enter a deal name"
            />
          )}
        />
      </FieldRow>

      <AssetSelection
        isNewAsset={isNewAsset}
        assetOptions={assetOptions}
        control={control}
        resetAssetDropdown={resetAssetDropdown}
        onAssetChange={handleAssetChange}
      />
      {isNewAsset && <AssetDetailsForm />}

      <Divider />

      {fields.map((field, index) => (
        <HoldingClassDetailsForm
          key={field.id}
          index={index}
          field={field}
          holdingClassOptions={getAvailableOptions(index)}
          onAdd={addHoldingClass}
          onRemove={() => removeHoldingClass(index)}
          isLast={index === fields.length - 1}
          isFirst={index === 0}
          resetHoldingClassDropdown={(index) =>
            resetHoldingClassDropdown(index)
          }
          isNewHoldingClass={isNewHoldingClass(index)}
          isHoldingClassSelectDisabled={isHoldingClassSelectDisabled(index)}
        />
      ))}

      <DataLossWarningModal
        disclosure={removeModalDisclosure}
        onConfirm={handleConfirmAssetChange}
        onCancel={handleCancelAssetChange}
        message="Changing the asset will remove all holding classes including any new ones you've created. Do you want to continue?"
      />
    </Spacing>
  );
};

type VehicleSelectionProps = {
  selectedVehicle: SelectedVehicle;
  control: Control<rules.CreateInvestmentFormValues>;
};

const VehicleSelection: FC<VehicleSelectionProps> = ({
  selectedVehicle,
  control,
}) => (
  <FieldRow title="Vehicle" centerTitle>
    <Controller
      name="vehicleId"
      control={control}
      render={({ field: { ref, ...rest } }) => (
        <AppSimpleSelect
          fullWidth
          disabled
          options={[
            {
              value: selectedVehicle._id,
              label: selectedVehicle.vehicleName,
            },
          ]}
          {...rest}
        />
      )}
    />
  </FieldRow>
);

export default InputDetailsForm;

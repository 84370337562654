import { zodResolver } from '@hookform/resolvers/zod';
import Button from 'components/Button';
import ButtonGroup from 'components/ButtonGroup';
import CountrySelector from 'components/CountrySelector';
import CurrencySelector from 'components/CurrencySelector';
import { AppTextInput, FieldRow } from 'components/FormElements';
import Heading from 'components/Heading';
import RecordView from 'components/RecordView';
import { CurrencyCode } from 'further-types/lp/vehicle';
import { Control, Controller, FieldPath, useForm } from 'react-hook-form';
import { bankReconciliation as rules } from 'further-ui/validations';
import { z } from 'zod';
import api from 'lib/trpcClient';
import useHandleTrpcMutation from 'hooks/ui/useHandleTrpcMutation';
import { useLayoutContext } from 'contexts/LayoutContext';
import { ADD_BANK_ACCOUNT, BANK_RECONCILIATION } from 'adminConstants/routes';
import { useHistory } from 'react-router-dom';
import Spacing from 'components/Spacing';
import useVehicle from 'hooks/lp/data/vehicle/useVehicle';
import PageLoader from 'components/PageLoader';

const formSchema = rules.AddBankAccountInput;

type FormValues = z.infer<typeof formSchema>;

type Props = {
  bankAccount?: {
    id: string;
    accountName: string;
    bankName: string;
    iban?: string;
    accountCurrency: CurrencyCode;
    accountAddress?: {
      address1: string;
      address2?: string;
      city: string;
      postcode: string;
      country: string;
    };
    bankFeedAccountId?: string;
  };
};

const FormField: React.FC<{
  label: string;
  name: FieldPath<FormValues>;
  control: Control<FormValues>;
  tooltipText?: string;
}> = ({ label, control, name, tooltipText }) => (
  <FieldRow title={label} centerTitle tooltipText={tooltipText}>
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <AppTextInput {...field} error={!!error} helperText={error?.message} />
      )}
    />
  </FieldRow>
);

const AccountSettings: React.FC<Props> = ({ bankAccount }) => {
  const { selectedVehicleId } = useLayoutContext();
  const history = useHistory();

  const mutationHandler = useHandleTrpcMutation()({
    successMessage: bankAccount
      ? 'Bank account updated.'
      : 'Bank account added.',
    invalidationHandler: (apiUtils) => apiUtils.bankReconciliation.invalidate(),
    onSuccess: () => {
      if (bankAccount) return;

      history.push(BANK_RECONCILIATION);
    },
  });

  const updateBankAccount =
    api.bankReconciliation.updateBankAccount.useMutation(mutationHandler);

  const addBankAccount =
    api.bankReconciliation.addBankAccount.useMutation(mutationHandler);

  const { vehicle, isLoading } = useVehicle(selectedVehicleId);

  const { control, handleSubmit, setValue } = useForm<FormValues>({
    shouldFocusError: true,
    resolver: zodResolver(formSchema),
    values: {
      bankAccount: bankAccount ?? {
        accountName: '',
        bankName: '',
        iban: '',
        accountCurrency: vehicle?.baseCurrency ?? CurrencyCode.GBP,
        accountAddress: {
          address1: '',
          city: '',
          postcode: '',
          country: '',
        },
      },
    },
    criteriaMode: 'all',
  });

  const onSubmit = (data: FormValues) => {
    if (bankAccount) {
      updateBankAccount.mutate({
        vehicleId: selectedVehicleId ?? '',
        bankAccount: {
          id: bankAccount.id,
          ...data.bankAccount,
        },
      });
    } else {
      addBankAccount.mutate({
        vehicleId: selectedVehicleId ?? '',
        ...data,
      });
    }
  };

  const handleConnectBankFeed = () => {
    if (!bankAccount) return;

    history.push(`${ADD_BANK_ACCOUNT}?bankAccountId=${bankAccount.id}`);
  };

  const isPending = updateBankAccount.isPending || addBankAccount.isPending;

  if (isLoading) return <PageLoader />;

  return (
    <Spacing>
      {bankAccount ? (
        <div>
          <Button
            variant="contained"
            color="primary"
            disabled={!!bankAccount?.bankFeedAccountId}
            onClick={handleConnectBankFeed}
          >
            {bankAccount?.bankFeedAccountId
              ? 'Bank feed connected'
              : 'Connect bank feed to account'}
          </Button>
        </div>
      ) : (
        <Heading variant="h2" noMargin>
          Add new account
        </Heading>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <RecordView size="medium">
          <FormField
            control={control}
            name="bankAccount.bankName"
            label="Bank name*"
          />
          <FormField
            control={control}
            name="bankAccount.accountName"
            label="Account name*"
            tooltipText="This should be a unique name used to refer to this account in the system."
          />
          <FieldRow title="Account currency*">
            <Controller
              control={control}
              name="bankAccount.accountCurrency"
              render={({
                field: { value, ...rest },
                fieldState: { error },
              }) => (
                <CurrencySelector
                  {...rest}
                  error={error}
                  value={value as CurrencyCode}
                />
              )}
            />
          </FieldRow>
          <FormField control={control} name="bankAccount.iban" label="IBAN" />
          <Heading variant="h4">Account address</Heading>
          <FormField
            control={control}
            name="bankAccount.accountAddress.address1"
            label="Address line 1"
          />
          <FormField
            control={control}
            name="bankAccount.accountAddress.address2"
            label="Address line 2"
          />
          <FormField
            control={control}
            name="bankAccount.accountAddress.city"
            label="Town/City"
          />
          <FormField
            control={control}
            name="bankAccount.accountAddress.postcode"
            label="Postal code"
          />
          <FieldRow title="Country">
            <Controller
              control={control}
              name="bankAccount.accountAddress.country"
              render={({
                field: { value, ref, ...rest },
                fieldState: { error },
              }) => (
                <CountrySelector
                  {...rest}
                  ref={ref}
                  value={value}
                  name="bankAccount.accountAddress.country"
                  placeholder="Select country"
                  error={error}
                  onChange={(value) => {
                    setValue('bankAccount.accountAddress.country', value);
                  }}
                />
              )}
            />
          </FieldRow>
          <ButtonGroup>
            <Button variant="contained" type="submit" loading={isPending}>
              {bankAccount ? 'Update account' : 'Add account'}
            </Button>
          </ButtonGroup>
        </RecordView>
      </form>
    </Spacing>
  );
};

export default AccountSettings;

import { z } from 'zod';
import {
  withPaginationParams,
  withSortableParams,
  isMongoId,
} from '../../helpers';
import { ActionRef, ActionTypes } from 'further-types/lp/action';
import { CurrencyCode, FxDataFeed } from 'further-types/lp/vehicle';

export const ListPaginated = withPaginationParams(
  z.object({
    search: z.string().optional().nullable(),
  }),
  ['vehicleName', 'baseCurrency'],
);

export const ListAll = withSortableParams(z.object({}), [
  'vehicleName',
  'baseCurrency',
]);

export const Create = z.object({
  vehicleName: z.string().min(1, 'Please enter vehicle name.'),
});

export const Update = Create.extend({
  baseCurrency: z.nativeEnum(CurrencyCode),
  fxDataFeed: z.nativeEnum(FxDataFeed),
  timezone: z.string().min(1, 'Please select a timezone.'),
});

const MISSING_TRANSACTION_TYPE_ERROR = 'Please select a transaction type';

export const UpdateAccountMappings = z.object({
  accountMappings: z.array(
    z.object({
      creditTransactionTypeId: z
        .string({ required_error: MISSING_TRANSACTION_TYPE_ERROR })
        .min(1, MISSING_TRANSACTION_TYPE_ERROR)
        .refine(isMongoId()),
      debitTransactionTypeId: z
        .string({ required_error: MISSING_TRANSACTION_TYPE_ERROR })
        .min(1, MISSING_TRANSACTION_TYPE_ERROR)
        .refine(isMongoId()),
      actionRef: z.nativeEnum(ActionRef),
      actionType: z.nativeEnum(ActionTypes),
    }),
  ),
});

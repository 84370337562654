interface BankDetails {
  beneficiaryName: string;
  bankAccountNumber?: string;
  bankSortCode?: string;
  iban?: string;
}

export const firmBankDetailsDisplay = (bankDetails: BankDetails) => {
  const {
    beneficiaryName = '',
    iban,
    bankAccountNumber,
    bankSortCode,
  } = bankDetails;

  const truncatedBeneficiaryName =
    beneficiaryName.length > 20
      ? `${beneficiaryName.slice(0, 20)}...`
      : beneficiaryName;

  return iban
    ? `${truncatedBeneficiaryName} - ${iban}`
    : `${truncatedBeneficiaryName} - ${bankAccountNumber ?? ''} - ${
        bankSortCode ?? ''
      }`;
};

import { FC } from 'react';
import { sortBy } from 'lodash';
import {
  AppTextInput,
  AppDatePicker,
  AppSimpleSelect,
  FieldRow,
} from 'components/FormElements';
import Spacing from 'components/Spacing';
import { Controller, useFormContext } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { CalendarDay, numberToCurrencyString } from 'further-ui/utils';
import Grid from '@mui/material/Grid2';
import { FormHelperText } from '@mui/material';
import {
  IncomeType,
  ExpenseType,
  IncomeOrExpenseType,
} from 'further-types/lp/income-and-expenses';
import Dropzone, { UploadedFile } from 'components/Dropzone';
import { UploadType } from 'further-types/files';
import useFileUpload, { UploadFileResult } from 'hooks/ui/useFileUpload';
import { useNotification } from 'hooks/ui/useNotification';
import { IncomeAndExpensesLabels } from 'further-ui/labels';
import CounterpartySelector from 'components/CounterpartySelector';
import VatRateSelector from 'components/VatRateSelector';
import CurrencySelector from 'components/CurrencySelector';
import { CurrencyCode } from 'further-types/lp/vehicle';
import LpActionSelector from 'components/LpActionSelector';

const incomeTypeOptions = sortBy(
  Object.values(IncomeType).map((value) => ({
    value,
    label: IncomeAndExpensesLabels[value],
  })),
  'label',
);

const expenseTypeOptions = sortBy(
  Object.values(ExpenseType).map((value) => ({
    value,
    label: IncomeAndExpensesLabels[value],
  })),
  'label',
);

type Props = {
  vehicles?: Array<{
    _id: string;
    vehicleName: string;
  }>;
  incomeOrExpense: IncomeOrExpenseType | '';
  transactionCurrency: CurrencyCode;
  contacts: Array<{
    _id: string;
    name: string;
  }>;
  actions?: Array<{
    id: string;
    ledgerDate: CalendarDay;
    asset?: {
      name: string;
    };
  }>;
};

const InputDetailsForm: FC<Props> = ({
  vehicles,
  incomeOrExpense,
  transactionCurrency,
  actions = [],
  contacts = [],
}) => {
  const { control, setValue } = useFormContext();
  const { uploadFile } = useFileUpload();
  const { error } = useNotification();

  const handleFileUpload = async (
    files: Array<UploadedFile>,
    onSuccess: (uploadFileResult: UploadFileResult) => void,
  ) => {
    if (!files?.[0]?.path) return;
    try {
      const uploadFileResult = await uploadFile(
        files[0],
        UploadType.LpTransactionAttachments,
      );
      onSuccess(uploadFileResult);
    } catch (_) {
      error("Something went wrong - the file couldn't be uploaded.");
    }
  };

  const actionOptions = actions.map((action) => ({
    id: action.id,
    name: `Investment into ${
      action.asset?.name
    } on ${action.ledgerDate.toLabel()}`,
    date: action.ledgerDate,
    assetName: action.asset?.name,
  }));

  return (
    <Spacing>
      <FieldRow title="Select vehicle" centerTitle>
        <Controller
          name="vehicleId"
          control={control}
          render={({ fieldState: { error }, field: { ref, ...rest } }) => (
            <AppSimpleSelect
              fullWidth
              options={
                vehicles?.map((vehicle) => ({
                  value: vehicle._id,
                  label: vehicle.vehicleName,
                })) ?? []
              }
              placeholder="Select vehicle"
              error={!!error}
              ref={ref}
              disabled={true}
              {...rest}
            />
          )}
        />
      </FieldRow>
      <FieldRow title="Counterparty" centerTitle>
        <Controller
          name="counterparty"
          control={control}
          render={({ fieldState: { error }, field }) => (
            <CounterpartySelector
              contacts={contacts}
              error={error}
              placeholder="Enter supplier name"
              {...field}
            />
          )}
        />
      </FieldRow>
      <FieldRow title="Linked actions" centerTitle>
        <Controller
          name="linkedActionId"
          control={control}
          render={({ field: { value, onChange } }) => (
            <LpActionSelector
              actions={actionOptions}
              placeholder="Select action"
              value={
                value
                  ? actionOptions.find((action) => action.id === value)
                  : null
              }
              onChange={(newValue) => {
                onChange(newValue?.id ?? null);
              }}
            />
          )}
        />
      </FieldRow>
      <FieldRow title="Income or expense" centerTitle>
        <Controller
          name="category"
          control={control}
          render={({
            fieldState: { error },
            field: { ref, onChange, ...rest },
          }) => (
            <AppSimpleSelect
              fullWidth
              options={[
                { value: IncomeOrExpenseType.Income, label: 'Income' },
                { value: IncomeOrExpenseType.Expense, label: 'Expense' },
              ]}
              placeholder="Select income or expense"
              error={!!error}
              ref={ref}
              onChange={(e) => {
                onChange(e.target.value);
                setValue('incomeOrExpenseType', '');
              }}
              {...rest}
            />
          )}
        />
      </FieldRow>
      <FieldRow title="Income or expense type" centerTitle>
        <Controller
          name="incomeOrExpenseType"
          control={control}
          render={({ fieldState: { error }, field: { ref, ...rest } }) => (
            <>
              <AppSimpleSelect
                fullWidth
                disabled={!incomeOrExpense}
                options={
                  incomeOrExpense === 'income'
                    ? incomeTypeOptions
                    : expenseTypeOptions
                }
                placeholder="Select income or expense type"
                error={!!error}
                ref={ref}
                {...rest}
              />
              {error?.message && (
                <FormHelperText error={true}>{error?.message}</FormHelperText>
              )}
            </>
          )}
        />
      </FieldRow>
      <FieldRow title="Ledger date" centerTitle>
        <Controller
          name="ledgerDate"
          control={control}
          render={({ fieldState: { error }, field: { ref, ...rest } }) => (
            <AppDatePicker
              error={!!error}
              ref={ref}
              helperText={error?.message?.toString()}
              {...rest}
            />
          )}
        />
      </FieldRow>
      <FieldRow title="Currency" centerTitle>
        <Controller
          name="currencyCode"
          control={control}
          render={({ fieldState: { error }, field }) => (
            <CurrencySelector
              error={error}
              placeholder="Enter currency"
              {...field}
            />
          )}
        />
      </FieldRow>

      <FieldRow title="Enter amount (excl. sales tax)" centerTitle>
        <Grid size={{ sm: 12, md: 12, lg: 6 }}>
          <Controller
            name="amountExVat"
            control={control}
            render={({
              fieldState: { error },
              field: { ref, onChange, ...rest },
            }) => (
              <NumericFormat
                {...rest}
                inputRef={ref}
                onValueChange={({ value }) => {
                  onChange(+value);
                }}
                allowNegative={false}
                decimalScale={5}
                allowLeadingZeros={false}
                thousandSeparator={true}
                customInput={AppTextInput}
                error={!!error}
                helperText={error?.message?.toString()}
                placeholder="Enter amount"
              />
            )}
          />
        </Grid>
      </FieldRow>
      <FieldRow title="Sales tax (e.g. VAT) rate" centerTitle>
        <Grid size={{ sm: 12, md: 12, lg: 6 }}>
          <Controller
            name="vatRate"
            control={control}
            render={({ fieldState: { error }, field }) => (
              <VatRateSelector
                error={error}
                placeholder="Enter VAT rate"
                {...field}
              />
            )}
          />
        </Grid>
      </FieldRow>
      <FieldRow title="Gross amount (inc. tax)" centerTitle>
        <Controller
          name="amountIncVat"
          control={control}
          render={({ field: { value } }) => (
            <p>
              {numberToCurrencyString(value, {
                fallback: '-',
                currency: transactionCurrency,
              })}
            </p>
          )}
        />
      </FieldRow>
      <FieldRow title="Invoice file (if applicable)" centerTitle>
        <Controller
          name="invoiceFile"
          control={control}
          render={({ field: { ref, onChange, value, ...rest } }) => (
            <Dropzone
              {...rest}
              ref={ref}
              maxFiles={1}
              defaultAcceptGroup="filesAndImages"
              onDropFile={async (files) => {
                await handleFileUpload(files, (uploadFileResult) => {
                  onChange({
                    path: uploadFileResult.filePath,
                    name: uploadFileResult.name,
                    preview: uploadFileResult.url,
                  });
                });
              }}
              files={
                value
                  ? [
                      {
                        preview: value.preview,
                        fileName: value.name,
                      },
                    ]
                  : []
              }
              onRemove={() => {
                onChange(null);
              }}
            />
          )}
        />
      </FieldRow>
      <FieldRow title="Description" centerTitle>
        <Controller
          name="description"
          control={control}
          render={({ field }) => <AppTextInput {...field} />}
        />
      </FieldRow>
    </Spacing>
  );
};

export default InputDetailsForm;

import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { ApiResponse } from 'further-types/api';
import { Api } from 'further-types/admin-user-organisation-membership';
import { api } from 'lib/httpClient';
import { useNotification } from 'hooks/ui/useNotification';
import useApiExceptionHandler from 'hooks/ui/useApiExceptionHandler';

const KEY = 'admin-user-organisation-membership';

export function useMutateAdminUserOrganisation(adminUserId?: string) {
  const queryClient = useQueryClient();
  const notification = useNotification();
  const handleApiException = useApiExceptionHandler();

  const create = useMutation({
    mutationFn: async (
      body: Api.CreateUserOrganisationMembershipRequestBody,
    ) => {
      const { data } = await api.post<
        ApiResponse<Api.MutateMembershipResponseBody>
      >(`admin-user-organisation-membership/${adminUserId}`, body);
      return data.data;
    },
    onSuccess: async () => {
      notification.success('User added to organisation.');
      await queryClient.invalidateQueries({ queryKey: [KEY, adminUserId] });
    },
    onError: (error: Error) => handleApiException(error),
  });

  const remove = useMutation({
    mutationFn: async (
      body: Api.RemoveUserOrganisationMembershipRequestBody,
    ) => {
      await api.delete<ApiResponse<Api.MutateMembershipResponseBody>>(
        `admin-user-organisation-membership/${adminUserId}`,
        { data: body },
      );
    },
    onSuccess: async () => {
      notification.success('User removed from organisation.');
      await queryClient.invalidateQueries({ queryKey: [KEY, adminUserId] });
    },
    onError: (error: Error) => handleApiException(error),
  });

  return {
    create,
    remove,
  };
}

export function useOrganisationMemberships(adminUserId?: string) {
  return useQuery<Api.GetUserOrganisationMembershipsResponseBody>({
    queryKey: [KEY, adminUserId],
    queryFn: async () => {
      const { data } = await api.get<
        ApiResponse<Api.GetUserOrganisationMembershipsResponseBody>
      >(`admin-user-organisation-membership/${adminUserId}`);
      return data.data;
    },
    enabled: !!adminUserId,
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60 * 60,
  });
}

export function useAvailableOrganisations() {
  return useQuery<Api.GetAvailableOrganisationsResponseBody>({
    queryKey: ['admin-user-available-organisations'],
    queryFn: async () => {
      const { data } = await api.get<
        ApiResponse<Api.GetAvailableOrganisationsResponseBody>
      >(`admin-user-organisation-membership/available-organisations`);
      return data.data;
    },
  });
}

export function useRolesForOrganisation(auth0OrgId?: string) {
  return useQuery<Api.GetAvailableRolesForOrganisationResponseBody>({
    queryKey: ['admin-user-roles-for-organisation', auth0OrgId],
    queryFn: async () => {
      const { data } = await api.post<
        ApiResponse<Api.GetAvailableRolesForOrganisationResponseBody>
      >(`admin-user-organisation-membership/available-roles-for-organisation`, {
        auth0OrgId,
      });
      return data.data;
    },
    enabled: !!auth0OrgId,
  });
}

import { useQuery } from '@tanstack/react-query';
import { Api } from 'further-types/investor-reporting';
import { api } from 'lib/httpClient';

async function fetchReportsForInvestor({ queryKey }) {
  try {
    const [, { investorId, page, perPage, sortKey, sortOrder }] = queryKey;
    const {
      data: { data },
    } = await api.get(`investor-reporting/reports-for-investor/${investorId}`, {
      params: { page, perPage, sortKey, sortOrder },
    });
    return data;
  } catch (error) {
    throw error;
  }
}

function useGetReportsForInvestorFetchAPI({
  params,
}: {
  params: Api.GetReportsForInvestorQueryParams;
}) {
  return useQuery({
    queryKey: ['reports-for-investor', params],
    queryFn: fetchReportsForInvestor,
    enabled: !!params.investorId,
    refetchOnWindowFocus: false,
  });
}

export function useGetReportsForInvestor(params) {
  return useGetReportsForInvestorFetchAPI(params);
}

import AppCheckbox from 'components/FormElements/AppCheckBox';
import InteractiveTable, { Column } from 'components/InteractiveTable';
import PageFilters, { Filter } from 'components/PageFilters';
import SearchField from 'components/SearchField';
import Spacing from 'components/Spacing';
import { ActionOperationType } from 'further-types/lp/action';
import { CurrencyCode } from 'further-types/lp/vehicle';
import { ActionRefLabels } from 'further-ui/labels';
import {
  numberToCurrencyString,
  dateToLabel,
  CalendarDay,
} from 'further-ui/utils';
import useFilters from 'hooks/ui/useFilters';

import { useState } from 'react';
import Review from './Review';
import Button from 'components/Button';
import useSplitActions, {
  Action,
} from 'hooks/lp/ui/bankReconciliation/useSplitActions';
import SplitActionDialog from './SplitActionDialog';
import { styled } from '@mui/material';
import ErrorBoundary from 'components/ErrorBoundary';

const SplitButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1, 1),
}));

type Props = {
  vehicleId: string;
  bankAccountId: string;
  preselectedActionId?: string;
  defaultActions: Array<Action>;
  transaction: {
    id: string;
    spent?: number;
    received?: number;
    accountCurrency: CurrencyCode;
    date: CalendarDay;
  };
  onClose: () => void;
};

const FindAndMatch: React.FC<Props> = ({
  vehicleId,
  bankAccountId,
  preselectedActionId,
  transaction,
  onClose,
  defaultActions,
}) => {
  const componentId = `find-and-match-${transaction.id}`;
  const transactionAmount = transaction.spent ?? transaction.received ?? 0;
  const [selectedActionIds, setSelectedActionIds] = useState<Array<string>>(
    preselectedActionId ? [preselectedActionId] : [],
  );

  const { filters, handleChangeFilters } = useFilters(
    `find-and-match-${transaction.id}`,
    {
      defaultFilters: {
        pastOnly: false,
        receivedOnly: false,
        searchQuery: '',
      },
    },
  );

  const {
    actions,
    actionToSplit,
    selectActionToSplit,
    showSplitActionDialog,
    closeSplitActionDialog,
    splitSelectedAction,
  } = useSplitActions(defaultActions, (sourceActionId, newActionId) => {
    setSelectedActionIds([
      ...selectedActionIds.filter((id) => id !== sourceActionId),
      newActionId,
    ]);
  });

  const checkOperationType = (action: Action) =>
    !filters.receivedOnly ||
    action.operationType === ActionOperationType.Received;

  const checkDate = (action: Action) =>
    !filters.pastOnly || action.ledgerDate.toUTCDate() <= new Date();

  const searchByQuery = (action: Action) =>
    !filters.searchQuery ||
    action.counterpartyLabel
      .toLowerCase()
      .includes(filters.searchQuery.toLowerCase()) ||
    action.unreconciledAmount === Number(filters.searchQuery);

  const actionsToDisplay = actions
    .filter(checkOperationType)
    .filter(checkDate)
    .filter(searchByQuery);

  const selectedActions = actionsToDisplay.filter((action) =>
    selectedActionIds.includes(action.id),
  );

  const handleActionSelection = (selectedActionIds: Array<string>) => {
    setSelectedActionIds(selectedActionIds);
  };

  const columns: Array<Column> = [
    {
      field: 'ledgerDate',
      headerName: 'Date',
      renderCell: ({ row }) => dateToLabel(row.ledgerDate),
      flex: 1,
    },
    {
      field: 'actionRef',
      headerName: 'Action',
      renderCell: ({ row }) => ActionRefLabels[row.actionRef],
      flex: 1,
    },
    {
      field: 'counterpartyLabel',
      headerName: 'Counterparty',
      flex: 1,
    },
    {
      field: 'spent',
      headerName: 'Spent',
      renderCell: ({ row }) =>
        numberToCurrencyString(
          row.operationType === ActionOperationType.Spent
            ? row.unreconciledAmount
            : undefined,
          {
            fallback: '-',
            currency: row.currencyCode,
          },
        ),
      flex: 1,
    },
    {
      field: 'received',
      headerName: 'Received',
      renderCell: ({ row }) =>
        numberToCurrencyString(
          row.operationType === ActionOperationType.Received
            ? row.unreconciledAmount
            : undefined,
          {
            fallback: '-',
            currency: row.currencyCode,
          },
        ),
      flex: 1,
    },
    {
      field: 'split',
      headerName: '',
      renderCell: ({ row }) =>
        row.id && !row.sourceActionId && selectedActionIds.includes(row.id) ? (
          <SplitButton
            variant="outlined"
            color="primary"
            size="small"
            fullWidth
            onClick={() => {
              if (row.id) {
                selectActionToSplit(row.id);
              }
            }}
          >
            Split
          </SplitButton>
        ) : null,
      flex: 1,
    },
  ];

  return (
    <Spacing size="md">
      <PageFilters pageId={componentId}>
        <Filter>
          <SearchField
            autoSearchOnDebounce
            name="searchQuery"
            variant="standard"
            placeholder="Search by counterparty or amount"
            fullWidth
            onSearch={(value) => handleChangeFilters({ searchQuery: value })}
            allowEmptyQuery
            iconPlacement="left"
          />
        </Filter>
        <Filter>
          <AppCheckbox
            label="Past transactions only"
            key="pastOnly"
            checked={!!filters.pastOnly}
            onChange={(event) => {
              handleChangeFilters({
                pastOnly: event.target.checked,
              });
            }}
          />

          <AppCheckbox
            label="Show received items only"
            key="receivedOnly"
            checked={!!filters.receivedOnly}
            onChange={(event) => {
              handleChangeFilters({
                receivedOnly: event.target.checked,
              });
            }}
          />
        </Filter>
      </PageFilters>
      <InteractiveTable
        key={JSON.stringify([...actionsToDisplay, ...selectedActionIds])}
        selectable
        onRowSelection={handleActionSelection}
        selectedRows={selectedActionIds}
        id={componentId}
        columns={columns}
        rows={actionsToDisplay}
        disableSorting
        disableTableConfiguration
        fullHeight
      />
      {!!selectedActions.length && (
        <ErrorBoundary>
          <Review
            vehicleId={vehicleId}
            bankAccountId={bankAccountId}
            transaction={transaction}
            selectedActions={selectedActions}
            onClose={onClose}
          />
        </ErrorBoundary>
      )}
      {showSplitActionDialog && (
        <SplitActionDialog
          onClose={closeSplitActionDialog}
          transactionAmount={Math.abs(transactionAmount)}
          actionAmount={actionToSplit?.unreconciledAmount ?? 0}
          onSplit={splitSelectedAction}
          currency={transaction.accountCurrency}
        />
      )}
    </Spacing>
  );
};

export default FindAndMatch;

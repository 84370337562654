import { Box, Grid2, styled, Typography } from '@mui/material';
import GridContainer from 'components/GridContainer';
import { CurrencyCode } from 'further-types/lp/vehicle';
import { numberToCurrencyString, CalendarDay } from 'further-ui/utils';

const Container = styled(Box)(({ theme }) => ({
  width: '100%',
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.borderColor?.dark}`,
  padding: theme.spacing(4, 5),
}));

type Props = {
  date?: CalendarDay;
  description?: string;
  spent?: number;
  received?: number;
  currency?: CurrencyCode;
};

const TransactionPreview: React.FC<Props> = ({
  date,
  description,
  spent,
  received,
  currency,
}) => (
  <Container>
    <GridContainer alignContent="center">
      <Grid2 size={{ xs: 6, md: 8 }}>
        <Typography variant="body1">
          {date?.toLabel('N/A', 'dd MMMM yyyy')}
        </Typography>
        <Typography variant="body1">{description}</Typography>
      </Grid2>
      <Grid2 size={{ xs: 3, md: 2 }}>
        <Typography variant="body2" color="textSecondary" align="right">
          Spent
        </Typography>
        <Typography variant="body1" align="right">
          {numberToCurrencyString(spent, { fallback: '-', currency })}
        </Typography>
      </Grid2>
      <Grid2 size={{ xs: 3, md: 2 }}>
        <Typography variant="body2" color="textSecondary" align="right">
          Received
        </Typography>
        <Typography variant="body1" align="right">
          {numberToCurrencyString(received, { fallback: '-', currency })}
        </Typography>
      </Grid2>
    </GridContainer>
  </Container>
);

export default TransactionPreview;

import { WithdrawalFee } from 'further-types/firm';
import { round } from 'lodash';

export const calculateWithdrawalFee = (
  withdrawalFees: Array<WithdrawalFee> | undefined,
  withdrawalAmount: number,
  customWithdrawalFee?: number,
) => {
  const noFees = {
    fee: customWithdrawalFee ?? 0,
    vat: 0,
    vatAmount: 0,
  };
  if (!withdrawalFees || withdrawalFees.length === 0) return noFees;

  const fee = withdrawalFees.find((fee) => {
    // Unset range will default to 0 and Infinity respectively
    return (
      withdrawalAmount >= (fee.rangeStart ?? 0) &&
      withdrawalAmount <= (fee.rangeEnd ?? Infinity)
    );
  });

  if (!fee) return noFees;

  const vatMultiplier = (100 + (fee.vat ?? 0)) / 100;

  const amount = fee.amount ?? 0;

  // If the customWithdrawalFee is passed, use that instead of the calculated fee amount
  const feeAmount = customWithdrawalFee
    ? customWithdrawalFee
    : round(amount * vatMultiplier, 2);

  // Custom withdrawal fees are inclusive of VAT so work backwards to get the VAT amount
  const vatAmount = customWithdrawalFee
    ? round(customWithdrawalFee - customWithdrawalFee / vatMultiplier, 2)
    : round(amount * vatMultiplier - amount, 2);

  return {
    fee: feeAmount,
    vat: fee.vat,
    vatAmount,
  };
};

export default calculateWithdrawalFee;

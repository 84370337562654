import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import FieldRow from 'components/FormElements/FieldRow';
import { FirmBankDetailsSelector } from 'components/FirmBankDetailsSelector';
import { createFirmEditRoute } from 'adminConstants/routes';
import RecordView from 'components/RecordView';
import CkEditor from 'components/CkEditor';

const useStyles = makeStyles()(() => ({
  marginLeftBtn: {
    marginLeft: 10,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  switchLabel: {
    color: '#656565',
    fontSize: 12,
    margin: '0 10px',

    '&:first-of-type': {
      marginLeft: 0,
    },
  },
  switchNote: {
    color: '#656565',
    fontSize: 12,
    marginLeft: 16,
  },
}));

type Props = any;

const BankDetails: React.FC<Props> = ({
  bank,
  bankError,
  handleCancel,
  handleClickBank,
  id,
  setBank,
  firmId,
  disabled = false,
}) => {
  const { classes } = useStyles();

  return (
    <RecordView>
      <FieldRow
        title="Select payment account"
        centerTitle
        tooltipText={
          <span>
            To add a new bank account, navigate to the{' '}
            <em>Fund bank account details</em> section of your{' '}
            <Link to={createFirmEditRoute(firmId)}>My Firm</Link> page.
          </span>
        }
      >
        <FirmBankDetailsSelector
          firmId={firmId}
          onChange={(firmBankDetailId) =>
            setBank({ ...bank, firmBankDetailId })
          }
          placeholder="Select payment account"
          currentValue={bank.firmBankDetailId}
        />
        {bankError?.firmBankDetailId && (
          <span style={{ color: 'red' }}>{bankError.firmBankDetailId}</span>
        )}
      </FieldRow>

      <FieldRow
        title="Payment helper text"
        informationText="This helper text appears on the bank transfer payment page, alongside bank details."
      >
        <CkEditor
          placeholder="(Optional) Enter up to 30-35 words in this field if you wish to provide additional payment instructions or context, for example how to pay by cheque, or what your custodian’s name is. The system automatically creates payment references for each investor, so please do not add references here."
          onChange={(value: string) => {
            setBank({ ...bank, title: value });
          }}
          value={bank?.title}
          disabled={false}
          onBlur={(value: string) => {
            setBank({ ...bank, title: value });
          }}
          extraLine
        />
      </FieldRow>

      <FieldRow>
        <div className={classes.buttonContainer}>
          <Button variant="outlined" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            className={classes.marginLeftBtn}
            variant="contained"
            color="primary"
            disabled={disabled}
            onClick={handleClickBank}
          >
            {id ? 'Update' : 'Save'}
          </Button>
        </div>
      </FieldRow>
    </RecordView>
  );
};

export default BankDetails;

import { Delete, Edit } from '@mui/icons-material';
import { createIncomeOrExpenseEditRoute } from 'adminConstants/routes';
import AlertDialog from 'components/AlertDialog';
import { AppAutocomplete, AppDatePicker } from 'components/FormElements';
import InteractiveTable, { Column } from 'components/InteractiveTable';
import PageContainer from 'components/PageContainer';
import PageContent from 'components/PageContent';
import PageFilters, { Filter } from 'components/PageFilters';
import SearchField from 'components/SearchField';
import { TableActions } from 'components/Table';
import { useLayoutContext } from 'contexts/LayoutContext';
import { IncomeOrExpenseType } from 'further-types/lp/income-and-expenses';
import { useDisclosure } from 'further-ui/hooks';
import {
  CalendarDay,
  dateToLabel,
  numberToCurrencyString,
} from 'further-ui/utils';
import useFilters from 'hooks/ui/useFilters';
import useHandleTrpcMutation from 'hooks/ui/useHandleTrpcMutation';
import { usePagination } from 'hooks/ui/usePagination';
import api from 'lib/trpcClient';

const pageId = 'income-and-expenses';

const actionTypes = [
  { label: 'Income', value: IncomeOrExpenseType.Income },
  { label: 'Expense', value: IncomeOrExpenseType.Expense },
];

const ListIncomeAndExpenses: React.FC = () => {
  const deleteDisclosure = useDisclosure();
  const { selectedVehicleId } = useLayoutContext();

  const pagination = usePagination({
    orderBy: 'ledgerDate',
    order: 'desc',
    id: pageId,
  });

  const { filters, handleChangeFilters } = useFilters<{
    searchQuery: string;
    actionType: IncomeOrExpenseType | null;
    ledgerDate: Date | null;
  }>(pageId, {
    defaultFilters: {
      searchQuery: '',
      actionType: null,
      ledgerDate: null,
    },
    onFiltersChange: pagination.toFirstPage,
  });

  const { data, isLoading, isFetching } = api.incomeAndExpenses.list.useQuery({
    vehicleId: selectedVehicleId ?? '',
    searchQuery: filters.searchQuery,
    actionType: filters.actionType ?? undefined,
    ledgerDate: filters.ledgerDate
      ? new CalendarDay(filters.ledgerDate)
      : undefined,
    ...pagination.properties,
  });

  const deleteIncomeOrExpense = api.incomeAndExpenses.remove.useMutation(
    useHandleTrpcMutation()({
      successMessage: 'Income or expense deleted successfully',
      invalidationHandler: (utils) => {
        utils.incomeAndExpenses.invalidate();
      },
    }),
  );

  const handleDeleteIncomeOrExpense = () => {
    deleteIncomeOrExpense.mutate({
      id: deleteDisclosure.actionProps.id,
      vehicleId: selectedVehicleId ?? '',
    });
    deleteDisclosure.onClose();
  };

  const columns: Array<Column> = [
    {
      headerName: 'Created date',
      field: 'createdDate',
      renderCell: ({ row }) => dateToLabel(row.createdDate),
      flex: 1,
    },
    {
      headerName: 'Ledger date',
      field: 'ledgerDate',
      renderCell: ({ row }) => dateToLabel(row.ledgerDate),
      flex: 1,
    },
    { headerName: 'Status', field: 'status', flex: 1 },
    {
      headerName: 'Action type',
      field: 'actionType',
      flex: 1,
      renderCell: ({ row }) =>
        row.actionType === IncomeOrExpenseType.Income ? 'Income' : 'Expense',
    },
    { headerName: 'Counterparty', field: 'counterparty', flex: 1 },
    {
      headerName: 'Amount',
      field: 'amount',
      renderCell: ({ row }) =>
        numberToCurrencyString(row.amount, {
          currency: row.currencyCode,
        }),
      flex: 1,
    },
    {
      headerName: 'Actions',
      sortable: false,
      field: 'actions',
      renderCell: ({ row }) => (
        <TableActions
          size="small"
          actions={[
            {
              label: 'Edit',
              icon: Edit,
              color: 'primary',
              link: createIncomeOrExpenseEditRoute(row.id ?? ''),
            },
            {
              label: 'Delete',
              icon: Delete,
              color: 'error',
              onClick: () => deleteDisclosure.stageAction({ id: row.id }),
            },
          ]}
        />
      ),
    },
  ];

  return (
    <PageContainer heading="Income and expenses: Overview" loading={isLoading}>
      <PageContent withSpacing>
        <PageFilters pageId={pageId}>
          <Filter>
            <SearchField
              autoSearchOnDebounce
              name="searchQuery"
              variant="standard"
              placeholder="Search by counterparty"
              fullWidth
              value={filters.searchQuery}
              onSearch={(searchQuery) => {
                handleChangeFilters({ searchQuery });
              }}
              allowEmptyQuery
              iconPlacement="left"
            />
          </Filter>
          <Filter>
            <AppAutocomplete
              name="actionType"
              value={actionTypes.find(
                (type) => type.value === filters.actionType,
              )}
              getOptionLabel={(option) => option.label}
              onChange={(_, newValue) => {
                handleChangeFilters({ actionType: newValue?.value });
              }}
              options={actionTypes}
              placeholder="Action type"
            />
          </Filter>
          <Filter>
            <AppDatePicker
              name="ledgerDate"
              value={filters.ledgerDate}
              onChange={(ledgerDate) => {
                handleChangeFilters({ ledgerDate });
              }}
              placeholder="Select date"
            />
          </Filter>
        </PageFilters>
        <InteractiveTable
          fullHeight
          columns={columns}
          id={`${pageId}-table`}
          loading={isFetching}
          disableTableConfiguration
          pagination={pagination}
          rows={data?.results ?? []}
          rowCount={data?.totalCount ?? 0}
        />

        <AlertDialog
          onClose={deleteDisclosure.onClose}
          open={deleteDisclosure.isOpen}
          title="Are you sure you wish to delete this action?"
          content="This cannot be undone and all information relating to the action will be lost."
          btnLabels={{ confirm: 'Delete income or expense', cancel: 'Go back' }}
          cancelBtnProps={{ variant: 'contained', color: 'primary' }}
          confirmBtnProps={{
            variant: 'outlined',
            color: 'primary',
            disabled: deleteIncomeOrExpense.isPending,
          }}
          onConfirm={handleDeleteIncomeOrExpense}
        />
      </PageContent>
    </PageContainer>
  );
};

export default ListIncomeAndExpenses;

import { Delete } from '@mui/icons-material';
import { styled } from '@mui/material';
import AlertDialog from 'components/AlertDialog';
import Button from 'components/Button';
import { Row } from 'components/Layout';
import { useDisclosure } from 'further-ui/hooks';
import useHandleTrpcMutation from 'hooks/ui/useHandleTrpcMutation';
import api from 'lib/trpcClient';

const DeleteButton = styled(Button)(({ theme }) => ({
  padding: 0,
  marginBottom: theme.spacing(2),
}));

type Props = {
  vehicleId: string;
  bankAccountId: string;
  transactionId: string;
};

const DeleteTransaction: React.FC<Props> = ({
  vehicleId,
  bankAccountId,
  transactionId,
}) => {
  const deleteDisclosure = useDisclosure();

  const deleteOperations =
    api.bankReconciliation.deleteBankAccountOperations.useMutation(
      useHandleTrpcMutation()({
        successMessage: 'Transaction deleted.',
        invalidationHandler: (utils) => {
          utils.bankReconciliation.invalidate();
        },
      }),
    );

  const handleDeleteTransaction = () => {
    deleteOperations.mutate({
      vehicleId,
      bankAccountId,
      operationIds: [transactionId],
    });
  };

  return (
    <Row justify="flex-end">
      <DeleteButton
        size="small"
        color="error"
        onClick={deleteDisclosure.onOpen}
      >
        <Delete />
        Delete transaction
      </DeleteButton>
      <AlertDialog
        open={deleteDisclosure.isOpen}
        onClose={deleteDisclosure.onClose}
        title="Are you sure?"
        content="This will change your overall statement balance. This is generally only required to remove duplicate statement lines."
        onConfirm={handleDeleteTransaction}
        btnLabels={{ confirm: 'Delete', cancel: 'Go back' }}
        confirmBtnProps={{
          color: 'error',
          disabled: deleteOperations.isPending,
        }}
      />
    </Row>
  );
};

export default DeleteTransaction;

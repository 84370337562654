import PageContainer from 'components/PageContainer';
import PageContent from 'components/PageContent';
import { useLayoutContext } from 'contexts/LayoutContext';
import PageLoader from 'components/PageLoader';
import { Link, useHistory, useLocation } from 'react-router-dom';
import api from 'lib/trpcClient';
import {
  Alert,
  AlertTitle,
  alpha,
  List,
  ListItem,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import { ADD_BANK_ACCOUNT, BANK_RECONCILIATION } from 'adminConstants/routes';
import Button from 'components/Button';
import ButtonGroup from 'components/ButtonGroup';
import { useState } from 'react';
import useHandleTrpcMutation from 'hooks/ui/useHandleTrpcMutation';
import Heading from 'components/Heading';
import Spacing from 'components/Spacing';

const Container = styled(Spacing)({
  maxWidth: '400px',
});

const AccountsList = styled(List)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(6),
  padding: 0,
}));

const Account = styled(ListItem, {
  shouldForwardProp: (prop) =>
    !['selected', 'disabled'].includes(prop.toString()),
})<{ selected: boolean; disabled?: boolean }>(
  ({ theme, selected, disabled }) => ({
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.borderColor?.dark ?? ''}`,
    borderRadius: theme.shape.borderRadius,
    height: '90px',
    cursor: disabled ? 'not-allowed' : 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    backgroundColor: disabled
      ? theme.palette.background.default
      : selected
      ? alpha(theme.palette.background.rootColor ?? '', 0.5)
      : theme.palette.background.header,

    '&:hover': {
      backgroundColor: disabled
        ? undefined
        : alpha(theme.palette.background.rootColor ?? '', selected ? 0.5 : 0.1),
    },
    maxWidth: '400px',
  }),
);

const ProcessBankAccount: React.FC = () => {
  const { selectedVehicleId } = useLayoutContext();
  const history = useHistory();
  const [selectedAccounts, setSelectedAccounts] = useState<Array<string>>([]);
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const requisitionId = searchParams.get('ref');
  const bankAccountId = searchParams.get('bankAccountId');

  const { data, isLoading } =
    api.bankReconciliation.getAvaiableBankAccounts.useQuery(
      {
        requisitionId: requisitionId ?? '',
        vehicleId: selectedVehicleId ?? '',
      },

      { enabled: !!requisitionId },
    );

  const addBankAccountsFromBankFeed =
    api.bankReconciliation.addBankAccountsFromBankFeed.useMutation(
      useHandleTrpcMutation()({
        successMessage: 'Accounts added successfully.',
        onSuccess: () => {
          history.replace(BANK_RECONCILIATION);
        },
        invalidationHandler: (apiUtils) =>
          apiUtils.bankReconciliation.invalidate(),
      }),
    );

  const handleAddAccounts = () => {
    if (!data || !selectedAccounts.length) return;

    const accounts = data.filter((account) =>
      selectedAccounts.includes(account.bankFeedAccountId),
    );

    addBankAccountsFromBankFeed.mutate({
      accounts,
      bankAccountId: bankAccountId ?? undefined,
      vehicleId: selectedVehicleId ?? '',
    });
  };

  const pageTitle = 'Bank Reconciliation';
  const pageHeader = bankAccountId
    ? 'Select account to connect to Further'
    : 'Select accounts to add to Further';

  if (!requisitionId || (!isLoading && !data?.length)) {
    return (
      <PageContainer heading={pageTitle}>
        <PageContent header={pageHeader}>
          <Alert severity="error">
            <AlertTitle>Something went wrong</AlertTitle>
            We can't find the account you're looking for.{' '}
            <Link to={ADD_BANK_ACCOUNT}>Click here to try again</Link>.
          </Alert>
        </PageContent>
      </PageContainer>
    );
  }

  if (isLoading) {
    return (
      <PageContainer heading={pageTitle}>
        <PageContent header={pageHeader}>
          <PageLoader />
        </PageContent>
      </PageContainer>
    );
  }

  const handleSelectAccount = (accountId: string) => {
    if (addBankAccountsFromBankFeed.isPending) return;

    // If we are connecting a bank account to the feed, we can add only one account
    if (bankAccountId) {
      setSelectedAccounts([accountId]);
      return;
    }

    if (selectedAccounts.includes(accountId)) {
      setSelectedAccounts(selectedAccounts.filter((id) => id !== accountId));
    } else {
      setSelectedAccounts([...selectedAccounts, accountId]);
    }
  };

  return (
    <PageContainer heading={pageTitle}>
      <PageContent withSpacing>
        <div>
          <Heading variant="h2" noMargin>
            {pageHeader}
          </Heading>
          <Heading variant="h5" noMargin>
            Click an account below to connect it to Further.
          </Heading>
        </div>
        <Container>
          <AccountsList>
            {data?.map((account) => {
              const isAccountAlreadyConnected =
                account.isAccountAlreadyConnected &&
                account.connectedBankAccountId !== bankAccountId;

              return (
                <Tooltip
                  key={account.bankFeedAccountId}
                  title={
                    isAccountAlreadyConnected
                      ? 'This account is already connected to Further.'
                      : ''
                  }
                >
                  <Account
                    onClick={() =>
                      isAccountAlreadyConnected
                        ? null
                        : handleSelectAccount(account.bankFeedAccountId)
                    }
                    selected={selectedAccounts.includes(
                      account.bankFeedAccountId,
                    )}
                    disabled={isAccountAlreadyConnected}
                  >
                    <Typography variant="h6">
                      {account.accountName} ({account.accountCurrency})
                    </Typography>
                    <div>
                      <Typography variant="body2">IBAN</Typography>
                      <Typography variant="body2">{account.iban}</Typography>
                    </div>
                  </Account>
                </Tooltip>
              );
            })}
          </AccountsList>
          <ButtonGroup>
            <Button
              variant="contained"
              color="primary"
              disabled={!selectedAccounts.length}
              loading={addBankAccountsFromBankFeed.isPending}
              onClick={handleAddAccounts}
            >
              {bankAccountId ? 'Add account' : 'Add accounts'}
            </Button>
          </ButtonGroup>
        </Container>
      </PageContent>
    </PageContainer>
  );
};

export default ProcessBankAccount;

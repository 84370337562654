import { Autocomplete, createFilterOptions } from '@mui/material';
import { AppTextInput } from 'components/FormElements';
import { forwardRef } from 'react';

type Props = {
  error?: {
    message?: string;
  };
  placeholder?: string;
  value?: number;
  onChange: (value: number) => void;
  name?: string;
  disabled?: boolean;
};

const VatRateSelector = forwardRef<HTMLDivElement, Props>(
  ({ name, disabled, error, placeholder, value, onChange }, ref) => {
    const filter = createFilterOptions<string>();

    return (
      <Autocomplete
        freeSolo
        disableClearable
        disabled={disabled}
        ref={ref}
        options={['0', '20']}
        renderInput={(params) => (
          <AppTextInput
            {...params}
            name={name}
            error={!!error}
            helperText={error?.message?.toString()}
            type="text"
            placeholder={placeholder}
            onKeyDown={(event) => {
              // check event.key is not a letter, space, or special character
              const isLetter = /^[a-zA-Z]$/.test(event.key);
              const isSpace = event.key === ' ';
              const isSpecialCharacter =
                /[!@#$%^&*()_+\-=[\]{};':"\\|,<>/?]/.test(event.key);

              if (isLetter || isSpace || isSpecialCharacter) {
                event.preventDefault();
              }
            }}
          />
        )}
        value={value?.toString() || ''}
        getOptionLabel={(option) =>
          option || String(option) === '0' ? `${option}%` : ''
        }
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;
          const trimmedInputValue = inputValue.trim();

          const isNumber = !isNaN(Number(trimmedInputValue));
          // Suggest the creation of a new value
          const isExisting = options.some(
            (option) => trimmedInputValue === String(option),
          );
          if (trimmedInputValue !== '' && !isExisting && isNumber) {
            filtered.push(`Use ${trimmedInputValue}`);
          }

          return filtered;
        }}
        onChange={(_, newValue) => {
          if (typeof newValue === 'number') {
            return onChange(newValue);
          }
          const newValueNumber = Number(
            newValue.startsWith('Use ') ? newValue.split(' ')[1] : newValue,
          );
          // if isNaN, set to 0
          if (isNaN(newValueNumber)) {
            return onChange(0);
          }
          onChange(newValueNumber);
        }}
      />
    );
  },
);

export default VatRateSelector;

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Spacing from 'components/Spacing';
import ButtonGroup from 'components/ButtonGroup';
import Button from 'components/Button';
import { AppTextInput, FieldRow } from 'components/FormElements';
import { useState } from 'react';
import { round } from 'lodash';
import { CurrencyCode } from 'further-types/lp/vehicle';
import { numberToCurrencyString } from 'further-ui/utils';
import { NumericFormat } from 'react-number-format';

const Title = styled(DialogTitle)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(4, 6, 0, 6),
  fontSize: '1.25rem',
}));

const Amount = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.text.secondary,
  textAlign: 'right',
}));

const Input = styled(AppTextInput)({
  '& input': {
    textAlign: 'right',
  },
});

type Props = {
  onClose: () => void;
  onSplit: (amount: number) => void;
  transactionAmount: number;
  actionAmount: number;
  currency: CurrencyCode;
};

const SplitActionDialog: React.FC<Props> = ({
  onClose,
  onSplit,
  transactionAmount,
  actionAmount,
  currency,
}) => {
  const [amountToSplit, setAmountToSplit] = useState(() =>
    transactionAmount <= actionAmount ? transactionAmount : 0,
  );

  const handleSplit = () => {
    onSplit(amountToSplit);
  };

  return (
    <Dialog open onClose={onClose} maxWidth="md" fullWidth>
      <Title>
        <span>Split transaction</span>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Title>

      <DialogContent>
        <Spacing size="sm">
          <Spacing size="md">
            <Typography variant="body1" color="text.secondary">
              Split transactions if you need to record a part payment.
            </Typography>
            <Spacing size="sm">
              <FieldRow title="Balance" centerTitle>
                <Amount variant="body2">
                  {numberToCurrencyString(actionAmount, {
                    currency,
                  })}
                </Amount>
              </FieldRow>
              <FieldRow title="Part payment" centerTitle>
                <NumericFormat
                  decimalScale={2}
                  min={0}
                  max={actionAmount}
                  required
                  placeholder="Enter amount"
                  allowNegative={false}
                  allowLeadingZeros={false}
                  thousandSeparator={true}
                  value={amountToSplit}
                  customInput={Input}
                  onValueChange={({ floatValue }) =>
                    setAmountToSplit(floatValue ?? 0)
                  }
                />
              </FieldRow>
              <FieldRow title="Remaining amount" centerTitle>
                <Amount variant="body2">
                  {numberToCurrencyString(
                    round(actionAmount - amountToSplit, 3),
                    {
                      currency,
                    },
                  )}
                </Amount>
              </FieldRow>
            </Spacing>
            <ButtonGroup>
              <Button variant="outlined" onClick={onClose}>
                Cancel
              </Button>
              <Button variant="contained" onClick={handleSplit}>
                Split
              </Button>
            </ButtonGroup>
          </Spacing>
        </Spacing>
      </DialogContent>
    </Dialog>
  );
};

export default SplitActionDialog;

import { BackgroundExportTypes } from 'further-types/background-exports';
import isMongoId from '../../helpers/is-mongo-id';
import { z } from 'zod';

export const createBackgroundExportInput = z.object({
  exportType: z.nativeEnum(BackgroundExportTypes),
  firmId: z
    .string()
    .optional()
    .refine(isMongoId({ optional: true })),
  params: z.object({
    dateRange: z.object({
      startDate: z.date().optional(),
      endDate: z.date().optional(),
    }),
    investorId: z
      .string()
      .optional()
      .refine(isMongoId({ optional: true })),
    externalInvestmentId: z.string().optional(),
  }),
});

export const downloadBackgroundExportInput = z.object({
  exportId: z.string().refine(isMongoId()),
});

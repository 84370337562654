import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import FieldRow from 'components/FormElements/FieldRow';
import TextField from 'components/FormElements/AppTextInput';
import Spacing from 'components/Spacing';
import CurrencySelector from 'components/CurrencySelector';
import { AppAutocomplete, AppSimpleSelect } from 'components/FormElements';
import { FxDataFeed } from 'further-types/lp/vehicle';
import timezones from 'timezones-list';

const fxDataFeedOptions = Object.values(FxDataFeed).map((feed) => ({
  value: feed,
  label: feed,
}));

const timezoneOptions = timezones.map((timezone) => ({
  value: timezone.tzCode,
  label: timezone.name,
}));

type Props = {
  disableChangeOfCurrency: boolean;
};

const GeneralInformation: FC<Props> = ({ disableChangeOfCurrency }) => {
  const { control, setValue } = useFormContext();

  return (
    <Spacing>
      <FieldRow title="Vehicle name*" centerTitle>
        <Controller
          name="vehicleName"
          control={control}
          render={({ fieldState: { error }, field: { ref, ...rest } }) => (
            <TextField
              {...rest}
              inputRef={ref}
              placeholder="e.g. Venture Fund I"
              helperText={error?.message?.toString()}
              fullWidth
            />
          )}
        />
      </FieldRow>

      <FieldRow
        title={disableChangeOfCurrency ? 'Base currency' : 'Base currency*'}
        centerTitle
        tooltipText={
          disableChangeOfCurrency
            ? 'You cannot change the base currency once transactions have been added.'
            : ''
        }
      >
        <Controller
          name="baseCurrency"
          control={control}
          render={({ fieldState: { error }, field: { ref, ...rest } }) => (
            <CurrencySelector
              {...rest}
              error={error}
              disabled={disableChangeOfCurrency}
            />
          )}
        />
      </FieldRow>

      <FieldRow title="Currency feed" centerTitle>
        <Controller
          name="fxDataFeed"
          control={control}
          render={({ fieldState: { error }, field: { value, onChange } }) => (
            <AppSimpleSelect
              options={fxDataFeedOptions}
              fullWidth
              error={!!error}
              value={value}
              onChange={(e) => onChange(e.target.value as FxDataFeed)}
            />
          )}
        />
      </FieldRow>
      <FieldRow title="Timezoe*" centerTitle>
        <Controller
          name="timezone"
          control={control}
          render={({ fieldState: { error }, field: { value } }) => (
            <AppAutocomplete
              name="timezone"
              value={timezoneOptions.find(
                (timezone) => timezone.value === value,
              )}
              getOptionLabel={(option) => option.label}
              onChange={(_, newValue) => {
                setValue('timezone', newValue?.value);
              }}
              options={timezoneOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  error={!!error}
                  helperText={error?.message}
                  placeholder="Select timezone"
                />
              )}
            />
          )}
        />
      </FieldRow>
    </Spacing>
  );
};

export default GeneralInformation;

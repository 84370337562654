import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { PropsWithChildren } from 'react';
import { CurrencyCode } from 'further-types/lp/vehicle';
import { investment as rules } from 'further-ui/validations';

// Define the asset schema for new assets

type Props = {
  values?: rules.CreateInvestmentFormValues;
  vehicleId: string;
  baseCurrency?: CurrencyCode;
};

const FormWrapper: React.FC<PropsWithChildren<Props>> = ({
  children,
  values,
  vehicleId,
}) => {
  const formMethods = useForm<rules.CreateInvestmentFormValues>({
    shouldFocusError: true,
    resolver: zodResolver(rules.CreateInvestmentSchema),
    defaultValues: values ?? {
      ...rules.defaultInvestment,
      vehicleId,
      holdingClasses: [rules.defaultHoldingClass],
    },
    criteriaMode: 'all',
    resetOptions: {
      keepDefaultValues: false,
    },
  });

  return <FormProvider {...formMethods}>{children}</FormProvider>;
};

export default FormWrapper;

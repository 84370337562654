import { List, ListItem, styled, Typography } from '@mui/material';
import Button from 'components/Button';
import { FindInPageOutlined } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { Column } from 'components/Layout';
import AlertDialog from 'components/AlertDialog';
import { useDisclosure } from 'further-ui/hooks';
import { ADD_BANK_ACCOUNT_WITHOUT_FEED } from 'adminConstants/routes';

const Icon = styled(FindInPageOutlined)(({ theme }) => ({
  fontSize: '120px',
  color: theme.palette.text.rootColor,
  opacity: 0.5,
}));

const Paragraph = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  maxWidth: '650px',
  textAlign: 'center',
}));

const Wrapper = styled(Column)(({ theme }) => ({
  padding: theme.spacing(6, 0),
  marginBottom: theme.spacing(6),
}));

const BulletList = styled(List)(({ theme }) => ({
  paddingLeft: theme.spacing(6),
  listStyleType: 'disc',
}));

const Bullet = styled(ListItem)(() => ({
  display: 'list-item',
  padding: 0,
}));

export enum NoResultsMode {
  NoResults = 'no-results',
  NoCountrySelected = 'no-country-selected',
}

type Props = {
  mode: NoResultsMode;
};

const NoResults: React.FC<Props> = ({ mode }) => {
  const history = useHistory();
  const addAccountDisclosure = useDisclosure();

  const headingText =
    mode === NoResultsMode.NoResults
      ? 'No results match your search'
      : 'Please select country to find your bank account.';

  return (
    <>
      <Wrapper centered spacing="sm">
        <Icon />
        <Typography variant="h4">{headingText}</Typography>
        {mode === NoResultsMode.NoResults && (
          <>
            <Paragraph color="text.secondary">
              Try checking your spelling or using a different country. If you
              can't find your bank, add an account without a bank feed to
              manually import transactions into Further.
            </Paragraph>
            <Button variant="outlined" onClick={addAccountDisclosure.onOpen}>
              Add without bank feed
            </Button>
          </>
        )}
      </Wrapper>
      <AlertDialog
        centeredContent={false}
        size="sm"
        open={addAccountDisclosure.isOpen}
        onClose={addAccountDisclosure.onClose}
        onConfirm={() => history.push(ADD_BANK_ACCOUNT_WITHOUT_FEED)}
        title="Add an account without a bank feed"
        btnLabels={{
          confirm: 'Continue',
          cancel: 'Cancel',
        }}
        content={
          <>
            <Typography>You may want to do this if:</Typography>
            <BulletList>
              <Bullet>Your bank doesn't currently provide a bank feed.</Bullet>
              <Bullet>
                Your bank doesn't provide a bank feed for your account type.
              </Bullet>
              <Bullet>You need a petty cash account.</Bullet>
            </BulletList>
            <Typography>
              Transactions downloaded from your bank can be manually imported
              into this account.
            </Typography>
          </>
        }
      />
    </>
  );
};

export default NoResults;

import { useEffect } from 'react';
import {
  useQuery,
  useMutation,
  useQueryClient,
  keepPreviousData,
} from '@tanstack/react-query';
import { api } from 'lib/httpClient';
import { useNotification } from 'hooks/ui/useNotification';
import useApiExceptionHandler from 'hooks/ui/useApiExceptionHandler';

async function fetchById({ queryKey }) {
  const [, { params }] = queryKey;

  const {
    data: { data },
  } = await api.get(`exit/${params.id}`);
  return data;
}

function useStageExitForCreation() {
  return useMutation({
    mutationFn: async (requestBody) => {
      const { data: response } = await api.post(
        `exit/stage-for-creation`,
        requestBody,
      );
      return response;
    },
  });
}

function useStageExitForEdit() {
  return useMutation({
    mutationFn: async (requestBody) => {
      const { data: response } = await api.post(
        `exit/stage-for-edit`,
        requestBody,
      );
      return response;
    },
  });
}

function useCreateExit() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (requestBody) => {
      const { data: response } = await api.post(`exit/create`, requestBody);
      await queryClient.invalidateQueries({ queryKey: ['exits'] });

      return response?.data;
    },
  });
}

function useEditExit() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (requestBody) => {
      const { data: response } = await api.post(
        //@ts-expect-error
        `exit/edit/${requestBody.exitId}`,
        requestBody,
      );
      await queryClient.invalidateQueries({ queryKey: ['exits'] });

      return response?.data;
    },
  });
}

function useDeleteExit() {
  const queryClient = useQueryClient();
  const notification = useNotification();
  const handleApiException = useApiExceptionHandler();

  return useMutation({
    mutationFn: async (props) => {
      //@ts-expect-error
      const { exitId, exitAmendmentId } = props;
      const { data: response } = await api.delete(
        `exit/${exitId}${
          exitAmendmentId ? `/exit-amendment/${exitAmendmentId}` : ''
        }`,
      );

      return response?.data;
    },
    onSuccess: async (_data, props) => {
      //@ts-expect-error
      props.onDeleteSuccess();
      notification.success('Exit deleted successfully.');

      await queryClient.invalidateQueries({ queryKey: ['exits'] });
    },
    onError: (error: Error) => {
      handleApiException(error);
    },
  });
}

export default function useExit({ params = {} }) {
  const notification = useNotification();

  const stageForCreation = useStageExitForCreation();
  const stageForEdit = useStageExitForEdit();

  const create = useCreateExit();
  const edit = useEditExit();
  const deleteExit = useDeleteExit();

  const getById = useQuery({
    queryKey: ['exit', { params }],
    queryFn: fetchById,
    //@ts-expect-error
    enabled: !!params.id,
    placeholderData: keepPreviousData,
    staleTime: 60 * 60 * 1000,
  });

  /// error msgs
  useEffect(() => {
    //@ts-expect-error
    const { responseMsg } = stageForEdit.error?.response?.data || {};
    if (responseMsg) notification.error(responseMsg);
    //@ts-expect-error
  }, [stageForEdit.error?.response?.data?.responseMsg, notification]);

  useEffect(() => {
    //@ts-expect-error
    const { responseMsg } = stageForCreation.error?.response?.data || {};
    if (responseMsg) notification.error(responseMsg);
    //@ts-expect-error
  }, [stageForCreation.error?.response?.data?.responseMsg, notification]);

  useEffect(() => {
    //@ts-expect-error
    const { responseMsg } = create.error?.response?.data || {};
    if (responseMsg)
      notification.error(responseMsg, { autoHideDuration: 12000 });
    //@ts-expect-error
  }, [create.error?.response?.data?.responseMsg, notification]);

  useEffect(() => {
    //@ts-expect-error
    const { responseMsg } = edit.error?.response?.data || {};
    if (responseMsg)
      notification.error(responseMsg, { autoHideDuration: 12000 });
    //@ts-expect-error
  }, [edit.error?.response?.data?.responseMsg, notification]);

  useEffect(() => {
    //@ts-expect-error
    const { responseMsg } = getById.error?.response?.data || {};
    if (responseMsg) notification.error(responseMsg);
    //@ts-expect-error
  }, [getById.error?.response?.data?.responseMsg, notification]);

  return {
    exit: getById,
    stageForEdit,
    stageForCreation,
    create,
    edit,
    deleteExit,
  };
}

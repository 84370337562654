import { Divider } from '@mui/material';
import FieldRow from 'components/FormElements/FieldRow';
import { pluralize } from 'utils/pluralize';
import RecordView from 'components/RecordView';
import AppTable from 'components/Table';
import Spacing from 'components/Spacing';
import { DeliveryType } from 'further-types/custom-updates';
import { Api } from 'further-types/custom-updates';
import { usePagination } from 'hooks/ui/usePagination';

type Props = any; //TODO: define props

const Summary: React.FC<Props> = ({
  data,
  classes,
  selectedTranchesOrCompanies,
}) => {
  const columns = [
    { key: 'name', label: 'Name', sort: false },
    { key: 'email', label: 'Email', sort: false },
    { key: 'type', label: 'User type', sort: false },
  ];

  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination();

  let title: string = 'Recipients';
  if (data.recipientFilter === Api.RecipientFilter.Tranche) {
    title = 'Tranches';
  } else if (data.recipientFilter === Api.RecipientFilter.Company) {
    title = 'Companies';
  }

  let recipientDescription: string = 'Sent to all registered investors';
  if (data.recipientFilter === Api.RecipientFilter.Specific) {
    recipientDescription = 'Sent to specific investors';
  } else if (data.recipientFilter === Api.RecipientFilter.NoSubscriptions) {
    recipientDescription =
      'Sent to all registered investors without subscriptions';
  } else if (
    data.recipientFilter === Api.RecipientFilter.Tranche ||
    data.recipientFilter === Api.RecipientFilter.Company
  ) {
    recipientDescription = selectedTranchesOrCompanies?.join(', ') || '-';
  }

  return (
    <>
      <RecordView>
        <FieldRow title={'Update type'}>Custom update</FieldRow>
        <FieldRow title={title}>{recipientDescription}</FieldRow>
        <FieldRow title="Delivery">
          {data.deliveryToInvestors === DeliveryType.EmailAndPortal
            ? 'Email and portal'
            : data.deliveryToInvestors === DeliveryType.Email
            ? 'Email'
            : 'Portal'}
        </FieldRow>
      </RecordView>
      <Divider className={classes.divider} />
      <RecordView>
        <FieldRow title="Subject line">{data?.subject || '-'}</FieldRow>
        <FieldRow title="Body title">{data?.title || '-'}</FieldRow>
        <FieldRow title="Body text">
          <>
            <div>Dear $investorfirstname,</div>
            <br />
            <div
              className="text-editor"
              dangerouslySetInnerHTML={{
                __html: data?.emailBody,
              }}
            />
            <br />
            Thanks,
            <br />
            {data?.accountManager?.name}
          </>
        </FieldRow>

        {data.attachments?.length > 0 && (
          <FieldRow
            title={`${pluralize('Attachment', data?.attachments?.length)}`}
          >
            {data?.attachments?.map((attachment, index) => {
              return (
                <div key={index} className="mb-1">
                  <a
                    key={index}
                    href={attachment?.preview || attachment?.url}
                    rel="noreferrer"
                    target={'_blank'}
                    className={classes.attachmentLink}
                  >
                    {attachment?.title || 'Attachment'}
                  </a>
                </div>
              );
            })}
          </FieldRow>
        )}
      </RecordView>
      {data.recipients?.length > 0 && (
        <>
          <Divider className={classes.divider} />
          <Spacing size="md">
            <h3>Recipient list</h3>
            <AppTable
              columns={columns}
              tablebody={
                data.recipients?.slice(
                  (page - 1) * rowsPerPage,
                  page * rowsPerPage,
                ) ?? []
              }
              pagination={true}
              page={page}
              rowsPerPage={rowsPerPage}
              count={data.recipients?.length || 0}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Spacing>
        </>
      )}
    </>
  );
};

export default Summary;

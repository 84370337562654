import { useGetRole } from 'hooks/ui/useGetRole';
import { useAdvisersLeanResponse } from 'hooks/data/adviser/useAdvisers';
import Button from 'components/Button';
import { ArrowDropDown } from '@mui/icons-material';
import { useState } from 'react';
import { useInvestor } from 'hooks/data/investor/useInvestor';
import Row from 'components/Layout/Row';
import SearchableMenu from 'components/SearchableMenu';
import AlertDialog from 'components/AlertDialog';
import { useDisclosure } from 'further-ui/hooks';

type Props = {
  investorDetail: {
    _id: string;
    dob: string;
    firmSpecificSettings: Array<{
      firmId: string;
      firmName: string;
      defaultAdviserId?: string;
      disableAllEmails: boolean;
    }>;
  };
};

const DefaultAdviserRow = ({ investorDetail }: Props) => {
  const { firmId } = useGetRole();
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);

  const editDisclosure = useDisclosure<{ adviserId: string }>();
  const deleteDisclosure = useDisclosure();

  const { setDefaultAdviser, isSettingDefaultAdviser } = useInvestor(
    investorDetail._id,
  );

  const { data, isLoading } = useAdvisersLeanResponse({
    firmId,
    order: 'asc',
    orderBy: 'fullName',
  });

  const advisers = data?.advisers ?? [];

  const handleUpdateInvestor = (
    adviserId: string | null,
    removeAllConnections?: boolean,
  ) => {
    setDefaultAdviser({
      defaultAdviserId: adviserId ?? null,
      removeAllConnections,
    });
    setMenuAnchorEl(null);
  };

  const adviser = advisers.find(
    (adviser) =>
      adviser._id ===
      investorDetail?.firmSpecificSettings?.find(
        (setting) => setting.firmId === firmId,
      )?.defaultAdviserId,
  );

  const getAdviserName = (adviser) => `${adviser.fullName} (${adviser.email})`;

  if (isSettingDefaultAdviser || isLoading) return <>Loading...</>;

  return (
    <div>
      {!isLoading && !isSettingDefaultAdviser && (
        <Row spacing="xs">
          {adviser ? <div>{getAdviserName(adviser)}</div> : null}
          <Button
            aria-controls="select-firm-menu"
            aria-haspopup="true"
            color="primary"
            variant="contained"
            size="extra-small"
            endIcon={<ArrowDropDown />}
            onClick={(event) => setMenuAnchorEl(event.currentTarget)}
          >
            {adviser ? 'Edit' : 'Add new default adviser'}
          </Button>
          {adviser && (
            <Button
              color="error"
              variant="outlined"
              size="extra-small"
              sx={{
                height: '24px',
              }}
              onClick={() => {
                deleteDisclosure.stageAction({});
              }}
            >
              Delete
            </Button>
          )}
        </Row>
      )}
      {menuAnchorEl && (
        <SearchableMenu
          id="select-firm-menu"
          anchorEl={menuAnchorEl}
          onClose={() => setMenuAnchorEl(null)}
          options={advisers}
          getOptionLabel={getAdviserName}
          onSelect={(adviser) =>
            editDisclosure.stageAction({ adviserId: adviser._id })
          }
          searchPlaceholder="Search by adviser name or email"
        />
      )}
      <AlertDialog
        onClose={editDisclosure.onClose}
        onConfirm={() => {
          handleUpdateInvestor(editDisclosure.actionProps.adviserId);
          editDisclosure.onClose();
        }}
        open={editDisclosure.isOpen}
        title={adviser ? 'Edit default adviser' : 'Add default adviser'}
        content={
          adviser ? (
            <p>
              This will change the adviser on all existing subscriptions made by
              this investor. The previous default adviser will not be able to
              see any subscription details once this change has taken place.
            </p>
          ) : (
            <p>
              You are adding a new default adviser for this investor. This will
              add the default adviser to all existing subscriptions made by the
              investor, and will override any adviser(s) currently selected on
              the investor’s existing subscriptions.
            </p>
          )
        }
        confirmBtnProps={{ variant: 'contained', color: 'primary' }}
        cancelBtnProps={{ variant: 'outlined', color: 'primary' }}
        btnLabels={{
          cancel: 'Go back',
          confirm: adviser ? 'Proceed' : 'Add default adviser',
        }}
      />
      <AlertDialog
        onClose={deleteDisclosure.onClose}
        onConfirm={() => {
          handleUpdateInvestor(null, false);
          deleteDisclosure.onClose();
        }}
        onAlternateConfirm={() => {
          handleUpdateInvestor(null, true);
          deleteDisclosure.onClose();
        }}
        open={deleteDisclosure.isOpen}
        title="Removing default adviser"
        content={
          <p>
            Do you wish to remove this adviser from all subscriptions connected
            to this investor? The adviser will not be able to see any
            subscription details if they are removed.
          </p>
        }
        confirmBtnProps={{
          variant: 'contained',
          color: 'primary',
          whiteSpace: 'nowrap',
        }}
        alternateConfirmBtnProps={{
          variant: 'contained',
          color: 'error',
          whiteSpace: 'nowrap',
        }}
        cancelBtnProps={{
          variant: 'outlined',
          color: 'primary',
          whiteSpace: 'nowrap',
        }}
        btnLabels={{
          cancel: 'Go back',
          confirm: 'Keep connections',
          alternateConfirm: 'Remove connections',
        }}
      />
    </div>
  );
};

export default DefaultAdviserRow;

import api from 'lib/trpcClient';
import { AllocationRule } from 'further-types/lp/allocations';

export type GenerateAllocationsParams = {
  allocationRule: AllocationRule;
  vehicleId: string;
  amount: number;
  excludedCommitmentIds: string[];
  currentAction?: { incomeOrExpenseId?: string };
  linkedActionId?: string | null;
};

const useAllocationsApi = () => {
  const {
    data: initialAllocationsData,
    mutate,
    isPending,
  } = api.allocations.generateAllocations.useMutation();

  const generateAllocations = ({
    allocationRule,
    vehicleId,
    amount,
    excludedCommitmentIds,
    currentAction,
    linkedActionId,
  }: GenerateAllocationsParams) => {
    return mutate({
      allocationRule,
      vehicleId,
      amount,
      excludedCommitmentIds,
      currentAction,
      linkedActionId,
    });
  };

  return {
    initialAllocationsData,
    generateAllocations,
    isPending,
  };
};

export default useAllocationsApi;

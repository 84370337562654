import {
  format,
  parse,
  isValid,
  isWithinInterval,
  startOfDay,
  startOfMonth,
  addMonths,
  eachMonthOfInterval,
  isFirstDayOfMonth,
  isSameDay,
  isBefore,
} from 'date-fns';
import {
  toDate,
  format as formatInTimeZone,
  toZonedTime,
  fromZonedTime,
} from 'date-fns-tz';

export function dateToLabel(
  date?: string | Date,
  fallback = 'N/A',
  withTimestamp = false,
  showAsUTC = false,
  forcedFormatString: string | null = null,
) {
  if (!date || !isValid(new Date(date))) return fallback;

  const formatString =
    forcedFormatString ??
    (withTimestamp ? 'dd/MM/yyyy HH:mm:ss' : 'dd/MM/yyyy');

  return showAsUTC
    ? `${formatInTimeZone(toZonedTime(date, 'UTC'), formatString, {
        timeZone: 'UTC',
      })} UTC`
    : format(new Date(date), formatString);
}

export function dateofBirthToLabel(dateISOStr: string, fallback = '') {
  const dateObj = new Date(dateISOStr);

  if (!dateISOStr || !isValid(dateObj)) return fallback;

  // date of birth is saved as 00:00:00 for UTC in the DB
  return format(
    parse(dateISOStr.split('T')[0], 'yyyy-MM-dd', new Date()),
    'dd/MM/yyyy',
  );
}

export function isInTaxYear(
  taxYear: {
    start: number;
    end: number;
  },
  date?: Date,
) {
  return !!(
    date &&
    isWithinInterval(date, {
      start: new Date(taxYear.start, 3, 6),
      end: getTaxYearEndDate(taxYear),
    })
  );
}

export function getPreviousTaxYear(date: Date) {
  const currentTaxYear = getTaxYear(date);

  return {
    start: currentTaxYear.start - 1,
    end: currentTaxYear.end - 1,
    startDate: new Date(currentTaxYear.start - 1, 3, 6, 0, 0, 0),
    endDate: new Date(currentTaxYear.end - 1, 3, 5, 23, 59, 59),
  };
}

export function getTaxYear(date: Date) {
  const currentYear = date.getFullYear();

  if (date >= new Date(currentYear, 3, 6)) {
    return {
      start: currentYear,
      end: currentYear + 1,
      startDate: new Date(currentYear, 3, 6, 0, 0, 0),
      endDate: new Date(currentYear + 1, 3, 5, 23, 59, 59),
    };
  } else {
    return {
      start: currentYear - 1,
      end: currentYear,
      startDate: new Date(currentYear - 1, 3, 6, 0, 0, 0),
      endDate: new Date(currentYear, 3, 5, 23, 59, 59),
    };
  }
}

export function toRequestDate(date?: Date | null) {
  return date ? format(date, 'yyyy-MM-dd') : date;
}

export function fromRequestDate(date?: string) {
  return date
    ? toDate(date, {
        timeZone: 'UTC',
      })
    : new Date();
}

export function getTaxYearEndDate(taxYear: { start: number; end: number }) {
  return new Date(taxYear.end, 3, 5, 23, 59, 59);
}

export function getTaxYearDatesWithPreviousYears(
  date: Date,
  numberOfPreviousYears: number,
) {
  const taxYears: Array<{
    start: number;
    end: number;
    startDate: Date;
    endDate: Date;
  }> = [];
  for (let i = 0; i <= numberOfPreviousYears; i++) {
    const currentTaxYear = getTaxYear(date);
    const taxYear = {
      start: currentTaxYear.start - i,
      end: currentTaxYear.end - i,
      startDate: new Date(currentTaxYear.start - i, 3, 6, 0, 0, 0),
      endDate: new Date(currentTaxYear.end - i, 3, 5, 23, 59, 59),
    };
    taxYears.push(taxYear);
  }
  return taxYears;
}

export function getDatesRangeForStoringInvestmentBalances(
  startDate: Date,
  now: Date = new Date(),
) {
  const firstDayOfStartMonth = fromZonedTime(startOfMonth(startDate), 'UTC');

  // Only include current month if startDate is the 1st
  const intervalStartingDate = isFirstDayOfMonth(startDate)
    ? firstDayOfStartMonth
    : addMonths(firstDayOfStartMonth, 1);

  if (
    isBefore(now, intervalStartingDate) ||
    isSameDay(now, intervalStartingDate)
  ) {
    return [now];
  }

  // Add first of each month
  const monthlyDates = eachMonthOfInterval({
    start: intervalStartingDate,
    end: now,
  }).map((date: Date) => fromZonedTime(startOfDay(date), 'UTC'));

  // Add current date if not already included
  if (!isSameDay(monthlyDates[monthlyDates.length - 1], now)) {
    monthlyDates.push(now);
  }

  return monthlyDates;
}

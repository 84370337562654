import { Button } from '@mui/material';

import AppDatePicker from 'components/FormElements/AppDatePicker';
import AppPasswordInput from 'components/FormElements/AppPasswordTextField';
import AppSelectBox from 'components/FormElements/AppSelectBox';
import FieldRow from 'components/FormElements/FieldRow';

import AdviserSelect from 'components/AdviserSelect';
import AppRadioButton from 'components/FormElements/AppRadioButton';
import { investorTypeDropdownList } from 'further-types/investor';
import { UserType } from 'further-types/user';
import { InvestorFormValues } from 'helpers/investor/investorForm';
import { useFirm } from 'hooks/data/firm/useFirm';
import useResendVerificationLinkMutation from 'hooks/data/user/useResendVerificationLink';
import { useGetRole } from 'hooks/ui/useGetRole';
import { Controller, useFormContext } from 'react-hook-form';
import InvestorAddress from './InvestorAddress';
import InvestorNationality from './InvestorNationality';
import SwitchField from './SwitchField';
import TextField from './TextField';

type Props = {
  id?: string;
  isSuperAdmin: boolean;
};

const PersonalDetails: React.FC<Props> = ({ id, isSuperAdmin }) => {
  const { control, watch } = useFormContext<InvestorFormValues>();

  const isDeceased = watch('isDeceased');
  const isEmailVerified = watch('isEmailVerify');
  const { firmId } = useGetRole();
  const { firm } = useFirm({ params: { firmId } });

  const {
    mutate: resendVerificationLinkMutation,
    isPending: isPendingVerificationLinkMutation,
  } = useResendVerificationLinkMutation();

  const isDisabled =
    isEmailVerified || isPendingVerificationLinkMutation || isSuperAdmin;

  const handleResendVerification = () => {
    if (!id) throw new Error('Investor ID is required');

    resendVerificationLinkMutation({
      id,
      userType: UserType.Investor,
      domain: firm?.data?.domain,
    });
  };

  const { isFirmManager } = useGetRole();

  return (
    <>
      <FieldRow title="First name*" centerTitle>
        <TextField name="firstName" control={control} />
      </FieldRow>
      <FieldRow title="Middle name" centerTitle>
        <TextField name="middleName" control={control} />
      </FieldRow>
      <FieldRow title="Last name*" centerTitle>
        <TextField name="lastName" control={control} />
      </FieldRow>
      <FieldRow title="Email address*" centerTitle>
        <TextField name="email" control={control} />
      </FieldRow>
      {isSuperAdmin && id && (
        <FieldRow title="Password" centerTitle>
          <Controller
            name="newPassword"
            control={control}
            render={({ field: { ref, ...rest }, fieldState: { error } }) => (
              <AppPasswordInput
                {...rest}
                inputRef={ref}
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </FieldRow>
      )}
      <FieldRow title="Phone number*" centerTitle>
        <TextField name="phoneNumber" control={control} />
      </FieldRow>
      <FieldRow title="Date of birth*" centerTitle>
        <Controller
          name="dob"
          control={control}
          render={({
            field: { ref, onChange, value, ...rest },
            fieldState: { error },
          }) => (
            <AppDatePicker
              {...rest}
              maxDate={new Date()} // should be set to today, and NOT to 18 years prior to current date
              inputRef={ref}
              error={!!error}
              value={value ?? null}
              helperText={error?.message}
              openOnFocus
              onChange={(date) => {
                onChange(date);
              }}
            />
          )}
        />
      </FieldRow>
      {id ? (
        <FieldRow title="Date registered*" centerTitle>
          <Controller
            name="dateRegister"
            control={control}
            render={({ field: { ref, ...rest }, fieldState: { error } }) => (
              <AppDatePicker
                {...rest}
                disabled
                onChange={() => {}}
                inputRef={ref}
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </FieldRow>
      ) : null}
      {isSuperAdmin ? (
        <FieldRow title="Investor type" centerTitle>
          <Controller
            name="investorType"
            control={control}
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            render={({ field: { ref, ...rest }, fieldState: { error } }) => (
              <AppSelectBox
                {...rest}
                data={investorTypeDropdownList}
                valueKey="value"
                labelKey="label"
                variant="outlined"
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </FieldRow>
      ) : null}
      <FieldRow title="Job title" centerTitle>
        <TextField name="profession" control={control} />
      </FieldRow>
      <FieldRow title="Sector" centerTitle>
        <TextField name="sector" control={control} />
      </FieldRow>

      <FieldRow title="Will the investor be claiming tax relief?" centerTitle>
        <span>No</span>
        <SwitchField name="isClamingTax" control={control} />
        <span>Yes</span>
      </FieldRow>
      {id ? (
        <FieldRow title="Email verified?" centerTitle>
          <Controller
            name="isEmailVerify"
            control={control}
            render={({
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              field: { ref, onChange, value, ...rest },
              fieldState: { error },
            }) => (
              <AppRadioButton
                {...rest}
                label={'Yes'}
                checked={!!value}
                error={!!error}
                helperText={error?.message}
                onChange={(e) => {
                  onChange(e.target.checked);
                }}
              />
            )}
          />

          <Button
            variant="outlined"
            color="primary"
            disabled={isDisabled}
            onClick={handleResendVerification}
          >
            Resend verification email
          </Button>
        </FieldRow>
      ) : null}
      {/* Address */}
      <InvestorAddress control={control} />
      <InvestorNationality control={control} />
      <FieldRow title="Vulnerable client" centerTitle>
        <span>No</span>
        <SwitchField name="isVulnerableClient" control={control} />
        <span>Yes</span>
      </FieldRow>
      <FieldRow title="Deceased" centerTitle>
        <span>No</span>
        <SwitchField name="isDeceased" control={control} />
        <span>Yes</span>
      </FieldRow>
      {isDeceased && (
        <FieldRow title="Date of death*" centerTitle>
          <Controller
            name="dateOfDeath"
            control={control}
            render={({
              field: { ref, onChange, value, ...rest },
              fieldState: { error },
            }) => (
              <AppDatePicker
                {...rest}
                maxDate={new Date()}
                inputRef={ref}
                error={!!error}
                value={value ?? null}
                helperText={error?.message}
                openOnFocus
                onChange={(date) => {
                  onChange(date);
                }}
              />
            )}
          />
        </FieldRow>
      )}
      <FieldRow title="Requires hard copy reports" centerTitle>
        <span>No</span>
        <SwitchField name="requiresHardCopyReports" control={control} />
        <span>Yes</span>
      </FieldRow>
      <FieldRow title="UK remittance taxpayer (BIR)" centerTitle>
        <span>No</span>
        <SwitchField name="isUkRemittanceTaxpayer" control={control} />
        <span>Yes</span>
      </FieldRow>
      {isFirmManager && !id && (
        <FieldRow title="Default adviser" centerTitle>
          <Controller
            name="defaultAdviserId"
            control={control}
            render={({ field }) => (
              <AdviserSelect
                adviserId={field.value}
                onChange={(adviser) => field.onChange(adviser?._id ?? null)}
                placeholder="Select default adviser"
                fullWidth
                id="defaultAdviserId"
              />
            )}
          />
        </FieldRow>
      )}
    </>
  );
};

export default PersonalDetails;

interface NumberToCurrencyStringOptions {
  hidePennies?: boolean;
  hidePenniesIfInteger?: boolean;
  currency?: string;
  unlimitedDp?: boolean;
  fallback?: string;
  minZero?: boolean;
  negativeInBrackets?: boolean;
}
export const numberToCurrencyDefaultOptions: NumberToCurrencyStringOptions = {
  hidePennies: false,
  hidePenniesIfInteger: false,
  currency: 'GBP',
  unlimitedDp: false,
  fallback: '',
  minZero: false,
  negativeInBrackets: false,
};

export const numberToCurrencyString = (
  input?: number,
  options: NumberToCurrencyStringOptions = numberToCurrencyDefaultOptions,
) => {
  const {
    hidePennies = numberToCurrencyDefaultOptions.hidePennies,
    hidePenniesIfInteger = numberToCurrencyDefaultOptions.hidePenniesIfInteger,
    currency = numberToCurrencyDefaultOptions.currency,
    unlimitedDp = numberToCurrencyDefaultOptions.unlimitedDp,
    fallback = '',
    minZero = numberToCurrencyDefaultOptions.minZero,
    negativeInBrackets = numberToCurrencyDefaultOptions.negativeInBrackets,
  } = options;

  if (!input && input !== 0) return fallback;

  let inputNumber = typeof input === 'number' ? input : Number(input);

  if (minZero && inputNumber < 0) {
    inputNumber = 0;
  }

  const isInteger = Number.isInteger(inputNumber);

  const fractionDigitsConfig =
    (isInteger && !!hidePenniesIfInteger) || !!hidePennies
      ? {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }
      : {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        };

  let output;
  if (unlimitedDp) {
    output = inputNumber.toLocaleString(undefined, {
      maximumFractionDigits: 20, // just set a really high number for maximumFractionDigits
      style: 'currency',
      currency,
    });
  } else {
    output = inputNumber.toLocaleString(undefined, {
      ...fractionDigitsConfig,
      style: 'currency',
      currency,
    });
  }

  if (output.startsWith('US$')) {
    return output.replace(/^US\$/, '$');
  }

  if (negativeInBrackets && inputNumber < 0) {
    return `(${output.replace('-', '')})`;
  }

  return output;
};

export const numberToShorthandCurrencyString = (input: number) => {
  const suffixes = ['', '', 'm', 'bn'];

  if (input < 1000000)
    return numberToCurrencyString(input, { hidePennies: true });

  const suffixIndex = Math.floor(Math.log10(Math.abs(input)) / 3);
  const scaledValue = input / Math.pow(10, suffixIndex * 3);

  const formattedValue = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
  }).format(scaledValue);

  return formattedValue + suffixes[suffixIndex];
};

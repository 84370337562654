import { RouteComponentProps, RouteProps, Route } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import PageLoader from 'components/PageLoader';
import ErrorBoundary from 'components/ErrorBoundary';

const RestrictedRoute: React.FC<
  RouteProps & {
    component: React.FC<RouteComponentProps>;
    useErrorBoundary?: boolean;
  }
> = ({ component, useErrorBoundary, ...rest }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <PageLoader />,
  });

  return (
    <Route
      render={(routeProps) =>
        useErrorBoundary ? (
          <ErrorBoundary>
            <Component {...routeProps} />
          </ErrorBoundary>
        ) : (
          <Component {...routeProps} />
        )
      }
      {...rest}
    />
  );
};

export default RestrictedRoute;

import { FC, useState, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import { dateToLabel, numberToCurrencyString } from 'further-ui/utils';
import PaymentsReconciliationTable from '../Investment/EditInvestment/PaymentsReconciliationTable';
import { PaymentExpectationApi } from 'further-types/payment';
import Grid from '@mui/material/Grid2';
import GridContainer from 'components/GridContainer';
import { AppTextInput } from 'components/FormElements';
import { useInvestmentUpdateAPI } from 'hooks/data/investment/useInvestments';
import { usePayments } from 'hooks/data/investment/usePayments';
import { InfoOutlined } from '@mui/icons-material';

type Props = {
  payment: PaymentExpectationApi.OutstandingPayment;
};

const Container = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  padding: '1.5rem',
  borderRadius: '0.25rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
}));

const TooltipIcon = styled(InfoOutlined)({
  fontSize: '16px',
  cursor: 'pointer',
  position: 'relative',
  top: '2px',
  marginLeft: '0.5rem',
});

const Label = styled(Typography)({
  fontWeight: 400,
  fontSize: '14px',
  marginBottom: '1rem',
});

const ValueContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flex: 1,
});

const Value = styled(Typography)({
  fontWeight: 600,
  fontSize: '14px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const PaymentExpectationRow: FC<Props> = ({ payment }) => {
  const [initialExternalInvestmentId, setInitialExternalInvestmentId] =
    useState(payment.investment?.externalInvestmentId);
  const [externalInvestmentId, setExternalInvestmentId] = useState(
    payment.investment?.externalInvestmentId,
  );

  const update = useInvestmentUpdateAPI(payment.investment?._id);
  const payments = usePayments(payment.investment?._id, payment._id);

  const onInvestmentIdSave = () => {
    update.mutate({ externalInvestmentId });
  };

  useEffect(() => {
    if (update.isSuccess) setInitialExternalInvestmentId(externalInvestmentId);
  }, [update.isSuccess]);

  return (
    <Container>
      <GridContainer>
        <Grid size={{ xs: 4 }} display="flex" flexDirection="column">
          <Label>Name</Label>
          <ValueContainer>
            <Value>{payment.investor?.fullName}</Value>
          </ValueContainer>
        </Grid>
        <Grid size={{ xs: 4 }} display="flex" flexDirection="column">
          <Label>Email</Label>
          <ValueContainer>
            <Value>{payment.investor?.email}</Value>
          </ValueContainer>
        </Grid>
        <Grid size={{ xs: 4 }} display="flex" flexDirection="column">
          <Label>Tranche</Label>
          <ValueContainer>
            <Value>{payment.investment?.fund?.fundName}</Value>
          </ValueContainer>
        </Grid>
        <Grid size={{ xs: 4 }} display="flex" flexDirection="column">
          <Label>Amount</Label>
          <ValueContainer>
            <Value>
              {numberToCurrencyString(payment.amount)}
              <Tooltip
                leaveDelay={250}
                arrow
                title={`This figure is made up of ${numberToCurrencyString(
                  payment.investment?.investmentAmount,
                )} total commitment and ${numberToCurrencyString(
                  payment.investment?.adviserFee ?? 0,
                )} adviser fee.`}
                placement="top"
              >
                <TooltipIcon />
              </Tooltip>
            </Value>
          </ValueContainer>
        </Grid>
        <Grid size={{ xs: 4 }} display="flex" flexDirection="column">
          <Label>Subscription date</Label>
          <ValueContainer>
            <Value>{dateToLabel(payment.investment?.investmentDate)}</Value>
          </ValueContainer>
        </Grid>
        <Grid
          size={{ xs: 4 }}
          display="flex"
          flexDirection="column"
          alignSelf="flex-end"
        >
          <Label>Subscription ID</Label>
          <ValueContainer gap={1}>
            <AppTextInput
              value={externalInvestmentId}
              onChange={(e) => {
                setExternalInvestmentId(e.target.value);
              }}
            />
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={onInvestmentIdSave}
              disabled={initialExternalInvestmentId === externalInvestmentId}
            >
              Save
            </Button>
          </ValueContainer>
        </Grid>
      </GridContainer>

      {!payments.isLoading ? (
        <PaymentsReconciliationTable
          key={payment._id}
          investmentId={payment.investment?._id}
          firmId={payment.investment?.firmId}
          payments={payments.data ?? []}
          darkerTableHeading={true}
        />
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <CircularProgress size={20} />
        </Box>
      )}
    </Container>
  );
};

export default PaymentExpectationRow;

import { alpha, styled, Typography } from '@mui/material';
import Heading from 'components/Heading';
import Spacing from 'components/Spacing';
import { UploadFileResult } from 'hooks/ui/useFileUpload';
import ButtonGroup from 'components/ButtonGroup';
import Button from 'components/Button';
import api from 'lib/trpcClient';
import { useLayoutContext } from 'contexts/LayoutContext';
import PageLoader from 'components/PageLoader';
import InteractiveTable from 'components/InteractiveTable';
import { usePagination } from 'hooks/ui/usePagination';
import { numberToCurrencyString } from 'further-ui/utils';
import { pluralize } from 'utils/pluralize';
import {
  CheckCircleOutlineOutlined,
  ErrorOutlineOutlined,
} from '@mui/icons-material';
import { Row } from 'components/Layout';
import useHandleTrpcMutation from 'hooks/ui/useHandleTrpcMutation';

const Table = styled(InteractiveTable)(({ theme }) => ({
  ['& .statement-entry-with-error']: {
    backgroundColor: alpha(theme.palette.error.main, 0.3),

    ['&:hover']: {
      backgroundColor: alpha(theme.palette.error.main, 0.4),
    },
  },
}));

const columns = [
  {
    field: 'date',
    headerName: 'Date',
    renderCell: ({ row }) => row.date.toLabel(),
    flex: 1,
  },
  {
    field: 'counterparty',
    headerName: 'Counterparty',
    flex: 1,
  },
  {
    field: 'description',
    headerName: 'Description',
    flex: 1,
  },
  {
    field: 'spent',
    headerName: 'Spent',
    flex: 1,
    renderCell: ({ row }) =>
      numberToCurrencyString(row.spent, {
        fallback: '-',
        currency: row.currency,
      }),
  },
  {
    field: 'received',
    headerName: 'Received',
    flex: 1,
    renderCell: ({ row }) =>
      numberToCurrencyString(row.received, {
        fallback: '-',
        currency: row.currency,
      }),
  },
];

type Props = {
  uploadedFile: UploadFileResult;
  dateFormat: string;
  onPreviousStep: () => void;
  onNextStep: (importedTransactions?: number) => void;
  bankAccountId: string;
};

const Review: React.FC<Props> = ({
  uploadedFile,
  dateFormat,
  onPreviousStep,
  onNextStep,
  bankAccountId,
}) => {
  const { selectedVehicleId } = useLayoutContext();
  const pagination = usePagination({ order: 'asc', orderBy: 'date' });

  const { data, isLoading } =
    api.bankReconciliation.bankStatement.getPreview.useQuery({
      bankAccountId,
      vehicleId: selectedVehicleId ?? '',
      dateFormat: dateFormat ?? '',
      bankStatementFilePath: uploadedFile.filePath,
      ...pagination,
    });

  const { operations, totalCount, totalErrors } = data ?? {
    operations: [],
    totalCount: 0,
    totalErrors: 0,
  };

  const importBankStatement =
    api.bankReconciliation.bankStatement.import.useMutation(
      useHandleTrpcMutation()({
        invalidationHandler: (utils) => {
          utils.bankReconciliation.invalidate();
        },
        onSuccess: () => onNextStep(totalCount),
      }),
    );

  const handleSubmit = () => {
    if (totalErrors || !dateFormat || !operations?.length) {
      return;
    }

    importBankStatement.mutate({
      vehicleId: selectedVehicleId ?? '',
      bankStatementFilePath: uploadedFile.filePath,
      bankAccountId,
      dateFormat,
    });
  };

  const SummaryIcon = totalErrors
    ? ErrorOutlineOutlined
    : CheckCircleOutlineOutlined;
  const summaryText = totalErrors
    ? `${totalErrors} ${pluralize('error', totalErrors)} found`
    : `${totalCount} ${pluralize(
        'transaction',
        totalCount,
      )} are ready to import`;
  const iconColor = totalErrors ? 'error' : 'primary';

  return (
    <Spacing size="lg">
      <Spacing size="sm">
        <Heading variant="h3" noMargin>
          Review and complete import
        </Heading>

        <Row centered spacing="xs">
          <SummaryIcon color={iconColor} fontSize="large" />
          <Typography variant="body1" color="textSecondary">
            {summaryText}
          </Typography>
        </Row>
      </Spacing>

      <Spacing size="sm">
        <Heading variant="h3" noMargin>
          Transaction preview
        </Heading>
        {isLoading ? (
          <PageLoader />
        ) : (
          <Table
            id="bank-statement-preview"
            disableSorting
            disableTableConfiguration
            columns={columns}
            rows={operations}
            rowCount={totalCount}
            pagination={pagination}
            getRowClassName={(params) => {
              return params.row.hasError ? 'statement-entry-with-error' : '';
            }}
          />
        )}
      </Spacing>
      <ButtonGroup>
        <Button
          variant="outlined"
          onClick={onPreviousStep}
          disabled={importBankStatement.isPending}
        >
          Back
        </Button>
        <Button
          variant="contained"
          disabled={!dateFormat || !operations?.length || !!totalErrors}
          onClick={handleSubmit}
          loading={importBankStatement.isPending}
        >
          Complete import
        </Button>
      </ButtonGroup>
    </Spacing>
  );
};

export default Review;

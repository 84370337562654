import { sumBy } from 'lodash';
import { WithdrawalStatus } from 'further-types/withdrawal';
import {
  numberToCurrencyString,
  dateToLabel,
  DateRange,
  getDateRange,
} from 'further-ui/utils';
import {
  RequestSourceToLabelMap,
  StatusToLabelMap,
} from 'adminConstants/withdrawalProperties';
import Table, { FooterCell, FooterRow, TableActions } from 'components/Table';
import PageContainer from 'components/PageContainer';
import { usePagination } from 'hooks/ui/usePagination';
import { Close, Undo } from '@mui/icons-material';
import FiltersAndActions from './FiltersAndActions';
import NotesAndAttachmentColumn from './NotesAndAttachmentColumn';
import useFilters from 'hooks/ui/useFilters';

import {
  useExportWithdrawals,
  useGetWithdrawals,
  useRejectWithdrawal,
  useRevertWithdrawal,
} from 'hooks/data/withdrawal/useWithdrawal';
import PageContent from 'components/PageContent';
import { useDebounce } from 'usehooks-ts';

type Withdrawal = ReturnType<
  typeof useGetWithdrawals
  //@ts-expect-error
>['data']['withdrawals'][number];

const ReviewWithdrawals: React.FC = () => {
  const pagination = usePagination({
    id: 'withdrawal-requests-table',
    orderBy: 'createdAt',
    order: 'desc',
  });
  const { filters, handleChangeFilters } = useFilters<{
    dateRange?: DateRange;
    investorName: string;
    status: WithdrawalStatus;
  }>('withdrawal-requests-table', {
    defaultFilters: {
      investorName: '',
      dateRange: undefined,
      status: WithdrawalStatus.Requested,
    },
    onFiltersChange: pagination.toFirstPage,
  });
  const rejectWithdrawal = useRejectWithdrawal();
  const revertWithdrawal = useRevertWithdrawal();
  const debouncedInvestorName = useDebounce(filters.investorName, 250);
  //@ts-expect-error
  const withdrawals = useGetWithdrawals({
    ...pagination.properties,
    ...filters,
    investorName: debouncedInvestorName,
    ...getDateRange(filters.dateRange),
  });
  //@ts-expect-error
  const exportWithdrawals = useExportWithdrawals({
    ...pagination.properties,
    ...filters,
    investorName: debouncedInvestorName,
    ...getDateRange(filters.dateRange),
  });

  const handleExport = (sendToCustodian?: boolean) => {
    exportWithdrawals.mutate(!!sendToCustodian);
  };

  const columns = [
    {
      label: 'Investor Name',
      key: 'investorName',
      render: (elm: Withdrawal) => elm.investor.fullName,
    },
    {
      label: 'Net withdrawal amount',
      key: 'amount',
      render: (elm: Withdrawal) =>
        numberToCurrencyString(elm?.amount - (elm?.withdrawalFee || 0)),
    },
    {
      label: 'Withdrawal fee',
      key: 'withdrawalFee',
      render: (elm: Withdrawal) =>
        numberToCurrencyString(elm?.withdrawalFee || 0),
    },
    {
      label: 'Withdrawal request date',
      key: 'createdAt',
      render: (elm: Withdrawal) => dateToLabel(elm?.createdAt),
    },
    {
      label: 'Withdrawal type',
      key: 'requestSource',
      render: (elm: Withdrawal) => RequestSourceToLabelMap[elm?.requestSource],
    },
    {
      label: 'Name on bank account',
      key: 'nameOnBankAccount',
      render: (elm: Withdrawal) => elm?.investorBankDetails?.bankAccountName,
      sort: false,
    },
    {
      label: 'Bank account number',
      key: 'bankAccountNumber',
      render: (elm: Withdrawal) => elm?.investorBankDetails?.bankAccountNumber,
      sort: false,
    },
    {
      label: 'Bank sort code',
      key: 'bankSortCode',
      render: (elm: Withdrawal) => elm?.investorBankDetails?.bankSortCode,
      sort: false,
    },
    {
      label: 'Status',
      key: 'status',
      render: (elm: Withdrawal) => (
        <span style={{ whiteSpace: 'nowrap' }}>
          {StatusToLabelMap[elm?.status]}
        </span>
      ),
      sort: false,
    },
    {
      label: 'Notes',
      key: 'notes',
      render: (elm: Withdrawal) => <NotesAndAttachmentColumn {...elm} />,
      sort: false,
    },
    {
      label: 'Actions',
      key: 'actions',
      render: (elm: Withdrawal) => (
        <TableActions
          showAsDropdown
          actions={[
            {
              label: 'Undo',
              onClick: () => revertWithdrawal.mutate({ withdrawalId: elm._id }),
              icon: Undo,
              visible: elm.status === WithdrawalStatus.Rejected,
            },
            {
              label: 'Reject',
              onClick: () => rejectWithdrawal.mutate(elm._id),
              icon: Close,
              color: 'error',
              visible: filters.status === WithdrawalStatus.Requested,
            },
            {
              label: 'Undo custodian export',
              visible: elm.status === WithdrawalStatus.ExportedToCustodian,
              onClick: () => revertWithdrawal.mutate({ withdrawalId: elm._id }),
              icon: Undo,
            },
            {
              label: 'Undo and reject withdrawal',
              visible: elm.status === WithdrawalStatus.ExportedToCustodian,
              onClick: () =>
                revertWithdrawal.mutate({
                  withdrawalId: elm._id,
                  shouldReject: true,
                }),
              icon: Close,
              color: 'error',
            },
          ]}
        />
      ),
      sort: false,
    },
  ];
  const tableEmtpyMessage = [
    WithdrawalStatus.ExportedToCustodian,
    WithdrawalStatus.Rejected,
  ].includes(filters.status)
    ? `No withdrawal requests found that have been ${StatusToLabelMap[
        filters.status
      ].toLowerCase()}`
    : 'No withdrawal requests found';

  const withdrawalsList = withdrawals.data?.withdrawals || [];

  return (
    <PageContainer heading="Withdrawals: Withdrawal record">
      <PageContent>
        <FiltersAndActions
          filters={filters}
          handleChangeFilters={handleChangeFilters}
          resultCount={withdrawalsList.length}
          hasExportError={exportWithdrawals.isError}
          isExportLoading={exportWithdrawals.isPending}
          onExport={handleExport}
        />
        <Table
          columns={columns}
          tablebody={withdrawalsList}
          count={withdrawals.data?.totalCount || 0}
          orderBy={pagination.orderBy}
          order={pagination.order}
          page={pagination.page}
          rowsPerPage={pagination.rowsPerPage}
          onRequestSort={pagination.handleRequestSort}
          onPageChange={pagination.handleChangePage}
          onRowsPerPageChange={pagination.handleChangeRowsPerPage}
          pagination={true}
          emptyMessage={tableEmtpyMessage}
          variant="nohover"
          TableFooter={
            <FooterRow>
              <FooterCell>Total</FooterCell>
              <FooterCell>
                {numberToCurrencyString(sumBy(withdrawalsList, 'amount'))}
              </FooterCell>
            </FooterRow>
          }
        />
      </PageContent>
    </PageContainer>
  );
};

export default ReviewWithdrawals;

import { AllocationRule } from 'further-types/lp/allocations';
import { FundingSource } from 'further-types/lp/investment';
import { FundingSourceLabels, AllocationRuleLabels } from 'further-ui/labels';

export const fundingSourceOptions = Object.values(FundingSource).map(
  (value) => ({
    id: value,
    title: FundingSourceLabels[value] || value,
  }),
);

export const allocationRuleOptions = Object.values(AllocationRule).map(
  (value) => ({
    id: value,
    title: AllocationRuleLabels[value] || value,
  }),
);

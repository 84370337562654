interface AppropriatenessItem {
  status: boolean;
  isManuallyPassedByAdmin: boolean;
  isExpired: boolean;
}

export const getCurrentAppropriatenessStatus = (
  appropriatenessItem?: AppropriatenessItem,
): string => {
  if (!appropriatenessItem) return 'Not attempted';

  if (appropriatenessItem.status && appropriatenessItem.isManuallyPassedByAdmin)
    return 'Admin pass';

  if (!appropriatenessItem.status && appropriatenessItem.isExpired)
    return 'Expired';

  return appropriatenessItem.status ? 'Pass' : 'Fail';
};

import { z } from 'zod';
import { isMongoId } from '../../helpers';

export const Create = z.object({
  firmName: z.string().min(1, 'Please enter firm name.'),
  keyContactName: z.string().min(1, 'Please enter key contact name.'),
  keyContactEmail: z.string().email('Please enter a valid email address.'),
  firmLogoPrimaryColorBg: z.string().optional().nullable(),
  firmLogoTransparentBg: z.string().optional().nullable(),
  firmLogoSquare: z.string().optional().nullable(),
});

export const Update = Create.extend({
  id: z.string().refine(isMongoId()),
});

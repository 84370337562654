import RecordView from 'components/RecordView';
import GridContainer from 'components/GridContainer';
import PageSection from 'components/PageSection';
import Spacing from 'components/Spacing';
import ButtonGroup from 'components/ButtonGroup';
import Button from 'components/Button';
import Grid from '@mui/material/Grid2';
import { FieldRow } from 'components/FormElements';
import { IncomeAndExpensesLabels } from 'further-ui/labels';
import { CalendarDay, numberToCurrencyString } from 'further-ui/utils';
import TransactionAllocator, {
  Transaction,
} from 'components/TransactionAllocator';
import PageLoader from 'components/PageLoader';
import useTransactionTypeSelectorOptions from 'hooks/form/useTransactionTypeSelectorOptions';
import api from 'lib/trpcClient';
import { ActionRef } from 'further-types/lp/action';
import { Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import useHandleTrpcMutation from 'hooks/ui/useHandleTrpcMutation';
import { Allocations } from 'components/AllocationsTable/types';
import AllocationsTable from 'components/AllocationsTable';
import { AllocationRule } from 'further-types/lp/allocations';
import { CurrencyCode, FxDataFeed } from 'further-types/lp/vehicle';
import { useNotification } from 'hooks/ui/useNotification';

type Props = {
  id?: string;
  onPreviousStep: () => void;
  onSuccess: () => void;
  vehicle: {
    _id: string;
    vehicleName: string;
    baseCurrency: CurrencyCode;
    fxDataFeed: FxDataFeed;
  };
  completedAllocationValues: {
    allocations: Array<Allocations>;
    allocationRule: AllocationRule;
  };
};

const StepReviewAndConfirm: React.FC<Props> = ({
  id,
  onPreviousStep,
  onSuccess,
  vehicle,
  completedAllocationValues,
}) => {
  const notification = useNotification();
  const { getValues, setValue, handleSubmit, getFieldState } = useFormContext();
  const values = getValues();

  const defaultTransactionTypes =
    api.action.getTransactionTypesForAction.useQuery({
      vehicleId: values.vehicleId,
      actionRef: ActionRef.IncomeOrExpense,
      actionType: values.incomeOrExpenseType,
      amountIncVatTcy: values.amountIncVat,
      amountExVatTcy: values.amountExVat,
    });

  const mutationOptions = useHandleTrpcMutation()({
    onSuccess,
    invalidationHandler: (utils) => {
      utils.generalLedger.invalidate();
      utils.incomeAndExpenses.invalidate();
    },
  });
  const {
    mutate: createIncomeOrExpense,
    isPending: createIncomeOrExpensePending,
  } = api.incomeAndExpenses.create.useMutation(mutationOptions);

  const {
    mutate: updateIncomeOrExpense,
    isPending: updateIncomeOrExpensePending,
  } = api.incomeAndExpenses.update.useMutation(mutationOptions);

  const { transactionTypeOptions, isLoading: transactionTypesLoading } =
    useTransactionTypeSelectorOptions();

  if (transactionTypesLoading || defaultTransactionTypes.isLoading) {
    return <PageLoader />;
  }

  if (!defaultTransactionTypes.data) {
    return (
      <Typography>
        To set up account mappings, please first create some transaction types.
      </Typography>
    );
  }

  const handleChangeTransactions = (transactions: Array<Transaction>) => {
    setValue('transactions', transactions);
  };

  const onSubmit = (data: any) => {
    const allocations = completedAllocationValues.allocations.map((item) => ({
      commitmentId: item.commitmentId,
      investorLabel: item.investorName,
      allocationAmount: item.allocationAmount,
      allocationPercentage: item.allocationPercentage,
      excludeFromAllocation: item.excludeFromAllocation,
    }));

    if (id) {
      updateIncomeOrExpense({
        ...data,
        id,
        ledgerDate: new CalendarDay(data.ledgerDate),
        allocations,
        allocationRule: completedAllocationValues.allocationRule,
      });
    } else {
      createIncomeOrExpense({
        ...data,
        ledgerDate: new CalendarDay(data.ledgerDate),
        allocations,
        allocationRule: completedAllocationValues.allocationRule,
      });
    }
  };

  const isPending =
    createIncomeOrExpensePending || updateIncomeOrExpensePending;

  const defaultTransactions =
    id && values.transactions.length > 0
      ? defaultTransactionTypes.data.map((transaction) => {
          const existingTransaction = values.transactions.find(
            (t: Transaction) => t.operationType === transaction.operationType,
          );

          const isCurrencyOrAmountChanged =
            getFieldState('currencyCode').isDirty ||
            getFieldState('amountExVat').isDirty ||
            getFieldState('amountIncVat').isDirty ||
            getFieldState('vatRate').isDirty;

          const isIncomeOrExpenseTypeChanged = getFieldState(
            'incomeOrExpenseType',
          ).isDirty;

          return {
            ...transaction,
            id: existingTransaction?.id,
            transactionTypeId: isIncomeOrExpenseTypeChanged
              ? transaction.transactionTypeId
              : existingTransaction?.transactionTypeId ??
                transaction.transactionTypeId,

            ...(existingTransaction.transactionCurrencyRate &&
            !isCurrencyOrAmountChanged
              ? {
                  transactionCurrencyRate:
                    existingTransaction.transactionCurrencyRate,
                  amountExVatLcy: existingTransaction?.amountExVatLcy,
                  amountIncVatLcy: existingTransaction?.amountIncVatLcy,
                }
              : {}),
          };
        })
      : defaultTransactionTypes.data;

  return (
    <RecordView>
      <Spacing size="lg">
        <GridContainer>
          <Grid size={{ sm: 12, md: 12, lg: 8 }}>
            <PageSection title="Summary of income/expense being processed">
              <Spacing size="sm">
                <FieldRow title="Vehicle">{vehicle?.vehicleName}</FieldRow>
                <FieldRow title="Counterparty">
                  {values.counterparty?.name}
                </FieldRow>
                <FieldRow title="Type">
                  {IncomeAndExpensesLabels[values.incomeOrExpenseType]}
                </FieldRow>
                <FieldRow title="Amount">
                  {numberToCurrencyString(values.amountIncVat, {
                    currency: values.currencyCode,
                  })}
                </FieldRow>
              </Spacing>
            </PageSection>
          </Grid>
        </GridContainer>

        <PageSection title="A: Review allocation">
          <AllocationsTable
            vehicleId={values.vehicleId}
            startingValues={completedAllocationValues.allocations}
            startingAllocationRule={completedAllocationValues.allocationRule}
            inputAmount={values.amountIncVat}
            inputLabel="income/expense"
            currency={values.currencyCode}
            isNotEditable
          />
        </PageSection>

        <PageSection title="B: Review account transactions">
          <TransactionAllocator
            transactionTypeOptions={transactionTypeOptions}
            defaultTransactions={defaultTransactions}
            onChange={handleChangeTransactions}
            transactionCurrency={values.currencyCode}
            transactionDate={values.ledgerDate}
            vehicle={vehicle}
          />
        </PageSection>
        <ButtonGroup>
          <Button
            variant="outlined"
            onClick={onPreviousStep}
            loading={isPending}
          >
            Previous step
          </Button>
          <ButtonGroup>
            <Button variant="outlined" color="primary" loading={isPending}>
              Save draft
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmit, () =>
                notification.error('There is an error in the form.'),
              )}
              loading={isPending}
            >
              Save and approve
            </Button>
          </ButtonGroup>
        </ButtonGroup>
      </Spacing>
    </RecordView>
  );
};

export default StepReviewAndConfirm;

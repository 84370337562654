import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  DataGridPro,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridCellParams,
  GridColDef,
  GridColumnsInitialState,
  GridEventListener,
  GridPaginationModel,
  GridProSlotsComponent,
  GridRenderCellParams,
  GridRowId,
  GridRowModesModel,
  GridRowParams,
  GridRowSelectionModel,
  GridSortModel,
  GridValidRowModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { PaginationState } from 'further-types/utils';
import { Box, styled } from '@mui/material';
import {
  collapseIconClass,
  defaultPinnedColumns,
  expandIconClass,
  getColumns,
  getDetailPanelHeight,
  getRowHeight,
  getRowsToRender,
  getSortModel,
  getTreeDataPath,
  pagSizeOptions,
  rowHeight,
  sectionCellClass,
  sectionSummaryCellClass,
  slotProps,
  sortingOrder,
  summaryCellClass,
} from './helpers';
import SectionCell from './SectionCell';
import ColumnMenu from './ColumnMenu';
import { isArray } from 'lodash';
import DetailPanelToggle from './DetailPanelToggle';
import useRowsEdit from './useRowsEdit';
import TableContextProvider, { useTableContext } from './contexts/TableContext';
import { COLUMNS_CONFIG_KEY_SUFFIX } from './hooks/useColumnsConfig';

export {
  useRowsEdit,
  TableContextProvider,
  useTableContext,
  COLUMNS_CONFIG_KEY_SUFFIX,
};

const ExpandedRow = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
}));

const ExpandIcon = styled(KeyboardArrowDown)(({ theme }) => ({
  position: 'absolute',
  right: '20px',
  backgroundColor: theme.palette.common.white,
  display: 'none',
}));

const TableContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'fullHeight',
})<{ fullHeight?: boolean }>(({ fullHeight }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  maxHeight: fullHeight ? 'none' : '500px',
}));

export interface Row extends GridValidRowModel {
  id?: string;
  isGroup?: boolean;
  isSummary?: boolean;
  isGroupSummary?: boolean;
  isExpandable?: boolean;
}

export type RowsGroup<T extends Row> = {
  [key: string]: {
    title: string;
    rows: Array<T & Row>;
    summary?: Record<string, React.ReactNode>;
  };
};

export interface Column<T extends Row = Row>
  extends Omit<GridColDef<T>, 'cellClassName'> {
  summary?: React.ReactNode;
}

const getTableSlots = ({
  displayHeader = true,
}): Partial<GridProSlotsComponent> => ({
  columnMenu: ColumnMenu,
  columnHeaders: displayHeader ? undefined : () => null,
});

const getGroupingColDef = (
  sectionHeader?: string,
  sectionValueField?: keyof Row,
  columnsCount = 0,
  hasExpandableRows = false,
) => ({
  headerName: sectionHeader,
  flex: 1,
  renderCell: (params: GridRenderCellParams<Row>) => {
    const displayedValue = sectionValueField
      ? params.row[sectionValueField]
      : params.formattedValue;
    return params.rowNode.type === 'group' ? (
      <SectionCell {...params} displayedValue={displayedValue} />
    ) : (
      displayedValue
    );
  },
  colSpan: (_: unknown, row: Row) => {
    if (row.isGroup) {
      return columnsCount + (hasExpandableRows ? 2 : 1);
    }
    return undefined;
  },
  cellClassName: (params: GridCellParams<Row>) => {
    if (params.rowNode.type === 'group') {
      return sectionCellClass;
    }
    if (params.row.isSummary) {
      return summaryCellClass;
    }
    if (params.row.isGroupSummary) {
      return sectionSummaryCellClass;
    }
    return '';
  },
});

type Props = {
  id: string;
  pagination?: PaginationState;
  disablePagination?: boolean;
  columns: Array<Column>;
  rows: Array<Row> | RowsGroup<Row>;
  selectable?: boolean;
  getExpandedContent?: (row: Row) => React.ReactNode;
  className?: string;
  loading?: boolean;
  sectionValueField?: keyof Row;
  sectionHeader?: string;
  sectionSummary?: string;
  hideHeader?: boolean;
  disableTableConfiguration?: boolean;
  disableSorting?: boolean;
  fullHeight?: boolean;
  rowsEditModel?: GridRowModesModel;
  onRowEditSubmit?: (row: Row) => Row;
  columnsConfig?: GridColumnsInitialState;
  onColumnsConfigChange?: (config: GridColumnsInitialState) => void;
  rowCount?: number;
  onRowSelection?: (selectedRowIds: Array<string>) => void;
  selectedRows?: Array<GridRowId>;
  getRowClassName?: (params: GridRowParams<Row>) => string;
};

/**
 * This component is still work in progress - shouldn't be used in user-facing features yet.
 */
const InteractiveTable: React.FC<Props> = ({
  pagination,
  disablePagination,
  columns,
  rows,
  selectable,
  getExpandedContent,
  className,
  loading,
  sectionValueField,
  sectionHeader,
  sectionSummary,
  hideHeader,
  disableTableConfiguration,
  disableSorting,
  fullHeight,
  rowsEditModel,
  onRowEditSubmit,
  onColumnsConfigChange,
  rowCount,
  columnsConfig,
  onRowSelection,
  getRowClassName,
  selectedRows,
}) => {
  const apiRef = useGridApiRef();
  const [expandedRowId, setExpandedRowId] = useState<string>();

  const memoizedColumns = useMemo(
    () => columns,
    [JSON.stringify(columns), rowsEditModel],
  );

  const showFooter = !!pagination && !disablePagination;
  const showSummaryRow = columns.some((col) => col.summary);

  const sortModel = useMemo(
    () => getSortModel(pagination),
    [pagination?.orderBy, pagination?.order],
  );

  const rowsToRender = useMemo(
    () =>
      getRowsToRender({
        rows,
        columns: memoizedColumns,
        showSummaryRow,
        sectionValueField,
        sectionSummary,
      }),
    [rows, memoizedColumns, showSummaryRow, sectionValueField, sectionSummary],
  );

  const handleColumnsConfigChange = useCallback(() => {
    const currentState = apiRef?.current?.exportState();
    if (!currentState) {
      return;
    }

    onColumnsConfigChange?.(currentState?.columns ?? {});
  }, [apiRef, onColumnsConfigChange]);

  const handleDetailPanelExpandedRowIdsChange = React.useCallback(
    (newIds: GridRowId[]) => {
      const selectedRowId = newIds[0]?.toString();

      setExpandedRowId(
        expandedRowId === selectedRowId ? undefined : selectedRowId,
      );
    },
    [expandedRowId],
  );

  const handleRowClick = useCallback<GridEventListener<'rowClick'>>(
    (params) => {
      if (!params.row.isExpandable) {
        return;
      }
      handleDetailPanelExpandedRowIdsChange([params.id.toString()]);
    },
    [handleDetailPanelExpandedRowIdsChange],
  );

  const handleSortModelChange = useCallback(
    (model: GridSortModel) => {
      if (!pagination) {
        return;
      }

      const newModel = model?.[0];

      if (
        !newModel ||
        !newModel.field ||
        (newModel.field === sortModel?.[0]?.field &&
          newModel.sort === sortModel?.[0]?.sort)
      ) {
        return;
      }
      pagination.handleRequestSort(null, newModel.field);
    },
    [pagination, sortModel],
  );

  const handlePaginationModelChange = useCallback(
    (model: GridPaginationModel) => {
      if (model.page + 1 !== pagination?.page) {
        pagination?.handleChangePage(null, model.page);
      }

      if (model.pageSize !== pagination?.rowsPerPage) {
        pagination?.handleChangeRowsPerPage({
          target: { value: model.pageSize },
        });
      }
    },
    [pagination?.rowsPerPage, pagination?.page],
  );

  const hasExpandableRows = useMemo(
    () => rowsToRender.some((row) => row.isExpandable),
    [rowsToRender],
  );

  const getDetailPanelContent = useMemo(
    () =>
      hasExpandableRows
        ? (params: GridRowParams<Row>) => {
            return params.row.isExpandable ? (
              <ExpandedRow>{getExpandedContent?.(params.row)}</ExpandedRow>
            ) : null;
          }
        : undefined,
    [hasExpandableRows, getExpandedContent],
  );

  const interactiveColumns = useMemo(
    () => [
      ...getColumns(memoizedColumns),
      ...(hasExpandableRows
        ? [
            {
              ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
              renderCell: (params: GridRenderCellParams<Row>) => (
                <DetailPanelToggle {...params} />
              ),
              cellClassName: (params: GridCellParams<Row>) => {
                if (params.row.isSummary) {
                  return summaryCellClass;
                }
                if (params.row.isGroupSummary) {
                  return sectionSummaryCellClass;
                }
                return '';
              },
            },
          ]
        : []),
    ],
    [memoizedColumns, hasExpandableRows],
  );

  const groupingColDef = useMemo(
    () =>
      getGroupingColDef(
        sectionHeader,
        sectionValueField,
        interactiveColumns.length,
        hasExpandableRows,
      ),
    [
      sectionHeader,
      sectionValueField,
      interactiveColumns.length,
      hasExpandableRows,
    ],
  );

  const tableSlots = useMemo(
    () =>
      getTableSlots({
        displayHeader: !hideHeader,
      }),
    [hideHeader],
  );

  const paginationModel = useMemo(() => {
    return {
      pageSize: pagination?.rowsPerPage ?? rowsToRender.length,
      page: (pagination?.page ?? 1) - 1,
    };
  }, [pagination?.rowsPerPage, pagination?.page]);

  useEffect(() => {
    if (!columnsConfig || !apiRef?.current) {
      return;
    }

    apiRef?.current?.restoreState({ columns: columnsConfig });
  }, [JSON.stringify(columnsConfig), apiRef]);

  const initialState = useMemo(() => {
    return columnsConfig
      ? {
          columns: columnsConfig,
        }
      : undefined;
  }, [JSON.stringify(columnsConfig)]);

  const handleRowSelection = useCallback(
    (selectedRowIds: GridRowSelectionModel) => {
      onRowSelection?.(selectedRowIds.map((id) => id.toString()));
    },
    [onRowSelection],
  );

  return (
    <TableContainer className="interactive-table" fullHeight={fullHeight}>
      <DataGridPro
        apiRef={apiRef}
        treeData={!isArray(rows)}
        getTreeDataPath={getTreeDataPath}
        defaultGroupingExpansionDepth={1}
        groupingColDef={groupingColDef}
        className={className}
        rows={rowsToRender}
        columns={interactiveColumns}
        loading={loading}
        pagination={!!pagination && !disablePagination}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationModelChange}
        getRowHeight={getRowHeight}
        onRowClick={handleRowClick}
        pageSizeOptions={pagSizeOptions}
        columnHeaderHeight={rowHeight}
        checkboxSelection={selectable}
        disableMultipleRowSelection={!selectable}
        disableRowSelectionOnClick
        disableColumnPinning
        hideFooterPagination={!pagination}
        hideFooter={!showFooter}
        disableVirtualization
        sortModel={sortModel}
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        onColumnVisibilityModelChange={handleColumnsConfigChange}
        onColumnOrderChange={handleColumnsConfigChange}
        detailPanelExpandedRowIds={expandedRowId ? [expandedRowId] : []}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={getDetailPanelHeight}
        slotProps={slotProps}
        disableMultipleColumnsSorting
        slots={tableSlots}
        sortingOrder={sortingOrder}
        disableColumnMenu={disableTableConfiguration}
        disableColumnResize={disableTableConfiguration}
        disableColumnReorder={disableTableConfiguration}
        disableColumnSorting={disableSorting}
        onDetailPanelExpandedRowIdsChange={
          handleDetailPanelExpandedRowIdsChange
        }
        onRowSelectionModelChange={handleRowSelection}
        rowSelectionModel={selectedRows}
        onColumnWidthChange={handleColumnsConfigChange}
        pinnedColumns={defaultPinnedColumns}
        rowModesModel={rowsEditModel}
        editMode="row"
        processRowUpdate={onRowEditSubmit}
        rowCount={rowCount ?? rowsToRender.length}
        disableAutosize
        initialState={initialState}
        getRowClassName={getRowClassName}
      />
      <ExpandIcon className={expandIconClass} />
      <ExpandIcon as={KeyboardArrowUp} className={collapseIconClass} />
    </TableContainer>
  );
};

export default InteractiveTable;

import { FC, useState, useEffect, useRef } from 'react';
import Grid from '@mui/material/Grid2';
import { capitalize, sumBy } from 'lodash';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  styled,
  Box,
  CircularProgress,
  Alert,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { AllocationRule } from 'further-types/lp/allocations';
import {
  numberToDisplayString,
  numberToCurrencyString,
} from 'further-ui/utils';
import { Allocations } from './types';
import AllocationsFormBody from './AllocationsFormBody';
import GridContainer from 'components/GridContainer';
import { FieldRow } from 'components/FormElements';
import { AppSimpleSelect } from 'components/FormElements';
import useAllocationsApi from 'hooks/lp/data/allocations/useAllocationsApi';
import useAllocationRule from './hooks/useAllocationRule';
import useExcludedCommitments from './hooks/useExcludedCommitments';
import useAllocationsForm from './hooks/useAllocationsForm';

const StyledAccordion = styled(Accordion)({
  width: '100%',
  borderColor: '#D8D8D8',
});

const AccordionHeader = styled(AccordionSummary)(({ theme }) => ({
  padding: theme.spacing(4),
  color: theme.palette.text.darkTitle,
  fontSize: theme.fontSizes?.regular,
  fontWeight: 'bold',

  '& .MuiAccordionSummary-content': {
    margin: '0 !important',
  },
}));

const AccordionBody = styled(AccordionDetails)(({ theme }) => ({
  backgroundColor: theme.palette.background.header,
  padding: 0,
}));

const AccordionSection = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'noBorderBottom',
})<{ noBorderBottom?: boolean }>(({ theme, noBorderBottom }) => ({
  padding: theme.spacing(4),
  borderBottom: noBorderBottom ? 'none' : `2px solid #D8D8D8`,
}));

const TableContainer = styled('table')(({ theme }) => ({
  display: 'table',
  tableLayout: 'fixed',
  width: '100%',
  fontSize: theme.fontSizes?.sm,
  color: theme.palette.text.darkBodyText,
  textAlign: 'left',
  borderCollapse: 'collapse',
}));

const EmptyRowCell = styled('td')(({ theme }) => ({
  padding: theme.spacing(4),
  textAlign: 'center',
}));

const TableHeading = styled('th')(({ theme }) => ({
  paddingRight: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

const FooterRow = styled('tr')(({ theme }) => ({
  color: theme.palette.text.darkTitle,
  fontSize: theme.fontSizes?.regular,
  fontWeight: 'bold',
}));

const FooterCell = styled('td')(({ theme, fontWeight }) => ({
  paddingRight: theme.spacing(2),
  fontWeight,
}));

type Props = {
  inputAmount: number;
  inputLabel: string;
  currency: string;
  vehicleId: string;
  startingValues: Array<Allocations>;
  startingAllocationRule?: AllocationRule;
  isNotEditable?: boolean;
  currentAction?: {
    incomeOrExpenseId?: string;
  };
  linkedActionId?: string | null;
  setLinkedActionId?: (id?: string | null) => void;
  onAllocationsChange?: (
    isValid: boolean,
    allocations: Array<Allocations>,
    allocationRule: AllocationRule,
    excludedCommitmentIds: Array<string>,
  ) => void;
};

const AllocationsTable: FC<Props> = ({
  inputAmount,
  inputLabel,
  currency,
  vehicleId,
  startingValues,
  startingAllocationRule,
  isNotEditable,
  currentAction,
  linkedActionId,
  setLinkedActionId,
  onAllocationsChange,
}) => {
  const [startingInputAmount] = useState(inputAmount);
  const excludedCommitmentIdsRef = useRef<Array<string>>([]);

  const { initialAllocationsData, generateAllocations, isPending } =
    useAllocationsApi();

  const {
    allocationRule,
    showLinkedActionWarning,
    previousLinkedActionId,
    unlinkAction,
    onAllocationRuleChange,
    setShowLinkedActionWarning,
    setAllocationRule,
  } = useAllocationRule({
    startingAllocationRule,
    linkedActionId,
    vehicleId,
    inputAmount,
    generateAllocations,
    excludedCommitmentIds: excludedCommitmentIdsRef.current,
    setLinkedActionId,
    currentAction,
  });

  const { control, trigger, setValue, values } =
    useAllocationsForm(inputAmount);

  const {
    excludedCommitmentIds,
    setExcludedCommitmentIds,
    getExcludedCommitmentNames,
    onExcludedCommitmentIdsChange,
  } = useExcludedCommitments({
    linkedActionId,
    allocationRule,
    setAllocationRule,
    unlinkAction,
    generateAllocations,
    setValue,
    vehicleId,
    inputAmount,
    currentAction,
    excludedCommitmentIdsRef,
  });

  // run on mount only
  useEffect(() => {
    if (startingValues.length) {
      setValue('allocations', startingValues);

      const excludedIds = startingValues
        .filter((item) => item.excludeFromAllocation)
        .map((item) => item.commitmentId);

      setExcludedCommitmentIds(excludedIds);
      excludedCommitmentIdsRef.current = excludedIds;
    } else {
      generateAllocations({
        allocationRule,
        vehicleId,
        amount: inputAmount,
        excludedCommitmentIds: excludedCommitmentIdsRef.current,
        currentAction,
        linkedActionId,
      });
    }
  }, []);

  // run on the remote data changing
  useEffect(() => {
    if (!isNotEditable && initialAllocationsData?.allocations.length) {
      setValue('allocations', initialAllocationsData?.allocations ?? []);
      const newExcludedIds = initialAllocationsData?.allocations
        .filter((item) => item.excludeFromAllocation)
        .map((item) => item.commitmentId);
      setExcludedCommitmentIds(newExcludedIds ?? []);
    }
  }, [initialAllocationsData?.allocations]);

  // send data to parent when allocations change
  useEffect(() => {
    const validateAndAct = async () => {
      if (onAllocationsChange) {
        const isValid = await trigger();
        onAllocationsChange(
          isValid,
          values,
          allocationRule,
          excludedCommitmentIds,
        );
      }
    };
    validateAndAct();
  }, [inputAmount, JSON.stringify(values), linkedActionId, allocationRule]); // this is a workaround to trigger the useEffect when the values change

  // update allocations when the input amount changes
  useEffect(() => {
    if (
      allocationRule !== AllocationRule.Manual &&
      inputAmount !== startingInputAmount
    ) {
      generateAllocations({
        allocationRule,
        vehicleId,
        amount: inputAmount,
        excludedCommitmentIds: excludedCommitmentIdsRef.current,
        currentAction,
        linkedActionId,
      });
    }
  }, [inputAmount]);

  // Separate effect for linkedActionId changes
  useEffect(() => {
    // Determine the appropriate allocation rule based on linkedActionId
    let newAllocationRule = allocationRule;

    if (linkedActionId) {
      newAllocationRule = AllocationRule.LinkedAction;
    }
    if (!linkedActionId && allocationRule === AllocationRule.LinkedAction) {
      newAllocationRule = AllocationRule.ByCommitmentAndClosingDate;
    }

    // Only update if the rule has changed
    if (newAllocationRule !== allocationRule) {
      setAllocationRule(newAllocationRule);
    }

    if (newAllocationRule !== AllocationRule.Manual) {
      setShowLinkedActionWarning(false);
      generateAllocations({
        allocationRule: newAllocationRule,
        vehicleId,
        amount: inputAmount,
        excludedCommitmentIds: excludedCommitmentIdsRef.current,
        currentAction,
        linkedActionId,
      });
    }
  }, [linkedActionId]);

  const excludedCommitmentNames = getExcludedCommitmentNames(values);

  return (
    <GridContainer>
      {!isNotEditable && (
        <Grid size={{ sm: 12, md: 12, lg: 8 }}>
          <FieldRow centerTitle title="Allocation rule">
            <AppSimpleSelect
              value={allocationRule}
              fullWidth
              options={[
                {
                  value: AllocationRule.ByCommitmentAndClosingDate,
                  label: `By commitment and closing date${
                    excludedCommitmentNames
                      ? ` excluding ${excludedCommitmentNames}`
                      : ''
                  }`,
                },
                {
                  value: AllocationRule.Manual,
                  label: 'Manual allocation',
                },
                ...(linkedActionId
                  ? [
                      {
                        value: AllocationRule.LinkedAction,
                        label: 'Linked action',
                        disabled: true,
                      },
                    ]
                  : []),
              ]}
              onChange={(e) =>
                onAllocationRuleChange(e.target.value as AllocationRule)
              }
            />
          </FieldRow>
        </Grid>
      )}
      {showLinkedActionWarning && (
        <Grid size={{ xs: 12 }}>
          <Alert severity="info">
            This allocation has been updated. The income or expense is now
            allocated differently to the linked investment.{' '}
            <Box
              component="span"
              sx={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={() => setLinkedActionId?.(previousLinkedActionId)}
            >
              Click here to revert to the linked allocation.
            </Box>
          </Alert>
        </Grid>
      )}
      <StyledAccordion defaultExpanded>
        <AccordionHeader expandIcon={<ExpandMoreIcon />}>
          Review and edit allocation
          {values.length !== 0 && isPending && (
            <CircularProgress size={18} sx={{ marginLeft: 2 }} />
          )}
        </AccordionHeader>
        <AccordionBody>
          <AccordionSection>
            <TableContainer>
              <thead>
                <tr>
                  <TableHeading>Investor</TableHeading>
                  <TableHeading>Total commitment</TableHeading>
                  <TableHeading>Unallocated commitment</TableHeading>
                  <TableHeading>Close date</TableHeading>

                  <TableHeading>
                    {capitalize(inputLabel)} allocated
                  </TableHeading>
                  <TableHeading>
                    {capitalize(inputLabel)} allocated (%)
                  </TableHeading>

                  <TableHeading>Exclude investor from allocation</TableHeading>
                </tr>
              </thead>
              <tbody>
                {values.length === 0 ? (
                  <tr>
                    <EmptyRowCell colSpan={7}>
                      {isPending ? (
                        <CircularProgress size={18} />
                      ) : values.length === 0 ? (
                        'No commitments found'
                      ) : (
                        ''
                      )}
                    </EmptyRowCell>
                  </tr>
                ) : (
                  <AllocationsFormBody
                    fields={values}
                    control={control}
                    setValue={setValue}
                    inputAmount={inputAmount}
                    isNotEditable={!!isNotEditable}
                    setToManualMode={() => {
                      setAllocationRule(AllocationRule.Manual);
                      if (linkedActionId) {
                        unlinkAction(linkedActionId);
                      }
                    }}
                    excludedCommitmentIds={excludedCommitmentIds}
                    onExcludedCommitmentIdsChange={
                      onExcludedCommitmentIdsChange
                    }
                    newAllocationsPending={isPending}
                  />
                )}
              </tbody>
            </TableContainer>
          </AccordionSection>
          <AccordionSection noBorderBottom>
            <TableContainer>
              <tbody>
                <FooterRow>
                  <FooterCell>{values.length} investors</FooterCell>
                  <FooterCell />
                  <FooterCell />
                  <FooterCell fontWeight="normal">
                    Total {inputLabel}
                  </FooterCell>
                  <FooterCell>
                    {numberToCurrencyString(
                      sumBy(values, (item) => Number(item.allocationAmount)),
                      { currency },
                    )}
                  </FooterCell>
                  <FooterCell>
                    {numberToDisplayString(
                      sumBy(values, (item) =>
                        Number(item.allocationPercentage),
                      ),
                    )}
                    %
                  </FooterCell>
                  <FooterCell />
                </FooterRow>
              </tbody>
            </TableContainer>
          </AccordionSection>
        </AccordionBody>
      </StyledAccordion>
    </GridContainer>
  );
};

export default AllocationsTable;

import { z } from 'zod';
import { CurrencyCode } from 'further-types/lp/vehicle';

import { AssetType } from 'further-types/lp/asset';
import isMongoId from '../../helpers/is-mongo-id';
import isCountryCode from '../../helpers/is-country-code';
import { AllocationRule } from 'further-types/lp/allocations';
import { FundingSource } from 'further-types/lp/investment';
import { ActionStatus } from 'further-types/lp/action';
import { IndividualTransaction } from '../transaction';
import { CalendarDay } from '../../../utils';
import { HoldingClassFormValues, HoldingClassSchema } from '../holding-class';

const optionalCountryCodeValidator = (
  val: string | undefined,
  ctx: z.RefinementCtx,
) => {
  if (val && !isCountryCode(val)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Please provide a valid country code',
    });
  }
};

const validateHoldingClassesCurrency = (
  holdingClasses: Array<HoldingClassFormValues>,
  ctx: z.RefinementCtx,
) => {
  if (holdingClasses.length <= 1) return;

  const firstCurrency = holdingClasses[0].currency;

  const allSameCurrency = holdingClasses.every(
    (hc) => hc.currency === firstCurrency,
  );

  if (!allSameCurrency) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'All holding classes must have the same currency.',
      path: [],
    });
  }
};

const NewAssetSchema = z.object({
  isNewAsset: z.literal(true),
  asset: z.object({
    assetType: z.nativeEnum(AssetType),
    name: z.string({
      required_error: 'Name is required',
    }),
    fullLegalName: z.string({
      required_error: 'Full legal name is required',
    }),
    baseCurrency: z.nativeEnum(CurrencyCode, {
      errorMap: () => ({ message: 'Base currency is required' }),
    }),
    registeredNumber: z.string().optional(),
    registrationCountry: z
      .string()
      .optional()
      .superRefine(optionalCountryCodeValidator),
    registeredAddressLine1: z.string().optional(),
    registeredAddressLine2: z.string().optional(),
    registeredAddressTownCity: z.string().optional(),
    registeredAddressPostalCode: z.string().optional(),
    registeredAddressCountry: z
      .string()
      .optional()
      .superRefine(optionalCountryCodeValidator),
  }),
});

const ExistingAssetSchema = z.object({
  isNewAsset: z.literal(false),
  name: z.string().optional(),
  assetId: z.string().refine(isMongoId(), {
    message: 'Please select an existing asset or create a new one',
  }),
});

const AllocationSchema = z.object({
  investorId: z.string(),
  investorName: z.string(),
  commitmentId: z.string().refine(isMongoId()),
  investorLabel: z.string().trim(),
  totalCommitment: z.number(),
  unallocatedCommitment: z.number(),
  closeDate: z.date(),
  allocationAmount: z.number(),
  allocationPercentage: z.number(),
  excludeFromAllocation: z.boolean(),
});

export const CreateInvestmentSchema = z
  .object({
    investmentId: z.string().optional(),
    vehicleId: z.string().refine(isMongoId(), {
      message: 'Invalid vehicle ID',
    }),
    dealName: z.string().min(1, 'Deal name is required'),
    dealCompletionDate: z.instanceof(CalendarDay).optional(),
    status: z.nativeEnum(ActionStatus),
    fundingSource: z.nativeEnum(FundingSource),
    allocationRule: z.nativeEnum(AllocationRule).optional(),
    allocations: z.array(AllocationSchema),
    transactions: z.array(IndividualTransaction).optional(),
    holdingClasses: z
      .array(HoldingClassSchema)
      .min(1, { message: 'At least one holding class is required' })
      .superRefine(validateHoldingClassesCurrency),
  })
  .and(
    z.discriminatedUnion('isNewAsset', [NewAssetSchema, ExistingAssetSchema]),
  );

export type CreateInvestmentFormValues = z.infer<typeof CreateInvestmentSchema>;

export const defaultHoldingClass = {
  isNewHoldingClass: false,
  holdingClassId: undefined,
  existingHoldingClassName: '',
  investmentAmount: 0,
  numberOfUnits: 0,
  unitPrice: 0,
  currency: undefined,
};

export const defaultInvestment = {
  vehicleId: '',
  dealName: '',
  dealCompletionDate: undefined,
  fundingSource: FundingSource.CAPITAL_CALL,
  allocationRule: AllocationRule.ByCommitmentAndClosingDate,
  status: ActionStatus.Draft,
  allocations: [],
  transactions: [],
  holdingClasses: [defaultHoldingClass],
  isNewAsset: false,
  assetId: '',
};

const passwordAllowedSpecialChars =
  '! @ £ $ % _ - ( ) [ ] ~ ; : ? . ^ & * + = ` # \' < > / \\ | { } , " € ¬ ¦';

export const validationMessages = {
  nameValidationError:
    'Only letters, hyphens, apostrophes and spaces may be entered in this field.',
  passwordValidationError: `Your password needs to be at least 8 characters, contain an uppercase letter, a lowercase letter, a number and one of the following symbols: ${passwordAllowedSpecialChars}`,
  passwordValidationErrorSpecialChars: `At least one of the following symbols: ${passwordAllowedSpecialChars}`,
  phoneNumberValidationError:
    'Phone number must be at least 8 digits long and contain only numbers or following symbols: + ( )',
};

export const validationRegexes = {
  password:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@£$%_\-()[\]~;:?.^&*+=`#'<>/\\|{},"€¬¦])[A-Za-z\d!@£$%_\-()[\]~;:?.^&*+=`#'<>/\\|{},"€¬¦]{8,}$/i,
  userName: /^[a-zA-Z'\- ]+$/i,
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i,
  phoneNumber: /^(?=(?:\D*\d){8,})[ ()\d+]+$/i,
  ukSortCode: /^\d{2}-\d{2}-\d{2}$/,
};

export const validatePassword = (password: string) => {
  if (!validationRegexes.password.test(password)) {
    return validationMessages.passwordValidationError;
  }
  return false;
};

export const validateUserName = (name: string) => {
  if (!validationRegexes.userName.test(name)) {
    return validationMessages.nameValidationError;
  }
  return false;
};

export const isValidEmail = (email: string) => {
  if (validationRegexes.email.test(email)) {
    return true;
  }
  return false;
};

export const validateUkSortCode = (sortCode: string) => {
  if (validationRegexes.ukSortCode.test(sortCode)) {
    return true;
  }
  return false;
};

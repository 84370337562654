import RecordView from 'components/RecordView';
import PageSection from 'components/PageSection';
import Spacing from 'components/Spacing';
import ButtonGroup from 'components/ButtonGroup';
import Button from 'components/Button';
import Grid from '@mui/material/Grid2';
import { FieldErrors, useFormContext } from 'react-hook-form';

import { useNotification } from 'hooks/ui/useNotification';
import InputDetailsForm from './InputDetailsForm';
import { CreateInvestmentFormValues } from 'further-ui/validations/lp/investment';

export type SelectedVehicle = {
  _id: string;
  vehicleName: string;
};
type StepCreateInvestmentProps = {
  onSuccess: () => void;
  selectedVehicle: SelectedVehicle;
};

const StepCreateInvestment: React.FC<StepCreateInvestmentProps> = ({
  onSuccess,
  selectedVehicle,
}) => {
  const notification = useNotification();
  const { handleSubmit } = useFormContext();

  const onSubmit = () => {
    onSuccess();
  };

  const onError = (errors: FieldErrors<CreateInvestmentFormValues>) => {
    const defaultError = 'Please fill in all required fields.';
    if (errors.holdingClasses) {
      notification.error(errors.holdingClasses.root?.message ?? defaultError);
    } else {
      notification.error(defaultError);
    }
  };

  return (
    <RecordView>
      <Spacing size="lg">
        <Grid container spacing={{ xs: 0, md: 6 }}>
          <Grid size={{ lg: 8, xs: 12 }}>
            <PageSection title="Select asset and holding class(es)">
              <form>
                <InputDetailsForm selectedVehicle={selectedVehicle} />
              </form>
            </PageSection>
          </Grid>
        </Grid>

        <ButtonGroup>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(onSubmit, onError)}
          >
            Continue
          </Button>
        </ButtonGroup>
      </Spacing>
    </RecordView>
  );
};

export default StepCreateInvestment;

import {
  AntiDilutionRights,
  CouponType,
  HoldingClassType,
  LiquidationPreferenceType,
  MonitoringRights,
} from 'further-types/lp/holding-class';

export const LiquidationPreferenceLabels = {
  [LiquidationPreferenceType.NonParticipating]: 'Non-Participating',
};

export const AntiDilutionLabels = {
  [AntiDilutionRights.NarrowBased]: 'Narrow-Based',
};

export const CouponTypeLabels = {
  [CouponType.Cash]: 'Cash',
};

export const MonitoringRightsLabels = {
  [MonitoringRights.ObserverRights]: 'Observer Rights',
};

export const HoldingClassLabels = {
  [HoldingClassType.Common]: 'Common',
  [HoldingClassType.Preferred]: 'Preferred',
};

import { AutoModeOutlined } from '@mui/icons-material';
import { styled, Typography } from '@mui/material';
import { Column } from 'components/Layout';

const Icon = styled(AutoModeOutlined)(({ theme }) => ({
  fontSize: '120px',
  color: theme.palette.text.rootColor,
  opacity: 0.5,
}));

const Wrapper = styled(Column)(({ theme }) => ({
  padding: theme.spacing(6, 0),
  marginBottom: theme.spacing(6),
}));

const Paragraph = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  maxWidth: '650px',
  textAlign: 'center',
}));

const ImportingTransactions: React.FC = () => {
  return (
    <Wrapper centered spacing="sm">
      <Icon
        sx={{
          animation: 'spin 1.5s linear infinite',
          '@keyframes spin': {
            '0%': {
              transform: 'rotate(0deg)',
            },
            '100%': {
              transform: 'rotate(360deg)',
            },
          },
        }}
      />
      <Typography variant="h4">Importing statement transactions</Typography>
      <Paragraph color="text.secondary">
        Please wait while we import your bank account transactions.
      </Paragraph>
    </Wrapper>
  );
};

export default ImportingTransactions;

import { Realm } from 'further-types/admin-user';
import { CurrencyCode, FxDataFeed } from 'further-types/lp/vehicle';
import useCurrentUser from 'hooks/data/currentUser/useCurrentUser';
import useVehicle from 'hooks/lp/data/vehicle/useVehicle';
import React, { useState, useContext } from 'react';
import { useLocalStorage } from 'usehooks-ts';

type Vehicle = {
  _id: string;
  vehicleName: string;
  baseCurrency: CurrencyCode;
  fxDataFeed: FxDataFeed;
};

type LayoutContextType = {
  isSidebarOpen: boolean;
  setSidebarOpen: (isSidebarOpen: boolean) => void;
  selectedVehicleId: string | null;
  setSelectedVehicle: (vehicle: Vehicle | null) => void;
  isVehicleSwitching: boolean;
  vehicle?: Vehicle;
  isVehicleLoading: boolean;
};

const LayoutContext = React.createContext<LayoutContextType>({
  isSidebarOpen: false,
  setSidebarOpen: () => {},
  selectedVehicleId: null,
  setSelectedVehicle: () => {},
  isVehicleSwitching: false,
  vehicle: undefined,
  isVehicleLoading: false,
});

const LayoutContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { profile } = useCurrentUser();
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [selectedVehicleId, setSelectedVehicleId] = useLocalStorage<
    string | null
  >('selectedVehicleId', null);
  const [isVehicleSwitching, setIsVehicleSwitching] = useState(false);

  const setSelectedVehicle = async (vehicle: Vehicle | null) => {
    setIsVehicleSwitching(true);
    // await a 500s timeout
    await new Promise((resolve) => setTimeout(resolve, 500));
    setSelectedVehicleId(vehicle?._id || null);
    setIsVehicleSwitching(false);
  };

  const currentSelectedVehicleId =
    profile?.data?.realm === Realm.Lp ? selectedVehicleId : null;

  const { vehicle, isLoading } = useVehicle(currentSelectedVehicleId);

  const layoutContextValue = {
    isSidebarOpen,
    setSidebarOpen,
    selectedVehicleId: currentSelectedVehicleId,
    setSelectedVehicle,
    isVehicleSwitching,
    vehicle,
    isVehicleLoading: isLoading,
  };

  return (
    <LayoutContext.Provider value={layoutContextValue}>
      {children}
    </LayoutContext.Provider>
  );
};

export const useLayoutContext = () => useContext(LayoutContext);

export default LayoutContextProvider;

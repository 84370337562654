import { useState, useEffect, MutableRefObject } from 'react';
import { Allocations } from '../types';
import { AllocationRule } from 'further-types/lp/allocations';
import { UseFormSetValue } from 'react-hook-form';
import { FormValues } from '../types';

type UseExcludedCommitmentsProps = {
  linkedActionId?: string | null;
  allocationRule: AllocationRule;
  setAllocationRule: (rule: AllocationRule) => void;
  unlinkAction: (actionId: string) => void;
  generateAllocations: (params: any) => void;
  setValue: UseFormSetValue<FormValues>;
  vehicleId: string;
  inputAmount: number;
  currentAction?: {
    incomeOrExpenseId?: string;
  };
  excludedCommitmentIdsRef: MutableRefObject<Array<string>>;
};

const useExcludedCommitments = ({
  linkedActionId,
  allocationRule,
  setAllocationRule,
  unlinkAction,
  generateAllocations,
  setValue,
  vehicleId,
  inputAmount,
  currentAction,
  excludedCommitmentIdsRef,
}: UseExcludedCommitmentsProps) => {
  const [excludedCommitmentIds, setExcludedCommitmentIds] = useState<
    Array<string>
  >([]);

  useEffect(() => {
    excludedCommitmentIdsRef.current = excludedCommitmentIds;
  }, [excludedCommitmentIds]);

  const getExcludedCommitmentNames = (allocations: Array<Allocations>) => {
    return allocations
      .filter((allocation) => allocation.excludeFromAllocation)
      .map((allocation) => allocation.investorName)
      .join(', ');
  };

  const onExcludedCommitmentIdsChange = (
    newExcludedCommitmentIds: Array<string>,
    index?: number,
  ) => {
    setExcludedCommitmentIds(newExcludedCommitmentIds);

    if (linkedActionId) {
      setAllocationRule(AllocationRule.Manual);
      // If we have an index, set the allocation values to 0
      if (index !== undefined) {
        setValue(`allocations.${index}.allocationAmount`, 0);
        setValue(`allocations.${index}.allocationPercentage`, 0);
      }
      unlinkAction(linkedActionId);
      return;
    }

    if (allocationRule !== AllocationRule.Manual) {
      generateAllocations({
        allocationRule,
        vehicleId,
        amount: inputAmount,
        excludedCommitmentIds: newExcludedCommitmentIds,
        currentAction,
        linkedActionId,
      });
    } else if (index !== undefined) {
      // In manual mode, set the allocation values to 0
      setValue(`allocations.${index}.allocationAmount`, 0);
      setValue(`allocations.${index}.allocationPercentage`, 0);
    }
  };

  return {
    excludedCommitmentIds,
    excludedCommitmentIdsRef,
    setExcludedCommitmentIds,
    getExcludedCommitmentNames,
    onExcludedCommitmentIdsChange,
  };
};

export default useExcludedCommitments;

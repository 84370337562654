import { z, ZodRawShape } from 'zod';

const withPaginationParams = <T extends ZodRawShape, const U extends string>(
  schema: z.ZodObject<T>,
  sortableColumns: readonly [U, ...Array<U>],
) => {
  return schema.extend({
    page: z.number(),
    rowsPerPage: z.number(),
    order: z.enum(['asc', 'desc']),
    orderBy: z.enum(['createdAt', 'updatedAt', ...sortableColumns]),
  });
};

export default withPaginationParams;

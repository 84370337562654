import { useState } from 'react';

export const useDisclosure = <T extends Record<string, any>>( // eslint-disable-line @typescript-eslint/no-explicit-any
  isOpenByDefault = false,
) => {
  const [isOpen, setIsOpen] = useState(isOpenByDefault);
  const [actionProps, setActionProps] = useState<T>({} as T);

  const onClose = () => {
    setActionProps({} as T);
    setIsOpen(false);
  };
  const onOpen = () => {
    setIsOpen(true);
  };
  const onToggle = () => {
    if (isOpen) {
      setActionProps({} as T);
    }
    setIsOpen(!isOpen);
  };
  const stageAction = (props: T) => {
    setActionProps(props);
    setIsOpen(true);
  };

  return {
    isOpen,
    onClose,
    onOpen,
    onToggle,
    stageAction,
    actionProps,
  };
};

export type Disclosure = ReturnType<typeof useDisclosure>;

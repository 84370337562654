import { Link, NavLink } from 'react-router-dom';
import { Button, IconButton, Tooltip } from '@mui/material';
import Table from 'components/Table';
import {
  numberToCurrencyString,
  dateToLabel,
  getCompanyLabel,
} from 'further-ui/utils';
import { CashBalanceItemTypeToLabelMap } from 'further-ui/labels';
import {
  createExitStatementViewRoute,
  createInvestmentEditRoute,
  createInvestorViewRoute,
  WITHDRAWALS_ADD,
} from 'adminConstants/routes';
import { AttachFile, Comment } from '@mui/icons-material';
import { InfoOutlined } from '@mui/icons-material';
import { FundFullDeploymentTrigger } from 'further-types/fund-fully-deployed-log';
import { createUploadSheetViewRoute } from 'adminConstants/routes';
import { usePagination } from 'hooks/ui/usePagination';
import { slice } from 'lodash';
import PageHeader from 'components/PageHeader';
import Heading from 'components/Heading';
import ResponsiveActions from 'components/PageHeader/ResponsiveActions';
import { downloadCashBalance } from 'helpers/investor/downloadCashBalance';
import Spacing from 'components/Spacing';

type AllocationViewLinkProps = any; //TODO: define props

const AllocationViewLink: React.FC<AllocationViewLinkProps> = ({
  shareAllocationUploadId,
}) => {
  if (!shareAllocationUploadId) return null;
  return shareAllocationUploadId.deletedAt ? (
    <>{getCompanyLabel(shareAllocationUploadId?.companyId)}</>
  ) : (
    <NavLink
      style={{ color: '#56B26C', fontWeight: 'bold' }}
      to={createUploadSheetViewRoute(shareAllocationUploadId?._id)}
    >
      {getCompanyLabel(shareAllocationUploadId?.companyId)}
    </NavLink>
  );
};

const columns = [
  {
    label: 'Date',
    key: 'date',
    sort: false,
    render: (elm) => dateToLabel(elm?.date),
  },
  {
    label: 'Type',
    key: 'type',
    sort: false,
    render: (elm) => {
      const label = CashBalanceItemTypeToLabelMap[elm?.type];
      if (
        elm?.type === 'INVESTMENT_RESIDUAL_CASH' ||
        elm?.type === 'INVESTMENT_RESIDUAL_CASH_REVERSAL'
      ) {
        const content =
          elm.fundFullyDeployedLog?.fullDeploymentTrigger ===
          FundFullDeploymentTrigger.FundSettings ? (
            'Residual cash moved because tranche was fully deployed/undeployed from the tranche settings page.'
          ) : (
            <>
              Residual cash moved because of a final deal allocation.{' '}
              <AllocationViewLink
                shareAllocationUploadId={
                  elm.fundFullyDeployedLog?.shareAllocationUploadId
                }
              />
            </>
          );

        return (
          <span>
            {label}{' '}
            <Tooltip title={content} arrow leaveDelay={250}>
              <InfoOutlined
                style={{
                  fontSize: '1rem',
                  verticalAlign: 'middle',
                  marginLeft: '0.25rem',
                }}
              />
            </Tooltip>
          </span>
        );
      }
      return elm.exitStatementId ? (
        <NavLink
          style={{ color: '#56B26C', fontWeight: 'bold' }}
          to={createExitStatementViewRoute(elm.exitStatementId)}
        >
          {label}
        </NavLink>
      ) : (
        label
      );
    },
  },
  {
    label: 'Company',
    key: 'company',
    sort: false,
    render: (elm) => {
      if (elm?.company) {
        if (elm.company.isVCT)
          return `${elm.company.firmId?.firmName} ${elm.company.fundId?.fundName}`;

        return elm.company.tradingName;
      }
      return null;
    },
  },
  {
    label: 'Details',
    key: 'investment',
    sort: false,
    render: (elm) =>
      elm?.type === 'TRANSFER' && elm.recipient ? (
        <Link to={createInvestorViewRoute(elm.recipient._id)}>
          <Button variant="text" size="small" style={{ fontWeight: '600' }}>
            Transferred to {elm.recipient.fullName}
          </Button>
        </Link>
      ) : elm?.investment ? (
        <Link to={createInvestmentEditRoute(elm.investment._id)}>
          <div style={{ fontWeight: '600', color: '#0009' }}>
            {elm.investment.fundId?.fundName} -{' '}
            {dateToLabel(elm.investment.investmentDate)}
          </div>
        </Link>
      ) : (
        elm?.notes ?? ''
      ),
  },
  {
    label: 'Money in',
    key: 'moneyIn',
    sort: false,
    render: (elm) => numberToCurrencyString(elm?.moneyIn),
  },
  {
    label: 'Money out',
    key: 'moneyOut',
    sort: false,
    render: (elm) => numberToCurrencyString(elm?.moneyOut),
  },
  {
    label: 'Balance',
    key: 'balance',
    sort: false,
    render: (elm) => numberToCurrencyString(elm?.balance),
  },
  {
    label: 'Notes',
    key: 'notes',
    sort: false,
    render: (elm) =>
      elm.notes &&
      elm.type !== 'INVESTOR_FEE' &&
      elm.type !== 'INVESTOR_DISCOUNT' ? (
        <Tooltip title={elm.notes}>
          <IconButton size="large">
            <Comment style={{ fontSize: 22 }} />
          </IconButton>
        </Tooltip>
      ) : null,
  },
  {
    label: 'Attachment',
    key: 'attachmentSignedGetUrl',
    sort: false,
    render: (elm) => {
      if (elm?.attachmentSignedGetUrl) {
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={elm.attachmentSignedGetUrl}
          >
            <IconButton size="small">
              <AttachFile />
            </IconButton>
          </a>
        );
      }
    },
  },
];

type Props = any; //TODO: define props

const CashBalanceTable: React.FC<Props> = ({
  cashBalance,
  investorDetails,
}) => {
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination();
  const startIndex = (page - 1) * parseInt(rowsPerPage);
  const result = slice(
    cashBalance.ledger,
    startIndex,
    startIndex + parseInt(rowsPerPage),
  );

  return (
    <Spacing>
      <PageHeader>
        <Heading variant="h2" noMargin>
          Cash Balance - {cashBalance.firm?.firmName}
        </Heading>
        <ResponsiveActions>
          <Link to={`${WITHDRAWALS_ADD}?investorId=${investorDetails._id}`}>
            <Button size="small" variant="contained" color="primary">
              Process withdrawal
            </Button>
          </Link>
          {cashBalance.ledger.length ? (
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => downloadCashBalance(cashBalance, investorDetails)}
            >
              Export
            </Button>
          ) : null}
        </ResponsiveActions>
      </PageHeader>
      <Table
        pagination={true}
        columns={columns}
        tablebody={result}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        variant="nohover"
        emptyMessage="This investor has a cash balance of 0. There is no cash available to withdraw."
        count={cashBalance.ledger?.length ?? 0}
        page={page}
        rowsPerPage={rowsPerPage}
      />
    </Spacing>
  );
};

export default CashBalanceTable;

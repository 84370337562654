import { InfoOutlined } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { Row } from 'components/Layout';

const TooltipTotalValue = () => {
  const totalValueTooltipText =
    'This is the combined value of all current holdings, exits and uninvested paid in capital. Where there are unpaid commitments, this figure may be materially lower than subscribed investment or uninvested capital.';
  return (
    <Row>
      <span>Total value</span>
      <Tooltip title={totalValueTooltipText}>
        <IconButton size="small">
          <InfoOutlined style={{ fontSize: '1rem' }} />
        </IconButton>
      </Tooltip>
    </Row>
  );
};

export default TooltipTotalValue;

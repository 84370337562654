import React from 'react';
import { round, sumBy } from 'lodash';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, Controller } from 'react-hook-form';
import Table from 'components/Table';
import {
  numberToCurrencyString,
  dateToLabel,
  toRequestDate,
} from 'further-ui/utils';
import { InvestmentPaymentType } from 'further-types/payment';
import { Api } from 'further-types/investment';
import {
  FirmBankDetailsSelector,
  CashBalanceOptionValue,
} from 'components/FirmBankDetailsSelector';
import schema from './schema';
import FormAmountPaidInput from './FormAmountPaidInput';
import FormDateInput from './FormDateInput';
import FormReferenceInput from './FormReferenceInput';
import FormActions from './FormActions';
import { useMutatePayment } from 'hooks/data/investment/usePayments';
import { styled } from '@mui/material';

const TableContainer = styled('div')<{ darkerTableHeading?: boolean }>(
  ({ darkerTableHeading }) => ({
    '& .MuiTableRow-root:last-child': {
      verticalAlign: 'top',
    },
    ...(darkerTableHeading && {
      '& th': {
        backgroundColor: '#f0f0f0',
      },
    }),
  }),
);

type Props = {
  investmentId: string;
  firmId: string;
  payments: Array<{
    transactions: Array<Api.InvestmentPaymentTransaction>;
    amount: number;
  }>;
  darkerTableHeading?: boolean;
};
export type FormData = {
  amountPaid: string;
  datePaid: Date;
  paidTo: string;
  reference: string;
  sendEmailConfirmation: boolean;
};
type TableElement = Api.InvestmentPaymentTransaction & { isFormRow: boolean };

const PaymentsReconciliationTable: React.FunctionComponent<Props> = ({
  investmentId,
  firmId,
  payments,
  darkerTableHeading = false,
}) => {
  const transactions = payments.flatMap((payment) => payment.transactions);
  const totalCommitment = sumBy(payments, 'amount');
  const fundsReceived = sumBy(transactions, 'amount');
  const amountOutstanding = round(totalCommitment - fundsReceived, 2);

  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      amountPaid: String(Math.max(amountOutstanding, 0)),
      datePaid: new Date(),
      paidTo: '',
      reference: '',
      sendEmailConfirmation: true,
    },
    shouldFocusError: true,
    resolver: zodResolver(schema),
    criteriaMode: 'all',
  });
  const paidToValue = watch('paidTo');

  const {
    create: { mutate: addPayment, isPending: isAddingPayment },
    remove: { mutate: unreconcile, isPending: isUnreconciling },
  } = useMutatePayment(investmentId);

  const onAddPayment = (formData: FormData) => {
    const isCashBalancePayment = formData.paidTo === CashBalanceOptionValue;
    const requestObject: Api.CreateInvestmentPaymentRequest = {
      amount: Number(formData.amountPaid?.replace(/£|,/g, '')),
      //@ts-expect-error
      date: toRequestDate(formData.datePaid),
      paymentType: isCashBalancePayment
        ? InvestmentPaymentType.CashBalance
        : InvestmentPaymentType.Reconciled,
      sendEmailConfirmation: formData.sendEmailConfirmation,
    };
    if (!isCashBalancePayment) {
      requestObject.firmBankDetailId = formData.paidTo;
      requestObject.reference = formData.reference;
    }
    addPayment(requestObject);
    reset({
      amountPaid: '0',
    });
  };

  const columns = [
    {
      label: 'Amount paid',
      key: 'investmentAmount',
      sort: false,
      render: (elm: TableElement) =>
        !elm.isFormRow ? (
          numberToCurrencyString(elm.amount)
        ) : (
          <FormAmountPaidInput control={control} error={errors?.amountPaid} />
        ),
    },
    {
      label: 'Payment date',
      key: 'paymentDate',
      sort: false,
      render: (elm: TableElement) =>
        !elm.isFormRow ? (
          dateToLabel(elm.date)
        ) : (
          <FormDateInput control={control} error={errors?.datePaid} />
        ),
    },
    {
      label: 'Payment',
      key: 'type',
      sort: false,
      render: (elm: TableElement) => {
        const types = {
          [InvestmentPaymentType.Banked]: 'Banked',
          [InvestmentPaymentType.ManuallyRecorded]: 'Manually recorded',
          [InvestmentPaymentType.CashBalance]: 'Paid from cash balance',
          [InvestmentPaymentType.Reconciled]: `Paid to ${
            elm.bankStatementLine?.firmBankDetail?.bankAccountName ??
            'bank account'
          }`,
        } as const;

        if (elm.isFormRow) {
          return (
            <Controller
              name="paidTo"
              control={control}
              render={({ field: { value, onChange } }) => (
                <FirmBankDetailsSelector
                  firmId={firmId}
                  onChange={(bankDetailId) => onChange(bankDetailId)}
                  currentValue={value}
                  placeholder="Select receipt account"
                  allowCashBalanceSelection
                  error={!!errors?.paidTo}
                  helperText={errors?.paidTo?.message}
                />
              )}
            />
          );
        } else {
          if (elm.paymentType && elm.paymentType in types) {
            return types[elm.paymentType];
          }
          return 'Manual';
        }
      },
    },
    {
      label: 'Reference',
      key: 'reference',
      sort: false,
      render: (elm: TableElement) =>
        !elm.isFormRow ? (
          elm.bankStatementLine?.reference || '-'
        ) : (
          <FormReferenceInput
            control={control}
            disabled={paidToValue === CashBalanceOptionValue}
            error={errors?.reference}
          />
        ),
    },
    {
      label: 'Actions',
      key: 'actions',
      sort: false,
      render: (elm: TableElement) => (
        <FormActions
          elm={elm}
          control={control}
          onAddPayment={handleSubmit(onAddPayment)}
          isAddingPayment={isAddingPayment}
          unreconcile={unreconcile}
          isUnreconciling={isUnreconciling}
        />
      ),
    },
  ];

  return (
    <TableContainer darkerTableHeading={darkerTableHeading}>
      <Table
        columns={columns}
        //@ts-expect-error
        tablebody={[...transactions, { isFormRow: true }]}
        emptyMessage="No payments found"
        variant="nohover"
      />
    </TableContainer>
  );
};

export default PaymentsReconciliationTable;

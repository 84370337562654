export const CashBalanceItemTypeToLabelMap = {
  INVESTMENT_RESIDUAL_CASH: 'Residual cash',
  INVESTMENT_FROZEN_CASH: 'Residual cash',
  INVESTMENT_RESIDUAL_CASH_REVERSAL: 'Residual cash return',
  EXIT: 'Exit',
  EXIT_AMENDMENT: 'Exit Amendment',
  WITHDRAWAL: 'Withdrawal',
  REJECTED_WITHDRAWAL: 'Cancelled withdrawal',
  NEW_INVESTMENT: 'Move to new investment',
  INTEREST: 'Interest',
  INVESTOR_FEE: 'Fee',
  WITHDRAWAL_FEE: 'Withdrawal fee',
  DIVIDEND: 'Dividend',
  MANUAL_ADJUSTMENT: 'Manual adjustment',
  TRANSFER: 'Transfer',
  INVESTOR_DISCOUNT: 'Discount',
};

import downloadFile from 'utils/downloadFile';
import { api } from 'lib/httpClient';

export const deleteInvestment = (id) => {
  return () => {
    return api
      .delete(`investment/delete/${id}`)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

// This is for share allocation upload
export const getExcelFile = (formData) => {
  return () => {
    return api
      .post(
        '/upload-shareholding/download-share-allocation-template-file',
        formData,
        {
          responseType: 'blob',
          validateStatus: (status) => status === 200,
        },
      )
      .then((response) => {
        downloadFile(
          response.data,
          `Allocation-upload-sheet-${Date.now()}.xlsx`,
        );
        return Promise.resolve(response);
      })
      .catch(async (error) => {
        if (error.response?.data instanceof Blob) {
          const text = await error.response.data.text();
          try {
            const errorData = JSON.parse(text);
            return Promise.reject(errorData);
          } catch (error) {
            return Promise.reject(error);
          }
        }
        return Promise.reject(error);
      });
  };
};
// This is for share allocation upload
export const uploadExcelFile = (formData) => {
  return () => {
    return api
      .post(`upload-shareholding/process-share-allocations-from-file`, formData)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
// This is for share allocation upload
export const uploadFinalExcelFile = (shareAllocationUploadId) => {
  return () => {
    return api
      .get(
        `upload-shareholding/create-share-allocations/${shareAllocationUploadId}`,
      )
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

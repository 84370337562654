import React from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Divider } from '@mui/material';
import PageContainer from 'components/PageContainer';
import { ADVISER, createAdviserEditRoute } from 'adminConstants/routes';
import AdviserFeeAmendments from './AdviserFeeAmendments';
import AdviserBasicDetails from './AdviserBasicDetails';
import { useAdviser } from 'hooks/data/adviser/useAdviser';
import AdviserInvestments from './AdviserInvestments';
import OrganisationDetails from './OrganisationDetails';
import Notes from 'components/Notes';
import { NoteRelation } from 'further-types/notes';
import PageContent from 'components/PageContent';
import Button from 'components/Button';
import RecordView from 'components/RecordView';
import Heading from 'components/Heading';
import PageHeader from 'components/PageHeader';
import ResponsiveActions from 'components/PageHeader/ResponsiveActions';

const breadcrumbs = [
  { label: 'Dashboard' },
  { label: 'Adviser', link: ADVISER },
  { label: 'View Adviser', link: '/', isActive: true },
];

const title = 'View adviser';

const ViewAdviser: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const adviser = useAdviser({ params: { id } });

  const adviserData = adviser?.fetch?.data?.adviser || {};

  const handleAdviserStatement = () => {
    adviser.exportStatement.fetch();
  };

  return (
    <PageContainer
      heading={title}
      breadcrumbs={breadcrumbs}
      loading={!!id && adviser.fetch.isLoading}
    >
      <PageContent>
        <PageHeader>
          <Heading variant="h2">Adviser details</Heading>
          <ResponsiveActions>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={handleAdviserStatement}
              loading={adviser.exportStatement.isFetching}
            >
              Download adviser statement
            </Button>

            <Link to={createAdviserEditRoute(id)}>
              <Button variant="outlined" color="primary" size="small">
                Edit adviser details
              </Button>
            </Link>
          </ResponsiveActions>
        </PageHeader>

        <RecordView>
          <AdviserBasicDetails adviserData={adviserData} />
          <AdviserFeeAmendments
            changeToInitialInvestmentFees={
              adviserData.changeToInitialInvestmentFees
            }
            commissionOnInitialInvestmentFees={
              adviserData.commissionOnInitialInvestmentFees
            }
            firmsInFeeAmendments={adviserData.firmsInFeeAmendments}
          />
        </RecordView>

        <Divider style={{ margin: '2rem 0' }} />

        <RecordView>
          <OrganisationDetails org={adviserData.organisation} />
        </RecordView>

        <Divider style={{ margin: '2rem 0' }} />

        <AdviserInvestments adviserId={id} adviserName={adviserData.fullName} />

        <Divider style={{ margin: '2rem 0' }} />

        <Notes relationId={id} noteRelation={NoteRelation.Adviser} />
      </PageContent>
    </PageContainer>
  );
};
export default ViewAdviser;

import { FC } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import RestrictedRoute from './RestrictedRoute';
import CommonRoutes from './CommonRoutes';
import * as paths from 'adminConstants/routes';
import Vehicles from 'routes/LpPages/Vehicle/ListVehicles';

import GeneralLedger from 'routes/LpPages/FundAccounting';
import AddVehicle from 'routes/LpPages/Vehicle/AddVehicle';
import FundSetup from 'routes/LpPages/FundSetup';
import AddEditIncomeOrExpense from 'routes/LpPages/IncomeAndExpenses/AddEditIncomeOrExpense';
import ListIncomeOrExpense from 'routes/LpPages/IncomeAndExpenses/ListIncomeAndExpenses';
import BankReconciliation from 'routes/LpPages/BankReconciliation';
import AddNewBankAccount from 'routes/LpPages/BankReconciliation/AddNewBankAccount';
import ProcessBankAccount from 'routes/LpPages/BankReconciliation/ProcessBankAccount';
import ImportStatement from 'routes/LpPages/BankReconciliation/ImportStatement';
import CreateInvestment from 'routes/LpPages/Investment/CreateInvestment';
const LpRoutes: FC = () => (
  <Switch>
    <Redirect path="/" exact to={paths.GENERAL_LEDGER} />
    <RestrictedRoute component={Vehicles} path={paths.VEHICLES} />
    <RestrictedRoute component={AddVehicle} path={paths.VEHICLE_ADD} />
    <RestrictedRoute component={GeneralLedger} path={paths.GENERAL_LEDGER} />
    <RestrictedRoute
      component={FundSetup}
      path={paths.FUND_SETUP}
      useErrorBoundary
    />
    <RestrictedRoute
      component={ImportStatement}
      path={paths.IMPORT_STATEMENT_FILE}
    />
    <RestrictedRoute
      component={AddNewBankAccount}
      path={paths.ADD_BANK_ACCOUNT}
    />
    <RestrictedRoute
      component={ProcessBankAccount}
      path={paths.PROCESS_BANK_ACCOUNT}
    />
    <RestrictedRoute
      component={BankReconciliation}
      path={paths.BANK_RECONCILIATION}
    />
    <RestrictedRoute
      component={AddEditIncomeOrExpense}
      path={paths.INCOME_OR_EXPENSE_ADD}
    />
    <RestrictedRoute
      component={AddEditIncomeOrExpense}
      path={paths.INCOME_OR_EXPENSE_EDIT}
    />
    <RestrictedRoute
      component={ListIncomeOrExpense}
      path={paths.INCOME_OR_EXPENSE_LIST}
    />
    <RestrictedRoute
      component={CreateInvestment}
      path={paths.INVESTMENT_CREATE}
    />
    {...CommonRoutes}
  </Switch>
);

export default LpRoutes;

import Table from 'components/Table';
import { useDisclosure } from 'further-ui/hooks';

import { useNotification } from 'hooks/ui/useNotification';
import AlertDialog from 'components/AlertDialog';
import AddBankAccountDialog from './AddBankAccountDialog';
import EditBankAccountDialog from './EditBankAccountDialog';
import useCommonStyles from '../commonStyles';
import { Delete, Edit } from '@mui/icons-material';
import { Button } from '@mui/material';
import {
  useListFirmBankDetails,
  useDeleteFirmBankDetails,
} from 'hooks/data/firm/useFirmBankDetails';
import { Api } from 'further-types/firm';
import { formatSortCode } from 'further-ui/utils';
import { TableActions } from 'components/Table';

const BankAccountDetailsForIncomingPayments = ({ firm }) => {
  const { classes: commonClasses } = useCommonStyles();
  const bankAccounts = useListFirmBankDetails(firm?._id);
  const deleteBankAccount = useDeleteFirmBankDetails(firm?._id);
  const deleteDisclosure = useDisclosure();
  const addDisclosure = useDisclosure();
  const editDisclosure = useDisclosure();
  const notification = useNotification();

  const columns = [
    {
      label: 'Beneficiary name',
      key: 'beneficiaryName',
      sort: false,
    },
    {
      label: 'Bank name',
      key: 'bankName',
      sort: false,
      render: (elm: Api.FirmBankDetail) => elm.bankName || '-',
    },
    {
      label: 'UK account number',
      key: 'bankAccountNumber',
      sort: false,
      render: (elm: Api.FirmBankDetail) => elm.bankAccountNumber || '-',
    },
    {
      label: 'UK sort code',
      key: 'bankSortCode',
      sort: false,
      render: (elm: Api.FirmBankDetail) =>
        elm.bankSortCode ? formatSortCode(elm.bankSortCode) : '-',
    },
    {
      label: 'IBAN (if applicable)',
      key: 'iban',
      sort: false,
      render: (elm: Api.FirmBankDetail) => elm.iban || '-',
    },
    {
      label: 'Actions',
      key: 'actions',
      sort: false,
      render: (elm: Api.FirmBankDetail) => (
        <TableActions
          showAsDropdown
          actions={[
            {
              icon: Delete,
              color: elm.isLinkedToFund ? 'default' : 'error',
              disabled: elm.isLinkedToFund,
              onClick: () => deleteDisclosure.stageAction({ id: elm._id }),
              tooltip: elm.isLinkedToFund
                ? 'This bank account is linked to a tranche and cannot be deleted.'
                : undefined,
              tooltipPlacement: 'left',
              label: 'Delete',
            },
            {
              icon: Edit,
              label: 'Edit',
              color: 'primary',
              onClick: () => editDisclosure.stageAction(elm),
            },
          ]}
        />
      ),
    },
  ];

  const handleConfirmDeleteBankAccount = async () => {
    if (deleteDisclosure.actionProps.id) {
      try {
        await deleteBankAccount.mutateAsync({
          bankDetailId: deleteDisclosure.actionProps.id,
        });
        notification.success('Bank account deleted successfully.');
      } catch (_) {
        notification.error('Bank account could not be deleted.');
      } finally {
        deleteDisclosure.onClose();
      }
    }
  };

  return (
    <>
      <div className={commonClasses.container}>
        <Table
          pagination={false}
          tablebody={bankAccounts.data ?? []}
          loading={bankAccounts.isLoading}
          columns={columns}
          order={'asc'}
          orderBy={'name'}
          page={1}
          rowsPerPage={1}
          variant="nohover"
        />

        <div className={commonClasses.buttonContainer}>
          <Button
            className={commonClasses.addMoreButton}
            onClick={addDisclosure.onOpen}
            variant="text"
            color="primary"
            size="small"
          >
            Add new bank account
          </Button>
        </div>
      </div>

      <AddBankAccountDialog disclosure={addDisclosure} firmId={firm?._id} />
      <EditBankAccountDialog disclosure={editDisclosure} firmId={firm?._id} />

      <AlertDialog
        open={deleteDisclosure.isOpen}
        onClose={deleteDisclosure.onClose}
        onConfirm={handleConfirmDeleteBankAccount}
        title={`Delete this bank account?`}
        content={<p>Are you sure you wish to delete this bank account?</p>}
        btnLabels={{
          cancel: 'Go back',
          confirm: 'Delete bank account',
        }}
      />
    </>
  );
};

export default BankAccountDetailsForIncomingPayments;

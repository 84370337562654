import { z } from 'zod';
import { sumBy, round } from 'lodash';
import { isMongoId } from './';

export default z.array(
  z.object({
    commitmentId: z.string().refine(isMongoId()),
    investorLabel: z.string().trim(),
    allocationAmount: z.number(),
    allocationPercentage: z.number(),
    excludeFromAllocation: z.boolean(),
  }),
);

export const allocationMatchesCheckAmount = (
  checkAmount: number,
  allocations: Array<{ allocationAmount: number }>,
) => {
  const totalAllocationAmount = sumBy(allocations, (item) =>
    Number(item.allocationAmount),
  );
  return round(totalAllocationAmount, 2) === checkAmount;
};

import { isEmpty } from 'lodash';

interface Company {
  isVCT?: boolean;
  tradingName?: string;
  shareClass?: string;
  firmId?: {
    firmName: string;
  };
  fundId?: {
    fundName: string;
  };
}

interface Options {
  includeShareClass?: boolean;
  includeFirmName?: boolean;
}

export const getCompanyLabel = (company?: Company, options?: Options) => {
  if (!company || isEmpty(company)) return '';
  const { includeShareClass = true, includeFirmName = false } = options ?? {};
  const { isVCT, tradingName, shareClass, firmId, fundId } = company;

  const shareClassLabel = shareClass?.length ? `(${shareClass})` : '';
  const firmNameLabel = firmId?.firmName ? `${firmId.firmName} - ` : '';

  if (isVCT) {
    return `${firmNameLabel}${fundId?.fundName ?? ''} ${shareClassLabel}`;
  }

  if (!includeFirmName) {
    return includeShareClass
      ? `${tradingName ?? ''} ${shareClassLabel}`
      : tradingName;
  }

  return includeShareClass
    ? `${firmNameLabel}${tradingName ?? ''} ${shareClassLabel}`
    : `${firmNameLabel}${tradingName ?? ''}`;
};

import {
  Person,
  AttachMoney,
  Notifications,
  PlaylistAddCheck,
  MoreVertOutlined,
  CachedOutlined,
  FileDownloadOutlined,
  ReportOutlined,
  CheckCircleOutlineOutlined,
  CancelOutlined,
  RestoreOutlined,
} from '@mui/icons-material';
import { Box, styled, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import Heading from 'components/Heading';
import PageContainer from 'components/PageContainer';
import PageContent from 'components/PageContent';
import PageSection from 'components/PageSection';
import { useEffect, useState } from 'react';
import Spacing from 'components/Spacing';
import { useHistory, useLocation } from 'react-router-dom';
import {
  ADD_BANK_ACCOUNT,
  BankReconciliationTab,
  createImportStatementFileRoute,
} from 'adminConstants/routes';
import BankAccountSelect from 'components/BankAccountSelect';
import api from 'lib/trpcClient';
import { useLayoutContext } from 'contexts/LayoutContext';
import { Row } from 'components/Layout';
import { numberToCurrencyString } from 'further-ui/utils';
import DropdownActions, { DropdownAction } from 'components/DropdownActions';
import ImportingTransactions from './ImportingTransactions';
import BankStatement from './BankStatement';
import BankReconciliationContextProvider, {
  useBankReconciliationContext,
} from 'contexts/BankReconciliationContext';
import AccountSettings from './AccountSettings';
import ReconcileTransactions from './ReconcileTransactions';
import ErrorBoundary from 'components/ErrorBoundary';
import ReconciledActions from './ReconciledActions';
import useManageBankAccount from 'hooks/lp/data/bankAccount/useManageBankAccount';
import Button from 'components/Button';

const tabsData = {
  [BankReconciliationTab.ReconcileTransactions]: {
    icon: Person,
    title: 'Reconcile transactions',
  },
  [BankReconciliationTab.BankStatement]: {
    icon: AttachMoney,
    title: 'Bank statement',
  },
  [BankReconciliationTab.ReconciledActions]: {
    icon: PlaylistAddCheck,
    title: 'Reconciled actions',
  },
  [BankReconciliationTab.AccountSettings]: {
    icon: Notifications,
    title: 'Account settings',
  },
};

const PageContentStyled = styled(PageContent, {
  shouldForwardProp: (prop) => prop !== 'showTopBar',
})<{ showTopBar: boolean }>(({ showTopBar }) => ({
  overflow: 'visible',

  '& .MuiCardContent-root': {
    paddingTop: showTopBar ? 0 : undefined,
  },
}));

const Balances = styled(Spacing)(({ theme }) => ({
  width: '100%',
  maxWidth: '440px',
  alignItems: 'flex-end',

  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
  },
}));

const Balance = styled(Row)(({ theme }) => ({
  width: '100%',
  border: `1px solid ${theme.palette.borderColor?.dark}`,
  padding: theme.spacing(2, 8, 2, 6),
  justifyContent: 'space-between',
}));

const ExpiredTopBar = styled(Row)(({ theme }) => ({
  backgroundColor: theme.palette.warning.main,
  borderTopLeftRadius: theme.shape.borderRadius,
  borderTopRightRadius: theme.shape.borderRadius,
  color: theme.palette.text.white,
  marginLeft: theme.spacing(-6),
  marginRight: theme.spacing(-6),
  marginBottom: theme.spacing(6),
  padding: theme.spacing(2, 6),
  textAlign: 'center',
}));

const RenewButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.white,
  borderColor: theme.palette.text.white,
  padding: theme.spacing(1, 3),
  fontWeight: theme.typography.fontWeightBold,
}));

const BankReconciliationWrapper: React.FC = () => {
  const { selectedVehicleId } = useLayoutContext();

  const { data: bankAccounts, isLoading } =
    api.bankReconciliation.listBankAccounts.useQuery({
      vehicleId: selectedVehicleId ?? '',
    });

  return (
    <BankReconciliationContextProvider
      vehicleId={selectedVehicleId ?? ''}
      bankAccounts={bankAccounts ?? []}
    >
      <PageContainer heading="Bank Reconciliation" loading={isLoading}>
        {isLoading ? null : <BankReconciliation />}
      </PageContainer>
    </BankReconciliationContextProvider>
  );
};

const BankReconciliation: React.FC = () => {
  const { hash } = useLocation();
  const history = useHistory();
  const { selectedBankAccount, setSelectedBankAccountId, bankAccounts } =
    useBankReconciliationContext();
  const [activeTab, setActiveTab] = useState<BankReconciliationTab>(
    (hash as BankReconciliationTab) ||
      BankReconciliationTab.ReconcileTransactions,
  );
  const {
    refreshBankFeed,
    deactivateBankFeed,
    renewBankConnection,
    isRefreshingBankFeed,
    isPending,
  } = useManageBankAccount(selectedBankAccount);

  const handleTabChange = (_: unknown, selectedTab: BankReconciliationTab) => {
    setActiveTab(selectedTab);
  };

  useEffect(() => {
    if (
      !selectedBankAccount ||
      selectedBankAccount.operationsCount > 0 ||
      isRefreshingBankFeed
    )
      return;

    refreshBankFeed();
  }, [selectedBankAccount?.id]);

  const bankAccountActions: Array<DropdownAction> = [
    {
      label: 'Refresh bank feed',
      icon: CachedOutlined,
      onClick: refreshBankFeed,
      disabled: isPending || !selectedBankAccount?.bankFeedAccountId,
    },
    {
      label: 'Renew bank connection',
      icon: RestoreOutlined,
      onClick: renewBankConnection,
      disabled: isPending || !selectedBankAccount?.bankFeedAccountId,
    },
    {
      label: 'Import a statement file',
      icon: FileDownloadOutlined,
      onClick: () =>
        history.push(
          createImportStatementFileRoute(selectedBankAccount?.id ?? ''),
        ),
      disabled: isPending,
    },
    {
      label: 'Deactivate bank feed',
      icon: CancelOutlined,
      onClick: deactivateBankFeed,
      disabled: isPending || !selectedBankAccount?.bankFeedAccountId,
      color: 'error',
    },
  ];

  const balancesMatch =
    selectedBankAccount?.balanceFromStatement ===
    selectedBankAccount?.calculatedBalance;

  const BalanceIcon = balancesMatch
    ? CheckCircleOutlineOutlined
    : ReportOutlined;
  const balanceIconColor = balancesMatch ? 'primary' : 'warning';
  const balanceTooltipText = balancesMatch
    ? 'Your statement balance and Further-calculated balance match. No action is required at this moment.'
    : 'Your statement balance and Further-calculated balance do not match. This may be because a transaction is missing or duplicated on your bank statement. Please review the system statement against your bank account to identify and fix any discrepancy.';

  const isBankFeedConnectionExpired = !!(
    selectedBankAccount?.bankFeedAccessValidTo &&
    selectedBankAccount.bankFeedAccessValidTo <= new Date()
  );

  return (
    <PageContentStyled showTopBar={isBankFeedConnectionExpired}>
      {isBankFeedConnectionExpired && (
        <ExpiredTopBar justify="center" centered spacing="md">
          <Typography variant="body1" color="text.white" fontWeight="bold">
            Your bank feed connection has expired and requires renewal
          </Typography>
          <RenewButton variant="outlined" onClick={renewBankConnection}>
            Renew bank feed
          </RenewButton>
        </ExpiredTopBar>
      )}
      <Spacing size="lg">
        <Row justify="space-between" fullWidth breakpoint="md">
          <BankAccountSelect
            bankAccounts={bankAccounts ?? []}
            onBankAccountChange={setSelectedBankAccountId}
            onBankAccountAdd={() => history.push(ADD_BANK_ACCOUNT)}
            selectedBankAccountId={selectedBankAccount?.id}
          />
          <Balances size="sm">
            <Box sx={{ width: '100%' }}>
              <Balance centered spacing="md">
                <Row centered spacing="xs">
                  <Tooltip title={balanceTooltipText}>
                    <BalanceIcon color={balanceIconColor} />
                  </Tooltip>
                  <Typography variant="body1" color="text.secondary">
                    Latest statement balance
                  </Typography>
                </Row>
                <Typography variant="h6" align="right">
                  {numberToCurrencyString(
                    selectedBankAccount?.balanceFromStatement,
                    {
                      currency: selectedBankAccount?.accountCurrency,
                    },
                  )}
                </Typography>
              </Balance>
              <Balance centered spacing="md">
                <Row centered spacing="xs">
                  <Tooltip title={balanceTooltipText}>
                    <BalanceIcon color={balanceIconColor} />
                  </Tooltip>
                  <Typography variant="body1" color="text.secondary">
                    Further-calculated balance
                  </Typography>
                </Row>
                <Typography variant="h6" align="right">
                  {numberToCurrencyString(
                    selectedBankAccount?.calculatedBalance,
                    {
                      currency: selectedBankAccount?.accountCurrency,
                    },
                  )}
                </Typography>
              </Balance>
            </Box>
            <DropdownActions
              id="bank-account-actions"
              title="Bank account actions"
              hideIconSeparator
              actions={bankAccountActions}
              icon={MoreVertOutlined}
            />
          </Balances>
        </Row>
        <Tabs
          showDivider
          value={activeTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
        >
          {Object.keys(tabsData).map((tab) => (
            <Tab
              key={tab}
              value={tab}
              label={
                <Heading variant="h5" noMargin icon={tabsData[tab].icon}>
                  {tabsData[tab].title}
                </Heading>
              }
            />
          ))}
        </Tabs>
        {isRefreshingBankFeed ? (
          <ImportingTransactions />
        ) : selectedBankAccount ? (
          <PageSection>
            {activeTab === BankReconciliationTab.ReconcileTransactions && (
              <ErrorBoundary>
                <ReconcileTransactions bankAccount={selectedBankAccount} />
              </ErrorBoundary>
            )}
            {activeTab === BankReconciliationTab.BankStatement && (
              <BankStatement bankAccount={selectedBankAccount} />
            )}
            {activeTab === BankReconciliationTab.ReconciledActions && (
              <ErrorBoundary>
                <ReconciledActions bankAccount={selectedBankAccount} />
              </ErrorBoundary>
            )}
            {activeTab === BankReconciliationTab.AccountSettings && (
              <AccountSettings bankAccount={selectedBankAccount} />
            )}
          </PageSection>
        ) : null}
      </Spacing>
    </PageContentStyled>
  );
};

export default BankReconciliationWrapper;

import { ActionOperationType } from 'further-types/lp/action';
import { CurrencyCode } from 'further-types/lp/vehicle';
import { useDisclosure } from 'further-ui/hooks';
import { CalendarDay } from 'further-ui/utils';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

export type Action = {
  id: string;
  sourceActionId?: string;
  operationType?: ActionOperationType;
  unreconciledAmount: number;
  currencyCode: CurrencyCode;
  counterpartyLabel: string;
  ledgerDate: CalendarDay;
};

export default function useSplitActions(
  defaultActions: Array<Action>,
  onSplit?: (sourceActionId: string, newActionId: string) => void,
) {
  const disclosure = useDisclosure();
  const [actionToSplit, setActionToSplit] = useState<Action | null>(null);
  const [actions, setActions] = useState<Array<Action>>(defaultActions);

  const selectActionToSplit = (actionId: string) => {
    const action = actions.find((action) => action.id === actionId);
    if (!action) return;

    setActionToSplit(action);
    disclosure.onOpen();
  };

  const splitSelectedAction = (amount: number) => {
    const sourceActionIndex = actions.findIndex(
      (action) => action.id === actionToSplit?.id,
    );
    if (sourceActionIndex < 0) return;

    const sourceAction = actions[sourceActionIndex];

    if (amount <= 0 || amount > sourceAction.unreconciledAmount) return;

    const newActionId = uuidv4();

    setActions([
      ...actions.slice(0, sourceActionIndex),
      {
        ...sourceAction,
        unreconciledAmount: sourceAction.unreconciledAmount - amount,
      },
      {
        ...sourceAction,
        id: newActionId,
        unreconciledAmount: amount,
        sourceActionId: sourceAction.id,
      },
      ...actions.slice(sourceActionIndex + 1),
    ]);
    setActionToSplit(null);
    disclosure.onClose();
    onSplit?.(sourceAction.id, newActionId);
  };

  return {
    actions,
    showSplitActionDialog: disclosure.isOpen,
    closeSplitActionDialog: disclosure.onClose,
    actionToSplit,
    selectActionToSplit,
    splitSelectedAction,
  };
}

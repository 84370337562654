import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { styled } from '@mui/material/styles';

const EditorWrapper = styled('div')({
  '& .ck-editor__editable': {
    '& ol, & ul': {
      margin: '0.3rem 0 0.3rem 0',
      paddingLeft: '1.5rem',
      listStylePosition: 'outside',
    },
    '& ul > li': {
      listStyle: 'disc outside',
      position: 'relative',
      marginBottom: '0.5rem',
      lineHeight: '1.5',
      color: 'var(--txt-common)',
    },
    '& ol > li': {
      listStyle: 'decimal outside',
      position: 'relative',
      marginBottom: '0.5rem',
      lineHeight: '1.5',
      color: 'var(--txt-common)',
    },
    '& li:last-child': {
      marginBottom: '0',
    },
    '& li > p': {
      display: 'inline',
      margin: '0',
    },
  },
});

const removeWatermark = (editors) => {
  const observer = new MutationObserver(() => {
    const poweredByElements = document.querySelectorAll(
      '.ck-powered-by, .ck-powered-by-balloon',
    );
    poweredByElements.forEach((element) => element.remove());
  });

  observer.observe(document.body, {
    childList: true,
    subtree: true,
  });

  editors.on('destroy', () => {
    observer.disconnect();
  });
};

const CkEditor = ({
  value,
  onChange,
  placeholder,
  disabled,
  onBlur,
  extraLine = false,
  showLists = true,
  addTargetToExternalLinks = false,
}) => {
  const sanitiseHtml = (html) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;

    // Remove meta tags
    tempDiv.querySelectorAll('meta').forEach((meta) => meta.remove());

    // Remove specific docs-internal-guid bold tag
    const guidBoldTag = tempDiv.querySelector('b[id^="docs-internal-guid"]');
    if (guidBoldTag) {
      tempDiv.innerHTML = guidBoldTag.innerHTML;
    }

    // Convert single br tags to paragraph breaks
    const brs = tempDiv.querySelectorAll('br');
    brs.forEach((br) => {
      if (
        br.parentElement?.tagName === 'DIV' ||
        br.parentElement?.tagName === 'BODY'
      ) {
        const p = document.createElement('p');
        p.innerHTML = '<br>';
        br.parentNode?.replaceChild(p, br);
      }
    });

    // Clean up styles
    tempDiv.querySelectorAll('[style]').forEach((el) => {
      el.removeAttribute('style');
    });

    // Apply inline styles to lists
    tempDiv.querySelectorAll('ol, ul').forEach((list) => {
      (list as HTMLElement).style.margin = '0.3rem 0 0.3rem 0';
      (list as HTMLElement).style.paddingLeft = '1.5rem';
      (list as HTMLElement).style.listStylePosition = 'outside';
    });

    tempDiv.querySelectorAll('ul > li').forEach((li) => {
      (li as HTMLElement).style.listStyle = 'disc outside';
    });

    tempDiv.querySelectorAll('ol > li').forEach((li) => {
      (li as HTMLElement).style.listStyle = 'decimal outside';
    });

    tempDiv.querySelectorAll('li').forEach((li) => {
      (li as HTMLElement).style.position = 'relative';
      (li as HTMLElement).style.marginBottom = '0.5rem';
      (li as HTMLElement).style.lineHeight = '1.5';
      (li as HTMLElement).style.color = 'var(--txt-common)';
    });

    tempDiv.querySelectorAll('li:last-child').forEach((li) => {
      (li as HTMLElement).style.marginBottom = '0';
    });

    tempDiv.querySelectorAll('li > p').forEach((p) => {
      (p as HTMLElement).style.display = 'inline';
      (p as HTMLElement).style.margin = '0';
    });

    return tempDiv.innerHTML;
  };

  const handlePaste = (event, data, editors) => {
    // Prevent the default paste behavior
    event.stop();

    const textData = data.getData('text/html') || data.getData('text/plain');

    //if we detect a word document, we don't want to sanitize it and let CKEditor handle it
    if (isWordDocument(textData)) {
      return;
    }

    try {
      const sanitisedHtml = sanitiseHtml(textData);

      // Get the editor model and create a batch for our changes
      const model = editors.model;
      const viewFragment = editors.data.htmlProcessor.toView(sanitisedHtml);
      const modelFragment = editors.data.toModel(viewFragment);

      // Insert the content at the current selection position
      model.insertContent(modelFragment);
    } catch (error) {
      console.error('Error processing paste:', error);
      // If there's an error, let the original paste proceed
    }
  };

  return (
    <EditorWrapper>
      <CKEditor
        className="document-editor-editor"
        editor={DecoupledEditor}
        onReady={(editors) => {
          // Insert the toolbar before the editable area.
          editors.ui
            .getEditableElement()
            .parentElement.insertBefore(
              editors.ui.view.toolbar.element,
              editors.ui.getEditableElement(),
            );
          if (extraLine) {
            editors.editing.view.change((writer) => {
              writer.setStyle(
                'min-height',
                '120px',
                editors.editing.view.document.getRoot(),
              );
            });
          }

          removeWatermark(editors);

          const editorElement = editors.ui.getEditableElement();
          editorElement.classList.add('text-component');
          // Listen for the paste event
          editors.editing.view.document.on('clipboardInput', (event, data) => {
            const { dataTransfer } = data;

            handlePaste(event, dataTransfer, editors);
          });
        }}
        config={{
          toolbar: [
            '|',
            'bold',
            'italic',
            'link',
            'underline',
            ...(showLists ? ['bulletedList', 'numberedList'] : []),
          ],
          link: {
            addTargetToExternalLinks,
            ...(addTargetToExternalLinks
              ? { defaultProtocol: 'https://' }
              : {}),
          },
          list: {
            properties: {
              styles: false,
              startIndex: false,
              reversed: false,
            },
          },
          placeholder,
          pasteFromOffice: {
            keepZeroMargins: true,
            removeFontBackgroundColor: true,
            removeStyles: true,
            converterOptions: {
              preserveListIndentation: true,
            },
          },
          removePlugins: !showLists
            ? [
                'List',
                'ListUI',
                'ListStyle',
                'ListProperties',
                'TodoList',
                'ListEditing',
                'IndentList',
              ]
            : [],
        }}
        disabled={disabled}
        data={value || ''}
        onChange={(_, editor) => {
          const rawData = editor.getData();
          onChange?.(rawData);
        }}
        onBlur={(_, editor) => {
          const rawData = editor.getData();
          const styledData = sanitiseHtml(rawData);
          onBlur?.(styledData);
        }}
      />
    </EditorWrapper>
  );
};

const isWordDocument = (text) => {
  // Check for common Word document HTML markers
  const wordMarkers = [
    'urn:schemas-microsoft-com:office:word',
    'xmlns:w="urn:schemas-microsoft-com:office:word"',
    '<w:WordDocument>',
    'mso-',
    '<o:OfficeDocumentSettings>',
  ];

  return wordMarkers.some((marker) => text.includes(marker));
};

export default CkEditor;

import { styled, Typography } from '@mui/material';
import Button from 'components/Button';
import ButtonGroup from 'components/ButtonGroup';
import Dropzone, { UploadedFile } from 'components/Dropzone';
import Heading from 'components/Heading';
import PageLoader from 'components/PageLoader';
import Spacing from 'components/Spacing';
import { useLayoutContext } from 'contexts/LayoutContext';
import { UploadType } from 'further-types/files';
import { FileType } from 'further-ui/utils';
import useFileUpload, { UploadFileResult } from 'hooks/ui/useFileUpload';
import { useNotification } from 'hooks/ui/useNotification';
import api from 'lib/trpcClient';
import TransactionPreview from './TransactionPreview';

const DownloadButton = styled(Button)({
  padding: 0,
  textDecoration: 'underline',
  '&:hover': {
    backgroundColor: 'transparent',
  },
});

type Props = {
  uploadedFile?: UploadFileResult;
  onFileUpload: (file: UploadFileResult | null) => void;
  onNextStep: () => void;
};

const UploadFile: React.FC<Props> = ({
  onFileUpload,
  uploadedFile,
  onNextStep,
}) => {
  const { selectedVehicleId } = useLayoutContext();
  const { uploadFile } = useFileUpload();
  const { error } = useNotification();

  const { data, isLoading } =
    api.bankReconciliation.bankStatement.getMostRecentOperation.useQuery({
      vehicleId: selectedVehicleId ?? '',
    });

  const downloadExampleFile =
    api.bankReconciliation.bankStatement.downloadExample.useMutation({
      onSuccess: (data) => {
        const mediaType = `data:${FileType.CSV};base64,`;

        const aElement = document.createElement('a');
        aElement.setAttribute('download', 'Bank statement.csv');
        aElement.href = `${mediaType}${data.file}`;
        aElement.setAttribute('target', '_blank');
        aElement.click();
      },
    });

  if (isLoading) {
    return <PageLoader />;
  }

  const handleFileUpload = async (files: Array<UploadedFile>) => {
    if (!files?.[0]?.path) return;
    try {
      const uploadFileResult = await uploadFile(
        files[0],
        UploadType.LpBankStatements,
      );
      onFileUpload(uploadFileResult);
    } catch (_) {
      error("Something went wrong - the file couldn't be uploaded.");
    }
  };

  const handleDownloadExample = () => {
    downloadExampleFile.mutate();
  };

  const handleSubmit = () => {
    if (!uploadedFile) return;

    onNextStep();
  };

  return (
    <Spacing size="lg">
      <Spacing size="sm">
        <Heading variant="h3" noMargin>
          Prepare file for upload
        </Heading>
        <Typography variant="body1" color="textSecondary">
          Download a template statement file for copying your bank statement
          entries into. It is best to download your bank statements in CSV so
          you can copy them into the upload file format.
        </Typography>
        <div>
          <DownloadButton
            variant="text"
            onClick={handleDownloadExample}
            loading={downloadExampleFile.isPending}
          >
            Click here to download our upload template
          </DownloadButton>
        </div>
      </Spacing>
      <Spacing size="sm">
        <Heading variant="h3" noMargin>
          Most recent transaction
        </Heading>
        <TransactionPreview {...(data ?? {})} />
      </Spacing>
      <Spacing size="sm">
        <Heading variant="h3" noMargin>
          File to upload
        </Heading>
        <Dropzone
          name="file"
          maxFiles={1}
          accept={{
            [FileType.CSV]: ['.csv'],
            [FileType.Excel]: ['.xls'],
            [FileType.ExcelAlt]: ['.xlsx'],
          }}
          onDropFile={handleFileUpload}
          files={
            uploadedFile
              ? [
                  {
                    preview: uploadedFile.url,
                    fileName: uploadedFile.name,
                  },
                ]
              : []
          }
          onRemove={() => {
            onFileUpload(null);
          }}
        />
        <Typography variant="body2" color="textSecondary">
          File should end in .XLS, .XLSX or .CSV.
        </Typography>
      </Spacing>
      <ButtonGroup>
        <Button
          variant="contained"
          disabled={!uploadedFile}
          onClick={handleSubmit}
        >
          Next
        </Button>
      </ButtonGroup>
    </Spacing>
  );
};

export default UploadFile;

import { Box, styled } from '@mui/material';
import { MuiDateRangePicker } from 'components/FormElements/DateRangePicker';
import InteractiveTable, { Column } from 'components/InteractiveTable';
import PageFilters, { Filter } from 'components/PageFilters';
import SearchField from 'components/SearchField';
import Spacing from 'components/Spacing';
import { ActionOperationType } from 'further-types/lp/action';
import { ActionRefLabels } from 'further-ui/labels';
import {
  DateRange,
  getDateRange,
  numberToCurrencyString,
} from 'further-ui/utils';
import useFilters from 'hooks/ui/useFilters';
import { usePagination } from 'hooks/ui/usePagination';
import api from 'lib/trpcClient';

const Reconciled = styled(Box)(({ theme }) => ({
  color: theme.palette.text.rootColor,
}));

const PartReconciled = styled(Box)(({ theme }) => ({
  color: theme.palette.warning.main,
}));

type Props = {
  bankAccount: {
    id: string;
    vehicleId: string;
  };
};

const ReconciledActions: React.FC<Props> = ({ bankAccount }) => {
  const pageId = `reconciled-actions-${bankAccount.id}`;

  const pagination = usePagination({ id: pageId });

  const { filters, handleChangeFilters, isFiltersChangePending } = useFilters<{
    dateRange?: DateRange;
    searchQuery?: string;
  }>(pageId, {
    defaultFilters: {
      dateRange: undefined,
      searchQuery: '',
    },
    onFiltersChange: pagination.toFirstPage,
  });

  const [data, query] = api.action.listActions.useSuspenseQuery({
    vehicleId: bankAccount.vehicleId,
    reconciledAndPartiallyReconciledOnly: true,
    dateRange: getDateRange(filters.dateRange),
    searchQuery: filters.searchQuery,
    page: pagination.page,
    rowsPerPage: pagination.rowsPerPage,
    orderBy: 'ledgerDate',
    order: 'desc',
  });

  const columns: Array<Column> = [
    {
      field: 'ledgerDate',
      headerName: 'Date',
      renderCell: ({ row }) => row.ledgerDate.toLabel(),
      flex: 1,
    },
    {
      field: 'actionRef',
      headerName: 'Action',
      renderCell: ({ row }) => ActionRefLabels[row.actionRef],
      flex: 1,
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
    },
    {
      field: 'reference',
      headerName: 'Reference',
      flex: 1,
    },
    {
      field: 'spent',
      headerName: 'Spent',
      renderCell: ({ row }) =>
        numberToCurrencyString(
          row.operationType === ActionOperationType.Spent
            ? row.amount
            : undefined,
          {
            fallback: '-',
            currency: row.currencyCode,
          },
        ),
      flex: 1,
    },
    {
      field: 'received',
      headerName: 'Received',
      renderCell: ({ row }) =>
        numberToCurrencyString(
          row.operationType === ActionOperationType.Received
            ? row.amount
            : undefined,
          {
            fallback: '-',
            currency: row.currencyCode,
          },
        ),
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      renderCell: ({ row }) =>
        row.isFullyReconciled ? (
          <Reconciled>Reconciled</Reconciled>
        ) : (
          <PartReconciled>Part-reconciled</PartReconciled>
        ),
      flex: 1,
    },
  ];

  return (
    <Spacing size="md">
      <PageFilters pageId={pageId}>
        <Filter>
          <MuiDateRangePicker
            dateRange={filters.dateRange}
            onDateRangeChange={(dateRange) => {
              handleChangeFilters({ dateRange });
            }}
            placeholder="Select date range"
          />
        </Filter>
        <Filter>
          <SearchField
            autoSearchOnDebounce
            name="searchQuery"
            variant="standard"
            placeholder="Search by description, counterparty or action"
            fullWidth
            value={filters.searchQuery}
            onSearch={(searchQuery) => {
              handleChangeFilters({ searchQuery });
            }}
            allowEmptyQuery
            iconPlacement="left"
          />
        </Filter>
      </PageFilters>
      <InteractiveTable
        disableTableConfiguration
        disableSorting
        id={pageId}
        columns={columns}
        rows={data?.results ?? []}
        loading={
          query.isFetching ||
          pagination.isPaginationChanging ||
          isFiltersChangePending
        }
        pagination={pagination}
        rowCount={data?.totalCount ?? 0}
      />
    </Spacing>
  );
};

export default ReconciledActions;

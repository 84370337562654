import {
  BankAccountOperationSource,
  BankAccountOperationStatus,
} from 'further-types/lp/bank-reconciliation';

export const BankAccountOperationStatusLabels = {
  [BankAccountOperationStatus.Unreconciled]: 'Unreconciled',
  [BankAccountOperationStatus.Reconciled]: 'Reconciled',
};

export const BankAccountOperationSourceLabels = {
  [BankAccountOperationSource.BankFeed]: 'Bank feed',
  [BankAccountOperationSource.Manual]: 'Upload',
};

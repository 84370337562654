import { useRef, useState } from 'react';
import { Add, ExpandMoreOutlined, Link, LinkOff } from '@mui/icons-material';
import {
  Box,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Popper,
  styled,
  Typography,
} from '@mui/material';
import { Row } from 'components/Layout';
import Spacing from 'components/Spacing';

const selectorWidth = '400px';

const BankAccountSelectStyled = styled(MenuList)(({ theme }) => ({
  maxWidth: selectorWidth,
  border: `1px solid ${theme.palette.borderColor?.dark}`,
  borderBottom: 'none',
  padding: 0,
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
  },
}));

const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  padding: theme.spacing(6),
  borderBottom: `1px solid ${theme.palette.borderColor?.dark}`,
}));

const AddIcon = styled(Add)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: theme.fontSizes?.xxl,
  top: '-2px',
  position: 'relative',
}));

const Container = styled(Box)(({ theme }) => ({
  position: 'relative',
  maxWidth: selectorWidth,
  flexGrow: 1,
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
  },
}));

const MenuContainer = styled(Box)(({ theme }) => ({
  maxWidth: selectorWidth,
  width: '100%',
  borderRadius: 0,
  border: 'none',
  backgroundColor: theme.palette.common.white,

  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
  },
}));

const Account: React.FC<{
  onClick: () => void;
  selected?: boolean;
  accountName: string;
  iban?: string;
  bankFeedAccountId?: string;
}> = ({ onClick, accountName, iban, bankFeedAccountId, selected }) => (
  <MenuItemStyled onClick={onClick}>
    <Spacing size="sm" fullWidth>
      <Row centered spacing="sm" justify="space-between">
        <Typography variant="h3" color="textSecondary">
          <Row spacing="xs" centered>
            {accountName}
            {bankFeedAccountId ? (
              <Link color="primary" />
            ) : (
              <LinkOff color="error" />
            )}
          </Row>
        </Typography>
        {selected ? <ExpandMoreOutlined color="primary" /> : null}
      </Row>
      <Typography color="textSecondary" variant="body1">
        IBAN
        <br />
        {iban}
      </Typography>
    </Spacing>
  </MenuItemStyled>
);

const AddAccount: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <MenuItemStyled selected={true} onClick={onClick}>
    <Row spacing="sm">
      <AddIcon />
      <Spacing size="sm">
        <Typography variant="h3" color="primary">
          Add new bank account
        </Typography>
        <Typography color="textSecondary" variant="body2">
          Select to start adding a new account
        </Typography>
      </Spacing>
    </Row>
  </MenuItemStyled>
);

type Props = {
  selectedBankAccountId?: string;
  bankAccounts: Array<{
    id: string;
    bankName: string;
    iban?: string;
    accountName: string;
    bankFeedAccountId?: string;
  }>;
  onBankAccountChange: (bankAccountId: string) => void;
  onBankAccountAdd: () => void;
};

const menuId = 'bank-account-select-menu';

const BankAccountSelect: React.FC<Props> = ({
  bankAccounts,
  onBankAccountAdd,
  onBankAccountChange,
  selectedBankAccountId,
}) => {
  const anchorRef = useRef<HTMLUListElement>(null);
  const [open, setOpen] = useState(false);

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleMenuItemClick = (accountId: string) => {
    onBankAccountChange(accountId);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const selectedBankAccount = bankAccounts.find(
    (bankAccount) => bankAccount.id === selectedBankAccountId,
  );

  const availableBankAccounts = bankAccounts.filter(
    (bankAccount) => bankAccount.id !== selectedBankAccountId,
  );

  return (
    <Container>
      <BankAccountSelectStyled id={menuId} ref={anchorRef}>
        {selectedBankAccount ? (
          <Account onClick={handleToggle} {...selectedBankAccount} selected />
        ) : (
          <AddAccount onClick={onBankAccountAdd} />
        )}
      </BankAccountSelectStyled>
      <Popper
        sx={{ zIndex: 1 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-start"
        slotProps={{ root: { style: { maxWidth: '540px', width: '100%' } } }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <MenuContainer>
              <ClickAwayListener onClickAway={handleClose}>
                <BankAccountSelectStyled id="account-select-menu" autoFocusItem>
                  {availableBankAccounts.map((bankAccount) => (
                    <Account
                      key={bankAccount.id}
                      onClick={() => handleMenuItemClick(bankAccount.id)}
                      {...bankAccount}
                    />
                  ))}
                  <AddAccount onClick={onBankAccountAdd} />
                </BankAccountSelectStyled>
              </ClickAwayListener>
            </MenuContainer>
          </Grow>
        )}
      </Popper>
    </Container>
  );
};

export default BankAccountSelect;

import React from 'react';
import { AccountType } from 'further-types/investor';
import Spacing from 'components/Spacing';
import RecordRow from 'components/RecordRow';

type Props = {
  bankDetails: Array<{
    bankAccountName?: string;
    bankName?: string;
    bankSortCode?: string;
    bankAccountNumber?: string;
    iban?: string;
    swift?: string;
    isUsPerson?: boolean;
  }>;
  investorDetails: {
    primaryTaxId?: string;
    secondaryTaxId?: string;
    taxResidencies?: Array<{ country?: string }>;
    isClamingTax?: boolean;
    registeredTaxNumber?: string;
    accountType: AccountType;
  };
};

const TaxAndBankDetails: React.FC<Props> = ({
  bankDetails,
  investorDetails,
}) => {
  return (
    <Spacing size="sm">
      <RecordRow label="Bank account name">
        {bankDetails[0]?.bankAccountName ?? '-'}
      </RecordRow>
      <RecordRow label="Bank name">{bankDetails[0]?.bankName ?? '-'}</RecordRow>
      <RecordRow label="Bank sort code">
        {bankDetails[0]?.bankSortCode ?? '-'}
      </RecordRow>
      <RecordRow label="Bank account number">
        {bankDetails[0]?.bankAccountNumber ?? '-'}
      </RecordRow>
      <RecordRow label="IBAN/non-UK account number">
        {bankDetails[0]?.iban ?? '-'}
      </RecordRow>
      <RecordRow label="SWIFT/BIC">{bankDetails[0]?.swift ?? '-'}</RecordRow>

      {investorDetails.accountType === AccountType.LegalEntity ? (
        <RecordRow label="Registered tax number">
          {investorDetails?.registeredTaxNumber ?? '-'}
        </RecordRow>
      ) : (
        <>
          <RecordRow label="Primary tax ID">
            {investorDetails?.primaryTaxId ?? '-'}
          </RecordRow>
          <RecordRow label="Secondary tax ID">
            {investorDetails?.secondaryTaxId ?? '-'}
          </RecordRow>
        </>
      )}
      <RecordRow label="Tax domicile/residency">
        {investorDetails?.taxResidencies?.length
          ? investorDetails.taxResidencies.map(({ country }) => {
              return <div key={country}>{country}</div>;
            })
          : '-'}
      </RecordRow>
      <RecordRow label="Claiming tax relief">
        {investorDetails?.isClamingTax ? 'Yes' : 'No'}
      </RecordRow>
      <RecordRow label="US person?">
        {bankDetails[0]?.isUsPerson ? 'Yes' : 'No'}
      </RecordRow>
    </Spacing>
  );
};

export default TaxAndBankDetails;

import { useQuery, useMutation, keepPreviousData } from '@tanstack/react-query';
import { ApiResponse } from 'further-types/api';
import { api } from 'lib/httpClient';
import { Api } from 'further-types/adviser';

// TODO: refactor to use correct type - AdviserApi.AdviserListResponse
export function useAdvisers({ params }) {
  return useQuery({
    queryKey: ['advisers', { params }],
    queryFn: async () => {
      const {
        data: { data },
      } = await api.get<ApiResponse<any>>('adviser/list', { params });

      return data;
    },
    placeholderData: keepPreviousData,
    staleTime: 60 * 60 * 1000,
  });
}

export function useAdvisersLeanResponse(params: Api.ListAdviserLeanQuery) {
  return useQuery({
    queryKey: ['advisers-lean-response', { params }],
    queryFn: async () => {
      const { data } = await api.get<ApiResponse<Api.ListAdviserLeanResponse>>(
        'adviser/list/lean-response',
        { params },
      );
      return data.data;
    },
  });
}

export function useAdvisersExport({ params }) {
  const { mutateAsync, isPending: isExportLoading } = useMutation({
    mutationFn: async () => {
      const response = await api.post('adviser/export', params, {
        responseType: 'blob',
      });
      if (response.status !== 200) {
        throw Error('Unexpected export response.');
      }
      return response.data;
    },
  });
  return {
    exportAdviser: mutateAsync,
    isExportLoading,
  };
}

import { api } from 'lib/httpClient';
import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { ApiResponse } from 'further-types/api';
import { Api } from 'further-types/eis-wizard';
import { useNotification } from 'hooks/ui/useNotification';
import useApiExceptionHandler from 'hooks/ui/useApiExceptionHandler';

type OnMutationSuccessFn = (data: Api.MutateEisWizardResponse) => void;

const useCreateEisWizard = (onMutationSuccess?: OnMutationSuccessFn) => {
  const handleApiException = useApiExceptionHandler();
  const queryClient = useQueryClient();

  return useMutation({
    throwOnError: false,
    mutationFn: async (data: Api.MutateEisWizardRequest) => {
      const path = data.createdWithoutCertificates
        ? 'eis-wizard/without-certificates'
        : 'eis-wizard';
      const result = await api.post<ApiResponse<Api.MutateEisWizardResponse>>(
        path,
        data,
      );
      return result.data;
    },
    onSuccess: async (data) => {
      onMutationSuccess?.(data.data);

      await queryClient.invalidateQueries({
        queryKey: ['eis-tasklist'],
      });
      await queryClient.invalidateQueries({
        queryKey: ['eisWizard'],
      });
    },
    onError: (error: Error) => {
      handleApiException(error);
    },
  });
};

const useUpdateEisWizard = (
  eisWizardId?: string,
  onMutationSuccess?: OnMutationSuccessFn,
) => {
  const handleApiException = useApiExceptionHandler();
  const queryClient = useQueryClient();

  return useMutation({
    throwOnError: false,
    mutationFn: async (data: Api.MutateEisWizardRequest) => {
      const result = await api.put<ApiResponse<Api.MutateEisWizardResponse>>(
        `eis-wizard/${eisWizardId}`,
        data,
      );
      return result.data;
    },
    onSuccess: async (data) => {
      onMutationSuccess?.(data.data);

      await queryClient.invalidateQueries({
        queryKey: ['eis-tasklist'],
      });
      await queryClient.invalidateQueries({
        queryKey: ['eisWizard'],
      });
    },
    onError: (error: Error) => {
      handleApiException(error);
    },
  });
};

function useDeleteEisWizard() {
  const notification = useNotification();
  const handleApiException = useApiExceptionHandler();
  const queryClient = useQueryClient();

  return useMutation({
    throwOnError: false,
    mutationFn: async (eisWizardId: string) => {
      const result = await api.delete<ApiResponse>(`eis-wizard/${eisWizardId}`);
      return result.data;
    },
    onSuccess: async (data) => {
      notification.success(data.responseMsg ?? '');

      await queryClient.invalidateQueries({
        queryKey: ['eis-tasklist'],
      });
      await queryClient.invalidateQueries({
        queryKey: ['eisWizard'],
      });
    },
    onError: (error: Error) => {
      handleApiException(error);
    },
  });
}

export function useEisWizard(
  onMutationSuccess?: OnMutationSuccessFn,
  eisWizardId?: string,
) {
  const eisWizard = useQuery({
    queryKey: ['eisWizard', eisWizardId],
    queryFn: async () =>
      (
        await api.get<ApiResponse<Api.GetEisWizardByIdResponse>>(
          `/eis-wizard/${eisWizardId}`,
        )
      ).data,
    placeholderData: keepPreviousData,
    staleTime: 60 * 60 * 1000,
    enabled: !!eisWizardId,
  });

  return {
    eisWizard,
    createEisWizard: useCreateEisWizard(onMutationSuccess),
    updateEisWizard: useUpdateEisWizard(eisWizardId, onMutationSuccess),
    deleteEisWizard: useDeleteEisWizard(),
  };
}

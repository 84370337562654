import { Box, Grid2, styled, Typography } from '@mui/material';
import GridContainer from 'components/GridContainer';
import Spacing from 'components/Spacing';
import { CurrencyCode } from 'further-types/lp/vehicle';
import { CalendarDay, numberToCurrencyString } from 'further-ui/utils';

const Details = styled(Box)(({ theme }) => ({
  height: '164px',
  width: '100%',
  border: `1px solid ${theme.palette.borderColor?.dark}`,
  padding: theme.spacing(7, 5),

  [theme.breakpoints.down('md')]: {
    height: 'auto',
  },
}));

const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: 'bold',
}));

type Props = {
  description: string;
  date: CalendarDay;
  spent?: number;
  received?: number;
  accountCurrency: CurrencyCode;
};

const TransactionDetails: React.FC<Props> = ({
  description,
  date,
  spent,
  received,
  accountCurrency,
}) => {
  const spentAmount = numberToCurrencyString(
    spent ? Math.abs(spent) : undefined,
    {
      fallback: '-',
      currency: accountCurrency,
    },
  );
  const receivedAmount = numberToCurrencyString(received, {
    fallback: '-',
    currency: accountCurrency,
  });

  return (
    <Details>
      <GridContainer gap={4}>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <Spacing size="sm">
            <Label>{date.toLabel()}</Label>
            <Label>{description}</Label>
          </Spacing>
        </Grid2>
        <Grid2 size={{ xs: 6, md: 3 }}>
          <Spacing size="sm">
            <Typography color="textSecondary">Spent</Typography>
            <Label>{spentAmount}</Label>
          </Spacing>
        </Grid2>
        <Grid2 size={{ xs: 6, md: 3 }}>
          <Spacing size="sm">
            <Typography color="textSecondary" align="right">
              Received
            </Typography>
            <Label align="right">{receivedAmount}</Label>
          </Spacing>
        </Grid2>
      </GridContainer>
    </Details>
  );
};

export default TransactionDetails;

import { Button } from '@mui/material';
import PageContainer from 'components/PageContainer';
import { makeStyles } from 'tss-react/mui';
import { useEffect, useState } from 'react';
import { AppDatePicker, AppSelectBox, FieldRow } from 'components/FormElements';
import CkEditor from 'components/CkEditor';
import { useInvestorBulletin } from 'hooks/data/investorBulletin/useInvestorBulletin';
import { useGetRole } from 'hooks/ui/useGetRole';
import { useFirm } from 'hooks/data/firm/useFirm';
import { endOfDay } from 'date-fns';
import PageContent from 'components/PageContent';
import RecordView from 'components/RecordView';

const removeHTML = (str) => {
  const doc = new DOMParser().parseFromString(str, 'text/html');
  return doc.body.textContent || '';
};

const useStyles = makeStyles()(() => ({
  introCopy: {
    color: '#656565',
    marginBottom: 20,
    marginTop: -10,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: 12,
    width: '100%',
    gap: 16,
  },
  characterCount: {
    fontSize: 12,
    marginTop: 4,
  },
  error: {
    color: '#E8453C',
  },
}));

const InvestorBulletin: React.FC = () => {
  const { classes } = useStyles();

  const characterLimit = 125;
  const [expiryDate, setExpiryDate] = useState<Date | null>(null);
  const [text, setText] = useState('');
  const [characterCount, setCharacterCount] = useState(0);

  const { isSuperAdmin, firmId } = useGetRole();

  const [selectedFirm, setSelectedFirm] = useState(firmId);

  const { updateBulletin, bulletin } = useInvestorBulletin(
    selectedFirm ?? null,
  );
  const disabled = updateBulletin.isPending || bulletin.isLoading;

  const { firms } = useFirm({ params: {} });

  const { data } = bulletin;

  useEffect(() => {
    setText(data?.text || '');
    setExpiryDate(data?.expiryDate || null);
  }, [data]);

  const onClearAndSubmit = () => {
    setText('');
    setExpiryDate(null);
    onSubmit({ text: '', expiryDate: null });
  };

  const onSubmit = ({ text, expiryDate }) => {
    if (characterCount > characterLimit) {
      return;
    }
    const date = expiryDate ? endOfDay(new Date(expiryDate)) : null;

    updateBulletin.mutate({
      text,
      expiryDate: date,
      //@ts-expect-error
      firmId: selectedFirm,
    });
  };

  return (
    <PageContainer heading="Investor communications: Manage bulletin">
      <PageContent header="Manage the investor portal bulletin">
        <p className={classes.introCopy}>
          If a bulletin is added below, it shows on the investor portal until
          the expiry date. To remove it, please click “Clear and save”.
        </p>
        <RecordView>
          {isSuperAdmin && (
            <FieldRow title="Firm">
              <AppSelectBox
                required
                data={firms?.data?.result ?? []}
                valueKey="_id"
                labelKey="firmName"
                value={selectedFirm}
                variant="outlined"
                onChange={(event) =>
                  setSelectedFirm(event.target.value as string)
                }
              />
            </FieldRow>
          )}
          {selectedFirm && (
            <>
              <FieldRow title="Bulletin text">
                <CkEditor
                  placeholder={`Max ${characterLimit} characters. This text will display in a banner at the top of your investor portal.`}
                  onChange={(value: string) => {
                    setCharacterCount(removeHTML(value).length);
                    setText(value);
                  }}
                  value={text}
                  disabled={false}
                  onBlur={(value: string) => {
                    setText(value);
                  }}
                  addTargetToExternalLinks={true}
                />
                <p
                  className={`${classes.characterCount} ${
                    characterCount > characterLimit && classes.error
                  }`}
                >
                  Character count: {characterCount}{' '}
                  {characterCount > characterLimit &&
                    `(Character count must be ${characterLimit} or less)`}
                </p>
              </FieldRow>
              <FieldRow title="Expiry date" centerTitle>
                <AppDatePicker
                  name="exitDate"
                  value={expiryDate}
                  onChange={(date) => setExpiryDate(date ?? null)}
                  disableFuture={false}
                  clearable
                />
              </FieldRow>
              <div className={classes.buttonContainer}>
                <Button
                  onClick={onClearAndSubmit}
                  variant="outlined"
                  color="primary"
                  disabled={disabled}
                >
                  Clear and save
                </Button>
                <Button
                  onClick={() => onSubmit({ text, expiryDate })}
                  variant="contained"
                  color="primary"
                  disabled={disabled}
                >
                  Save
                </Button>
              </div>
            </>
          )}
        </RecordView>
      </PageContent>
    </PageContainer>
  );
};

export default InvestorBulletin;

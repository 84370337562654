import { z } from 'zod';
import { isMongoId, withPaginationParams } from '../../helpers';
import {
  IncomeType,
  ExpenseType,
  IncomeOrExpenseType,
} from 'further-types/lp/income-and-expenses';
import { CurrencyCode } from 'further-types/lp/vehicle';
import transactionAllocationsInput, {
  allocationMatchesCheckAmount,
} from '../../helpers/transaction-allocations-input';
import { AllocationRule } from 'further-types/lp/allocations';
import { CreateTransactions, UpdateTransactions } from '../transaction';
import { CalendarDay } from '../../../utils';

const noIncomeOrExpenseType = 'Please select the income or expense type';

export const CreateIncomeOrExpensePreAllocations = z.object({
  vehicleId: z.string().refine(isMongoId()),
  counterparty: z.object({
    _id: z
      .string()
      .optional()
      .refine(isMongoId({ optional: true })),
    name: z.string().min(1, {
      message: 'Please enter a counterparty name',
    }),
  }),
  linkedActionId: z
    .string()
    .optional()
    .nullable()
    .refine(isMongoId({ optional: true })),
  category: z.nativeEnum(IncomeOrExpenseType, {
    message: 'Please select the income or expense type',
  }),
  incomeOrExpenseType: z
    .nativeEnum(IncomeType, {
      message: noIncomeOrExpenseType,
    })
    .or(
      z.nativeEnum(ExpenseType, {
        message: noIncomeOrExpenseType,
      }),
    ),
  ledgerDate: z.instanceof(CalendarDay),
  currencyCode: z.nativeEnum(CurrencyCode, {
    message: 'Please select a valid currency',
  }),
  amountExVat: z.number({
    required_error: 'Please enter an amount',
    invalid_type_error: 'Please enter a valid amount',
  }),
  vatRate: z
    .number({
      required_error: 'Please enter a valid VAT rate',
      invalid_type_error: 'Please enter a valid VAT rate',
    })
    .min(0)
    .max(100),
  amountIncVat: z.number(),
  invoiceFile: z
    .object({
      path: z.string(),
      name: z.string(),
      preview: z.string(),
    })
    .optional()
    .nullable(),
  description: z.string().optional(),
  transactions: CreateTransactions,
});

export const GetIncomeOrExpense = z.object({
  id: z.string().refine(isMongoId()),
});

export const CreateIncomeOrExpense = CreateIncomeOrExpensePreAllocations.extend(
  {
    allocations: transactionAllocationsInput,
    allocationRule: z.nativeEnum(AllocationRule),
  },
).refine(
  (data) => allocationMatchesCheckAmount(data.amountIncVat, data.allocations),
  'Total allocation amount must match input amount',
);

export const UpdateIncomeOrExpense = CreateIncomeOrExpensePreAllocations.extend(
  {
    allocations: transactionAllocationsInput,
    allocationRule: z.nativeEnum(AllocationRule),
    id: z.string().refine(isMongoId()),
    transactions: UpdateTransactions,
  },
).refine(
  (data) => allocationMatchesCheckAmount(data.amountIncVat, data.allocations),
  'Total allocation amount must match input amount',
);

export const DeleteIncomeOrExpense = z.object({
  id: z.string().refine(isMongoId()),
});

export const ListIncomeOrExpenses = withPaginationParams(
  z.object({
    actionType: z.nativeEnum(IncomeOrExpenseType).optional(),
    searchQuery: z.string().optional(),
    ledgerDate: z.instanceof(CalendarDay).optional(),
  }),
  [
    'ledgerDate',
    'createdDate',
    'amount',
    'counterparty',
    'status',
    'actionType',
  ],
);

import RecordRow from 'components/RecordRow';
import Spacing from 'components/Spacing';
import { format, parseISO } from 'date-fns';
import { dateofBirthToLabel, dateToLabel } from 'further-ui/utils';
import { Fragment } from 'react';
import DownloadTransferButton from '../DownloadTransferButton';

type Props = {
  investorDetail: any;
};

const IndividualDetails: React.FC<Props> = ({ investorDetail }) => {
  return (
    <Spacing size="sm">
      <RecordRow label="First name">{investorDetail?.firstName}</RecordRow>
      <RecordRow label="Middle name">
        {investorDetail?.middleName || '-'}
      </RecordRow>
      <RecordRow label="Last name">{investorDetail?.lastName || '-'}</RecordRow>
      <RecordRow label="Email address">
        {investorDetail?.email || '-'}
      </RecordRow>
      <RecordRow label="Phone number">
        {investorDetail?.phoneNumber || '-'}
      </RecordRow>
      <RecordRow label="Date of birth">
        {investorDetail?.dob ? dateofBirthToLabel(investorDetail?.dob) : '-'}
      </RecordRow>
      <RecordRow label="Date registered">
        {investorDetail?.createdAt
          ? format(parseISO(investorDetail?.createdAt), 'dd/MM/yyyy')
          : '-'}
      </RecordRow>
      <RecordRow label="Last login">
        {dateToLabel(investorDetail.lastLoginDate, '-', true, true)}
      </RecordRow>
      <RecordRow label="Job title">{investorDetail?.profession}</RecordRow>
      <RecordRow label="Sector">{investorDetail?.sector ?? '-'}</RecordRow>
      <RecordRow label="Email verified">
        {investorDetail?.isEmailVerify ? 'Yes' : 'No'}
      </RecordRow>
      {investorDetail.address && investorDetail.address.length
        ? investorDetail.address.map((single, index) => {
            return (
              <Fragment key={index}>
                <RecordRow
                  label={index === 0 ? 'Current address' : 'Previous address'}
                >
                  {single?.isManual ? (
                    <div>
                      <div>{single?.address1}</div>
                      <div>{single?.address2}</div>
                      <div>{single?.city}</div>
                      <div>
                        {/* we force postcodes to be a string on investor upload but there may be some legacy
                            number postcodes remaining so check type before converting to upperCase */}
                        {typeof single?.postcode === 'string'
                          ? single?.postcode.toUpperCase()
                          : single?.postcode}
                      </div>
                      <div>{single?.country}</div>
                    </div>
                  ) : (
                    <div>
                      <div>{single?.address1}</div>
                      <div>{single?.address2}</div>
                      <div>{single?.city}</div>
                      <div>
                        {typeof single?.postcode === 'string'
                          ? single?.postcode.toUpperCase()
                          : single?.postcode}
                      </div>
                      <div>{single?.country}</div>
                    </div>
                  )}
                </RecordRow>
                {index === 0 && (
                  <RecordRow label="Has lived at this address for past 3 years?">
                    {investorDetail?.address &&
                    investorDetail?.address.length === 1
                      ? 'Yes'
                      : 'No'}
                  </RecordRow>
                )}
              </Fragment>
            );
          })
        : []}

      <RecordRow label="Nationality">{investorDetail?.nationality}</RecordRow>
      {investorDetail.otherNationality?.length > 0 && (
        <RecordRow
          label={`Other ${
            investorDetail.otherNationality.length > 1
              ? 'nationalities'
              : 'nationality'
          }`}
        >
          {investorDetail.otherNationality.map((record, index) => {
            return (
              <div key={index}>
                <span>{record?.country}</span>
              </div>
            );
          })}
        </RecordRow>
      )}
      <RecordRow label="Vulnerable client">
        {investorDetail?.isVulnerableClient ? 'Yes' : 'No'}
      </RecordRow>
      <RecordRow label="Deceased" direction="row">
        {investorDetail?.isDeceased ? 'Yes' : 'No'}
        {investorDetail?.isDeceased && (
          <DownloadTransferButton
            investorId={investorDetail._id}
            investorName={`${investorDetail.fullName}`}
          />
        )}
      </RecordRow>

      {investorDetail?.isDeceased && (
        <RecordRow label="Date of death">
          {investorDetail?.dateOfDeath
            ? dateofBirthToLabel(investorDetail?.dateOfDeath)
            : '-'}
        </RecordRow>
      )}
      <RecordRow label="UK remittance taxpayer (BIR)">
        {investorDetail?.isUkRemittanceTaxpayer ? 'Yes' : 'No'}
      </RecordRow>
    </Spacing>
  );
};

export default IndividualDetails;

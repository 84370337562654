export enum Country {
  UnitedKingdom = 'United Kingdom',
  Afghanistan = 'Afghanistan',
  AlandIslands = 'Aland Islands',
  Albania = 'Albania',
  Algeria = 'Algeria',
  AmericanSamoa = 'American Samoa',
  Andorra = 'Andorra',
  Angola = 'Angola',
  Anguilla = 'Anguilla',
  Antarctica = 'Antarctica',
  AntiguaAndBarbuda = 'Antigua and Barbuda',
  Argentina = 'Argentina',
  Armenia = 'Armenia',
  Aruba = 'Aruba',
  Australia = 'Australia',
  Austria = 'Austria',
  Azerbaijan = 'Azerbaijan',
  Bahamas = 'Bahamas, The',
  Bahrain = 'Bahrain',
  Bangladesh = 'Bangladesh',
  Barbados = 'Barbados',
  Belarus = 'Belarus',
  Belgium = 'Belgium',
  Belize = 'Belize',
  Benin = 'Benin',
  Bermuda = 'Bermuda',
  Bhutan = 'Bhutan',
  Bolivia = 'Bolivia',
  BonaireSintEustatiusAndSaba = 'Bonaire, Sint Eustatius and Saba',
  BosniaAndHerzegovina = 'Bosnia and Herzegovina',
  Botswana = 'Botswana',
  Brazil = 'Brazil',
  BritishIndianOceanTerritory = 'British Indian Ocean Territory',
  BritishVirginIslands = 'British Virgin Islands',
  Brunei = 'Brunei',
  Bulgaria = 'Bulgaria',
  BurkinaFaso = 'Burkina Faso',
  Burundi = 'Burundi',
  Cambodia = 'Cambodia',
  Cameroon = 'Cameroon',
  Canada = 'Canada',
  CapeVerde = 'Cape Verde',
  CaymanIslands = 'Cayman Islands',
  CentralAfricanRepublic = 'Central African Republic',
  Chad = 'Chad',
  Chile = 'Chile',
  China = 'China',
  ChristmasIsland = 'Christmas Island',
  CocosIslands = 'Cocos Islands',
  Colombia = 'Colombia',
  Comoros = 'Comoros',
  CongoDemocraticRepublic = 'Congo, Democratic Republic of',
  CongoRepublic = 'Congo, Republic of the',
  CookIslands = 'Cook Islands',
  CostaRica = 'Costa Rica',
  CoteDIvoire = "Cote d'Ivoire",
  Croatia = 'Croatia',
  Cuba = 'Cuba',
  Curacao = 'Curacao',
  Cyprus = 'Cyprus',
  CzechRepublic = 'Czech Republic',
  Denmark = 'Denmark',
  Djibouti = 'Djibouti',
  Dominica = 'Dominica',
  DominicanRepublic = 'Dominican Republic',
  Ecuador = 'Ecuador',
  Egypt = 'Egypt',
  ElSalvador = 'El Salvador',
  EquatorialGuinea = 'Equatorial Guinea',
  Eritrea = 'Eritrea',
  Estonia = 'Estonia',
  Eswatini = 'Eswatini',
  Ethiopia = 'Ethiopia',
  FalklandIslands = 'Falkland Islands',
  FaroeIslands = 'Faroe Islands',
  Fiji = 'Fiji',
  Finland = 'Finland',
  France = 'France',
  FrenchGuiana = 'French Guiana',
  FrenchPolynesia = 'French Polynesia',
  Gabon = 'Gabon',
  Gambia = 'Gambia, The',
  Georgia = 'Georgia',
  Germany = 'Germany',
  Ghana = 'Ghana',
  Gibraltar = 'Gibraltar',
  Greece = 'Greece',
  Greenland = 'Greenland',
  Grenada = 'Grenada',
  Guadeloupe = 'Guadeloupe',
  Guam = 'Guam',
  Guatemala = 'Guatemala',
  Guernsey = 'Guernsey',
  Guinea = 'Guinea',
  GuineaBissau = 'Guinea-Bissau',
  Guyana = 'Guyana',
  Haiti = 'Haiti',
  Honduras = 'Honduras',
  HongKong = 'Hong Kong',
  Hungary = 'Hungary',
  Iceland = 'Iceland',
  India = 'India',
  Indonesia = 'Indonesia',
  Iran = 'Iran',
  Iraq = 'Iraq',
  Ireland = 'Ireland',
  IsleOfMan = 'Isle of Man',
  Israel = 'Israel',
  Italy = 'Italy',
  Jamaica = 'Jamaica',
  Japan = 'Japan',
  Jersey = 'Jersey',
  Jordan = 'Jordan',
  Kazakhstan = 'Kazakhstan',
  Kenya = 'Kenya',
  Kiribati = 'Kiribati',
  KoreaNorth = 'Korea, North',
  KoreaSouth = 'Korea, South',
  Kosovo = 'Kosovo',
  Kuwait = 'Kuwait',
  Kyrgyzstan = 'Kyrgyzstan',
  Laos = 'Laos',
  Latvia = 'Latvia',
  Lebanon = 'Lebanon',
  Lesotho = 'Lesotho',
  Liberia = 'Liberia',
  Libya = 'Libya',
  Liechtenstein = 'Liechtenstein',
  Lithuania = 'Lithuania',
  Luxembourg = 'Luxembourg',
  Macau = 'Macau',
  Madagascar = 'Madagascar',
  Malawi = 'Malawi',
  Malaysia = 'Malaysia',
  Maldives = 'Maldives',
  Mali = 'Mali',
  Malta = 'Malta',
  MarshallIslands = 'Marshall Islands',
  Martinique = 'Martinique',
  Mauritania = 'Mauritania',
  Mauritius = 'Mauritius',
  Mayotte = 'Mayotte',
  Mexico = 'Mexico',
  Micronesia = 'Micronesia, Federated States of',
  Moldova = 'Moldova',
  Monaco = 'Monaco',
  Mongolia = 'Mongolia',
  Montenegro = 'Montenegro',
  Montserrat = 'Montserrat',
  Morocco = 'Morocco',
  Mozambique = 'Mozambique',
  Myanmar = 'Myanmar',
  Namibia = 'Namibia',
  Nauru = 'Nauru',
  Nepal = 'Nepal',
  Netherlands = 'Netherlands',
  NewCaledonia = 'New Caledonia',
  NewZealand = 'New Zealand',
  Nicaragua = 'Nicaragua',
  Niger = 'Niger',
  Nigeria = 'Nigeria',
  Niue = 'Niue',
  NorfolkIsland = 'Norfolk Island',
  NorthMacedonia = 'North Macedonia',
  NorthernMarianaIslands = 'Northern Mariana Islands',
  Norway = 'Norway',
  Oman = 'Oman',
  Pakistan = 'Pakistan',
  Palau = 'Palau',
  Panama = 'Panama',
  PapuaNewGuinea = 'Papua New Guinea',
  Paraguay = 'Paraguay',
  Peru = 'Peru',
  Philippines = 'Philippines',
  Pitcairn = 'Pitcairn',
  Poland = 'Poland',
  Portugal = 'Portugal',
  PuertoRico = 'Puerto Rico',
  Qatar = 'Qatar',
  Reunion = 'Reunion',
  Romania = 'Romania',
  Russia = 'Russia',
  Rwanda = 'Rwanda',
  SaintBarthelemy = 'Saint Barthélemy',
  SaintHelenaAscensionAndTristanDaCunha = 'Saint Helena, Ascension and Tristan da Cunha',
  SaintKittsAndNevis = 'Saint Kitts and Nevis',
  SaintLucia = 'Saint Lucia',
  SaintMartin = 'Saint Martin',
  SaintPierreAndMiquelon = 'Saint Pierre and Miquelon',
  SaintVincentAndGrenadines = 'Saint Vincent and the Grenadines',
  Samoa = 'Samoa',
  SanMarino = 'San Marino',
  SaoTomeAndPrincipe = 'Sao Tome and Principe',
  SaudiArabia = 'Saudi Arabia',
  Senegal = 'Senegal',
  Serbia = 'Serbia',
  Seychelles = 'Seychelles',
  SierraLeone = 'Sierra Leone',
  Singapore = 'Singapore',
  SintMaarten = 'Sint Maarten',
  Slovakia = 'Slovakia',
  Slovenia = 'Slovenia',
  SolomonIslands = 'Solomon Islands',
  Somalia = 'Somalia',
  SouthAfrica = 'South Africa',
  SouthSudan = 'South Sudan',
  Spain = 'Spain',
  SriLanka = 'Sri Lanka',
  Sudan = 'Sudan',
  Suriname = 'Suriname',
  SvalbardAndJanMayen = 'Svalbard and Jan Mayen',
  Swaziland = 'Swaziland',
  Sweden = 'Sweden',
  Switzerland = 'Switzerland',
  Syria = 'Syria',
  Taiwan = 'Taiwan',
  Tajikistan = 'Tajikistan',
  Tanzania = 'Tanzania',
  Thailand = 'Thailand',
  TimorLeste = 'Timor-Leste',
  Togo = 'Togo',
  Tokelau = 'Tokelau',
  Tonga = 'Tonga',
  TrinidadAndTobago = 'Trinidad and Tobago',
  Tunisia = 'Tunisia',
  Turkey = 'Turkey',
  Turkmenistan = 'Turkmenistan',
  TurksAndCaicosIslands = 'Turks and Caicos Islands',
  Tuvalu = 'Tuvalu',
  Uganda = 'Uganda',
  Ukraine = 'Ukraine',
  UnitedArabEmirates = 'United Arab Emirates',
  UnitedStates = 'United States',
  UnitedStatesMinorOutlyingIslands = 'United States Minor Outlying Islands',
  Uruguay = 'Uruguay',
  Uzbekistan = 'Uzbekistan',
  Vanuatu = 'Vanuatu',
  Vatican = 'Vatican',
  Venezuela = 'Venezuela',
  Vietnam = 'Vietnam',
  VirginIslands = 'Virgin Islands',
  WallisAndFutuna = 'Wallis and Futuna',
  WestBank = 'West Bank',
  WesternSahara = 'Western Sahara',
  Yemen = 'Yemen',
  Zambia = 'Zambia',
  Zimbabwe = 'Zimbabwe',
}

export const nationality = Object.values(Country).map((country) => ({
  Country: country,
}));

// create a country list with the country names and country ids, and sort it by country name. The ids are created from the country names by removing all non-alphanumeric characters and converting to lower case.
export const countryDropdownList = Object.values(Country).map((country) => ({
  value: country,
  label: country,
}));

export const countries = [
  {
    name: Country.Afghanistan,
    code: 'AF',
  },
  {
    name: Country.AlandIslands,
    code: 'AX',
  },
  {
    name: Country.Albania,
    code: 'AL',
  },
  {
    name: Country.Algeria,
    code: 'DZ',
  },
  {
    name: Country.AmericanSamoa,
    code: 'AS',
  },
  {
    name: Country.Andorra,
    code: 'AD',
  },
  {
    name: Country.Angola,
    code: 'AO',
  },
  {
    name: Country.Anguilla,
    code: 'AI',
  },
  {
    name: Country.Antarctica,
    code: 'AQ',
  },
  {
    name: Country.AntiguaAndBarbuda,
    code: 'AG',
  },
  {
    name: Country.Argentina,
    code: 'AR',
  },
  {
    name: Country.Armenia,
    code: 'AM',
  },
  {
    name: Country.Aruba,
    code: 'AW',
  },
  {
    name: Country.Australia,
    code: 'AU',
  },
  {
    name: Country.Austria,
    code: 'AT',
  },
  {
    name: Country.Azerbaijan,
    code: 'AZ',
  },
  {
    name: Country.Bahamas,
    code: 'BS',
  },
  {
    name: Country.Bahrain,
    code: 'BH',
  },
  {
    name: Country.Bangladesh,
    code: 'BD',
  },
  {
    name: Country.Barbados,
    code: 'BB',
  },
  {
    name: Country.Belarus,
    code: 'BY',
  },
  {
    name: Country.Belgium,
    code: 'BE',
  },
  {
    name: Country.Belize,
    code: 'BZ',
  },
  {
    name: Country.Benin,
    code: 'BJ',
  },
  {
    name: Country.Bermuda,
    code: 'BM',
  },
  {
    name: Country.Bhutan,
    code: 'BT',
  },
  {
    name: Country.Bolivia,
    code: 'BO',
  },
  {
    name: Country.BonaireSintEustatiusAndSaba,
    code: 'BQ',
  },
  {
    name: Country.BosniaAndHerzegovina,
    code: 'BA',
  },
  {
    name: Country.Botswana,
    code: 'BW',
  },
  {
    name: Country.Brazil,
    code: 'BR',
  },
  {
    name: Country.BritishIndianOceanTerritory,
    code: 'IO',
  },
  {
    name: Country.BritishVirginIslands,
    code: 'VG',
  },
  {
    name: Country.Brunei,
    code: 'BN',
  },
  {
    name: Country.Bulgaria,
    code: 'BG',
  },
  {
    name: Country.BurkinaFaso,
    code: 'BF',
  },
  {
    name: Country.Burundi,
    code: 'BI',
  },
  {
    name: Country.Cambodia,
    code: 'KH',
  },
  {
    name: Country.Cameroon,
    code: 'CM',
  },
  {
    name: Country.Canada,
    code: 'CA',
  },
  {
    name: Country.CapeVerde,
    code: 'CV',
  },
  {
    name: Country.CaymanIslands,
    code: 'KY',
  },
  {
    name: Country.CentralAfricanRepublic,
    code: 'CF',
  },
  {
    name: Country.Chad,
    code: 'TD',
  },
  {
    name: Country.Chile,
    code: 'CL',
  },
  {
    name: Country.China,
    code: 'CN',
  },
  {
    name: Country.ChristmasIsland,
    code: 'CX',
  },
  {
    name: Country.CocosIslands,
    code: 'CC',
  },
  {
    name: Country.Colombia,
    code: 'CO',
  },
  {
    name: Country.Comoros,
    code: 'KM',
  },
  {
    name: Country.CongoDemocraticRepublic,
    code: 'CD',
  },
  {
    name: Country.CongoRepublic,
    code: 'CG',
  },
  {
    name: Country.CookIslands,
    code: 'CK',
  },
  {
    name: Country.CostaRica,
    code: 'CR',
  },
  {
    name: Country.CoteDIvoire,
    code: 'CI',
  },
  {
    name: Country.Croatia,
    code: 'HR',
  },
  {
    name: Country.Cuba,
    code: 'CU',
  },
  {
    name: Country.Curacao,
    code: 'CW',
  },
  {
    name: Country.Cyprus,
    code: 'CY',
  },
  {
    name: Country.CzechRepublic,
    code: 'CZ',
  },
  {
    name: Country.Denmark,
    code: 'DK',
  },
  {
    name: Country.Djibouti,
    code: 'DJ',
  },
  {
    name: Country.Dominica,
    code: 'DM',
  },
  {
    name: Country.DominicanRepublic,
    code: 'DO',
  },
  {
    name: Country.Ecuador,
    code: 'EC',
  },
  {
    name: Country.Egypt,
    code: 'EG',
  },
  {
    name: Country.ElSalvador,
    code: 'SV',
  },
  {
    name: Country.EquatorialGuinea,
    code: 'GQ',
  },
  {
    name: Country.Eritrea,
    code: 'ER',
  },
  {
    name: Country.Estonia,
    code: 'EE',
  },
  {
    name: Country.Eswatini,
    code: 'SZ',
  },
  {
    name: Country.Ethiopia,
    code: 'ET',
  },
  {
    name: Country.FalklandIslands,
    code: 'FK',
  },
  {
    name: Country.FaroeIslands,
    code: 'FO',
  },
  {
    name: Country.Fiji,
    code: 'FJ',
  },
  {
    name: Country.Finland,
    code: 'FI',
  },
  {
    name: Country.France,
    code: 'FR',
  },
  {
    name: Country.FrenchGuiana,
    code: 'GF',
  },
  {
    name: Country.FrenchPolynesia,
    code: 'PF',
  },
  {
    name: Country.Gabon,
    code: 'GA',
  },
  {
    name: Country.Gambia,
    code: 'GM',
  },
  {
    name: Country.Georgia,
    code: 'GE',
  },
  {
    name: Country.Germany,
    code: 'DE',
  },
  {
    name: Country.Ghana,
    code: 'GH',
  },
  {
    name: Country.Gibraltar,
    code: 'GI',
  },
  {
    name: Country.Greece,
    code: 'GR',
  },
  {
    name: Country.Greenland,
    code: 'GL',
  },
  {
    name: Country.Grenada,
    code: 'GD',
  },
  {
    name: Country.Guadeloupe,
    code: 'GP',
  },
  {
    name: Country.Guam,
    code: 'GU',
  },
  {
    name: Country.Guatemala,
    code: 'GT',
  },
  {
    name: Country.Guernsey,
    code: 'GG',
  },
  {
    name: Country.Guinea,
    code: 'GN',
  },
  {
    name: Country.GuineaBissau,
    code: 'GW',
  },
  {
    name: Country.Guyana,
    code: 'GY',
  },
  {
    name: Country.Haiti,
    code: 'HT',
  },
  {
    name: Country.Honduras,
    code: 'HN',
  },
  {
    name: Country.HongKong,
    code: 'HK',
  },
  {
    name: Country.Hungary,
    code: 'HU',
  },
  {
    name: Country.Iceland,
    code: 'IS',
  },
  {
    name: Country.India,
    code: 'IN',
  },
  {
    name: Country.Indonesia,
    code: 'ID',
  },
  {
    name: Country.Iran,
    code: 'IR',
  },
  {
    name: Country.Iraq,
    code: 'IQ',
  },
  {
    name: Country.Ireland,
    code: 'IE',
  },
  {
    name: Country.IsleOfMan,
    code: 'IM',
  },
  {
    name: Country.Israel,
    code: 'IL',
  },
  {
    name: Country.Italy,
    code: 'IT',
  },
  {
    name: Country.Jamaica,
    code: 'JM',
  },
  {
    name: Country.Japan,
    code: 'JP',
  },
  {
    name: Country.Jersey,
    code: 'JE',
  },
  {
    name: Country.Jordan,
    code: 'JO',
  },
  {
    name: Country.Kazakhstan,
    code: 'KZ',
  },
  {
    name: Country.Kenya,
    code: 'KE',
  },
  {
    name: Country.Kiribati,
    code: 'KI',
  },
  {
    name: Country.KoreaNorth,
    code: 'KP',
  },
  {
    name: Country.KoreaSouth,
    code: 'KR',
  },
  {
    name: Country.Kosovo,
    code: 'XK',
  },
  {
    name: Country.Kuwait,
    code: 'KW',
  },
  {
    name: Country.Kyrgyzstan,
    code: 'KG',
  },
  {
    name: Country.Laos,
    code: 'LA',
  },
  {
    name: Country.Latvia,
    code: 'LV',
  },
  {
    name: Country.Lebanon,
    code: 'LB',
  },
  {
    name: Country.Lesotho,
    code: 'LS',
  },
  {
    name: Country.Liberia,
    code: 'LR',
  },
  {
    name: Country.Libya,
    code: 'LY',
  },
  {
    name: Country.Liechtenstein,
    code: 'LI',
  },
  {
    name: Country.Lithuania,
    code: 'LT',
  },
  {
    name: Country.Luxembourg,
    code: 'LU',
  },
  {
    name: Country.Macau,
    code: 'MO',
  },
  {
    name: Country.Madagascar,
    code: 'MG',
  },
  {
    name: Country.Malawi,
    code: 'MW',
  },
  {
    name: Country.Malaysia,
    code: 'MY',
  },
  {
    name: Country.Maldives,
    code: 'MV',
  },
  {
    name: Country.Mali,
    code: 'ML',
  },
  {
    name: Country.Malta,
    code: 'MT',
  },
  {
    name: Country.MarshallIslands,
    code: 'MH',
  },
  {
    name: Country.Martinique,
    code: 'MQ',
  },
  {
    name: Country.Mauritania,
    code: 'MR',
  },
  {
    name: Country.Mauritius,
    code: 'MU',
  },
  {
    name: Country.Mayotte,
    code: 'YT',
  },
  {
    name: Country.Mexico,
    code: 'MX',
  },
  {
    name: Country.Micronesia,
    code: 'FM',
  },
  {
    name: Country.Moldova,
    code: 'MD',
  },
  {
    name: Country.Monaco,
    code: 'MC',
  },
  {
    name: Country.Mongolia,
    code: 'MN',
  },
  {
    name: Country.Montenegro,
    code: 'ME',
  },
  {
    name: Country.Montserrat,
    code: 'MS',
  },
  {
    name: Country.Morocco,
    code: 'MA',
  },
  {
    name: Country.Mozambique,
    code: 'MZ',
  },
  {
    name: Country.Myanmar,
    code: 'MM',
  },
  {
    name: Country.Namibia,
    code: 'NA',
  },
  {
    name: Country.Nauru,
    code: 'NR',
  },
  {
    name: Country.Nepal,
    code: 'NP',
  },
  {
    name: Country.Netherlands,
    code: 'NL',
  },
  {
    name: Country.NewCaledonia,
    code: 'NC',
  },
  {
    name: Country.NewZealand,
    code: 'NZ',
  },
  {
    name: Country.Nicaragua,
    code: 'NI',
  },
  {
    name: Country.Niger,
    code: 'NE',
  },
  {
    name: Country.Nigeria,
    code: 'NG',
  },
  {
    name: Country.Niue,
    code: 'NU',
  },
  {
    name: Country.NorfolkIsland,
    code: 'NF',
  },
  {
    name: Country.NorthMacedonia,
    code: 'MK',
  },
  {
    name: Country.NorthernMarianaIslands,
    code: 'MP',
  },
  {
    name: Country.Norway,
    code: 'NO',
  },
  {
    name: Country.Oman,
    code: 'OM',
  },
  {
    name: Country.Pakistan,
    code: 'PK',
  },
  {
    name: Country.Palau,
    code: 'PW',
  },
  {
    name: Country.Panama,
    code: 'PA',
  },
  {
    name: Country.PapuaNewGuinea,
    code: 'PG',
  },
  {
    name: Country.Paraguay,
    code: 'PY',
  },
  {
    name: Country.Peru,
    code: 'PE',
  },
  {
    name: Country.Philippines,
    code: 'PH',
  },
  {
    name: Country.Pitcairn,
    code: 'PN',
  },
  {
    name: Country.Poland,
    code: 'PL',
  },
  {
    name: Country.Portugal,
    code: 'PT',
  },
  {
    name: Country.PuertoRico,
    code: 'PR',
  },
  {
    name: Country.Qatar,
    code: 'QA',
  },
  {
    name: Country.Reunion,
    code: 'RE',
  },
  {
    name: Country.Romania,
    code: 'RO',
  },
  {
    name: Country.Russia,
    code: 'RU',
  },
  {
    name: Country.Rwanda,
    code: 'RW',
  },
  {
    name: Country.SaintBarthelemy,
    code: 'BL',
  },
  {
    name: Country.SaintHelenaAscensionAndTristanDaCunha,
    code: 'SH',
  },
  {
    name: Country.SaintKittsAndNevis,
    code: 'KN',
  },
  {
    name: Country.SaintLucia,
    code: 'LC',
  },
  {
    name: Country.SaintMartin,
    code: 'MF',
  },
  {
    name: Country.SaintPierreAndMiquelon,
    code: 'PM',
  },
  {
    name: Country.SaintVincentAndGrenadines,
    code: 'VC',
  },
  {
    name: Country.Samoa,
    code: 'WS',
  },
  {
    name: Country.SanMarino,
    code: 'SM',
  },
  {
    name: Country.SaoTomeAndPrincipe,
    code: 'ST',
  },
  {
    name: Country.SaudiArabia,
    code: 'SA',
  },
  {
    name: Country.Senegal,
    code: 'SN',
  },
  {
    name: Country.Serbia,
    code: 'RS',
  },
  {
    name: Country.Seychelles,
    code: 'SC',
  },
  {
    name: Country.SierraLeone,
    code: 'SL',
  },
  {
    name: Country.Singapore,
    code: 'SG',
  },
  {
    name: Country.SintMaarten,
    code: 'SX',
  },
  {
    name: Country.Slovakia,
    code: 'SK',
  },
  {
    name: Country.Slovenia,
    code: 'SI',
  },
  {
    name: Country.SolomonIslands,
    code: 'SB',
  },
  {
    name: Country.Somalia,
    code: 'SO',
  },
  {
    name: Country.SouthAfrica,
    code: 'ZA',
  },
  {
    name: Country.SouthSudan,
    code: 'SS',
  },
  {
    name: Country.Spain,
    code: 'ES',
  },
  {
    name: Country.SriLanka,
    code: 'LK',
  },
  {
    name: Country.Sudan,
    code: 'SD',
  },
  {
    name: Country.Suriname,
    code: 'SR',
  },
  {
    name: Country.SvalbardAndJanMayen,
    code: 'SJ',
  },
  {
    name: Country.Swaziland,
    code: 'SZ',
  },
  {
    name: Country.Sweden,
    code: 'SE',
  },
  {
    name: Country.Switzerland,
    code: 'CH',
  },
  {
    name: Country.Syria,
    code: 'SY',
  },
  {
    name: Country.Taiwan,
    code: 'TW',
  },
  {
    name: Country.Tajikistan,
    code: 'TJ',
  },
  {
    name: Country.Tanzania,
    code: 'TZ',
  },
  {
    name: Country.Thailand,
    code: 'TH',
  },
  {
    name: Country.TimorLeste,
    code: 'TL',
  },
  {
    name: Country.Togo,
    code: 'TG',
  },
  {
    name: Country.Tokelau,
    code: 'TK',
  },
  {
    name: Country.Tonga,
    code: 'TO',
  },
  {
    name: Country.TrinidadAndTobago,
    code: 'TT',
  },
  {
    name: Country.Tunisia,
    code: 'TN',
  },
  {
    name: Country.Turkey,
    code: 'TR',
  },
  {
    name: Country.Turkmenistan,
    code: 'TM',
  },
  {
    name: Country.TurksAndCaicosIslands,
    code: 'TC',
  },
  {
    name: Country.Tuvalu,
    code: 'TV',
  },
  {
    name: Country.Uganda,
    code: 'UG',
  },
  {
    name: Country.Ukraine,
    code: 'UA',
  },
  {
    name: Country.UnitedArabEmirates,
    code: 'AE',
  },
  {
    name: Country.UnitedKingdom,
    code: 'GB',
  },
  {
    name: Country.UnitedStates,
    code: 'US',
  },
  {
    name: Country.UnitedStatesMinorOutlyingIslands,
    code: 'UM',
  },
  {
    name: Country.Uruguay,
    code: 'UY',
  },
  {
    name: Country.Uzbekistan,
    code: 'UZ',
  },
  {
    name: Country.Vanuatu,
    code: 'VU',
  },
  {
    name: Country.Vatican,
    code: 'VA',
  },
  {
    name: Country.Venezuela,
    code: 'VE',
  },
  {
    name: Country.Vietnam,
    code: 'VN',
  },
  {
    name: Country.VirginIslands,
    code: 'VI',
  },
  {
    name: Country.WallisAndFutuna,
    code: 'WF',
  },
  {
    name: Country.WestBank,
    code: 'PS',
  },
  {
    name: Country.WesternSahara,
    code: 'EH',
  },
  {
    name: Country.Yemen,
    code: 'YE',
  },
  {
    name: Country.Zambia,
    code: 'ZM',
  },
  {
    name: Country.Zimbabwe,
    code: 'ZW',
  },
];

import { Box, styled } from '@mui/material';
import { AppSelectBox, AppTextInput, FormLabel } from 'components/FormElements';
import { Row } from 'components/Layout';
import Spacing from 'components/Spacing';
import { useBankReconciliationContext } from 'contexts/BankReconciliationContext';
import { Controller, useFormContext } from 'react-hook-form';
import { FormValues } from '..';

const Container = styled(Box)(({ theme }) => ({
  width: '100%',
  border: `1px solid ${theme.palette.borderColor?.dark}`,
  display: 'flex',
  alignItems: 'stretch',
  justifyContent: 'stretch',
  padding: theme.spacing(5),
  height: '146px',

  [theme.breakpoints.down('md')]: {
    height: 'auto',
  },
}));

const CreateTransfer: React.FC = () => {
  const { control } = useFormContext<FormValues>();
  const { selectedBankAccount, bankAccounts } = useBankReconciliationContext();

  const bankAccountOptions = bankAccounts
    .filter((bankAccount) => bankAccount.id !== selectedBankAccount?.id)
    .map((bankAccount) => ({
      label: bankAccount.accountName,
      value: bankAccount.id,
    }));

  return (
    <Container>
      <Row fullWidth spacing="lg" breakpoint="sm">
        <Spacing size="sm" fullWidth>
          <FormLabel title="Select transfer bank account" />
          <Controller
            control={control}
            name="targetBankAccountId"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <AppSelectBox
                data={bankAccountOptions}
                value={value}
                onChange={onChange}
                placeholder="Select bank account"
                valueKey="value"
                variant="outlined"
                labelKey="label"
                fullWidth
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </Spacing>
        <Spacing size="sm" fullWidth>
          <FormLabel title="Enter reference (optional)" />

          <Controller
            control={control}
            name="reference"
            render={({ field }) => <AppTextInput fullWidth {...field} />}
          />
        </Spacing>
      </Row>
    </Container>
  );
};

export default CreateTransfer;

import { Box } from '@mui/material';
import { PropsWithChildren } from 'react';

type Props = {
  direction?: 'column' | 'row';
  size?: 'sm' | 'md' | 'lg';
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline';
  fullWidth?: boolean;
  className?: string;
};

const sizeMap = {
  sm: 3,
  md: 6,
  lg: 8,
};

const Spacing: React.FC<PropsWithChildren<Props>> = ({
  children,
  direction = 'column',
  size = 'md',
  alignItems = 'stretch',
  fullWidth,
  className,
}) => {
  return (
    <Box
      display="flex"
      flexDirection={direction}
      gap={sizeMap[size]}
      alignItems={alignItems}
      width={fullWidth ? '100%' : undefined}
      className={className}
    >
      {children}
    </Box>
  );
};

export default Spacing;

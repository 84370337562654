import React from 'react';
import FieldRow from 'components/FormElements/FieldRow';
import { TextField, Typography } from '@mui/material';
import CkEditor from 'components/CkEditor';
import CompanyCard from '../Common/CompanyCard';
import RecordView from 'components/RecordView';

type Props = any; //TODO: define props

const AddDeploymentUpdateDetails: React.FC<Props> = ({
  errorText,
  classes,
  data,
  setData,
  company,
}) => {
  return (
    <RecordView>
      <FieldRow title="Subject line*">
        <TextField
          required
          name="subject"
          fullWidth
          placeholder="e.g. You have a new company in your portfolio"
          variant="outlined"
          value={data?.subject}
          onChange={(event) =>
            setData({ ...data, subject: event.target.value })
          }
          error={errorText?.subject}
          helperText={errorText?.subject}
        />
      </FieldRow>
      <FieldRow title="Email title*">
        <TextField
          required
          name="title"
          fullWidth
          placeholder="e.g. Meet your new portfolio company: Company A"
          variant="outlined"
          value={data?.title}
          onChange={(event) => setData({ ...data, title: event.target.value })}
          error={errorText?.title}
          helperText={errorText?.title}
        />
      </FieldRow>
      <FieldRow>
        <Typography variant="body2">Dear $investorfirstname, </Typography>
      </FieldRow>
      <FieldRow title="Email top paragraph*">
        <CkEditor
          //@ts-expect-error
          name="emailTopParagraph"
          placeholder={`We're excited to let you know that you have a new company in your portfolio.`}
          value={data?.emailTopParagraph}
          onChange={(value) => {
            setData({
              ...data,
              emailTopParagraph: value,
            });
          }}
          onBlur={(value) => {
            setData({
              ...data,
              emailTopParagraph: value,
            });
          }}
        />
        <p className={classes?.errorCk}>{errorText?.emailTopParagraph}</p>
      </FieldRow>
      <CompanyCard company={company} />
      <FieldRow title="Email bottom paragraph and sign-off*">
        <CkEditor
          //@ts-expect-error
          name="emailBottomParagraph"
          placeholder={`We're excited to let you know that you have a new company in your portfolio.`}
          value={data?.emailBottomParagraph}
          onChange={(value) => {
            setData({
              ...data,
              emailBottomParagraph: value,
            });
          }}
          onBlur={(value) => {
            setData({
              ...data,
              emailBottomParagraph: value,
            });
          }}
        />
        <p className={classes?.errorCk}>{errorText?.emailBottomParagraph}</p>
      </FieldRow>
    </RecordView>
  );
};
export default AddDeploymentUpdateDetails;

export interface Address {
  address1: string;
  address2?: string;
  city: string;
  postcode: string;
  country: string;
}

export const getFormattedAddress = (
  address: Address,
  excludeCountry = false,
) => {
  const { address1, address2, city, postcode, country } = address;

  const addressParts: Array<string> = [];

  if (address1) addressParts.push(address1);
  if (address2) addressParts.push(address2);
  if (city) addressParts.push(city);
  if (postcode) addressParts.push(postcode);
  if (!excludeCountry && country) addressParts.push(country);

  return addressParts.join(', ');
};

import { Control, Controller } from 'react-hook-form';
import { RadioGroup, Typography, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { investment as rules } from 'further-ui/validations';
import RadioWithInput from '../RadioWithInput';
import Grid from '@mui/material/Grid2';
import { FC } from 'react';
import { AppSelectBox } from 'components/FormElements';
import { InfoOutlined } from '@mui/icons-material';
import { AssetSelectionType } from 'further-types/lp/investment';

const SelectionHelperText = styled(Typography)({
  fontSize: '14px',
  lineHeight: '22px',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  color: 'text.secondary',
});

type AssetSelectionProps = {
  isNewAsset: boolean;
  assetOptions: Array<{ id: string; title: string }>;
  control: Control<rules.CreateInvestmentFormValues>;
  resetAssetDropdown: () => void;
  onAssetChange: (name: string) => void;
};

const AssetSelection: FC<AssetSelectionProps> = ({
  isNewAsset,
  assetOptions,
  control,
  resetAssetDropdown,
  onAssetChange,
}) => (
  <Controller
    name="isNewAsset"
    control={control}
    defaultValue={false}
    render={({ field: { value, onChange, ...rest } }) => (
      <RadioGroup
        {...rest}
        value={value ? AssetSelectionType.NEW : AssetSelectionType.EXISTING}
        onChange={(e) => onChange(e.target.value === AssetSelectionType.NEW)}
      >
        <Grid container spacing={{ xs: 6 }}>
          <RadioWithInput
            value={AssetSelectionType.EXISTING}
            label={
              <span
                style={{ display: 'flex', alignItems: 'center', gap: '0.2rem' }}
              >
                Select existing asset
                <Tooltip title="Select an asset that already exists in the system">
                  <InfoOutlined style={{ fontSize: 18, cursor: 'pointer' }} />
                </Tooltip>
              </span>
            }
            inputComponent={
              <Controller
                name="assetId"
                control={control}
                render={({
                  field: { ref, value, ...rest },
                  fieldState: { error },
                }) => (
                  <AppSelectBox
                    {...rest}
                    value={value}
                    onChange={(event) => {
                      rest.onChange(event);
                      const selectedAsset = assetOptions.find(
                        (asset) => asset.id === event.target.value,
                      );
                      onAssetChange(selectedAsset?.title || '');
                    }}
                    disabled={isNewAsset}
                    fullWidth
                    data={assetOptions}
                    placeholder="Select asset"
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            }
          />

          <RadioWithInput
            value={AssetSelectionType.NEW}
            label="Create new asset"
            onRadioClick={() => {
              resetAssetDropdown();
              onAssetChange('');
            }}
            inputComponent={
              !value && (
                <SelectionHelperText>
                  Select if the asset is not yet in the system
                </SelectionHelperText>
              )
            }
          />
        </Grid>
      </RadioGroup>
    )}
  />
);

export default AssetSelection;

import { ReactNode, useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TablePagination from '@mui/material/TablePagination';
import { Box, InputBase, styled } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { makeStyles } from 'tss-react/mui';
import { numberToDisplayString } from 'further-ui/utils';
import TableHeading from './TableHeading';
import TableItem from './TableItem';
import { Column } from './types';
import TableActions from './TableActions';
import { FooterRow, FooterCell } from './TableFooter';

export { TableActions, FooterRow, FooterCell };
export type { Column };

const TableTitle = styled('h4')(({ theme }) => ({
  backgroundColor: theme.palette.background.header,
  padding: '15px 10px 5px 10px',
  color: theme.palette.text.darkTitle,
}));

export const useStyles = makeStyles()((theme) => ({
  searchIcon: {
    [theme.breakpoints.down('sm')]: {
      padding: 9,
    },
  },
  tableBody: {
    position: 'relative',
  },
  fixedColumns: {
    tableLayout: 'fixed',
  },
  searchRoot: {
    position: 'relative',
    width: 260,
    [theme.breakpoints.up('md')]: {
      width: 350,
    },
    '& .MuiSvgIcon-root': {
      position: 'absolute',
      left: 18,
      top: '50%',
      transform: 'translateY(-50%)',
      zIndex: 1,
    },
    '& .MuiInputBase-root': {
      width: '100%',
    },
    '& .MuiInputBase-input': {
      height: 48,
      borderRadius: 30,
      border: '1px solid',
      borderColor: theme.palette.common.dark,
      color: theme.palette.common.dark,
      boxSizing: 'border-box',
      padding: '5px 15px 5px 50px',
      transition: 'all 0.3s ease',
    },
  },
  paginationComman: {
    marginTop: 10,
    marginBottom: 0,
    '& .MuiToolbar-root': {
      display: 'flex',
      alignItems: 'center',
      right: '23px',
      '@media screen and (max-width: 359.98px)': {
        position: 'relative',
      },
    },
  },
  cmTable: {
    '& th': {
      whiteSpace: 'nowrap',
      paddingTop: '10px',
      fontSize: '14px',
      fontWeight: '600',
    },
    '& th:first-of-type': {
      paddingLeft: '10px',
    },
    '& th:last-child': {
      paddingRight: '10px',
    },
    '& td:first-of-type': {
      paddingLeft: '10px',
    },
    '& td:last-child': {
      paddingRight: '10px',
    },
  },
  subduedColumnHeadings: {
    '& th': {
      color: theme.palette.text.darkBodyText,
      fontSize: '12px',
      fontWeight: 'bold',
    },
  },

  // common table footer styling classes
  tFootRow: {
    backgroundColor: '#fbfbfa',

    '&:nth-of-type(2)': {
      borderBottom: '1px solid #ededed',
      borderTop: '1px solid #ededed',

      '& td': {},
    },
  },
  tFootCell: {
    fontWeight: 'bold',
    padding: '8px 8px 8px 6px',

    '&:nth-of-type(1)': {
      textAlign: 'right',
      paddingRight: 24,
    },
  },
}));

type Props<T> = {
  title?: string;
  columns: Array<Column<T>>;
  tablebody: Array<T>;
  onRequestSort?: (event: React.MouseEvent<unknown>, columnKey: string) => void;
  order?: any;
  orderBy?: any;
  onChangeSearch?: () => void;
  search?: string;
  page?: number;
  onPageChange?: (event: unknown, page: number) => void;
  count?: number;
  pagination?: boolean;
  loading?: boolean;
  rowsPerPage?: number;
  onRowsPerPageChange?: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >;
  emptyMessage?: string;
  TableFooter?: ReactNode;
  CustomTableHeading?: ReactNode;
  variant?: string;
  selectable?: boolean;
  onSelectionChange?: (selections: Array<T>) => void;
  hideEmptyMessage?: boolean;
  collapsedContent?: (row: T) => ReactNode;
  maintainExpandedState?: boolean;
  expandedRowIds?: Array<string>;
  getRowId?: (row: T) => string;
  fixedColumns?: boolean;
};

const AppTable = <T extends Record<string, any>>({
  title,
  columns,
  tablebody,
  onRequestSort,
  order,
  orderBy,
  onChangeSearch,
  search,
  page,
  onPageChange,
  count = 0,
  pagination,
  loading,
  rowsPerPage,
  onRowsPerPageChange,
  emptyMessage,
  TableFooter,
  CustomTableHeading,
  variant = 'nohover',
  selectable,
  onSelectionChange,
  hideEmptyMessage = false,
  collapsedContent,
  maintainExpandedState,
  expandedRowIds,
  getRowId,
  fixedColumns,
}: Props<T>) => {
  const [selectedRowsIndexes, setSelectedRowsIndexes] = useState<Array<number>>(
    [],
  );

  const updateSelection = (selectedIndexes) => {
    setSelectedRowsIndexes(selectedIndexes);
    onSelectionChange?.(
      tablebody?.filter((_, index) => selectedIndexes.includes(index)) ?? [],
    );
  };

  const handleSelect = (selectedRowIndex: number) => {
    if (!tablebody.length) return;

    const selectedIndexes = selectedRowsIndexes.includes(selectedRowIndex)
      ? selectedRowsIndexes.filter((index) => index !== selectedRowIndex)
      : [...selectedRowsIndexes, selectedRowIndex];

    updateSelection(selectedIndexes);
  };
  const handleSelectAll = () => {
    if (!tablebody.length) return;

    const selectedIndexes =
      selectedRowsIndexes.length < tablebody.length
        ? [...Array(tablebody.length).keys()]
        : [];

    updateSelection(selectedIndexes);
  };

  useEffect(() => {
    if (!selectable) return;

    updateSelection([]);
  }, [selectable, page, rowsPerPage]);

  const { classes, cx } = useStyles();
  return (
    <div className="Cmt-table-responsive">
      {search ? (
        <Box
          pr={3}
          marginLeft={'auto'}
          marginBottom={3}
          className={classes.searchRoot}
        >
          <InputBase
            placeholder={'Search here...'}
            inputProps={{ 'aria-label': 'search' }}
            onChange={onChangeSearch}
          />
          <SearchIcon />
        </Box>
      ) : (
        ''
      )}
      {title && <TableTitle>{title}</TableTitle>}
      <Table
        className={`${classes.cmTable} ${
          fixedColumns ? classes.fixedColumns : ''
        } ${title ? classes.subduedColumnHeadings : ''}`}
      >
        <TableHead>
          <TableHeading
            columns={columns}
            onRequestSort={onRequestSort}
            order={order}
            orderBy={orderBy}
            selectable={!!selectable}
            selectedCount={selectedRowsIndexes.length}
            totalCount={tablebody.length}
            onSelect={handleSelectAll}
            hasCollapsedContent={!!collapsedContent}
          />
          {CustomTableHeading ? CustomTableHeading : null}
        </TableHead>
        <TableBody className={classes.tableBody}>
          <TableItem
            hideEmptyMessage={hideEmptyMessage}
            columns={columns}
            rows={tablebody}
            loading={loading}
            emptyMessage={emptyMessage}
            variant={variant}
            showSkeleton={tablebody.length === 0 && loading}
            rowsPerPage={rowsPerPage}
            selectable={selectable}
            onSelect={handleSelect}
            selectedRowsIndexes={selectedRowsIndexes}
            collapsedContent={collapsedContent}
            maintainExpandedState={maintainExpandedState}
            expandedRowIds={expandedRowIds}
            getRowId={getRowId}
          />
        </TableBody>
        {TableFooter ? TableFooter : null}
      </Table>
      {pagination ? (
        <div className={cx(classes.paginationComman)}>
          <TablePagination
            color="primary"
            component="div"
            count={count}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} of ${numberToDisplayString(count)}`
            }
            page={page ? page - 1 : 0}
            onPageChange={onPageChange ?? (() => {})}
            rowsPerPage={rowsPerPage ?? 10}
            onRowsPerPageChange={onRowsPerPageChange ?? (() => {})}
          />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default AppTable;

import { Controller } from 'react-hook-form';
import { AppDatePicker, AppSelectBox, FieldRow } from 'components/FormElements';
import { fundingSourceOptions } from './consts';
import { useFormContext } from 'react-hook-form';
import { investment as rules } from 'further-ui/validations';
import { CalendarDay } from 'further-ui/utils';

const BaseInvestmentForm: React.FC = () => {
  const { control } = useFormContext<rules.CreateInvestmentFormValues>();

  return (
    <>
      <FieldRow
        title="Deal completion date"
        tooltipText="Select a target date if the actual deal completion date is unknown at the point of processing. This transaction can be edited after submission to include the actual completion date."
        centerTitle
      >
        <Controller
          name="dealCompletionDate"
          control={control}
          render={({
            fieldState: { error },
            field: { ref, value, onChange, ...rest },
          }) => (
            <AppDatePicker
              error={!!error}
              ref={ref}
              value={value ? value.toUTCDate() : null}
              onChange={(date) => onChange(date ? new CalendarDay(date) : null)}
              placeholder="Select deal completion date"
              helperText={error?.message?.toString()}
              {...rest}
            />
          )}
        />
      </FieldRow>
      <FieldRow
        title="Funding source"
        tooltipText="Select whether this action will be paid through general working capital on account or fresh capital that will be part of a future capital call."
        centerTitle
      >
        <Controller
          name="fundingSource"
          control={control}
          render={({ fieldState: { error }, field: { ref, ...rest } }) => (
            <AppSelectBox
              error={!!error}
              data={fundingSourceOptions}
              placeholder="Select funding source"
              helperText={error?.message?.toString()}
              {...rest}
            />
          )}
        />
      </FieldRow>
    </>
  );
};

export default BaseInvestmentForm;

import { FC } from 'react';
import AlertDialog from 'components/AlertDialog';
import { Disclosure } from 'further-ui/hooks';
import { TransactionCurrencyRateDetails } from 'further-types/lp/transaction';

type Props = {
  disclosure: Disclosure;
  fxRate: TransactionCurrencyRateDetails | null;
  onRateChange: (rate: TransactionCurrencyRateDetails) => void;
};

const CancelCustomRateDialog: FC<Props> = ({
  disclosure,
  onRateChange,
  fxRate,
}) => {
  return (
    <AlertDialog
      open={disclosure.isOpen}
      onClose={disclosure.onClose}
      title="Warning"
      content="Reverting to the live FX feed will delete any manual FX data input for this transaction. This step cannot be undone."
      onConfirm={() => {
        onRateChange({
          ...fxRate,
          overrideRate: undefined,
          overrideSource: undefined,
        });
        disclosure.onClose();
      }}
      btnLabels={{
        confirm: 'Revert to live feed',
        cancel: 'Go back',
      }}
      confirmBtnProps={{
        color: 'error',
      }}
    />
  );
};

export default CancelCustomRateDialog;

import { isMongoId, withSortableParams } from '../../helpers';
import { z } from 'zod';
import { AssetType } from 'further-types/lp/asset';

export const ListAll = withSortableParams(
  z.object({
    assetType: z.nativeEnum(AssetType),
    vehicleId: z.string().refine(isMongoId(), {
      message: 'Invalid vehicle ID',
    }),
  }),
  ['assetType'],
);

import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider } from 'react-hook-form';
import api from 'lib/trpcClient';
import useHandleTrpcMutation from 'hooks/ui/useHandleTrpcMutation';
import { useLayoutContext } from 'contexts/LayoutContext';
import GeneralInformation from './GeneralInformation';
import RecordView from 'components/RecordView';
import PageSection from 'components/PageSection';
import Button from 'components/Button';
import { vehicle as rules } from 'further-ui/validations';
import Spacing from 'components/Spacing';
import ButtonGroup from 'components/ButtonGroup';

const FundSetup: React.FC = () => {
  const mutationOpts = useHandleTrpcMutation();
  const { selectedVehicleId } = useLayoutContext();

  const [vehicleData] = api.vehicle.getById.useSuspenseQuery({
    vehicleId: selectedVehicleId ?? '',
  });

  const updateVehicle = api.vehicle.update.useMutation(
    mutationOpts({
      successMessage: 'Vehicle updated successfully',
      invalidationHandler: (utils) => utils.vehicle.invalidate(),
    }),
  );

  const formMethods = useForm({
    shouldFocusError: true,
    resolver: zodResolver(rules.Update),
    values: vehicleData,
    criteriaMode: 'all',
  });

  const onSubmit = async (data) => {
    updateVehicle.mutate({ ...data, vehicleId: selectedVehicleId ?? '' });
  };

  return (
    <FormProvider {...formMethods}>
      <form>
        <RecordView size="full">
          <PageSection title="General information">
            <Spacing>
              <GeneralInformation
                disableChangeOfCurrency={
                  (vehicleData.transactionsCount ?? 0) > 0
                }
              />
              <ButtonGroup>
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={formMethods.handleSubmit(onSubmit)}
                  loading={updateVehicle.isPending}
                >
                  Save
                </Button>
              </ButtonGroup>
            </Spacing>
          </PageSection>
        </RecordView>
      </form>
    </FormProvider>
  );
};

export default FundSetup;
